import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon, PlayIcon } from '@heroicons/react/20/solid';
import { Button, Loading, Modal } from '@src/components/common';
import { useGetProjectFile } from '@src/hooks';
import { AeMediaPreview } from '@src/models';

export const AudioPreviewer = ({
  projectId,
  value,
  preview,
  onPreviewChange
}: {
  projectId: string;
  value: string;
  preview: AeMediaPreview;
  onPreviewChange: (previewActive: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  const { link, internal } = preview;
  const { isLoading, data: blob, error } = useGetProjectFile(projectId, value, showPreview && internal);

  const loading = internal && isLoading;
  const showError = internal && error;

  const doShow = async () => {
    setShowPreview(true);
    onPreviewChange(true);
  };

  const doHide = () => {
    setShowPreview(false);
    onPreviewChange(false);
  };

  if (!showPreview) {
    return (
      <div>
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            doShow();
          }}
          className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
        >
          <PlayIcon className="mr-1 h-5 w-5" />
          {t('components.project.template.layer.previewer.common.play')}
        </button>
      </div>
    );
  } else {
    return (
      <Modal visible={showPreview} close={doHide}>
        <div>
          {loading && <Loading />}
          {!loading && (
            <>
              {showError && (
                <div>
                  <p className="text-sm text-gray-900">{value}</p>
                  <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                    <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                    {t('components.project.template.layer.previewer.common.errorLoading', { context: 'audio' })}
                  </p>
                </div>
              )}
              {!showError && (
                <div className="w-full">
                  <audio className="w-full rounded" controls autoPlay>
                    <source src={internal && blob ? URL.createObjectURL(blob) : link} />
                  </audio>
                </div>
              )}
            </>
          )}
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              doHide();
            }}
            className="w-full justify-center"
          >
            {t('general.action.close')}
          </Button>
        </div>
      </Modal>
    );
  }
};
