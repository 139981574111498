import { AbstractRenderDto } from '..';

import { App } from './app';

export * from './app';

export enum ColorPaletteGroup {
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  GRAY = 'GRAY',
  BEIGE = 'BEIGE',
  PINK = 'PINK',
  CYAN = 'CYAN',
  ORANGE = 'ORANGE',
  BLUE = 'BLUE',
  NAVY = 'NAVY',
  PURPLE = 'PURPLE',
  RED = 'RED',
  BROWN = 'BROWN',
  BLACK = 'BLACK'
}

export declare type ColorPalette = {
  group: ColorPaletteGroup;
  defaultPalette: boolean;
  primary: string;
  secondary?: string;
  tertiary?: string;
};

export declare type Design = {
  id: string;
  name: string;
  description: string;
  publishedDate: string;
  category: DesignCategory;
  renderUiDisabled: boolean;
  variants: DesignVariant[];
  palettes: ColorPalette[];
  defaultExample: DesignExample;
  parameters: DesignParameter[];
  app?: App;
};

export declare type DesignApp = Design & Required<Pick<Design, 'app'>>;

export enum DesignAspectRatio {
  WIDE = 'WIDE',
  SQUARE = 'SQUARE',
  STORY = 'STORY'
}

export enum DesignCategory {
  ECOMMERCE = 'ECOMMERCE',
  MEDIA = 'MEDIA',
  SLIDESHOW = 'SLIDESHOW',
  SUMMARY = 'SUMMARY',
  TWITTER = 'TWITTER'
}

export declare type DesignVariant = {
  id: string;
  name: string;
  aspectRatio: DesignAspectRatio;
  defaultVariant: boolean;
  examples: Record<ColorPaletteGroup, DesignExample>;
  duration: number;
};

export declare type DesignExample = {
  thumbnailUrl: string;
  videoUrl: string;
};

export enum DesignsFilterType {
  COLOR_PALETTE = 'COLOR_PALETTE',
  ASPECT_RATIO = 'ASPECT_RATIO'
}
export enum DesignParameterType {
  STRING = 'STRING',
  COLOR = 'COLOR',
  MEDIA = 'MEDIA',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  COMPLEX = 'COMPLEX'
}

export declare type DesignParameter = {
  key: string;
  name: string;
  description?: string;
  optional: boolean;
} & {
  [DesignParameterType.STRING]: {
    defaultValue?: string;
    sampleValue?: string;
    type: DesignParameterType.STRING;
  };
  [DesignParameterType.COLOR]: {
    defaultValue?: string;
    sampleValue?: string;
    type: DesignParameterType.COLOR;
  };
  [DesignParameterType.MEDIA]: {
    defaultValue?: string;
    sampleValue?: string;
    type: DesignParameterType.MEDIA;
  };
  [DesignParameterType.NUMBER]: {
    defaultValue?: number;
    sampleValue?: number;
    type: DesignParameterType.NUMBER;
  };
  [DesignParameterType.BOOLEAN]: {
    defaultValue?: boolean;
    sampleValue?: boolean;
    type: DesignParameterType.BOOLEAN;
  };
  [DesignParameterType.COMPLEX]: {
    defaultValue?: never;
    sampleValue?: never;
    type: DesignParameterType.COMPLEX;
  };
}[DesignParameterType];

export type DesignRender = AbstractRenderDto & {
  designId: string;
  variantId?: string;
  colorPalette?: ColorPaletteGroup;
};

export declare type DesignsFilterBase<T extends DesignsFilterType> = {
  type: T;
};

export declare type ColorPaletteFilter = DesignsFilterBase<DesignsFilterType.COLOR_PALETTE> & {
  options: ColorPaletteGroup[];
};

export declare type AspectRatioFilter = DesignsFilterBase<DesignsFilterType.ASPECT_RATIO> & {
  options: DesignAspectRatio[];
};

export declare type DesignsFilter = ColorPaletteFilter | AspectRatioFilter;
