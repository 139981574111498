import { useTranslation } from 'react-i18next';

import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { VoiceoverForm } from '@src/components';
import { AnyVoiceoverProviderSettings, RevisionState } from '@src/models';

export const VoiceoverView = ({
  currentVoiceover,
  setCurrentVoiceover,
  voiceoverUrl,
  state,
  showClonedInfo
}: {
  currentVoiceover: AnyVoiceoverProviderSettings;
  setCurrentVoiceover: (currentVoiceover: AnyVoiceoverProviderSettings) => void;
  voiceoverUrl?: string;
  state: RevisionState;
  showClonedInfo?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="space-y-6 divide-y divide-dashed divide-gray-200">
        {voiceoverUrl && (
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
              {t('components.article.ArticleVideo.voiceoverSettings.voiceoverTitle')}
              <p className="text-sm font-normal text-gray-500">
                {t('components.article.ArticleVideo.voiceoverSettings.voiceoverDescription')}
              </p>
            </label>
            <audio className="mt-4 w-full" controls>
              <source src={voiceoverUrl} />
            </audio>
            {showClonedInfo && (
              <div className="mt-4 flex justify-center">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <p className="ml-2 max-w-full text-sm text-gray-500">
                  {t('components.article.ArticleVideo.voiceoverSettings.voiceoverInfo')}
                </p>
              </div>
            )}
          </div>
        )}
        <VoiceoverForm state={state} currentVoiceover={currentVoiceover} setCurrentVoiceover={setCurrentVoiceover} />
      </div>
    </>
  );
};
