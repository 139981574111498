import { maxWeightAssign } from 'munkres-algorithm';

import { AeItemType } from '@plainly/types';
import { AnyAeItem, CompositionAeItem, Layer, ProjectMetaData } from '@src/models';
import { isEmpty } from '@src/utils';

import { findCompositionsByPath, getRootComposition } from './layerUtils';

const findLayerById = (comp: CompositionAeItem, internalId: string): AnyAeItem | undefined => {
  for (const child of comp.children) {
    if (child.internalId === internalId) {
      return child;
    }

    if (child.type === AeItemType.COMPOSITION) {
      const found = findLayerById(child as CompositionAeItem, internalId);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

// Function to compare two strings word by word
const fuzzyCompare = (value1: string, value2: string): number => {
  const words1 = value1.split(' ');
  const words2 = value2.split(' ');
  const maxScore = 10; // Maximum score for a perfect match

  const minLength = Math.min(words1.length, words2.length);
  let matchCount = 0;

  // Compare word by word
  for (let i = 0; i < minLength; i++) {
    if (words1[i] === words2[i]) {
      matchCount++;
    }
  }

  // Calculate match score
  const matchScore = (matchCount / Math.max(words1.length, words2.length)) * maxScore;

  return matchScore;
};

/**
 * This function calculates the score of similarity of the newLayer and the originalLayer.
 *
 * The score is calculated as follows:
 *  - if the internalId is the same, the score is 100
 *  - if the name and type are the same, the score is 50
 *  - if the value is the same, the score is 10
 *  - if the type is AeItemType.MEDIA, the score is 20
 *
 * The score is negated, so that layers with a higher score are considered more similar.
 *
 * @param oldLayer - the original layer
 * @param newLayer - the new layer
 * @returns {number} the score of similarity
 */
const scoreLayer = (oldLayer: AnyAeItem, newLayer: AnyAeItem): number => {
  if (oldLayer.internalId === newLayer.internalId) {
    return 100; // max score
  }

  let score = 0;
  if (oldLayer.name === newLayer.name && oldLayer.type === newLayer.type) {
    score += 50;

    if (oldLayer.type === AeItemType.MEDIA && newLayer.type === AeItemType.MEDIA) {
      score += 20;
    }
  }

  if (oldLayer.value && newLayer.value) {
    score += fuzzyCompare(oldLayer.value, newLayer.value);
  }

  return score;
};

/**
 * For each originally parametrized layer, this function tries to find a matching layer in the new composition tree.
 * If layer is found, it is scored by the `scoreLayer` scoring function.
 */
const collectMatchingLayerScores = (
  currentParametrizedLayers: Partial<Layer>[],
  currentComp: CompositionAeItem,
  newComp: CompositionAeItem,
  currentCompName: string,
  newCompositionName: string
): Record<string, { foundLayer: Partial<Layer>; score: number }[]> => {
  const scoredLayers: Record<string, { foundLayer: Partial<Layer>; score: number }[]> = {};

  for (const layer of currentParametrizedLayers) {
    if (!layer.internalId) {
      continue;
    }

    // Get the parametrized layer from meta
    const currentMetaLayer = findLayerById(currentComp, layer.internalId);
    if (!currentMetaLayer) {
      continue;
    }

    // Iterate over each composition and check if it exists in a new composition tree
    for (const comp of layer.compositions || []) {
      const path = comp.name
        .split('->')
        .filter(s => s.trim().length > 0)
        .slice(1)
        .join('->');
      const matchedComps = findCompositionsByPath(newComp, path);

      if (isEmpty(matchedComps)) {
        // Couldn't match the layer - add it directly
        scoredLayers[layer.internalId] = [];
      } else {
        // Find and score each of the matching layer
        for (const matchedComp of matchedComps) {
          // It should match by ID or NAME+TYPE+MEDIA_TYPE+NOT_IN_ALREADY_PARAMETRIZED
          const matchedLayerScores = matchedComp.children.map(cl => ({
            foundLayer: {
              ...layer,
              internalId: cl.internalId,
              compositions: layer.compositions?.map(c => {
                return {
                  ...c,
                  id: matchedComp.id,
                  name: c.name.replace(currentCompName, newCompositionName),
                  layers: c.layers ? [...c.layers, cl] : [cl]
                };
              })
            } as Partial<Layer>,
            score: scoreLayer(currentMetaLayer, cl)
          }));

          scoredLayers[layer.internalId] = matchedLayerScores;
        }
      }
    }
  }

  return scoredLayers;
};

/**
 * This function returns a list of new parametrized layers based on the scores of the old layers.
 */
const getNewParametrizedLayers = (
  oldToNewScoredParametrizedLayers: Record<string, { foundLayer: Partial<Layer>; score: number }[]>,
  currentParametrizedLayers: Partial<Layer>[]
): Partial<Layer>[] => {
  // 1. Extract old layer IDs
  const oldLayerIds = Object.keys(oldToNewScoredParametrizedLayers);

  // 2. Extract unique new layer IDs
  const newLayerIdSet = new Set<string>();
  for (const scoredLayers of Object.values(oldToNewScoredParametrizedLayers)) {
    for (const { foundLayer } of scoredLayers) {
      if (foundLayer.internalId) {
        newLayerIdSet.add(foundLayer.internalId);
      }
    }
  }
  const newLayerIds = Array.from(newLayerIdSet);

  // 3. Build the score matrix
  const numOldLayers = oldLayerIds.length;
  const numNewLayers = newLayerIds.length;
  const scoreMatrix: number[][] = Array.from(
    { length: numOldLayers },
    () => Array(numNewLayers).fill(-Infinity) // Use -Infinity for maximization
  );

  // Populate the score matrix
  for (let i = 0; i < numOldLayers; i++) {
    const oldLayerId = oldLayerIds[i];
    const scoredLayers = oldToNewScoredParametrizedLayers[oldLayerId];

    for (const { foundLayer, score } of scoredLayers) {
      const j = newLayerIds.indexOf(foundLayer.internalId || '');
      if (j !== -1) {
        scoreMatrix[i][j] = score;
      }
    }
  }

  // 4. Apply the Hungarian Algorithm
  const indices = maxWeightAssign(scoreMatrix).assignments.map((assignment, i) => [i, assignment] as [number, number]);

  // 5. Map the assignments back to layer IDs
  const assignments: Record<string, string | undefined> = {};
  for (const [row, col] of indices) {
    const oldLayerId = oldLayerIds[row];
    if (col < newLayerIds.length && scoreMatrix[row][col] !== -Infinity) {
      const newLayerId = newLayerIds[col];
      assignments[oldLayerId] = newLayerId;
    } else {
      assignments[oldLayerId] = undefined;
    }
  }

  // 6. Create the new parametrized layers
  const newParametrizedLayers: Record<string, Partial<Layer>> = {};

  for (const layer of currentParametrizedLayers) {
    if (!layer.internalId) {
      continue;
    }

    const matchingLayerId = assignments[layer.internalId];
    if (matchingLayerId) {
      const scoredLayers = oldToNewScoredParametrizedLayers[layer.internalId];
      const newLayer = scoredLayers.find(sl => sl.foundLayer.internalId === matchingLayerId)?.foundLayer;
      if (newLayer) {
        newParametrizedLayers[matchingLayerId] = newLayer;
      }
    } else {
      newParametrizedLayers[layer.internalId] = layer;
    }
  }

  return Object.values(newParametrizedLayers);
};

export const relinkParametrizedLayers = (
  currentParametrizedLayers: Partial<Layer>[],
  currentCompName: string,
  newCompositionName: string,
  projectMetadata: ProjectMetaData
): Partial<Layer>[] => {
  const currentComp = getRootComposition(projectMetadata, currentCompName);
  const newComp = getRootComposition(projectMetadata, newCompositionName);
  if (!currentComp || !newComp) {
    return [];
  }

  const oldToNewScoredParametrizedLayers = collectMatchingLayerScores(
    currentParametrizedLayers,
    currentComp,
    newComp,
    currentCompName,
    newCompositionName
  );

  return getNewParametrizedLayers(oldToNewScoredParametrizedLayers, currentParametrizedLayers);
};
