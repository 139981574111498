import { useTranslation } from 'react-i18next';

import { tryFormatJSON } from '@src/utils';

import { SideInfoHeader } from './SideInfoHeader';

export const RenderIntegrationsInfo = ({
  integrations
}: {
  integrations: {
    skipAll?: boolean;
    passthrough?: string;
  };
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <SideInfoHeader title={t('general.common.integrations')} />
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8">
          <div>
            <dt className="text-sm font-medium text-gray-500">{t('components.render.common.passthrough')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {integrations.passthrough ? (
                <p className="mt-2 w-full overflow-auto whitespace-pre rounded-md border border-gray-300 p-2 font-mono text-xs">
                  {tryFormatJSON(integrations.passthrough)}
                </p>
              ) : (
                t('components.render.common.noPassthrough')
              )}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">{t('components.render.common.integrationsSkipAll')}</dt>
            <dd className="mt-1 break-all text-sm text-gray-900">
              {integrations.skipAll ? t('general.common.yes') : t('general.common.no')}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
