import classNames from 'classnames';
import { TFunction, useTranslation } from 'react-i18next';

import { List, Loading, Table } from '@src/components';
import localizationHelper from '@src/i18n';
import { DailyUsage } from '@src/models';
import { formatResourceUsage, toMb } from '@src/utils';

export type DailyUsageTableProps = {
  dailyUsages?: DailyUsage[];
  loading: boolean;
  pageSize?: number;
  onLoadMore?: () => void;
  resourceType: string | undefined;
};

export const DailyUsageTable = ({ dailyUsages, loading, pageSize, onLoadMore, resourceType }: DailyUsageTableProps) => {
  const { t } = useTranslation();
  return (
    <>
      {/* DESKTOP */}
      <div className="hidden sm:block">
        <div className="mt-2 flex flex-col">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <Table
              data={dailyUsages}
              isLoading={loading}
              loadingTitle={t('components.usage.common.loading')}
              emptyTitle={t('components.usage.UsageDetails.empty')}
              headerRow={<DailyUsageTableHeaderRow t={t} />}
              renderItem={(model: DailyUsage) => (
                <DailyUsageTableRow key={Math.random()} dailyUsage={model} t={t} resourceType={resourceType} />
              )}
              pageSize={pageSize}
              onLoadMore={onLoadMore}
            />
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="sm:hidden">
        {loading && <Loading className="flex-1" title={t('components.usage.common.loading')} />}
        {!loading && (
          <>
            {dailyUsages?.length && (
              <List
                emptyListMessage={t('components.usage.UsageDetails.empty')}
                data={dailyUsages}
                renderItem={(model: DailyUsage) => (
                  <DailyUsageListItem key={Math.random()} dailyUsageItem={model} t={t} resourceType={resourceType} />
                )}
                pageSize={pageSize}
                onLoadMore={onLoadMore}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const DailyUsageTableHeaderRow = ({ t }: { t: TFunction }) => (
  <tr>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('components.usage.DailyUsageTable.date')}
    </th>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('components.usage.DailyUsageTable.changeType')}
    </th>
    <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
      {t('components.usage.DailyUsageTable.total')}
    </th>
    <th className="hidden bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:table-cell">
      {t('components.usage.DailyUsageTable.count')}
    </th>
  </tr>
);

export type UsageProps = {
  dailyUsage: DailyUsage;
  resourceType: string | undefined;
  t: TFunction;
};

const DailyUsageTableRow = ({ dailyUsage, resourceType, t }: UsageProps) => {
  return (
    <tr className={classNames('bg-white px-4 py-4 hover:bg-gray-50')}>
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
        {localizationHelper.forDate().formatDateStringLocally(dailyUsage.date)}
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
        {t('components.usage.DailyUsageTable.type', { context: dailyUsage.changeType || resourceType })}
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
        {t('components.usage.common.resourceUsage', {
          context: resourceType,
          count: Math.round(dailyUsage.total),
          decimal: formatResourceUsage(dailyUsage.total),
          minutes: formatResourceUsage(dailyUsage.total / 60),
          megabytes: formatResourceUsage(toMb(dailyUsage.total))
        })}
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{dailyUsage.count || '-'}</td>
    </tr>
  );
};

export type DailyUsageListItemProps = {
  dailyUsageItem: DailyUsage;
  resourceType: string | undefined;
  t: TFunction;
};

const DailyUsageListItem = ({ dailyUsageItem, t, resourceType }: DailyUsageListItemProps) => {
  return (
    <li className="p-3">
      <span className="flex items-center space-x-4">
        <span className="flex flex-1 space-x-2 truncate">
          <span className="flex w-full flex-col truncate text-sm text-gray-500">
            <span className="flex w-full justify-between">
              <span className="flex truncate text-gray-800">
                {' '}
                {t('components.usage.DailyUsageTable.type', { context: dailyUsageItem.changeType || resourceType })}
              </span>
              <span className="flex truncate text-gray-800">
                {' '}
                {localizationHelper.forDate().formatDateStringLocally(dailyUsageItem.date)}{' '}
              </span>
            </span>
            <span>
              {t('components.usage.common.resourceUsage', {
                context: resourceType,
                count: dailyUsageItem.count ? Math.round(dailyUsageItem.count) : 0,
                decimal: formatResourceUsage(dailyUsageItem.total),
                minutes: formatResourceUsage(dailyUsageItem.total / 60),
                megabytes: formatResourceUsage(toMb(dailyUsageItem.total))
              })}
            </span>
          </span>
        </span>
      </span>
    </li>
  );
};
