import { useTranslation } from 'react-i18next';

import { useQueryParams } from '@src/hooks';
import { RENDER_FORM } from '@src/routes';

import { EmptyState } from '../common';

type EmptyRendersProps = {
  projectId?: string;
  templateId?: string;
};

export const EmptyRenders = ({ projectId, templateId }: EmptyRendersProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();
  const route = withQueryParams(RENDER_FORM, { projectId, templateId });

  return (
    <EmptyState
      title={t('components.render.EmptyRenders.title')}
      subtitle={t('components.render.EmptyRenders.description')}
      route={route}
      buttonText={t('components.common.action.newRender')}
    />
  );
};
