import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  OrganizationCard,
  OrganizationSettingsHeader,
  OrganizationSettingsNavigation,
  ScrollingPage
} from '@src/components';

export const OrganizationSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.OrganizationSettingsPage')}</title>
        </Helmet>
        <OrganizationSettingsHeader />
        <OrganizationSettingsNavigation tab="general" />
        <OrganizationCard />
      </ScrollingPage>
    </HelmetProvider>
  );
};
