import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@src/components';

export type ActionConfirmationProps = {
  loading: boolean;
  title: string;
  text: string;
  actionLabel: string;
  confirmMatch: string;
  onConfirm: () => void;
  disabled?: boolean;
  danger?: boolean;
};

export const ActionConfirmation = ({
  loading,
  title,
  text,
  actionLabel,
  confirmMatch,
  onConfirm,
  disabled,
  danger
}: ActionConfirmationProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => setConfirmationInput(undefined), [showConfirmation]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        {!showConfirmation && (
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>{text}</p>
              </div>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
              <Button secondary={!danger} danger={danger} onClick={() => setShowConfirmation(true)} disabled={disabled}>
                {actionLabel}
              </Button>
            </div>
          </div>
        )}
        {showConfirmation && (
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('components.common.action.ActionConfirmation.confirmTitle')}
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  <Trans
                    i18nKey={'components.common.action.ActionConfirmation.confirmText'}
                    tOptions={{ phrase: confirmMatch }}
                  >
                    Please type <span className="font-medium">{confirmMatch}</span> in order to confirm.
                  </Trans>
                </p>
              </div>
              <form
                className="mt-2 flex flex-col items-end space-y-2 md:flex-row"
                onSubmit={e => {
                  e.preventDefault();
                  onConfirm();
                }}
              >
                <input
                  autoFocus
                  onChange={e => setConfirmationInput(e.target.value)}
                  type="text"
                  name="confirm"
                  id="confirm"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
                <div className="flex md:ml-2">
                  <Button
                    type="submit"
                    danger={danger}
                    loading={loading}
                    disabled={confirmationInput !== confirmMatch || loading}
                  >
                    {t('general.action.confirm')}
                  </Button>
                  <Button secondary onClick={() => setShowConfirmation(false)} className="ml-2">
                    {t('general.action.cancel')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
