import { SceneForm } from '@src/components';
import { ArticleVideoScene, RevisionState } from '@src/models';

export const SceneView = ({
  currentScene,
  setCurrentScene,
  state
}: {
  currentScene: ArticleVideoScene;
  setCurrentScene: (currentScene: ArticleVideoScene) => void;
  state: RevisionState;
}) => {
  return (
    <div className="space-y-6">
      <SceneForm
        disabled={state !== RevisionState.DRAFT && state !== RevisionState.ERROR}
        currentScene={currentScene}
        setCurrentScene={setCurrentScene}
      />
    </div>
  );
};
