import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AnyVideoRevision, RevisionState } from '@src/models';

export const RevisionStateBadge = ({ revision }: { revision: AnyVideoRevision }) => {
  const { t } = useTranslation();

  return (
    <span
      title={revision?.state === RevisionState.ERROR ? revision.message : undefined}
      className={classNames(
        'ml-auto flex items-center rounded-md px-1.5 py-0.5 text-xs ring-1 ring-inset',
        revision?.state === RevisionState.FINAL && 'bg-green-50 text-green-700 ring-green-600/20',
        revision?.state === RevisionState.DRAFT && ' bg-gray-50 text-gray-600 ring-gray-500/10',
        revision?.state === RevisionState.PREVIEW && 'bg-blue-50 text-blue-700 ring-blue-700/10',
        revision?.state === RevisionState.ERROR && 'cursor-help bg-red-50 text-red-700 ring-red-600/10',
        revision?.state === RevisionState.PROCESSING &&
          'animate-pulse-tailwind bg-yellow-50 text-yellow-800 ring-yellow-600/20'
      )}
    >
      {t('components.article.ArticleVideo.state', {
        context: revision.state
      })}
    </span>
  );
};
