import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MediaAutoScaleTransform, ScriptType } from '@plainly/types';
import { Checkbox } from '@src/components/common';
import { MediaAutoScaleActionScript } from '@src/models';

type MediaAutoScaleScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: MediaAutoScaleActionScript | undefined) => void;
  editScript?: MediaAutoScaleActionScript;
};

export const MediaAutoScaleScriptingForm = ({
  setFormValid,
  setFormScript,
  editScript
}: MediaAutoScaleScriptingFormProps) => {
  const { t } = useTranslation();
  const [fill, setFill] = useState<boolean>(editScript?.fill ?? true);
  const [fixedRatio, setFixedRatio] = useState<boolean>(editScript?.fixedRatio ?? true);
  const [transform, setTransform] = useState<MediaAutoScaleTransform | undefined>(editScript?.transform ?? undefined);

  const transformSizeValid = transform?.size?.sizeX !== 0 || transform?.size?.sizeY !== 0;
  const valid = fill || fixedRatio || transformSizeValid;
  const positionNotZero = transform?.position?.posX !== 0 && transform?.position?.posY !== 0;

  useEffect(() => {
    setFormValid(valid);

    valid &&
      setFormScript({
        scriptType: ScriptType.MEDIA_AUTO_SCALE,
        fill: fill,
        fixedRatio: fixedRatio,
        transform: {
          size: transformSizeValid ? transform?.size : undefined,
          position: positionNotZero ? transform?.position : undefined
        }
      });

    !valid && setFormScript(undefined);
  }, [
    valid,
    fill,
    fixedRatio,
    transform?.size,
    transform?.position,
    setFormValid,
    setFormScript,
    transformSizeValid,
    positionNotZero
  ]);

  const disableFillAndFixedRatio = transform?.size && (transform.size.sizeX !== 0 || transform.size.sizeY !== 0);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset className="space-y-5">
        <Checkbox
          id={'fill'}
          checked={fill === true}
          onChange={e => setFill(e.target.checked)}
          label={t('components.project.template.layer.scripts.common.fill')}
          description={t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.fillDescription')}
          disabled={disableFillAndFixedRatio}
        />
        <Checkbox
          id={'fixedRatio'}
          checked={fixedRatio === true}
          onChange={e => setFixedRatio(e.target.checked)}
          label={t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.fixedRatio')}
          description={t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.fixedRatioDescription')}
          disabled={disableFillAndFixedRatio}
          className={'mb-2'}
        />

        <div>
          <label htmlFor="transform.scale" className="block text-sm font-medium text-gray-700">
            {t('general.common.size')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.sizeDescription')}
            </p>
          </label>

          <div className="mt-1 flex w-full gap-5">
            <div
              className={classNames(
                'flex flex-1 rounded-md shadow-sm',
                (fill || fixedRatio) && 'cursor-not-allowed opacity-50'
              )}
            >
              <span className="inline-flex w-10 items-center justify-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.px')}
              </span>
              <input
                type="number"
                name="transform.size.sizeX"
                id="transform.size.sizeX"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={e => {
                  if (!isNaN(parseInt(e.target.value)) && e.target.value !== '0') {
                    setTransform({
                      ...transform,
                      size: { sizeX: parseInt(e.target.value), sizeY: transform?.size?.sizeY || 0 }
                    });
                  } else {
                    setTransform({
                      ...transform,
                      size: { sizeX: 0, sizeY: transform?.size?.sizeY || 0 }
                    });
                  }
                }}
                defaultValue={transform?.size?.sizeX}
                disabled={fixedRatio || fill}
              />
            </div>

            <div
              className={classNames(
                'flex flex-1 rounded-md shadow-sm',
                (fill || fixedRatio) && 'cursor-not-allowed opacity-50'
              )}
            >
              <span className="inline-flex w-10 items-center justify-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.px')}
              </span>
              <input
                type="number"
                name="transform.size.sizeY"
                id="transform.size.sizeY"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={e => {
                  if (!isNaN(parseInt(e.target.value)) && e.target.value !== '0') {
                    setTransform({
                      ...transform,
                      size: { sizeX: transform?.size?.sizeX || 0, sizeY: parseInt(e.target.value) }
                    });
                  } else {
                    setTransform({
                      ...transform,
                      size: { sizeX: transform?.size?.sizeX || 0, sizeY: 0 }
                    });
                  }
                }}
                defaultValue={transform?.size?.sizeY}
                disabled={fixedRatio || fill}
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="transform.position" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.position')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.positionDescription')}
            </p>
          </label>

          <div className="mt-1 flex w-full gap-5">
            <div className="flex flex-1 rounded-md shadow-sm">
              <span className="inline-flex w-10 items-center justify-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.positionX')}
              </span>
              <input
                type="number"
                name="transform.position.x"
                id="transform.position.x"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={e => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setTransform({
                      ...transform,
                      position: { posX: parseInt(e.target.value), posY: transform?.position?.posY || 0 }
                    });
                  } else {
                    setTransform({
                      ...transform,
                      position: { posX: 0, posY: transform?.position?.posY || 0 }
                    });
                  }
                }}
                defaultValue={transform?.position?.posX}
              />
            </div>

            <div className="flex flex-1 rounded-md shadow-sm">
              <span className="inline-flex w-10 items-center justify-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                {t('components.project.template.layer.scripts.MediaAutoScaleScriptingForm.positionY')}
              </span>
              <input
                type="number"
                name="transform.position.y"
                id="transform.position.y"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={e => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setTransform({
                      ...transform,
                      position: { posX: transform?.position?.posX || 0, posY: parseInt(e.target.value) }
                    });
                  } else {
                    setTransform({
                      ...transform,
                      position: { posX: transform?.position?.posX || 0, posY: 0 }
                    });
                  }
                }}
                defaultValue={transform?.position?.posY}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};
