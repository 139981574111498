import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AeItemType, ScriptType } from '@plainly/types';
import { LayerCombobox } from '@src/components/project/template/layer/scripts';
import { CompositionAeItem, CompositionIdentifier, SceneManagementScript } from '@src/models';
import { isEmpty } from '@src/utils';

import { AeItemWithCompositions } from '../../TemplateLayersForm';

type SceneManagementScriptFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: SceneManagementScript | undefined) => void;
  editScript?: SceneManagementScript;
  item: AeItemWithCompositions;
};

export const SceneManagementForm = ({
  setFormValid,
  setFormScript,
  editScript,
  item
}: SceneManagementScriptFormProps) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<SceneManagementScript>({
    scriptType: ScriptType.SCENE_MANAGEMENT,
    maxScenes: editScript?.maxScenes || 10,
    maxHeadlines: editScript?.maxHeadlines || 2,
    sceneShiftOverlap: editScript?.sceneShiftOverlap || 0,
    sceneCompositionPrefix: editScript?.sceneCompositionPrefix || 'Scene',
    mediaCompositionPrefix: editScript?.mediaCompositionPrefix || 'Media',
    headlineCompositionPrefix: editScript?.headlineCompositionPrefix || 'Headline',
    mainAssetLayerName: editScript?.mainAssetLayerName || '',
    sceneAssetLayerName: editScript?.sceneAssetLayerName || '',
    headingLayerName: editScript?.headingLayerName || '',
    subHeadingLayerName: editScript?.subHeadingLayerName || ''
  });

  const valid =
    inputs.mainAssetLayerName !== '' &&
    inputs.sceneAssetLayerName !== '' &&
    inputs.sceneCompositionPrefix !== '' &&
    inputs.mediaCompositionPrefix !== '' &&
    inputs.headlineCompositionPrefix !== '' &&
    inputs.headingLayerName !== '';

  useEffect(() => {
    setFormValid(valid);
    if (valid) {
      setFormScript(inputs);
    } else {
      setFormScript(undefined);
    }
  }, [valid, inputs, setFormValid, setFormScript]);

  const layer = item.layer as CompositionAeItem;
  const compositions: CompositionIdentifier[] = [
    {
      id: layer.id,
      name: layer.name,
      layers: layer.children
    }
  ];

  const getInnerLayers = (prefix: string, compositions: CompositionIdentifier[]) => {
    const sceneComposition = compositions
      .flatMap(c => c.layers)
      .find(
        l => prefix.trim() && l?.name.startsWith(prefix) && l?.type === AeItemType.COMPOSITION
      ) as CompositionAeItem;

    return sceneComposition
      ? [
          {
            id: sceneComposition.id,
            name: sceneComposition.name,
            layers: sceneComposition.children
          }
        ]
      : [];
  };

  const sceneLayers = getInnerLayers(inputs.sceneCompositionPrefix, compositions);
  const assetLayers = getInnerLayers(inputs.mediaCompositionPrefix, sceneLayers);
  const headlineLayers = getInnerLayers(inputs.headlineCompositionPrefix, sceneLayers);

  return (
    <fieldset className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <LayerCombobox
        compositions={compositions}
        label={t('components.project.template.layer.scripts.SceneManagementScriptingForm.mainAssetLayer')}
        description={t('components.project.template.layer.scripts.SceneManagementScriptingForm.mainAssetLayerDesc')}
        input={inputs.mainAssetLayerName}
        onChange={e => setInputs({ ...inputs, mainAssetLayerName: e })}
        className="mb-0"
      />
      <span className="inline-flex w-full items-center justify-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
        {t('components.common.scenesTitle')}
      </span>
      <div>
        <label htmlFor="sceneCompositionPrefix" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneCompositionPrefix')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneCompositionPrefixDesc')}
          </p>
        </label>
        <input
          id="sceneCompositionPrefix"
          name="sceneCompositionPrefix"
          type="text"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setInputs({ ...inputs, sceneCompositionPrefix: e.target.value.trim() })}
          defaultValue={inputs.sceneCompositionPrefix}
        />
      </div>
      <div>
        <label htmlFor="maxScenes" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.maxScenes')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.maxScenesDesc')}
          </p>
        </label>
        <input
          id="maxScenes"
          name="maxScenes"
          type="number"
          inputMode="numeric"
          step={1}
          min={1}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => {
            const value = parseInt(e.target.value);
            value > 0 ? setInputs({ ...inputs, maxScenes: value }) : setInputs({ ...inputs, maxScenes: undefined });
          }}
          defaultValue={inputs.maxScenes}
        />
      </div>
      <div>
        <label htmlFor="sceneShiftOverlap" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneShiftOverlap')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneShiftOverlapDesc')}
          </p>
        </label>
        <input
          id="sceneShiftOverlap"
          name="sceneShiftOverlap"
          type="number"
          inputMode="numeric"
          step={1}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => {
            const value = parseInt(e.target.value);
            value > 0
              ? setInputs({ ...inputs, sceneShiftOverlap: value })
              : setInputs({ ...inputs, sceneShiftOverlap: undefined });
          }}
          defaultValue={inputs.sceneShiftOverlap}
        />
      </div>
      <div>
        <label htmlFor="mediaCompositionPrefix" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.mediaCompositionPrefix')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.mediaCompositionPrefixDesc')}
          </p>
        </label>
        <input
          id="mediaCompositionPrefix"
          name="mediaCompositionPrefix"
          type="text"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setInputs({ ...inputs, mediaCompositionPrefix: e.target.value.trim() })}
          defaultValue={inputs.mediaCompositionPrefix}
        />
      </div>
      <LayerCombobox
        compositions={assetLayers}
        label={t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneAssetLayer')}
        description={t('components.project.template.layer.scripts.SceneManagementScriptingForm.sceneAssetLayerDesc')}
        onChange={e => setInputs({ ...inputs, sceneAssetLayerName: e })}
        input={!isEmpty(assetLayers) ? inputs.sceneAssetLayerName : ''}
        className="mb-0"
      />
      <span className="inline-flex w-full items-center justify-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
        {t('components.common.headlinesTitle')}
      </span>
      <div>
        <label htmlFor="headlineCompositionPrefix" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.headlineCompositionPrefix')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.headlineCompositionPrefixDesc')}
          </p>
        </label>
        <input
          id="headlineCompositionPrefix"
          name="headlineCompositionPrefix"
          type="text"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => setInputs({ ...inputs, headlineCompositionPrefix: e.target.value.trim() })}
          defaultValue={inputs.headlineCompositionPrefix}
        />
      </div>
      <div>
        <label htmlFor="maxHeadlines" className="block text-sm font-medium text-gray-700">
          {t('components.project.template.layer.scripts.SceneManagementScriptingForm.maxHeadlines')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.project.template.layer.scripts.SceneManagementScriptingForm.maxHeadlinesDesc')}
          </p>
        </label>
        <input
          id="maxHeadlines"
          name="maxHeadlines"
          type="number"
          inputMode="numeric"
          step={1}
          min={1}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={e => {
            const value = parseInt(e.target.value);
            value > 0
              ? setInputs({ ...inputs, maxHeadlines: value })
              : setInputs({ ...inputs, maxHeadlines: undefined });
          }}
          defaultValue={inputs.maxHeadlines}
        />
      </div>
      <LayerCombobox
        compositions={headlineLayers}
        label={t('components.project.template.layer.scripts.SceneManagementScriptingForm.headingName')}
        description={t('components.project.template.layer.scripts.SceneManagementScriptingForm.headingNameDesc')}
        onChange={e => setInputs({ ...inputs, headingLayerName: e })}
        input={!isEmpty(headlineLayers) ? inputs.headingLayerName : ''}
        className="mb-0"
      />
      <LayerCombobox
        compositions={headlineLayers}
        label={t('components.project.template.layer.scripts.SceneManagementScriptingForm.subHeadingName')}
        description={t('components.project.template.layer.scripts.SceneManagementScriptingForm.subHeadingNameDesc')}
        onChange={e => setInputs({ ...inputs, subHeadingLayerName: e })}
        input={!isEmpty(headlineLayers) ? inputs.subHeadingLayerName : ''}
        className="mb-0"
      />
    </fieldset>
  );
};

export default SceneManagementForm;
