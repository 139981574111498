import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Button } from '@src/components/common';
import { useCreateWebArticle, useQueryParams } from '@src/hooks';
import { ArticleInputType, Brand, CreateWebArticleDto } from '@src/models';
import * as routes from '@src/routes';
import { isEmpty, isValidUrl } from '@src/utils';

import { mockArticles } from './utils';

export type ArticleCreateFormProps = {
  brand: Brand;
};

export const ArticleCreateForm = ({ brand }: ArticleCreateFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { searchQuery } = useQueryParams();

  const test = searchQuery.get('test') || false;
  const testArticleIndex = Math.floor(Math.random() * 3);

  const [inputs, setInputs] = useState<CreateWebArticleDto>({
    type: ArticleInputType.WEB_ARTICLE,
    articleUrl: test ? mockArticles[testArticleIndex].articleUrl : '',
    body: test ? mockArticles[testArticleIndex].body : ''
  });

  const { isLoading, mutateAsync: createArticle } = useCreateWebArticle();

  const isValidForm = isValidUrl(inputs.articleUrl) && inputs.body !== '';
  const hasEnabledVideoTypes = !isEmpty(brand.settings.videoTypes.filter(vt => vt.enabled));
  const canFireCreate = isValidForm && !isLoading && hasEnabledVideoTypes;

  const fireCreate = async () => {
    let newArticle = null;

    newArticle = await createArticle({
      brandId: brand.id,
      article: inputs
    });

    if (newArticle) navigate(generatePath(routes.ARTICLE_DETAILS, { brandId: brand.id, articleId: newArticle.id }));
  };

  return (
    <form
      className="space-y-6"
      onSubmit={e => {
        e.preventDefault();
        fireCreate();
      }}
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="flex flex-col flex-nowrap md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.publishers.common.createArticle')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{t('components.article.ArticleCreateForm.description')}</p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="space-y-6">
              <div>
                <div>
                  <label htmlFor="brandName" className="block text-sm font-medium text-gray-700">
                    {t('components.article.ArticleCreateForm.brandNameLabel')}
                  </label>
                  <input
                    type="text"
                    name="brandName"
                    id="brandName"
                    disabled
                    className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm sm:text-sm"
                    defaultValue={brand.settings.name}
                  />
                  {!hasEnabledVideoTypes && (
                    <p className="mt-1 flex items-center text-xs text-yellow-900 md:mt-2">
                      <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400" />
                      {t('components.article.ArticleCreateForm.missingVideoTypes')}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="articleUrl" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.article.ArticleCreateForm.articleUrlLabel')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.article.ArticleCreateForm.articleUrlDesc')}</p>
                  <input
                    type="url"
                    name="articleUrl"
                    id="articleUrl"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, articleUrl: e.target.value })}
                    defaultValue={inputs.articleUrl}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="body" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.article.ArticleCreateForm.articleBodyLabel')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.article.ArticleCreateForm.articleBodyDesc')}</p>
                  <textarea
                    minLength={250}
                    id="body"
                    name="body"
                    rows={12}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, body: e.target.value })}
                    defaultValue={inputs.body}
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {navigate.length > 0 && (
          <Button secondary={true} onClick={() => navigate(-1)}>
            {t('general.action.back')}
          </Button>
        )}
        <Button className="ml-3" type="submit" loading={isLoading} disabled={!canFireCreate}>
          {t('general.action.create')}
        </Button>
      </div>
    </form>
  );
};
