import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ActionConfirmation } from '@src/components';
import { useDeleteProjectTemplate } from '@src/hooks';
import * as routes from '@src/routes';

export const TemplateDeleteAction = ({
  projectId,
  templateId,
  templateName
}: {
  projectId: string;
  templateId: string;
  templateName: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading, mutateAsync: deleteTemplate } = useDeleteProjectTemplate();

  const doDelete = () => {
    deleteTemplate({ projectId, templateId }).then(() =>
      navigate(generatePath(routes.PROJECT_DETAILS, { id: projectId }))
    );
  };

  return (
    <ActionConfirmation
      loading={isLoading}
      title={t('components.project.template.TemplateDeleteAction.title')}
      text={t('components.project.template.TemplateDeleteAction.text')}
      actionLabel={t('components.project.template.TemplateDeleteAction.action')}
      confirmMatch={templateName}
      onConfirm={doDelete}
      danger
    />
  );
};
