import classNames from 'classnames';

type FullPageProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const FullPage = ({ children, className, ...rest }: FullPageProps) => {
  return (
    <div {...rest} className={classNames('flex min-h-full', className)}>
      {children}
    </div>
  );
};
