import { ProjectRenderDto } from '@src/models';

import { CodeModal } from '../common';

type RenderBatchParametrizationModalProps = {
  showModal: boolean;
  onClose: () => void;
  render?: ProjectRenderDto;
};

export const RenderBatchParametrizationModal = ({
  showModal,
  onClose,
  render
}: RenderBatchParametrizationModalProps) => {
  const content = JSON.stringify(render, undefined, 2);

  return <CodeModal showModal={showModal} onClose={onClose} content={content} size="md" />;
};
