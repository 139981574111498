import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { Checkbox } from '@src/components/common';
import { TimeActionCropScript } from '@src/models';

type CropScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: TimeActionCropScript | undefined) => void;
  editScript?: TimeActionCropScript;
};

export const CropScriptingForm = ({ setFormValid, setFormScript, editScript }: CropScriptingFormProps) => {
  const { t } = useTranslation();
  const [compStartsAtInPoint, setCompStartsAtInPoint] = useState<boolean>(editScript?.compStartsAtInPoint || false);
  const [compEndsAtOutPoint, setCompEndsAtOutPoint] = useState<boolean>(editScript?.compEndsAtOutPoint || false);
  const valid = compStartsAtInPoint || compEndsAtOutPoint;

  useEffect(() => {
    setFormValid(valid);

    valid &&
      setFormScript({
        scriptType: ScriptType.CROP,
        compStartsAtInPoint: compStartsAtInPoint,
        compEndsAtOutPoint: compEndsAtOutPoint
      });

    !valid && setFormScript(undefined);
  }, [valid, compStartsAtInPoint, compEndsAtOutPoint, setFormValid, setFormScript]);

  return (
    <div className="max-h-vh-300 overflow-auto px-4 py-5">
      <fieldset className="space-y-5">
        <Checkbox
          id={'compStartsAtInPoint'}
          checked={compStartsAtInPoint === true}
          onChange={e => setCompStartsAtInPoint(e.target.checked)}
          label={t('components.project.template.layer.scripts.CropScriptingForm.compStartsAtInPointName')}
          description={t('components.project.template.layer.scripts.CropScriptingForm.compStartsAtInPointDescription')}
        />
        <Checkbox
          id={'compEndsAtOutPoint'}
          checked={compEndsAtOutPoint === true}
          onChange={e => setCompEndsAtOutPoint(e.target.checked)}
          label={t('components.project.template.layer.scripts.CropScriptingForm.compEndsAtOutPointName')}
          description={t('components.project.template.layer.scripts.CropScriptingForm.compEndsAtOutPointDescription')}
          className={'mb-5'}
        />
      </fieldset>
    </div>
  );
};
