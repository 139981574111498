import { MouseEvent, TouchEvent } from 'react';

import {
  KeyboardSensor as LibKeyboardSensor,
  MouseSensor as LibMouseSensor,
  TouchSensor as LibTouchSensor
} from '@dnd-kit/core';

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

// Extend dnd-kit sensors with custom handler that allows to block DnD event propagation
export class MouseSensor extends LibMouseSensor {
  static activators = [{ eventName: 'onMouseDown', handler }] as (typeof LibMouseSensor)['activators'];
}

export class TouchSensor extends LibTouchSensor {
  static activators = [{ eventName: 'onTouchStart', handler }] as (typeof LibTouchSensor)['activators'];
}

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [{ eventName: 'onKeyDown', handler }] as (typeof LibKeyboardSensor)['activators'];
}
