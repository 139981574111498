import { useCallback, useState } from 'react';

import { CheckIcon, SignalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

export const UpdatableString = ({
  value,
  loading,
  onUpdate
}: {
  value: string;
  loading: boolean;
  onUpdate: (value: string) => Promise<void>;
}) => {
  const [localValue, setLocalValue] = useState<string>();
  const [editing, setEditing] = useState(false);

  const cancel = useCallback(() => {
    setEditing(false);
    setLocalValue(undefined);
  }, []);

  const handleUpdate = useCallback(async () => {
    const trimmedValue = localValue?.trim();
    if (trimmedValue && trimmedValue !== value) {
      await onUpdate(trimmedValue);
      cancel();
    } else {
      cancel();
    }
  }, [cancel, localValue, onUpdate, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.key === 'Escape') cancel();
    },
    [cancel]
  );

  return (
    <>
      {!editing && !loading && (
        <div
          className="group flex w-full max-w-fit cursor-pointer items-center gap-1 truncate"
          onClick={() => setEditing(true)}
        >
          <span className="truncate duration-150 group-hover:text-gray-600">{value}</span>
          <PencilSquareIcon className="h-4 w-4 text-gray-900 duration-150 group-hover:text-gray-600" />
        </div>
      )}
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          handleUpdate();
        }}
        onKeyDown={handleKeyDown}
        className="flex w-full items-center gap-1"
      >
        {(editing || loading) && (
          <>
            <div className="relative flex w-full items-center gap-1">
              <input
                disabled={loading}
                required
                className="relative -mb-[1px] block w-full border-0 border-b border-gray-500 bg-inherit p-0 text-sm text-gray-900 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                type="text"
                autoFocus
                defaultValue={value}
                onChange={e => setLocalValue(e.target.value)}
              />
              {loading && <SignalIcon className="absolute right-1 h-4 w-4 shrink-0 animate-spin text-gray-500" />}
            </div>
            <button
              disabled={loading}
              className="group flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-md bg-gray-100 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
              type="submit"
            >
              <CheckIcon className="h-4 w-4 shrink-0 text-green-700 group-hover:text-green-600" />
            </button>
            <button
              disabled={loading}
              className="group flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center rounded-md bg-gray-100 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
              type="button"
              onClick={cancel}
            >
              <XMarkIcon className="h-4 w-4 shrink-0 text-red-700 group-hover:text-red-600" />
            </button>
          </>
        )}
      </form>
    </>
  );
};
