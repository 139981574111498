import { FormEvent, Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, RectangleStackIcon } from '@heroicons/react/20/solid';
import { Button } from '@src/components';
import { useNotifications } from '@src/hooks';
import { generateBatchRenderId, isEmpty } from '@src/utils';

type RenderBatchCsvFileUploadProps = {
  nextStep: () => void;
  setTableData: (data: Record<string, string>[]) => void;
  setBatchRenderId: (id: string) => void;
};

export const RenderBatchCsvFileUpload = ({
  nextStep,
  setTableData,
  setBatchRenderId
}: RenderBatchCsvFileUploadProps) => {
  const { t } = useTranslation();
  const { notifyError } = useNotifications();

  const [file, setFile] = useState<File>();
  const [rowsLoaded, setRowsLoaded] = useState<Record<string, string>[]>();
  const [range, setRange] = useState<{ start: number; end: number } | undefined>();

  const rows = useMemo(() => (range ? rowsLoaded?.slice(range.start - 1, range.end) : rowsLoaded), [rowsLoaded, range]);
  const zeroRows = isEmpty(rowsLoaded);
  const tooManyRows = rows && rows.length > 1000;
  const disabled = !rows || zeroRows || tooManyRows;

  const rowsLoadedMessage = () => {
    if (zeroRows) {
      return (
        <div className="mt-4 rounded-md bg-red-50 px-4 py-2">
          <p className="font-small text-xs text-red-800">{t('components.render.RenderBatchCsvFileUpload.zeroRows')}</p>
        </div>
      );
    } else if (tooManyRows) {
      return (
        <div className="mt-4 rounded-md bg-red-50 px-4 py-2">
          <p className="font-small text-xs text-red-800">
            {t('components.render.RenderBatchCsvFileUpload.tooManyRows')}
          </p>
        </div>
      );
    } else {
      return (
        <p className="text-xs text-gray-500">
          {t('components.render.RenderBatchCsvFileUpload.rowMessage', { count: rows?.length })}
        </p>
      );
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (rows) {
      setTableData(rows);
    }

    nextStep();
  };

  useEffect(() => {
    if (file) {
      Papa.parse<Record<string, string>>(file, {
        header: true,
        dynamicTyping: false,
        newline: undefined,
        skipEmptyLines: true,
        transform: function (value) {
          return value.replace(/<br>/g, '\n');
        },
        complete: function (results) {
          const filtered = results.data.filter(d => !isEmpty(Object.values(d).filter(v => v)));
          setRowsLoaded(filtered);
          setBatchRenderId(generateBatchRenderId(file.name));
        },
        error: function (error) {
          notifyError(error.message);
          setFile(undefined);
        }
      });
    }
  }, [file, notifyError, setBatchRenderId]);

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <label className="mt-3 block text-sm font-medium text-gray-700">
          {t('components.render.RenderBatchCsvFileUpload.title')}
        </label>
        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-6 pb-6 pt-5">
          <div className="space-y-1 text-center">
            <RectangleStackIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex place-content-center text-sm text-gray-600">
              {file && <p className="pr-1">{file.name}</p>}
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>{(!file && t('general.action.selectFile')) || t('general.action.changeFile')}</span>
                <input
                  id="file-upload"
                  accept=".csv"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  required
                  onChange={event => setFile(event?.target?.files ? event.target.files[0] : undefined)}
                />
              </label>
            </div>
            {!file ? (
              <p className="text-xs text-gray-500">{t('components.render.RenderBatchCsvFileUpload.uploadFile')}</p>
            ) : (
              rowsLoadedMessage()
            )}
          </div>
        </div>
        {rowsLoaded && (
          <div>
            <label className="mt-3 block w-full text-sm font-medium text-gray-700">
              {t('components.render.RenderBatchCsvFileUpload.selectRows')}
            </label>
            <div className="flex flex-col sm:flex-row">
              <div className="sm:mr-4 sm:w-1/2">
                <Listbox
                  value={range ? 1 : 0}
                  onChange={value => {
                    if (value > 0) {
                      setRange({
                        start: 1,
                        end: rowsLoaded?.length
                      });
                    } else {
                      setRange(undefined);
                    }
                  }}
                >
                  {({ open }) => (
                    <>
                      <div className="relative mt-1">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {t('components.render.RenderBatchCsvFileUpload.rowsOption', {
                              context: !range ? 'ALL' : 'CUSTOM'
                            })}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </ListboxButton>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption
                              key={0}
                              className={({ focus }) =>
                                classNames(
                                  focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={0}
                            >
                              {({ selected, focus }) => (
                                <>
                                  <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                  >
                                    {t('components.render.RenderBatchCsvFileUpload.rowsOption', { context: 'ALL' })}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        focus ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                            <ListboxOption
                              key={1}
                              className={({ focus }) =>
                                classNames(
                                  focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={1}
                            >
                              {({ selected, focus }) => (
                                <>
                                  <span
                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                  >
                                    {t('components.render.RenderBatchCsvFileUpload.rowsOption', { context: 'CUSTOM' })}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        focus ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                          </ListboxOptions>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <div className="mt-2 flex w-full sm:mt-0 sm:w-1/2">
                <div className="mr-4 w-1/2">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span
                      className={classNames(
                        'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm',
                        !range && 'cursor-not-allowed opacity-50'
                      )}
                    >
                      {t('components.render.RenderBatchCsvFileUpload.rangeFrom')}
                    </span>
                    <input
                      min={1}
                      max={range?.end || rowsLoaded.length}
                      type="number"
                      name="from"
                      id="from"
                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
                      value={range?.start || ''}
                      onChange={e => range?.end && setRange({ ...range, start: parseInt(e.target.value) })}
                      disabled={!range}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span
                      className={classNames(
                        'inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm',
                        !range && 'cursor-not-allowed opacity-50'
                      )}
                    >
                      {t('components.render.RenderBatchCsvFileUpload.rangeTo')}
                    </span>
                    <input
                      min={range?.start || 1}
                      max={rowsLoaded.length}
                      type="number"
                      name="to"
                      id="to"
                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm sm:leading-6"
                      value={range?.end || ''}
                      onChange={e => range?.start && setRange({ ...range, end: parseInt(e.target.value) })}
                      disabled={!range}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-end">
        <Button disabled={disabled} type="submit">
          {t('general.action.next')}
        </Button>
      </div>
    </form>
  );
};
