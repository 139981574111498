import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { PlusIcon } from '@heroicons/react/16/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { BatchRenderPassthrough, IntegrationsData, WebhookData } from '@src/types';
import { isEmpty } from '@src/utils';

import { Checkbox, StyledA } from '../common';

type RenderBatchPassthroughTableProps = {
  title: string;
  passthrough: BatchRenderPassthrough;
  setPassthrough: (passthrough: BatchRenderPassthrough) => void;
  data: Record<string, string>[];
  onShowModal: () => void;
  id: string;
  webhookData?: WebhookData;
  setWebhookData?: (newData: WebhookData) => void;
  integrationsData?: IntegrationsData;
  setIntegrationsData?: (newData: IntegrationsData) => void;
};

export const RenderBatchPassthroughTable = ({
  title,
  passthrough,
  setPassthrough,
  data,
  onShowModal,
  id,
  webhookData,
  setWebhookData,
  integrationsData,
  setIntegrationsData
}: RenderBatchPassthroughTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300 py-2">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:w-2/3">
              {title}
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 lg:table-cell lg:w-1/3"
            >
              <div className="flex w-full justify-end">
                <button
                  disabled={
                    (id === 'webhookPassthrough' && !webhookData?.url) ||
                    (id === 'integrationsPassthrough' && integrationsData?.skipAll)
                  }
                  type="button"
                  className="group -ml-1 flex items-center rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
                  onClick={onShowModal}
                >
                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                    <PlusIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                    {t('components.render.RenderBatchTable.webhookPassthroughButton')}
                  </span>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {id === 'webhookPassthrough' && setWebhookData && (
            <>
              <tr>
                <td colSpan={3} className="space-y-6 py-6 pl-4 pr-3">
                  <div>
                    <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                      {t('components.render.common.webhookUrl')}
                    </label>
                    <p className="text-sm text-gray-500">
                      {t('components.render.RenderAdvancedOptionsForm.webhookUrlDescription')}
                    </p>
                    <input
                      type="url"
                      name="url"
                      id="url"
                      autoComplete="url"
                      className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={webhookData?.url}
                      onChange={e => setWebhookData({ ...webhookData, url: e.target.value })}
                    />
                  </div>

                  <Checkbox
                    id={'onFailure'}
                    checked={webhookData?.onFailure || false}
                    onChange={e => {
                      if (webhookData?.url) setWebhookData({ ...webhookData, onFailure: e.target.checked });
                    }}
                    label={t('components.render.RenderAdvancedOptionsForm.webhookOnFailure')}
                    description={t('components.render.RenderAdvancedOptionsForm.webhookOnFailureDescription')}
                    disabled={!webhookData?.url}
                  />

                  <Checkbox
                    id={'onInvalid'}
                    checked={webhookData?.onInvalid || false}
                    onChange={e => {
                      if (webhookData?.url) setWebhookData({ ...webhookData, onInvalid: e.target.checked });
                    }}
                    label={t('components.render.RenderAdvancedOptionsForm.webhookOnInvalid')}
                    description={t('components.render.RenderAdvancedOptionsForm.webhookOnInvalidDescription')}
                    disabled={!webhookData?.url}
                  />

                  <div>
                    <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                      {t('components.render.RenderBatchTable.webhookParametersTitle')}
                    </label>
                    <p className="text-sm text-gray-500">
                      {t('components.render.RenderBatchTable.webhookParametersDesc')}
                    </p>
                  </div>

                  <Parameters passthrough={passthrough} setPassthrough={setPassthrough} data={data} id={id} />
                </td>
              </tr>
            </>
          )}

          {id === 'integrationsPassthrough' && setIntegrationsData && (
            <tr>
              <td colSpan={3} className="space-y-6 py-6 pl-4 pr-3">
                <Checkbox
                  id={'skipIntegrations'}
                  checked={integrationsData?.skipAll || false}
                  onChange={e => setIntegrationsData({ ...integrationsData, skipAll: e.target.checked })}
                  label={t('components.render.common.integrationsSkipAll')}
                  description={t('components.render.RenderAdvancedOptionsForm.integrationsSkipAllDescription')}
                />

                <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                    {t('components.render.RenderBatchTable.integrationsParametersTitle')}
                  </label>
                  <p className="text-sm text-gray-500">
                    {t('components.render.RenderBatchTable.integrationsParametersDesc')}
                  </p>
                </div>

                <Parameters
                  passthrough={passthrough}
                  setPassthrough={setPassthrough}
                  data={data}
                  id={id}
                  disabled={integrationsData?.skipAll}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const Parameters = ({
  passthrough,
  setPassthrough,
  data,
  id,
  disabled
}: {
  passthrough: BatchRenderPassthrough;
  setPassthrough: (passthrough: BatchRenderPassthrough) => void;
  data: Record<string, string>[];
  id: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        '!mt-1 rounded-md border border-dashed border-gray-200 bg-white p-4',
        disabled && 'pointer-events-none opacity-50'
      )}
    >
      {!isEmpty(Object.keys(passthrough.passthrough)) ? (
        <>
          <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {Object.keys(passthrough.passthrough).map(pass => (
              <div key={pass} className="cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none ">
                <div className="flex h-full">
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <span className="block text-sm font-medium text-gray-900">
                        {passthrough.passthrough[pass].isColumnSelect
                          ? t('components.render.RenderBatchTable.webhookPassthroughColumn', {
                              column: passthrough.passthrough[pass].value
                            })
                          : t('components.render.RenderBatchTable.webhookPassthroughCustomValue')}
                      </span>
                      <span className="mt-1 flex flex-1 items-center text-sm text-gray-500">
                        {passthrough.passthrough[pass].isColumnSelect
                          ? t('components.render.RenderBatchTable.webhookPassthroughExamples', {
                              examples: passthrough.passthrough[pass].isColumnSelect
                                ? data
                                    .flatMap(d => d[passthrough.passthrough[pass].value])
                                    .slice(0, 3)
                                    .join(', ')
                                    .concat('...')
                                : passthrough.passthrough[pass].value
                            })
                          : t('components.render.RenderBatchTable.webhookPassthroughValue', {
                              value: passthrough.passthrough[pass].value
                            })}
                      </span>
                      <span className="mt-6 text-sm text-gray-500">
                        <Trans
                          i18nKey={'components.render.RenderBatchTable.webhookPassthroughParameterName'}
                          tOptions={{ name: pass }}
                          context={passthrough.sendAsJson ? 'json' : 'plain'}
                        >
                          {passthrough.sendAsJson ? 'As JSON parameter' : 'As plain value'}
                          <code className="prose prose-sm text-sm font-medium text-gray-900">{pass}</code>.
                        </Trans>
                      </span>
                    </span>
                  </span>
                  <div>
                    <StyledA>
                      <XMarkIcon
                        className={classNames('h-5 w-5')}
                        aria-hidden="true"
                        onClick={() => {
                          const newWebhookPass = { ...passthrough.passthrough };
                          delete newWebhookPass[pass];
                          setPassthrough({ ...passthrough, passthrough: newWebhookPass });
                        }}
                      />
                    </StyledA>
                  </div>
                  <span className="pointer-events-none absolute -inset-px rounded-lg" aria-hidden="true" />
                </div>
              </div>
            ))}
          </div>
          <Checkbox
            id={id}
            checked={passthrough.sendAsJson}
            onChange={e => setPassthrough({ ...passthrough, sendAsJson: e.target.checked })}
            label={t('components.render.RenderBatchPassthroughTable.sendAsJson')}
            description={t('components.render.RenderBatchPassthroughTable.sendAsJsonDescription')}
            disabled={Object.keys(passthrough.passthrough).length > 1}
            className="col-span-3"
          />
        </>
      ) : (
        <p className="col-span-3 text-center text-sm text-gray-500">{t('components.render.RenderBatchTable.empty')}</p>
      )}
    </div>
  );
};
