import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PlainlyCombobox, ProjectCombobox, RequiredMarker, TemplateCombobox } from '@src/components';
import { useAnyProjectItemReducer } from '@src/hooks';
import { CommonVideoSettings, DesignCategory } from '@src/models';
import { LANGUAGES } from '@src/tools/publishers/utils';

export type CommonVideoTypeInputsProps = {
  designCategory?: DesignCategory;
  inputs: CommonVideoSettings;
  onUpdate: (updateFunction: (prev: CommonVideoSettings) => CommonVideoSettings) => void;
  onSelectionChange:
    | ((
        item?:
          | {
              name: string;
              code: string;
            }
          | undefined
      ) => void)
    | undefined;
};

export const CommonVideoTypeInputs = ({
  designCategory,
  inputs,
  onUpdate,
  onSelectionChange
}: CommonVideoTypeInputsProps) => {
  const { t } = useTranslation();
  const [state, updateProjectItem, updateRenderItem] = useAnyProjectItemReducer();
  const { projectItem: selectedProject, renderItem: selectedTemplate } = state;

  useEffect(() => {
    if (selectedProject && selectedTemplate) {
      onUpdate(prev => ({
        ...prev,
        projectId: selectedProject.id,
        templateId: selectedTemplate.id,
        design: selectedProject.isDesign
      }));
    } else {
      onUpdate(prev => ({
        ...prev,
        projectId: '',
        templateId: '',
        design: false
      }));
    }
  }, [onUpdate, selectedProject, selectedTemplate]);

  return (
    <>
      <div className="md:col-span-1">
        <h3 className="text-md font-normal leading-6 text-gray-900">
          {t('components.brand.videoTypes.commonInputs.projectTemplateLanguageTitle')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t('components.brand.videoTypes.commonInputs.projectTemplateLanguageDescription')}
        </p>
      </div>
      <div className="space-y-6 md:col-span-2">
        <div>
          <label htmlFor="projectId" className="block text-sm font-medium text-gray-700">
            {t('general.action.selectProject')}
            <RequiredMarker />
            <p className="text-sm font-normal text-gray-500">
              {t('components.brand.videoTypes.commonInputs.projectDescription')}
            </p>
          </label>
          <div className="mt-1">
            {designCategory && (
              <ProjectCombobox
                designCategory={designCategory}
                includeDesigns={true}
                onChange={updateProjectItem}
                showNotAnalyzed={false}
              />
            )}
            {!designCategory && (
              <ProjectCombobox includeDesigns={false} onChange={updateProjectItem} showNotAnalyzed={false} />
            )}
          </div>
        </div>
        <div>
          <label htmlFor="templateId" className="block text-sm font-medium text-gray-700">
            {t('general.action.selectTemplate')}
            <RequiredMarker />
            <p className="text-sm font-normal text-gray-500">
              {t('components.brand.videoTypes.commonInputs.templateDescription')}
            </p>
          </label>
          <div className="mt-1">
            <TemplateCombobox includeDesigns={true} item={selectedProject} onChange={updateRenderItem} />
          </div>
        </div>
        <div>
          <label htmlFor="targetLanguage" className="block text-sm font-medium text-gray-700">
            {t('components.brand.videoTypes.commonInputs.targetLanguageLabel')}
            <RequiredMarker />
            <p className="text-sm font-normal text-gray-500">
              {t('components.brand.videoTypes.commonInputs.targetLanguageDescription')}
            </p>
          </label>
          <PlainlyCombobox
            hasSearch
            className="mt-1"
            data={LANGUAGES.map(language => ({
              name: language,
              item: language,
              label: t('general.languages.lang', { context: language.code }),
              selected: language.code === inputs.targetLanguage
            }))}
            onSelectionChange={onSelectionChange}
          />
        </div>
      </div>
    </>
  );
};
