import { useTranslation } from 'react-i18next';

import { Checkbox } from '@src/components/common';

export const ExcludeLayers = ({
  exclude,
  setExclude,
  disabled
}: {
  exclude: {
    excludeAdjustmentLayers: boolean;
    excludeDisabledLayers: boolean;
    excludeGuideLayers: boolean;
  };
  setExclude: (exclude: {
    excludeAdjustmentLayers: boolean;
    excludeDisabledLayers: boolean;
    excludeGuideLayers: boolean;
  }) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        disabled={disabled}
        id={'excludeAdjustmentLayers'}
        checked={exclude.excludeAdjustmentLayers === true}
        onChange={e => setExclude({ ...exclude, excludeAdjustmentLayers: e.target.checked })}
        label={t('components.project.autoGenerateTemplates.common.excludeAdjustment')}
        description={t('components.project.autoGenerateTemplates.common.excludeAdjustmentDesc')}
      />
      <Checkbox
        disabled={disabled}
        id={'excludeDisabledLayers'}
        checked={exclude.excludeDisabledLayers === true}
        onChange={e => setExclude({ ...exclude, excludeDisabledLayers: e.target.checked })}
        label={t('components.project.autoGenerateTemplates.common.excludeDisabled')}
        description={t('components.project.autoGenerateTemplates.common.excludeDisabledDesc')}
      />
      <Checkbox
        disabled={disabled}
        id={'excludeGuideLayers'}
        checked={exclude.excludeGuideLayers === true}
        onChange={e => setExclude({ ...exclude, excludeGuideLayers: e.target.checked })}
        label={t('components.project.autoGenerateTemplates.common.excludeGuide')}
        description={t('components.project.autoGenerateTemplates.common.excludeGuideDesc')}
        className={'mb-2'}
      />
    </>
  );
};
