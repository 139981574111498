import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmationModal } from '@src/components';
import { useUpgradeAllProjects } from '@src/hooks';

export const ProjectUpgradeAllAction = () => {
  const { t } = useTranslation();
  const [modalVisible, toggleModal] = useState(false);
  const { isLoading, mutateAsync: upgradeAllProjects } = useUpgradeAllProjects();

  const doUpgrade = () => {
    upgradeAllProjects().then(() => toggleModal(false));
  };

  return (
    <>
      <Button secondary className="ml-2" disabled={isLoading} onClick={() => toggleModal(true)}>
        {t('components.project.ProjectsHeader.upgradeAll')}
      </Button>
      <ConfirmationModal
        title={t('components.project.ProjectUpgradeAllAction.confirmationTitle')}
        description={t('components.project.ProjectUpgradeAllAction.confirmationDescription')}
        visible={modalVisible}
        isLoading={isLoading}
        onConfirm={doUpgrade}
        onClose={() => toggleModal(false)}
      />
    </>
  );
};
