import { useTranslation } from 'react-i18next';

import { StepNavigation } from '@src/components/common';

export const RenderBatchDownloadNavigation = ({ activeStep }: { activeStep: number }) => {
  const { t } = useTranslation();
  const steps = [
    { value: 1, name: t('components.render.batchDownload.RenderBatchDownloadNavigation.selectBatch') },
    { value: 2, name: t('components.render.batchDownload.RenderBatchDownloadNavigation.previewAndDownload') }
  ];

  return <StepNavigation steps={steps} activeStep={activeStep} />;
};
