import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  RenderBatchDownloadHeader,
  RenderBatchDownloadNavigation,
  RenderBatchDownloadOptions,
  RenderBatchDownloadPreview,
  ScrollingPage
} from '@src/components';

export const RenderBatchDownloadPage = () => {
  const { t } = useTranslation();
  const { step } = useParams() as { step: string };
  const stepNum = parseInt(step) || 1;

  const getContent = () => {
    switch (stepNum) {
      case 1:
        return <RenderBatchDownloadOptions />;
      case 2:
        return <RenderBatchDownloadPreview />;
    }
  };

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.RenderBatchDownloadPage')}</title>
        </Helmet>
        <RenderBatchDownloadHeader />
        <RenderBatchDownloadNavigation activeStep={stepNum} />
        {getContent()}
      </ScrollingPage>
    </HelmetProvider>
  );
};
