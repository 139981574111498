import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AnyJobError, BackendJobErrorCode } from '@plainly/types';
import { StyledA, WorkerErrorParams } from '@src/components';
import {
  displayContactSupport,
  getErrorReason,
  getErrorSolution,
  getTroubleshootLink
} from '@src/components/common/error/utils';

export const ErrorCard = ({
  error,
  analysis,
  upgradeError
}: {
  error: AnyJobError;
  analysis?: boolean;
  upgradeError?: boolean;
}) => {
  const { t } = useTranslation();

  const invalidRender = error.code === BackendJobErrorCode.RenderInvalid;
  const jobContext = analysis ? 'analysis' : 'render';
  const invalidContext = invalidRender ? 'invalid' : upgradeError ? 'upgradeError' : 'error';

  const warning = invalidRender || upgradeError;

  return (
    <div className={classNames('shadow sm:rounded-lg', warning ? 'bg-yellow-50' : 'bg-red-50')}>
      <div className="flex items-center gap-3 px-4 py-5 sm:px-6">
        <h2
          id="project-error-title"
          className={classNames('text-lg font-medium leading-6 ', warning ? 'text-yellow-800' : 'text-red-800')}
        >
          {t('components.common.error.ErrorCard.title', { context: `${jobContext}_${invalidContext}` })}
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              {warning ? t('general.common.warning') : t('general.common.error')}
            </dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <span>{t(getErrorReason(error.code))}</span>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">{t('components.common.error.ErrorCard.solution')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <span>{t(getErrorSolution(error.code))}</span>
            </dd>
          </div>
          {upgradeError && (
            <div className="sm:col-span-2">
              <dd className="mt-1 flex flex-col text-sm italic text-gray-900">
                <span>{t('components.common.error.ErrorCard.toClearWarningText')}</span>
              </dd>
            </div>
          )}
          <WorkerErrorParams error={error} />
        </dl>
      </div>
      <div className="mt-2 flex justify-end border-t border-gray-200 px-4 py-5 text-sm sm:px-6">
        <StyledA href={getTroubleshootLink(error.code)} target="_blank">
          {t('components.common.error.ErrorCard.viewDocumentation')}
        </StyledA>
        {displayContactSupport(error.code) && (
          <StyledA href="https://www.plainlyvideos.com/contact" target="_blank" className="ml-2">
            {t('general.action.contactSupport')}
          </StyledA>
        )}
      </div>
    </div>
  );
};
