import { useTranslation } from 'react-i18next';

import { DesignsHelpLinks, Header, HelpNotification } from '@src/components';

export const DesignsHeader = () => {
  const { t } = useTranslation();

  return (
    <Header
      header={t('general.common.designs')}
      subheader={t('components.designs.DesignsPage.subtitle')}
      beta
      actions={<HelpNotification type="button" links={DesignsHelpLinks} />}
    />
  );
};
