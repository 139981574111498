import { format, parseISO } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const localeMap: Record<string, Locale | undefined> = {
  en: undefined
};

export const parseISOSafe = (value?: string) => value && parseISO(value);

export const formatDateWithCurrentLocale = (date: Date | number, currentLocale: string, formatStyle: string) => {
  return format(date, formatStyle, {
    locale: localeMap[currentLocale]
  });
};

export const distanceInWordsToNowWithCurrentLocale = (
  date: Date | number,
  currentLocale: string,
  addSuffix = false
) => {
  return formatDistanceToNow(date, {
    locale: localeMap[currentLocale],
    addSuffix
  });
};
