import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';

import { useAxiosModify, useAxiosRead } from '@src/hooks';
import { Brand, BrandSettings, CreateBrandDto, PlainlyApiError } from '@src/models';

const BRANDS_CACHE_ROOT = 'brands';

export const brandsCacheAdd = (client: QueryClient, brand: Brand) => {
  client.setQueryData<Brand[]>(BRANDS_CACHE_ROOT, brands => (brands ? [...brands, brand] : [brand]));
  client.setQueryData([BRANDS_CACHE_ROOT, brand.id], brand);
};

export const brandsCacheReplace = (client: QueryClient, brand: Brand) => {
  client.setQueryData<Brand[]>(BRANDS_CACHE_ROOT, brands =>
    brands ? brands.map(b => (b.id === brand.id ? brand : b)) : [brand]
  );
  client.setQueryData([BRANDS_CACHE_ROOT, brand.id], brand);
};

export const useGetBrands = () => {
  const { get } = useAxiosRead<Brand[]>();

  const { data, isLoading, error } = useQuery<Brand[]>(BRANDS_CACHE_ROOT, async () => {
    const response = await get('/video-genius/brands');
    return response.data;
  });

  return { data, isLoading, error };
};

export const useGetBrandDetails = (brandId: string | undefined, enabled = true) => {
  const { get } = useAxiosRead<Brand>();

  const cacheKey = [BRANDS_CACHE_ROOT, brandId];

  const { data, isLoading, error, refetch } = useQuery<Brand>(
    cacheKey,
    async () => {
      const response = await get(`/video-genius/brands/${brandId}`);
      return response.data;
    },
    {
      enabled: !!brandId && enabled
    }
  );

  return { data, isLoading, error, refetch };
};

export const useCreateBrand = () => {
  const { post } = useAxiosModify<CreateBrandDto, Brand>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation<Brand, PlainlyApiError, CreateBrandDto>({
    mutationFn: async (brand: CreateBrandDto) => {
      const response = await post('/video-genius/brands', brand);
      return response.data;
    },
    onSuccess: brand => {
      brandsCacheAdd(queryClient, brand);
    }
  });

  return { mutateAsync, isLoading, error };
};

type BrandEditParams = {
  brandId: string;
  settings: BrandSettings;
};

export const useEditBrand = () => {
  const { post } = useAxiosModify<{ settings: BrandSettings }, Brand>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation<Brand, PlainlyApiError, BrandEditParams>({
    mutationFn: async ({ brandId, settings }: BrandEditParams) => {
      const response = await post(`/video-genius/brands/${brandId}`, { settings });
      return response.data;
    },
    onSuccess: (edited: Brand) => {
      brandsCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteBrand = () => {
  const { delete: deleteBrand } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (brandId: string) => {
      const response = await deleteBrand(`/video-genius/brands/${brandId}`);

      return response.data;
    },
    onSuccess: (_, brandId) => {
      queryClient.setQueryData<Brand[]>(
        BRANDS_CACHE_ROOT,
        brands => brands?.filter(brand => brand.id !== brandId) ?? []
      );
      queryClient.removeQueries([BRANDS_CACHE_ROOT, brandId]);
    }
  });

  return { mutateAsync, isLoading, error };
};
