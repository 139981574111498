import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import * as routes from '@src/routes';

export const OrganizationSettingsNavigation = ({ tab }: { tab: 'general' | 'team' | 'subscription' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    {
      name: t('components.common.general'),
      href: routes.ORGANIZATION_SETTINGS,
      current: tab === 'general'
    },
    {
      name: t('components.user.common.teamMembers'),
      href: routes.ORGANIZATION_SETTINGS_TEAM,
      current: tab === 'team'
    },
    {
      name: t('components.user.common.subscription'),
      href: routes.ORGANIZATION_SETTINGS_SUBSCRIPTION,
      current: tab === 'subscription'
    }
  ];

  const selectDefault = tabs.find(t => t.current)?.name;

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t('general.action.selectATab')}
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={selectDefault}
          onChange={e => {
            const next = tabs.find(t => t.name === e.target.value);
            if (next && !next.current) {
              navigate(next.href);
            }
          }}
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map(tab => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};
