import { useCallback, useMemo } from 'react';
import { decode, encode } from 'js-base64';
import { useNavigate } from 'react-router-dom';

import { useQueryParams } from '../useQueryParams';

export type VideoNavigationParams = {
  revisionId: string;
} & (
  | { activeView: 'scenes'; sceneId?: number; headlinesTab?: number; assetsTab?: 'suggested' | 'search' | 'manual' }
  | { activeView: 'sm-settings'; assetsTab?: 'search' | 'manual' }
  | { activeView: 'voiceover' }
  | { activeView: 'avatar' }
  | { activeView: 'general' }
);

export const useVideoNavigationParams = () => {
  const navigate = useNavigate();
  const { searchQuery, updateQueryParams } = useQueryParams();

  const videoNavigationState: VideoNavigationParams = useMemo(() => {
    const data = searchQuery.get('data');
    return data ? JSON.parse(decode(data)) : undefined;
  }, [searchQuery]);

  const updateVideoNavigationState = useCallback(
    (payload: VideoNavigationParams | ((current: VideoNavigationParams) => VideoNavigationParams)) => {
      // if payload is a functions, call it with the current state
      if (typeof payload === 'function') {
        payload = payload(videoNavigationState);
      }

      const data = encode(JSON.stringify(payload));
      navigate(
        {
          search: `?${updateQueryParams(window.location, {
            data
          })}`
        },
        { replace: videoNavigationState === undefined }
      );
    },
    [navigate, updateQueryParams, videoNavigationState]
  );

  return {
    videoNavigationState,
    updateVideoNavigationState
  };
};
