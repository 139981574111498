import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ArticlesTimelineItem, Button, Loading, LoadingProps } from '@src/components';
import { useGetArticles } from '@src/hooks';
import { BLANK_SVG_SRC } from '@src/icons';
import * as routes from '@src/routes';
import { isEmpty } from '@src/utils';

const EmptyArticlesList = ({ title }: { title: string }) => (
  <div className="mt-6 inline-flex h-full w-full  grow flex-col place-content-center items-center">
    <div className="flex flex-col items-center">
      <img className="h-56 w-56" src={BLANK_SVG_SRC} alt="Empty" />
      <span className="my-6 px-6 text-sm sm:px-4">{title}</span>
    </div>
  </div>
);

const LoadingArticles = ({ title }: LoadingProps) => (
  <div className="mt-6 inline-flex w-full grow flex-col">
    <Loading title={title} />
  </div>
);

export const ArticlesTimeline = ({ brandId, maxItems }: { brandId: string; maxItems: number }) => {
  const { t } = useTranslation();

  const { isLoading, data: articles = [], refetch } = useGetArticles(brandId);
  const empty = isEmpty(articles);

  const shownArticles = useMemo(() => articles.slice(0, maxItems), [articles, maxItems]);

  return (
    <div className="divide-y divide-gray-200 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.brand.ArticlesTimeline.title')}
        </h2>
      </div>
      <div className="flow-root pt-6">
        {isLoading && <LoadingArticles title={t('components.publishers.common.loading')} />}
        {!isLoading && (
          <>
            {empty && <EmptyArticlesList title={t('components.publishers.common.articlesEmpty')} />}
            {!empty && (
              <ul className="px-4 sm:px-6">
                {shownArticles.map((article, index) => (
                  <ArticlesTimelineItem
                    key={article.id}
                    brandId={brandId}
                    model={article}
                    isLastItem={index === shownArticles.length - 1}
                  />
                ))}
              </ul>
            )}
            <div className="flex items-center justify-end border-t border-gray-200 bg-gray-50 px-4 py-4 sm:rounded-b-lg sm:px-6">
              <Button secondary onClick={() => refetch()} loading={isLoading}>
                {t('general.action.update')}
              </Button>
              {!empty && (
                <Link to={generatePath(routes.ARTICLES_BRAND_LIST, { brandId })}>
                  <Button className="ml-2">{t('general.action.showAll')}</Button>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
