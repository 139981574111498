import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ProjectDetails, ScrollingPage } from '@src/components';

export const ProjectDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.ProjectDetailsPage')}</title>
        </Helmet>
        <ProjectDetails projectId={id} />
      </ScrollingPage>
    </HelmetProvider>
  );
};
