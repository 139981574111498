import { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { generatePath, Link } from 'react-router-dom';

import { getRenderStateBadge, VideoOutput } from '@src/components';
import localizationHelper from '@src/i18n';
import { Render, RenderState } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

type RendersGridProps = {
  renders: Render[][] | undefined | null;
  pageSize?: number;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isSuccess: boolean;
};

export const RendersGrid = ({ renders, hasNextPage, fetchNextPage, isSuccess }: RendersGridProps) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const content =
    isSuccess &&
    renders?.map((page, index) => {
      const isLastPage = index === renders.length - 1;
      return page.map((render, i) => {
        const isLastInLastPage = isLastPage && i === page.length - 1;
        return (
          <li key={i} ref={isLastInLastPage ? ref : undefined} className="relative">
            <RendersGridCell model={render} />
          </li>
        );
      });
    });

  return (
    <>
      <ul className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3 sm:gap-x-8">{content}</ul>
    </>
  );
};

const RendersGridCell = ({ model: render }: PropsWithModel<Render>) => {
  const { t } = useTranslation();

  return (
    <>
      <VideoOutput render={render} />
      <Link className={classNames('block px-2 py-4')} to={generatePath(routes.RENDER_DETAILS, { id: render.id })}>
        <div className="grid-cols grid ">
          <div className="flex justify-between">
            <span className="text-sm text-gray-800">{render.templateName}</span>
            {getRenderStateBadge(render.state, render.retried || false, t)}
          </div>
          <span className="text-sm text-gray-500">{render.projectName}</span>
          <time className="flex text-sm text-gray-500">
            {render.state !== RenderState.DONE
              ? localizationHelper.forDate().formatDistanceToNowWithDateString(render.createdDate, true)
              : render.expired
                ? t('components.render.RendersGrid.dateLabel', {
                    context: 'EXPIRED',
                    date: localizationHelper.forDate().formatDistanceToNowWithDateString(render.createdDate, true)
                  })
                : t('components.render.RendersGrid.dateLabel', {
                    context: 'VALID',
                    date: localizationHelper.forDate().formatDistanceToNowWithDateString(render.createdDate, true),
                    days: localizationHelper.forDate().formatDistanceToNowWithDateString(render.expirationDate, true)
                  })}
          </time>
        </div>
      </Link>
    </>
  );
};
