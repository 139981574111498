import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import {
  AppmixerIntegrations,
  AppmixerSupportedProducts,
  Button,
  Header,
  HelpNotification,
  IntegrationsPageHelpLinks,
  ScrollingPage
} from '@src/components';
import { useQueryParams } from '@src/hooks';
import * as routes from '@src/routes';

import '@src/components/appmixer/styles/appmixerIntegrationsPage.css';

export const AppmixerIntegrationsPage = () => {
  const { t } = useTranslation();
  const { tab } = useParams() as { tab?: 'templates' | 'active' | 'custom' };
  const { searchQuery } = useQueryParams();
  const projectId = searchQuery.get('projectId');

  return (
    <HelmetProvider>
      <ScrollingPage className="flex flex-col gap-6">
        <Helmet>
          <title>{t('general.pageTitle.AppmixerIntegrationsPage')}</title>
        </Helmet>
        <Header
          header={t('general.common.integrations')}
          subheader={t('components.appmixer.AppmixerIntegrationsPage.subtitle')}
          beta
          actions={
            <div className="flex flex-row-reverse md:flex-row">
              <HelpNotification type="button" links={IntegrationsPageHelpLinks} className="mr-2" />
              <Link to={routes.INTEGRATIONS_ACCOUNTS} className="ml-2 mr-2 md:ml-0">
                <Button secondary>{t('general.common.accounts')}</Button>
              </Link>
              <Link to={routes.INTEGRATIONS_LOGS}>
                <Button secondary>{t('general.common.logs')}</Button>
              </Link>
            </div>
          }
        />
        <AppmixerIntegrations tab={tab} />
        {!projectId && tab !== 'active' && <AppmixerSupportedProducts />}
      </ScrollingPage>
    </HelmetProvider>
  );
};
