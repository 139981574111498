import { AvatarProviderType, VoiceoverProviderType } from '@src/models';

export type LangType = {
  name: string;
  code: string;
  supportedBy: (AvatarProviderType | VoiceoverProviderType)[];
};

export const LANGUAGES: LangType[] = [
  { name: 'Afrikaans (South Africa)', code: 'af-ZA', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Arabic', code: 'ar-XA', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Basque (Spain)', code: 'eu-ES', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Bengali (India)', code: 'bn-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Bulgarian (Bulgaria)', code: 'bg-BG', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Catalan (Spain)', code: 'ca-ES', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Chinese (Hong Kong)', code: 'yue-HK', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Czech (Czech Republic)', code: 'cs-CZ', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Danish (Denmark)', code: 'da-DK', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Dutch (Belgium)', code: 'nl-BE', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Dutch (Netherlands)', code: 'nl-NL', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'English (Australia)', code: 'en-AU', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'English (India)', code: 'en-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'English (UK)', code: 'en-GB', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'English (US)', code: 'en-US', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Filipino (Philippines)', code: 'fil-PH', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Finnish (Finland)', code: 'fi-FI', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'French (Canada)', code: 'fr-CA', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'French (France)', code: 'fr-FR', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Galician (Spain)', code: 'gl-ES', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'German (Switzerland)', code: 'de-CH', supportedBy: [AvatarProviderType.D_ID] },
  { name: 'German (Germany)', code: 'de-DE', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Greek (Greece)', code: 'el-GR', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Gujarati (India)', code: 'gu-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Hebrew (Israel)', code: 'he-IL', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Hindi (India)', code: 'hi-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Hungarian (Hungary)', code: 'hu-HU', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Icelandic (Iceland)', code: 'is-IS', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Indonesian (Indonesia)', code: 'id-ID', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Italian (Italy)', code: 'it-IT', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Japanese (Japan)', code: 'ja-JP', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Kannada (India)', code: 'kn-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Korean (South Korea)', code: 'ko-KR', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Latvian (Latvia)', code: 'lv-LV', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Lithuanian (Lithuania)', code: 'lt-LT', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Malay (Malaysia)', code: 'ms-MY', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Malayalam (India)', code: 'ml-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Mandarin Chinese', code: 'cmn-CN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Marathi (India)', code: 'mr-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Norwegian (Norway)', code: 'nb-NO', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Polish (Poland)', code: 'pl-PL', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Portuguese (Brazil)', code: 'pt-BR', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Portuguese (Portugal)', code: 'pt-PT', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Punjabi (India)', code: 'pa-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Romanian (Romania)', code: 'ro-RO', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Russian (Russia)', code: 'ru-RU', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Serbian (Serbia)', code: 'sr-RS', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Slovak (Slovakia)', code: 'sk-SK', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Spanish (Spain)', code: 'es-ES', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Spanish (US)', code: 'es-US', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Swedish (Sweden)', code: 'sv-SE', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Tamil (India)', code: 'ta-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Telugu (India)', code: 'te-IN', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Thai (Thailand)', code: 'th-TH', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Turkish (Turkey)', code: 'tr-TR', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Ukrainian (Ukraine)', code: 'uk-UA', supportedBy: [VoiceoverProviderType.GCP] },
  { name: 'Vietnamese (Vietnam)', code: 'vi-VN', supportedBy: [VoiceoverProviderType.GCP] }
];
