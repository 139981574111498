export enum SubscriptionIdentifier {
  FREE = 'FREE',
  FREE_TRIAL = 'FREE_TRIAL',
  STARTER = 'STARTER',
  STARTER_YEARLY = 'STARTER_YEARLY',
  TEAM = 'TEAM',
  TEAM_YEARLY = 'TEAM_YEARLY',
  PRO = 'PRO',
  PRO_YEARLY = 'PRO_YEARLY'
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  TRAILING = 'TRAILING',
  PAST_DUE = 'PAST_DUE',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED'
}

export enum PlainlyPackage {
  FREE = 'FREE',
  STARTER = 'STARTER',
  TEAM = 'TEAM',
  PRO = 'PRO'
}

export declare type Subscription = {
  active: boolean;
  subscriptionIdentifier?: SubscriptionIdentifier;
  plainlyPackage?: PlainlyPackage;
  status?: SubscriptionStatus;
  billingInterval?: 'MONTHLY' | 'YEARLY';
  price: number;
  currency?: string;
  startedDate?: string;
  endDate?: string;
  nextPaymentAmount?: number;
  nextPaymentCurrency?: string;
  nextPaymentDate?: string;
  paymentMethod?: string;
  updateUrl?: string;
  cancelUrl?: string;
  internallyManaged: boolean;
  resources: SubscriptionResourcesDto;
};

export declare type SubscriptionResourcesDto = {
  period: 'MONTH' | 'YEAR' | 'CONTRACT';
  featureGated: boolean;
  storageBytesLimit?: number;
  renderingSecondsLimit?: number;
  renderingRetentionHours?: number;
  renderingMaxParallel?: number;
  publishersArticleVideosLimit?: number;
};

export type InvoiceDto = {
  id: number;
  amount: number;
  currency: string;
  payoutDate: string;
  paid: number;
  receiptUrl: string;
};
