import classNames from 'classnames';

import { StockAsset, StockAssetSearchOut } from '@src/models';

type AssetDisplayProps =
  | {
      asset?: StockAsset;
      out?: never;
      actions?: React.ReactNode;
      className: string;
      onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    }
  | {
      asset?: never;
      out: StockAssetSearchOut;
      actions?: React.ReactNode;
      className: string;
      onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    };

export const AssetDisplay = ({ asset, out, actions, className, onClick }: AssetDisplayProps) => {
  const missingAsset = !asset && !out;

  const type = asset ? (asset.type === 'manual' ? asset.assetType : asset.out.assetType) : out?.assetType;
  const url = asset ? (asset.type === 'manual' ? asset.url : asset.out.url) : out?.url;
  const thumb = asset ? (asset.type === 'manual' ? asset.url : asset.out.thumb) : out?.thumb;

  return (
    <div
      className={classNames(
        'relative flex justify-center overflow-hidden rounded-md',
        className,
        missingAsset ? 'border border-gray-200' : 'bg-gray-900'
      )}
      onClick={onClick}
    >
      {missingAsset ? (
        <div className="h-full w-full bg-missing-article-data-image bg-repeat object-cover opacity-5" />
      ) : (
        <>
          {type === 'IMAGE' && <img src={thumb} alt="" className="h-full w-auto object-cover" />}
          {type === 'VIDEO' && <video controls src={url} poster={thumb} className="h-full w-auto object-cover" />}
        </>
      )}
      {actions}
    </div>
  );
};
