import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Loading,
  LoadingProps,
  PresenterTypeForm,
  ScrollingPage,
  SocialMediaTypeForm,
  SummarySlideshowTypeForm
} from '@src/components';
import { useGetBrandDetails, useQueryParams } from '@src/hooks';

const LoadingBrandDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const VideoTypeCreatePage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const { searchQuery } = useQueryParams();

  const videoType = searchQuery.get('type');
  const { isLoading, data: brand } = useGetBrandDetails(id);

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.VideoTypeCreatePage', { context: videoType })}</title>
        </Helmet>
        <>
          {isLoading && <LoadingBrandDetails title={t('components.brand.BrandDetails.loading')} />}
          {!isLoading && brand && (
            <>
              {videoType === 'SOCIAL_MEDIA' && <SocialMediaTypeForm brand={brand} />}
              {videoType === 'PRESENTER' && <PresenterTypeForm brand={brand} />}
              {videoType === 'SUMMARY_SLIDESHOW' && <SummarySlideshowTypeForm brand={brand} />}
            </>
          )}
        </>
      </ScrollingPage>
    </HelmetProvider>
  );
};
