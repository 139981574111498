import { ArticleVideoScene } from '@src/models';

export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const sceneStartTime = (sceneIndex: number, scenes: ArticleVideoScene[]) => {
  if (sceneIndex >= 0 && sceneIndex < scenes.length) {
    return formatDuration(
      scenes.slice(0, sceneIndex).reduce((total, scene) => (scene.duration ? total + scene.duration : 0), 0)
    );
  }
  throw new Error('Invalid scene index');
};

export const sceneWidthPercentage = (totalDuration: number, sceneIndex: number, scenes: ArticleVideoScene[]) => {
  if (sceneIndex >= 0 && sceneIndex < scenes.length) {
    const sceneDuration = scenes[sceneIndex].duration || 0;
    return (sceneDuration / totalDuration) * 100;
  }
  throw new Error('Invalid scene index');
};

export const upToSceneWidthPercentage = (totalDuration: number, sceneIndex: number, scenes: ArticleVideoScene[]) => {
  if (sceneIndex >= 0 && sceneIndex <= scenes.length) {
    return (
      (scenes.slice(0, sceneIndex).reduce((total, scene) => (scene.duration ? total + scene.duration : 0), 0) /
        totalDuration) *
      100
    );
  }
  throw new Error('Invalid scene index');
};
