import i18n from './config';
import { distanceInWordsToNowWithCurrentLocale, formatDateWithCurrentLocale, parseISOSafe } from './datefns';

const dateLocalizationHelper = (locale: string) => {
  return {
    formatDateStringLocally: (date?: string) => {
      const dateObj = parseISOSafe(date);
      if (dateObj) {
        return formatDateWithCurrentLocale(dateObj, locale, 'PP');
      }
    },

    formatDateTimeStringLocally: (date?: string, format?: string) => {
      const dateObj = parseISOSafe(date);
      if (dateObj) {
        return formatDateWithCurrentLocale(dateObj, locale, format || 'PPpp');
      }
    },

    formatDistanceToNowWithDate: (date: Date | number, addSuffix = false) => {
      return distanceInWordsToNowWithCurrentLocale(date, locale, addSuffix);
    },

    formatDistanceToNowWithDateString: (date?: string, addSuffix = false) => {
      const dateObj = parseISOSafe(date);
      if (dateObj) {
        return distanceInWordsToNowWithCurrentLocale(dateObj, locale, addSuffix);
      }
    }
  };
};

const numberLocalizationHelper = (locale: string, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(locale, options);
};

/**
 * Simple constant for language manipulations.
 */
const localizationHelper = {
  setLocale: (lang: string) => {
    i18n.changeLanguage(lang);
  },

  getLocale: () => i18n.language,

  forDate: () => dateLocalizationHelper(localizationHelper.getLocale()),

  forNumber: (options?: Intl.NumberFormatOptions) => numberLocalizationHelper(localizationHelper.getLocale(), options)
};
export default localizationHelper;
