import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ProjectList, ProjectsHeader, ScrollingPage } from '@src/components';

export const ProjectsListPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="flex flex-col flex-nowrap space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.ProjectListPage')}</title>
        </Helmet>
        <ProjectsHeader />
        <ProjectList />
      </ScrollingPage>
    </HelmetProvider>
  );
};
