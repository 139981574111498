import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { RevisionStateBadge } from '@src/components';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import localizationHelper from '@src/i18n';
import { AnyVideoRevision } from '@src/models';

export const VideoSidebarRevisions = ({
  revisions,
  currentRevision,
  setShowVideoDialog
}: {
  revisions: AnyVideoRevision[];
  currentRevision: AnyVideoRevision;
  setShowVideoDialog: (showVideoDialog: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { updateVideoNavigationState } = useVideoNavigationParams();

  const currentRevisionIndex = revisions.findIndex(revision => revision.id === currentRevision.id);

  return (
    <Popover>
      <div className="border-b border-gray-200 bg-white pb-2 pt-4 lg:px-2">
        <div className="mx-auto max-w-7xl px-5 lg:px-3">
          <PopoverButton className="flex w-full flex-col justify-between gap-x-1 space-y-1 text-xs font-semibold tracking-wider text-gray-600">
            <div className="flex max-w-7xl items-center gap-2">
              <h3 className="inline-flex items-center gap-x-1 text-xs font-semibold uppercase tracking-wider text-gray-600">
                {t('components.common.revisions')}
              </h3>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <p className="flex w-full items-center py-2 text-xs font-medium text-gray-700">
              <span className="flex items-center gap-2">
                <span
                  className="flex h-7 w-7 cursor-help items-center justify-center rounded-lg border border-gray-200 bg-white text-xs text-gray-700"
                  title={
                    currentRevision.automated
                      ? t('components.article.ArticleVideo.sidebar.automated')
                      : t('components.article.ArticleVideo.sidebar.manual')
                  }
                >
                  {currentRevision.automated ? 'A' : currentRevisionIndex}
                </span>
                {localizationHelper
                  .forDate()
                  .formatDateTimeStringLocally(currentRevision.lastModified, 'MMM dd, h:mm a')}
              </span>
              <RevisionStateBadge revision={revisions[currentRevisionIndex]} />
            </p>
          </PopoverButton>
        </div>
      </div>
      <ul>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <PopoverPanel className="absolute z-50 w-full space-y-1 bg-white py-3 shadow-lg ring-1 ring-gray-900/5 sm:py-2 lg:px-2">
            {revisions.map((item, index) => (
              <li
                key={index}
                className={classNames(currentRevisionIndex === index ? 'cursor-default' : 'cursor-pointer', 'group')}
                onClick={() => {
                  if (item.videoType === 'SOCIAL_MEDIA') {
                    updateVideoNavigationState({
                      revisionId: item.id,
                      activeView: 'sm-settings',
                      assetsTab: 'search'
                    });
                  } else {
                    updateVideoNavigationState({
                      revisionId: item.id,
                      activeView: 'scenes',
                      sceneId: 1,
                      headlinesTab: 1,
                      assetsTab: 'suggested'
                    });
                  }
                  setShowVideoDialog(false);
                }}
              >
                <p
                  className={classNames(
                    'flex items-center rounded-md px-5 py-1 text-xs font-medium text-gray-500/60 sm:py-2 lg:px-3',
                    currentRevisionIndex === index ? 'bg-gray-200/40' : 'group-hover:bg-gray-200/30'
                  )}
                >
                  <span className="flex items-center gap-2">
                    <span
                      className={classNames(
                        'flex h-7 w-7 items-center justify-center rounded-lg border text-xs',
                        currentRevisionIndex === index
                          ? 'cursor-help border-indigo-500 bg-indigo-500 text-white'
                          : 'border-gray-200 bg-white text-gray-700'
                      )}
                      title={
                        item.automated
                          ? t('components.article.ArticleVideo.sidebar.automated')
                          : t('components.article.ArticleVideo.sidebar.manual')
                      }
                    >
                      {revisions[index].automated ? 'A' : index}
                    </span>
                    {localizationHelper
                      .forDate()
                      .formatDateTimeStringLocally(revisions[index].lastModified, 'MMM dd, h:mm a')}
                  </span>
                  <RevisionStateBadge revision={revisions[index]} />
                </p>
              </li>
            ))}
          </PopoverPanel>
        </Transition>
      </ul>
    </Popover>
  );
};
