import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Modal } from '@src/components/common';
import { useCreateBrand, useEditBrand, useQueryParams } from '@src/hooks';
import { Brand, StockAssetPreference, SummaryProviderType, VideoType, VoiceoverProviderType } from '@src/models';
import * as routes from '@src/routes';

export const AutoGenerateModal = ({
  generate,
  setGenerate
}: {
  generate: boolean;
  setGenerate: (generate: boolean) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [brandId, setBrandId] = useState('');
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();

  const { mutateAsync: createBrand, error: errorCreateBrand } = useCreateBrand();
  const { mutateAsync: createVideoTypes, error: errorCreateVideoTypes } = useEditBrand();

  const error = errorCreateBrand || errorCreateVideoTypes;

  const createExampleBrand = useCallback(async () => {
    const response: Brand = await createBrand({
      name: 'CNN',
      logoUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/CNN_International_logo.svg/2048px-CNN_International_logo.svg.png',
      language: 'en-GB',
      baseUrl: 'https://edition.cnn.com/'
    });

    setBrandId(response.id);

    await createVideoTypes({
      brandId: response.id,
      settings: {
        baseUrl: response.settings.baseUrl,
        language: response.settings.language,
        logoUrl: response.settings.logoUrl,
        name: response.settings.name,
        videoTypes: [
          {
            videoType: VideoType.SUMMARY_SLIDESHOW,
            projectId: 'summary-placeholder@v1',
            templateId: 'wide',
            design: true,
            enabled: true,
            targetLanguage: response.settings.language,
            defaultCta: 'Read more at edition.cnn.com',
            defaultLogoUrl: response.settings.logoUrl,
            stockAssetSettings: {
              assetPreference: StockAssetPreference.MIXED
            },
            summaryProviderSettings: {
              type: SummaryProviderType.CHAT_GPT,
              writingStyle: 'Follow the CNN news writing style'
            },
            voiceoverProviderSettings: {
              type: VoiceoverProviderType.GCP,
              audioConfigPitch: undefined,
              audioConfigSpeakingRate: undefined,
              voiceName: 'en-GB-News-J'
            }
          },
          {
            videoType: VideoType.SOCIAL_MEDIA,
            projectId: 'media-abstract@v1',
            templateId: 'story',
            design: true,
            defaultCta: 'Read more at edition.cnn.com',
            defaultMusic: undefined,
            enabled: true,
            targetLanguage: response.settings.language,
            defaultLogoUrl: response.settings.logoUrl
          }
        ]
      }
    });

    setShowModal(true);
    setGenerate(false);
  }, [createBrand, createVideoTypes, setGenerate]);

  useEffect(() => {
    if (generate) {
      createExampleBrand();
    }
  }, [createExampleBrand, generate]);

  return (
    <Modal visible={showModal}>
      <div className="mx-auto sm:w-full sm:max-w-lg">
        <div>
          <div
            className={classNames(
              'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
              error ? 'bg-red-100' : 'bg-green-100'
            )}
          >
            {error && <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
            {!error && <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">
              {error && t('components.brand.BrandsList.errorGenerating')}
              {!error && t('components.brand.BrandsList.doneGenerating')}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {error && t('components.brand.BrandsList.errorGeneratingDescription')}
                {!error && t('components.brand.BrandsList.generateExampleArticles')}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-col-reverse gap-3 sm:mt-6 sm:flex-row">
          <Button className="w-full justify-center" secondary onClick={() => setShowModal(false)}>
            {t('general.action.dismiss')}
          </Button>
          {!error && (
            <Link
              aria-disabled={!brandId}
              className="w-full disabled:opacity-50"
              to={withQueryParams(generatePath(routes.ARTICLE_CREATE, { brandId: brandId }), { test: true })}
            >
              <Button className="w-full justify-center">{t('general.action.generate')}</Button>
            </Link>
          )}
        </div>
      </div>
    </Modal>
  );
};
