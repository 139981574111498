import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { Button, List, Loading, TemplateListItem } from '@src/components';
import { Project, Template } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

export type TemplateListProps = PropsWithModel<Project> & {
  onAutoGenerateClick: () => void;
};

export const TemplateList = ({ loading, model: project, onAutoGenerateClick }: TemplateListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {loading && <Loading className="flex-1" title={t('components.project.template.TemplateList.loading')} />}
      {!loading && (
        <List
          emptyListMessage={t('components.project.template.TemplateList.empty')}
          data={project.templates}
          renderItem={(model: Template) => {
            return (
              <TemplateListItem
                key={model.id}
                projectId={project.id}
                model={model}
                isDefaultTemplate={project.defaultTemplateId === model.id}
                enableCheckMeta={project.analyzed}
              />
            );
          }}
          headerTitle={t('general.common.templates')}
          headerAction={
            <>
              <Button secondary onClick={onAutoGenerateClick} disabled={!project.analyzed}>
                {t('components.project.template.TemplateList.autoGenerate')}
              </Button>
              <Link
                className="ml-2"
                to={generatePath(routes.PROJECT_TEMPLATE_CREATE, { projectId: project.id, step: '' })}
              >
                <Button disabled={!project.analyzed}>{t('general.action.addNew')}</Button>
              </Link>
            </>
          }
        />
      )}
    </>
  );
};
