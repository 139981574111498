import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import {
  Alert,
  ArticleDeleteAction,
  ArticleVideosGrid,
  Breadcrumb,
  BreadcrumbItem,
  CopyIdAction,
  HeaderWithBreadcrumbs,
  HelpNotification,
  Loading,
  LoadingProps,
  NotFound,
  StyledA,
  StyledLink,
  VideoGeniusArticleDetailsPageHelpLinks
} from '@src/components';
import { useGetArticleDetails, useGetBrandDetails } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Article } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

type ArticleInformationProps = PropsWithModel<Article> & { brandName: string };

const ArticleInformation = ({ model: article, brandName }: ArticleInformationProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="article-information-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.article.ArticleDetails.articleInformation')}
        </h2>
      </div>
      <div className="border-t border-gray-200 bg-opacity-25 bg-cover bg-center bg-no-repeat px-4 py-6 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.article.ArticleDetails.title')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              {article.input.title && <span>{article.input.title}</span>}
              <span className="text-xs">
                <StyledA href={article.input.articleUrl} target="_blank" rel="noreferrer">
                  {t('components.article.common.viewOriginalArticle')}
                </StyledA>
              </span>
              <CopyIdAction className="mt-1" id={article.id} type="article" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t('components.article.ArticleDetails.articlesBrand')}
            </dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <StyledLink className="w-fit" to={generatePath(routes.BRAND_DETAILS, { id: article.brandId })}>
                {brandName}
              </StyledLink>
              <CopyIdAction className="mt-1" id={article.brandId} type="brand" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.createdOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(article.createdDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.lastModifiedOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(article.lastModified)}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

const LoadingArticleDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const ArticleDetails = ({ brandId, articleId }: { brandId?: string; articleId?: string }) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingArticle, data: article } = useGetArticleDetails(brandId, articleId);
  const { isLoading: isLoadingBrand, data: brand } = useGetBrandDetails(brandId);

  const isLoading = isLoadingArticle || isLoadingBrand;

  return (
    <>
      {isLoading && <LoadingArticleDetails title={t('components.article.ArticleDetails.loading')} />}
      {!isLoading && (
        <>
          {!article && <NotFound title={t('components.article.ArticleDetails.empty')} />}
          {article && brand && (
            <div className="space-y-6">
              <HeaderWithBreadcrumbs
                breadcrumbs={
                  <Breadcrumb>
                    <BreadcrumbItem to={generatePath(routes.ARTICLES)} label={t('general.common.articles')} />
                    <BreadcrumbItem
                      to={generatePath(routes.ARTICLES_BRAND_LIST, { brandId: article.brandId })}
                      label={brand.settings.name}
                    />
                    {article.input.title && (
                      <BreadcrumbItem to={window.location.pathname} label={article.input.title} truncate />
                    )}
                  </Breadcrumb>
                }
                header={t('components.article.ArticleDetails.articleDetailsTitle')}
                actions={<HelpNotification type="button" links={VideoGeniusArticleDetailsPageHelpLinks} />}
              />
              {(!article.input.title || !article.input.image) && (
                <Alert
                  className="text-sm font-medium text-yellow-800"
                  type="warn"
                  show
                  alertContent={
                    <Trans
                      i18nKey={'components.article.ArticleDetails.titleOrImageMissing'}
                      tOptions={{ phrase: 'here' }}
                    >
                      Title or image missing. We depend on the Open Graph, check
                      <a
                        className="text-indigo-600 hover:text-indigo-500"
                        href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#video-creation`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                      for details.
                    </Trans>
                  }
                />
              )}
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-3 lg:col-start-1">
                  <section>
                    <ArticleVideosGrid brandId={brandId} articleId={articleId} />
                  </section>
                  <section aria-labelledby="article-information-title">
                    <ArticleInformation model={article} brandName={brand.settings.name} />
                  </section>
                  <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    <ArticleDeleteAction
                      brandId={article.brandId}
                      articleId={article.id}
                      confirmation={t('components.article.ArticleDetails.deleteConfirmation')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
