import classNames from 'classnames';

export const Checkbox = ({
  id,
  checked,
  onChange,
  label,
  description,
  disabled,
  className,
  tooltip
}: {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  description: string;
  disabled?: boolean;
  className?: string;
  tooltip?: string;
}) => {
  return (
    <div
      className={classNames('relative flex items-start', disabled && 'opacity-50', tooltip && 'cursor-help', className)}
      title={tooltip}
    >
      <div className="flex h-5 items-center">
        <input
          id={id}
          name={id}
          type="checkbox"
          className={classNames(
            'h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500',
            disabled && 'cursor-not-allowed'
          )}
          checked={checked}
          onChange={e => onChange(e)}
          disabled={disabled}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className={classNames('font-medium text-gray-700', tooltip && 'cursor-help')}>
          {label}
        </label>
        <p className="text-gray-500">{description}</p>
      </div>
    </div>
  );
};
