// index
export const DASHBOARD = '/dashboard';

// index
export const DASHBOARD_START = '/dashboard/start';

// projects
export const PROJECTS_LIST = '/dashboard/projects';
export const PROJECTS_CREATE = '/dashboard/projects/create';
export const PROJECT_DETAILS = '/dashboard/projects/:id';
export const PROJECT_EDIT = '/dashboard/projects/:id/edit';

// project templates
export const PROJECT_TEMPLATE_CREATE = '/dashboard/projects/:projectId/templates/create/:step';
export const PROJECT_TEMPLATE_DETAILS = '/dashboard/projects/:projectId/templates/:templateId';
export const PROJECT_TEMPLATE_EDIT = '/dashboard/projects/:projectId/templates/:templateId/edit/:step';

// renders
export const RENDERS_LIST = '/dashboard/renders';
export const RENDER_FORM = '/dashboard/renders/new';
export const RENDER_DETAILS = '/dashboard/renders/:id';
export const RENDER_BATCH_CSV_PROJECT = '/dashboard/renders/batch/project/:projectId/:templateId/csv/:step';
export const RENDER_BATCH_CSV_DESIGN = '/dashboard/renders/batch/design/:projectId/:templateId/csv/:step';
export const RENDER_BATCH_DOWNLOAD = '/dashboard/renders/batch/download/:step';

// other
export const STATISTICS = '/dashboard/statistics';
export const USAGE_DETAILS = '/dashboard/usage/details';

// user
export const USER_SETTINGS = '/dashboard/user/profile';
export const USER_SETTINGS_GENERAL = '/dashboard/user/profile/general';
export const USER_INVITES = '/dashboard/user/profile/invites';

// organization
export const ORGANIZATION_SETTINGS = '/dashboard/user/settings';
export const ORGANIZATION_SETTINGS_GENERAL = '/dashboard/user/settings/general';
export const ORGANIZATION_SETTINGS_TEAM = '/dashboard/user/settings/team';
export const ORGANIZATION_SETTINGS_SUBSCRIPTION = '/dashboard/user/settings/subscription';

// payment
export const PAYMENT_CUSTOM = '/payment/custom/:productId';

// designs
export const PUBLIC_DESIGNS = '/dashboard/designs';
export const DESIGN_DETAILS_URL = '/dashboard/designs/:designId';
export const DESIGN_RENDER = '/dashboard/designs/:designId?render';

// integrations
export const INTEGRATIONS = '/dashboard/integrations';
export const INTEGRATIONS_TAB = '/dashboard/integrations/:tab';
export const INTEGRATIONS_LOGS = '/dashboard/integrations/logs';
export const INTEGRATIONS_ACCOUNTS = '/dashboard/integrations/accounts';

// videoGenius
export const VIDEO_GENIUS = '/video-genius';

//brands
export const BRANDS = '/video-genius/brands';
export const BRANDS_CREATE = '/video-genius/brands/create';
export const BRAND_DETAILS = '/video-genius/brands/:id';
export const BRAND_EDIT = '/video-genius/brands/:id/edit';
export const VIDEO_TYPE_CREATE = '/video-genius/brands/:id/create';
export const VIDEO_TYPE_EDIT = '/video-genius/brands/:id/:videoId/edit';

// articles
export const ARTICLES = '/video-genius/articles';
export const ARTICLES_BRAND_LIST = '/video-genius/articles/:brandId/all';
export const ARTICLE_CREATE = '/video-genius/articles/:brandId/create';
export const ARTICLE_DETAILS = '/video-genius/articles/:brandId/:articleId';

// videos
export const VIDEO_DETAILS = '/video-genius/articles/:brandId/:articleId/videos/:videoId';
