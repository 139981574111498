import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Loading, ProjectCreateForm, ScrollingPage } from '@src/components';
import { useGetProjectDetails } from '@src/hooks';

export const ProjectEditPage = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const { isLoading, data: project, error } = useGetProjectDetails(id);

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.ProjectEditPage')}</title>
        </Helmet>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {error ||
              (!project && (
                <div>
                  <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                    <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                    {t('components.project.ProjectEditPage.errorLoading')}
                  </p>
                </div>
              ))}
            {project && <ProjectCreateForm project={project} />}
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
