import { useQuery } from 'react-query';

import { BatchRenderDto, Render } from '@src/models';

import { useAxiosRead } from '../useAxios';

import { getRenderListRefreshInterval } from './useRenderApi';

const BATCH_CACHE_ROOT = 'batch';

export const useGetBatchRenderList = () => {
  const { get } = useAxiosRead<BatchRenderDto[]>();

  const cacheKey = [BATCH_CACHE_ROOT, 'list'];

  const { data, isLoading, error, isRefetching } = useQuery<BatchRenderDto[]>(cacheKey, async () => {
    const response = await get('/renders/batch/list');
    return response.data;
  });

  return { data, isLoading, error, isRefetching };
};

export const useGetBatchRendersById = (batchRenderId: string | undefined) => {
  const { get } = useAxiosRead<Render[]>();

  const cacheKey = [BATCH_CACHE_ROOT, batchRenderId];

  const { data, isLoading, error, isRefetching } = useQuery<Render[]>(
    cacheKey,
    async () => {
      const response = await get('/renders/batch', { params: { batchRenderId } });
      return response.data;
    },
    {
      refetchInterval: getRenderListRefreshInterval,
      enabled: !!batchRenderId
    }
  );

  return { data, isLoading, error, isRefetching };
};
