import { LayerEffectType } from '@plainly/types';
import { DataEffectLayer, DataLayer, Layer, LayerType, MediaLayer, SolidColorLayer } from '@src/models';
import { isEmpty, isValidHexColor } from '@src/utils';

export class ValidationResult {
  constructor(
    public layerId: string,
    public errorCodes: string[] = [],
    public key: string | undefined = undefined
  ) {}

  public addErrorCode = (errorCode: string) => this.errorCodes.push(errorCode);
  public isValid = () => isEmpty(this.errorCodes);
}

const validateLayer = (layer: Layer, value: string, key?: string): ValidationResult => {
  return new ValidationResult(layer.internalId, [], key);
};

const validateDataLayer = async (layer: DataLayer, value: string, key?: string): Promise<ValidationResult> => {
  return validateLayer(layer, value, key);
};

const validateMediaLayer = async (layer: MediaLayer, value: string, key?: string): Promise<ValidationResult> => {
  const result = validateLayer(layer, value, key);
  if (!value) return result;
  return result;
};

const validateSolidLayer = async (layer: SolidColorLayer, value: string, key?: string): Promise<ValidationResult> => {
  const result = validateLayer(layer, value, key);
  if (!value) return result;

  if (!isValidHexColor(value)) {
    result.addErrorCode('components.render.RenderForm.errors.invalidHexColor');
  }

  return result;
};

const validateDataEffectLayer = async (
  layer: DataEffectLayer,
  value: string,
  key?: string
): Promise<ValidationResult> => {
  const result = validateLayer(layer, value, key);
  if (!value) return result;

  switch (layer.effectType) {
    case LayerEffectType.EFFECT_COLOR_CONTROL:
      if (!isValidHexColor(value)) {
        result.addErrorCode('components.render.RenderForm.errors.invalidHexColor');
      }
      break;
    case LayerEffectType.EFFECT_SLIDER_CONTROL: {
      const sliderValue = parseFloat(value);
      if (isNaN(sliderValue)) {
        result.addErrorCode('components.render.RenderForm.errors.nan');
      }
      if (sliderValue > 100 || sliderValue < 0) {
        result.addErrorCode('components.render.RenderForm.errors.sliderValueOutOfRange');
      }
      break;
    }
  }

  return result;
};

export const validate = async (layer: Layer, value: string, key?: string): Promise<ValidationResult> => {
  switch (layer.layerType) {
    case LayerType.DATA:
      return await validateDataLayer(layer, value, key);
    case LayerType.MEDIA:
      return await validateMediaLayer(layer, value, key);
    case LayerType.SOLID_COLOR:
      return await validateSolidLayer(layer, value, key);
    case LayerType.COMPOSITION:
      // composition always valid
      return new ValidationResult(layer.internalId);
    case LayerType.DATA_EFFECT:
      return await validateDataEffectLayer(layer, value, key);
  }
};
