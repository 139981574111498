import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AcceptableClientSideApiError,
  ClientSideApiError,
  ErrorCode,
  ErrorOptions,
  GeneralCommunicationApiError,
  NoInternetConnectionApiError,
  PlainlyApiError,
  ServerSideApiError
} from '@src/models';

import { useActiveOrganizationId } from './useActiveOrganizationId';

export const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
export const WEB_API_URL_V2 = `${BASE_URL}/webapi/v2`;
const PLAINLY_ERROR_CODE_HEADER = 'X-PlainlyErrorCode'.toLowerCase();

const defaultConfig: AxiosRequestConfig = {
  baseURL: WEB_API_URL_V2,
  responseType: 'json'
};

const attachHeaders = (instance: AxiosInstance, headers: Partial<AxiosRequestHeaders>) => {
  Object.keys(headers).forEach((key: string) => {
    instance.defaults.headers[key] = headers[key];
  });
};

/**
 * Relativize the given url against the WEB_API_URL_V2 by removing
 * the basePath if it matches the start of the given url.
 *
 * @param url - the url to relativize
 * @returns the relativized url
 */
export const relativizeUrl = (url: string): string => {
  // Extract the path part of the BASE_URL
  const basePath = new URL(WEB_API_URL_V2).pathname;
  let newUrl = url;

  // Check if the url starts with the basePath and remove it if true
  if (newUrl.startsWith(basePath)) {
    newUrl = url.substring(basePath.length);
  }

  return newUrl;
};

/**
 * API error handling explanation:
 * - we intercept the non-OK responses
 * - if we find our error header, construct new instance of the PlainlyApiError and throw it
 * - if this is not the case, translate to one of the general error codes depending on the response code
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toPlainlyError = (error: any, errorOptions: ErrorOptions): PlainlyApiError => {
  const response: AxiosResponse | undefined = error.response;
  if (response) {
    const code = response.headers[PLAINLY_ERROR_CODE_HEADER];
    const errorCode = Object.values(ErrorCode).find(e => `${e}` === code);
    const message = response.data?.message;
    const errors = response.data?.errors;

    if (errorCode) {
      return new PlainlyApiError(errorCode, errorOptions, false, message, errors);
    } else {
      const { status } = response;

      // for server side errors use dedicated one
      if (status >= 500) {
        return new ServerSideApiError(errorOptions, status, message, errors);
      }

      // for client side, split based on status code
      if (status >= 400) {
        if (status === 401) {
          return new AcceptableClientSideApiError(ErrorCode.GENERAL_UNAUTHORIZED, errorOptions, status);
        } else if (status === 403) {
          return new AcceptableClientSideApiError(ErrorCode.GENERAL_FORBIDDEN, errorOptions, status);
        } else if (status === 429) {
          return new AcceptableClientSideApiError(ErrorCode.GENERAL_TOO_MANY_REQUESTS, errorOptions, status);
        } else {
          return new ClientSideApiError(errorOptions, status, message, errors);
        }
      }
    }
  }

  // check if user is online
  if (!navigator.onLine) {
    return new NoInternetConnectionApiError(errorOptions);
  }

  // this is the final escape if it's nothing of above
  const errorMessage = error.message || `${error}`;
  return new GeneralCommunicationApiError(errorOptions, errorMessage);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAxios = <TReq = any, TRes = any>(config?: AxiosRequestConfig<TReq>, errorOptions?: ErrorOptions) => {
  const { getAccessTokenSilently } = useAuth0();
  const { getActiveOrganizationId } = useActiveOrganizationId();

  const instance = axios.create({
    ...defaultConfig,
    ...(config || {})
  });

  attachHeaders(instance, {});

  instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      if (typeof config.headers.Authorization === 'undefined') {
        config.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
      }

      const activeOrganizationId = getActiveOrganizationId();
      if (activeOrganizationId) {
        config.headers['x-plainly-organization-id'] = activeOrganizationId;
      }

      return config;
    } catch (error) {
      console.error('Error in request interceptor', JSON.stringify(error, null, '\t'));
      throw error;
    }
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      console.error(`Error in response for request`, JSON.stringify(error, null, '\t'));
      return Promise.reject(toPlainlyError(error, errorOptions || {}));
    }
  );

  return {
    get: async (url: string, config?: AxiosRequestConfig<TReq> | undefined): Promise<AxiosResponse<TRes, TReq>> =>
      instance.get(url, config),
    delete: async (url: string, config?: AxiosRequestConfig<TReq> | undefined): Promise<AxiosResponse<TRes, TReq>> =>
      instance.delete(url, config),
    post: async (
      url: string,
      data: TReq,
      config?: AxiosRequestConfig<TReq> | undefined
    ): Promise<AxiosResponse<TRes, TReq>> => instance.post(url, data, config),
    put: async (
      url: string,
      data: TReq,
      config?: AxiosRequestConfig<TReq> | undefined
    ): Promise<AxiosResponse<TRes, TReq>> => instance.put(url, data, config),
    patch: async (
      url: string,
      data: TReq,
      config?: AxiosRequestConfig<TReq> | undefined
    ): Promise<AxiosResponse<TRes, TReq>> => instance.patch(url, data, config)
  };
};

export const useAxiosRead = <TRes>(config?: AxiosRequestConfig<never>, errorOptions?: ErrorOptions) =>
  useAxios<never, TRes>(config, errorOptions);

export const useAxiosModify = <TReq, TRes>(config?: AxiosRequestConfig<TReq>, errorOptions?: ErrorOptions) =>
  useAxios<TReq, TRes>(config, errorOptions);
