import { VoiceoverFormInputs } from '@src/components/publishers/forms';
import { AnyVoiceoverProviderSettings, RevisionState } from '@src/models';

export const VoiceoverForm = ({
  state,
  currentVoiceover,
  setCurrentVoiceover
}: {
  state: RevisionState;
  currentVoiceover: AnyVoiceoverProviderSettings;
  setCurrentVoiceover: (currentVoiceover: AnyVoiceoverProviderSettings) => void;
}) => {
  return (
    <div className="space-y-6 pt-6">
      <VoiceoverFormInputs
        disabled={state !== RevisionState.DRAFT && state !== RevisionState.ERROR}
        inputs={currentVoiceover}
        setInputs={setCurrentVoiceover}
      />
    </div>
  );
};
