export enum StockProviderType {
  RF123 = 'RF123',
  UNSPLASH = 'UNSPLASH',
  PEXELS = 'PEXELS'
}

export enum StockAssetPreference {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  MIXED = 'MIXED'
}

export type StockAssetSettings = {
  assetPreference: StockAssetPreference;
};

export type StockAssetSearchIn = {
  assetPreference?: StockAssetPreference;
  automated: boolean;
  providers?: StockProviderType[];
  query: string[];
};

export enum Rank {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  EXCEPTIONAL = 'EXCEPTIONAL'
}

type StockAssetType = 'IMAGE' | 'VIDEO';

export type StockAssetSearchOut = {
  id: string;
  provider: StockProviderType;
  assetType: StockAssetType;
  query?: string[];
  url?: string;
  thumb: string;
  width?: number;
  height?: number;
  duration?: number;
  paid: boolean;
  preview: boolean;
  rank: Rank;
};

export type StockAssetSearch = {
  in: StockAssetSearchIn;
  outs: StockAssetSearchOut[];
};

export type StockAsset =
  | {
      type: 'manual';
      url: string;
      assetType: StockAssetType;
    }
  | {
      type: 'stock';
      out: StockAssetSearchOut;
    };
