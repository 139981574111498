import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Description, Field, Label, Switch } from '@headlessui/react';
import { Button, FeatureGating, Modal, StyledA } from '@src/components';
import { useSetProjectSharing } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ErrorCode, PlainlyPackage, Project } from '@src/models';
import { PropsWithModel } from '@src/types';

type ProjectSharingModalProps = {
  visible: boolean;
  projectId: string;
  closeModal: () => void;
};

const ProjectSharingEnableModalContent = ({ projectId, closeModal }: Omit<ProjectSharingModalProps, 'visible'>) => {
  const { t } = useTranslation();
  const [autoLatest, setAutoLatest] = useState(true);

  const { isLoading, mutateAsync: createShareUrl } = useSetProjectSharing();

  const handleSubmit = async () => {
    await createShareUrl({
      projectId,
      shareInfo: {
        enabled: true,
        autoLatest
      }
    });

    closeModal();
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="mx-auto max-w-xl transform rounded-xl transition-all">
        <Field as="div" className="flex items-center justify-between">
          <span className="flex flex-grow flex-col">
            <Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
              {t('components.project.ProjectSharingCard.autoLatest')}
            </Label>
            <Description as="span" className="text-sm text-gray-500">
              {t('components.project.ProjectSharingCard.autoLatestDescription')}
            </Description>
          </span>
          <Switch
            checked={autoLatest}
            onChange={() => setAutoLatest(!autoLatest)}
            className={classNames(
              autoLatest ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                autoLatest ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </Field>
      </div>
      <div className="flex justify-end pt-4">
        <Button secondary className="mr-3" onClick={closeModal}>
          {t('general.action.cancel')}
        </Button>
        <Button type="submit" loading={isLoading} disabled={isLoading}>
          {t('components.project.ProjectSharingCard.enable')}
        </Button>
      </div>
    </form>
  );
};

const ProjectSharingEnableModal = ({ visible, projectId, closeModal }: ProjectSharingModalProps) => {
  return (
    <Modal visible={visible} close={closeModal}>
      <ProjectSharingEnableModalContent projectId={projectId} closeModal={closeModal} />
    </Modal>
  );
};

const ProjectSharingDisableModalContent = ({ projectId, closeModal }: Omit<ProjectSharingModalProps, 'visible'>) => {
  const { t } = useTranslation();

  const { isLoading, mutateAsync: createShareUrl } = useSetProjectSharing();

  const handleSubmit = async () => {
    await createShareUrl({
      projectId,
      shareInfo: {
        enabled: false
      }
    });

    closeModal();
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className="mx-auto max-w-xl transform rounded-xl transition-all">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {' '}
          {t('components.project.ProjectSharingCard.disableTitle')}
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{t('components.project.ProjectSharingCard.disableInfo')}</p>
        </div>
      </div>
      <div className="flex justify-end pt-4">
        <Button secondary className="mr-3" onClick={closeModal}>
          {t('general.action.cancel')}
        </Button>
        <Button type="submit" loading={isLoading} disabled={isLoading} danger>
          {t('components.project.ProjectSharingCard.disable')}
        </Button>
      </div>
    </form>
  );
};

const ProjectSharingDisableModal = ({ visible, projectId, closeModal }: ProjectSharingModalProps) => {
  return (
    <Modal visible={visible} close={closeModal}>
      <ProjectSharingDisableModalContent projectId={projectId} closeModal={closeModal} />
    </Modal>
  );
};

type ProjectSharingCardProps = PropsWithModel<Project>;

export const ProjectSharingCard = ({ model: project, loading }: ProjectSharingCardProps) => {
  const { t } = useTranslation();
  const [enableModal, setEnableModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const sharing = project.sharing;

  const { isLoading: autoLatestLoading, mutateAsync: createShareUrl } = useSetProjectSharing();

  const handleAutoLatestChange = (value: boolean) => {
    createShareUrl({
      projectId: project.id,
      shareInfo: {
        enabled: true,
        autoLatest: value
      }
    });
  };

  const enabled = sharing?.enabled;

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('components.project.ProjectSharingCard.header')}
              </h3>
              <p className="mt-1 text-sm text-gray-500">{t('components.project.ProjectSharingCard.subHeader')}</p>
            </div>
            <div className="ml-4 mt-2 shrink-0">
              {!enabled && (
                <FeatureGating
                  enabledPackages={[PlainlyPackage.TEAM, PlainlyPackage.PRO]}
                  freeTrialAllowed={true}
                  message={t(`general.errorCode.${ErrorCode.FEATURE_GATING_NOT_ALLOWED_SHARING_LINKS}`)}
                >
                  <Button disabled={!project.analyzed} onClick={() => setEnableModal(true)}>
                    {t('components.project.ProjectSharingCard.enable')}
                  </Button>
                </FeatureGating>
              )}
              {enabled && (
                <Button onClick={() => setDisableModal(true)}>
                  {t('components.project.ProjectSharingCard.disable')}
                </Button>
              )}
            </div>
          </div>
        </div>
        {enabled && sharing && (
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t('components.project.ProjectSharingCard.enabledOn')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {localizationHelper.forDate().formatDateStringLocally(sharing.enabledDate)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t('components.project.ProjectSharingCard.autoLatest')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <Field as="div" className="flex items-center">
                    <Switch
                      disabled={loading || autoLatestLoading}
                      checked={sharing.autoLatest}
                      onChange={(value: boolean) => handleAutoLatestChange(value)}
                      className={classNames(
                        sharing.autoLatest ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                        autoLatestLoading && 'animate-pulse-tailwind'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          sharing.autoLatest ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                    <Label as="span" className="ml-3 text-sm">
                      <span>{t('components.project.ProjectSharingCard.autoLatestDescription')}</span>
                    </Label>
                  </Field>
                </dd>
              </div>
            </dl>
          </div>
        )}
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          {!enabled && (
            <div className="text-sm leading-6">
              <StyledA
                href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#static-link-sharing`}
                target="_blank"
                rel="noreferrer"
              >
                {t('components.project.ProjectSharingCard.learn')}
                <span aria-hidden="true"> &rarr;</span>
              </StyledA>
            </div>
          )}
          {enabled && (
            <p className="text-sm italic text-gray-500">{t('components.project.ProjectSharingCard.locationInfo')}</p>
          )}
        </div>
      </div>
      <ProjectSharingEnableModal
        visible={enableModal}
        closeModal={() => setEnableModal(false)}
        projectId={project.id}
      />
      <ProjectSharingDisableModal
        visible={disableModal}
        closeModal={() => setDisableModal(false)}
        projectId={project.id}
      />
    </>
  );
};
