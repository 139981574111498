import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, ExcludeLayers, TargetComposition } from '@src/components';
import { AnyAutoCreateTemplateDto, AutoCreateTemplateType } from '@src/models';

export const AllAutoGenerateTemplatesForm = ({
  setData,
  projectId
}: {
  setData: (data: AnyAutoCreateTemplateDto | undefined) => void;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const [allLayers, setAllLayers] = useState(false);
  const [greedy, setGreedy] = useState(false);
  const [targetCompositionName, setTargetCompositionName] = useState<string | undefined>();
  const [exclude, setExclude] = useState({
    excludeAdjustmentLayers: false,
    excludeDisabledLayers: false,
    excludeGuideLayers: false
  });

  useEffect(() => {
    setData({
      type: AutoCreateTemplateType.all,
      allLayers,
      greedy,
      targetCompositionName: targetCompositionName || undefined,
      excludeAdjustmentLayers: exclude.excludeAdjustmentLayers,
      excludeDisabledLayers: exclude.excludeDisabledLayers,
      excludeGuideLayers: exclude.excludeGuideLayers
    });
  }, [
    allLayers,
    exclude.excludeAdjustmentLayers,
    exclude.excludeDisabledLayers,
    exclude.excludeGuideLayers,
    greedy,
    setData,
    targetCompositionName
  ]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset className="space-y-5">
        <TargetComposition
          targetCompositionName={targetCompositionName}
          setTargetCompositionName={setTargetCompositionName}
          context="all"
          projectId={projectId}
        />
        <Checkbox
          id={'greedy'}
          checked={greedy === true}
          onChange={e => setGreedy(e.target.checked)}
          label={t('components.project.autoGenerateTemplates.AllAutoGenerateTemplatesForm.greedy')}
          description={t('components.project.autoGenerateTemplates.AllAutoGenerateTemplatesForm.greedyDesc')}
        />
        <Checkbox
          id={'allLayers'}
          checked={allLayers === true}
          onChange={e => setAllLayers(e.target.checked)}
          label={t('components.project.autoGenerateTemplates.AllAutoGenerateTemplatesForm.allLayers')}
          description={t('components.project.autoGenerateTemplates.AllAutoGenerateTemplatesForm.allLayersDesc')}
        />
        <ExcludeLayers exclude={exclude} setExclude={setExclude} disabled={!allLayers} />
      </fieldset>
    </div>
  );
};
