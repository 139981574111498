import { ReactNode } from 'react';

import { Header, HeaderProps } from '@src/components';

export type HeaderWithBreadcrumbsProps = HeaderProps & {
  breadcrumbs: ReactNode;
};

export const HeaderWithBreadcrumbs = ({ breadcrumbs, ...rest }: HeaderWithBreadcrumbsProps) => (
  <div>
    <div>{breadcrumbs}</div>
    <Header {...rest} className="mt-2" />
  </div>
);
