import {
  VideoSidebarResources,
  VideoSidebarRevisions,
  VideoSidebarScenes,
  VideoSidebarSettings
} from '@src/components';
import { AnyVideoRevision, ArticleVideoScene } from '@src/models';

export const VideoSidebarOptions = ({
  originalArticleUrl,
  revisions,
  currentRevision,
  setShowVideoDialog,
  scenes
}: {
  originalArticleUrl: string;
  revisions: AnyVideoRevision[];
  currentRevision: AnyVideoRevision;
  setShowVideoDialog: (showVideoDialog: boolean) => void;
  scenes?: ArticleVideoScene[];
}) => {
  return (
    <>
      <VideoSidebarRevisions
        revisions={revisions}
        currentRevision={currentRevision}
        setShowVideoDialog={setShowVideoDialog}
      />
      <div className="flex h-full flex-col space-y-8">
        {scenes && <VideoSidebarScenes scenes={scenes} setShowVideoDialog={setShowVideoDialog} />}
        <VideoSidebarSettings videoType={currentRevision.videoType} setShowVideoDialog={setShowVideoDialog} />
        <VideoSidebarResources
          originalArticleUrl={originalArticleUrl}
          renderId={currentRevision.finalRender?.renderId || currentRevision.previewRender?.renderId}
        />
      </div>
    </>
  );
};
