import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DesignFormField } from '@src/components';
import { useFireDesignAppRender, useNotifications, useValidateUrls } from '@src/hooks';
import { useDesignCurl } from '@src/hooks/utils/useDesignCurl';
import { AbstractRenderDto, AppRender, ColorPalette, DesignApp, DesignParameterType, DesignVariant } from '@src/models';
import { RENDER_DETAILS } from '@src/routes';
import { addAdvancedOptions } from '@src/utils';

import { Button, ButtonCopyCurl } from '../common';

export type DesignAppFormProps = {
  design: DesignApp;
  selectedColor: ColorPalette | undefined;
  selectedVariant: DesignVariant | undefined;
  advancedOptions?: AbstractRenderDto;
  closeForm: () => void;
};
export const DesignAppForm = ({
  design,
  selectedColor,
  selectedVariant,
  advancedOptions,
  closeForm
}: DesignAppFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyInfo } = useNotifications();
  const { app } = design;

  const [render, setRender] = useState<AppRender>({
    designId: design?.id
  });

  const { isLoading, mutateAsync: postRender } = useFireDesignAppRender(app.webApiUrl);

  const mandatoryInputs = design.app?.inputs
    .filter(i => !i.optional)
    .flatMap(i => i.key)
    .reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});

  const renderInputs = design.app?.inputs
    .filter(i => (render || {})[i.key])
    .flatMap(i => i.key)
    .reduce((acc, cur) => ({ ...acc, [cur]: render[cur] }), {});

  const inputs = { ...mandatoryInputs, ...renderInputs };

  const { curlWithApiKey, curlWithoutApiKey } = useDesignCurl({
    designId: render.designId || '',
    variantId: render.variantId || '',
    colorPalette: render.colorPalette || '',
    parameters: render.parameters,
    inputs: inputs,
    outputFormat: advancedOptions?.outputFormat || render.outputFormat,
    webhook: advancedOptions?.webhook,
    options: advancedOptions?.options,
    url: app?.apiUrl && `${import.meta.env.VITE_APP_BASE_URL}${app.apiUrl}`
  });

  const onFormFieldUpdate = (key: string, value: string | boolean) => {
    const newRender = {
      ...render,
      parameters: {
        ...render.parameters,
        [key]: value
      }
    };

    if (!value) {
      delete newRender.parameters[key];
    }

    setRender(newRender);
  };

  const onFormInputUpdate = (key: string, value: string | boolean) => {
    const newRender = {
      ...render,
      [key]: value
    };

    if (!value) {
      delete newRender[key];
    }

    setRender(newRender);
  };

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const canRender =
    (render &&
      app?.inputs.filter(i => !i.optional).every(i => render[i.key || '']) &&
      app.parameters.filter(p => !p.optional).every(p => (render.parameters || {})[p.key]) &&
      urlsValid) ||
    false;

  useEffect(() => {
    setRender(prev => ({
      ...prev,
      colorPalette: selectedColor?.group || prev.colorPalette,
      variantId: selectedVariant?.id || prev.variantId
    }));
  }, [selectedColor, selectedVariant]);

  useEffect(() => {
    if (advancedOptions) {
      setRender(prev => addAdvancedOptions(prev, advancedOptions));
    }
  }, [advancedOptions]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    canRender &&
      postRender(render).then(response => {
        if (!response) {
          return;
        }
        notifyInfo(t('components.render.RenderForm.renderSubmitted'));
        navigate(generatePath(RENDER_DETAILS, { id: response.id }));
      });
  };

  return (
    <form onSubmit={e => onSubmit(e)}>
      <div className="space-y-6 bg-white px-4 py-6 sm:px-6">
        {app?.inputs.map((p, index) => (
          <DesignFormField
            key={index}
            parameter={p}
            onChange={onFormInputUpdate}
            value={render[p.key] ? render[p.key] : undefined}
            onValidation={handleInvalidUrls}
          />
        ))}
        {app?.parameters.map((p, index) =>
          p.type === DesignParameterType.COLOR && selectedColor?.group !== undefined ? null : (
            <DesignFormField
              key={index}
              parameter={p}
              onChange={onFormFieldUpdate}
              value={render.parameters ? render.parameters[p.key] : undefined}
              onValidation={handleInvalidUrls}
            />
          )
        )}
      </div>
      <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
        <ButtonCopyCurl curlWithApiKey={curlWithApiKey} curlWithoutApiKey={curlWithoutApiKey} />
        <div>
          <Button secondary className="mr-3" onClick={closeForm}>
            {t('general.action.cancel')}
          </Button>
          <Button type="submit" loading={isLoading} disabled={isLoading || !canRender}>
            {t('general.action.render')}
          </Button>
        </div>
      </div>
    </form>
  );
};
