import { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SignalIcon } from '@heroicons/react/20/solid';
import { useGetTeamMembers } from '@src/hooks';

export const CreatedByInformation = ({ userId }: { userId: string | undefined }) => {
  const { t } = useTranslation();
  const { data: teamMembers, isLoading } = useGetTeamMembers(!!userId);
  const createdBy = useMemo(() => teamMembers?.find(user => user.id === userId), [teamMembers, userId]);

  return (
    <span
      title={!userId ? t('components.common.user.CreatedByInformation.unknown') : undefined}
      className={classNames(!userId && 'cursor-help')}
    >
      {isLoading && <SignalIcon className="h-4 w-4 animate-spin" />}
      {!isLoading && (
        <>
          {!userId && '-'}
          {userId && (
            <>
              {!createdBy && t('components.common.user.CreatedByInformation.exMember')}
              {createdBy && createdBy.name}
            </>
          )}
        </>
      )}
    </span>
  );
};
