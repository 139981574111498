import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { OutputFormatType, PostEncode } from '@src/models';

type PostEncodingType = 'default' | 'none' | 'custom' | 'smallest' | 'captions' | 'scale';

export const PostEncoding = ({
  onPostEncodeTypeChange,
  postEncoding
}: {
  onPostEncodeTypeChange: (postEncoding?: PostEncode) => void;
  postEncoding?: PostEncode;
}) => {
  const { t } = useTranslation();

  const [type, setType] = useState<PostEncodingType | undefined>(postEncoding?.type);
  const [encodingFormat, setEncodingFormat] = useState<OutputFormatType | undefined>(
    postEncoding?.type === 'custom' ? postEncoding?.encodingFormat : undefined
  );
  const [encodingParamsLine, setEncodingParamsLine] = useState<string | undefined>(
    postEncoding?.type === 'custom' ? postEncoding?.encodingParamsLine : undefined
  );
  const [scalingPercentage, setScalingPercentage] = useState<number | undefined>(
    postEncoding?.type === 'scale' ? postEncoding?.scalingPercentage : undefined
  );

  useEffect(() => {
    if (type === 'default' || type === 'none' || type === 'smallest') {
      onPostEncodeTypeChange({ type });
    } else if (type === 'custom' && (encodingFormat || encodingParamsLine)) {
      onPostEncodeTypeChange({
        type,
        encodingFormat: encodingFormat ? encodingFormat : undefined,
        encodingParamsLine: encodingParamsLine ? encodingParamsLine : undefined
      });
    } else if (type === 'scale' && scalingPercentage) {
      onPostEncodeTypeChange({ type, scalingPercentage });
    } else {
      onPostEncodeTypeChange(undefined);
    }
  }, [encodingFormat, encodingParamsLine, onPostEncodeTypeChange, scalingPercentage, type]);

  return (
    <>
      <div className="sm:col-span-6">
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
          {t('components.render.common.postEncodingType')}
        </label>
        <p className="text-sm text-gray-500">
          {t('components.render.advancedOptions.PostEncoding.postEncodingTypeDescription')}
        </p>
        <select
          id="type"
          name="type"
          autoComplete="type"
          className={classNames(
            'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
            !type && 'text-gray-500'
          )}
          defaultValue={type || ''}
          onChange={e => setType(e.target.value as 'default' | 'none' | 'custom' | 'smallest')}
        >
          <option key="" value="">
            {t('components.render.advancedOptions.PostEncoding.postEncodingTypeSelect')}
          </option>
          <option key="default" value="default">
            {t('components.render.common.postEncodingType', { context: 'default' })}
          </option>
          <option key="none" value="none">
            {t('components.render.common.postEncodingType', { context: 'none' })}
          </option>
          <option key="custom" value="custom">
            {t('components.render.common.postEncodingType', { context: 'custom' })}
          </option>
          <option key="smallest" value="smallest">
            {t('components.render.common.postEncodingType', { context: 'smallest' })}
          </option>
          <option key="scale" value="scale">
            {t('components.render.common.postEncodingType', { context: 'scale' })}
          </option>
        </select>
      </div>
      {type === 'custom' && (
        <>
          <div className="sm:col-span-6">
            <label htmlFor="format" className="block text-sm font-medium text-gray-700">
              {t('components.render.RenderAdvancedOptionsForm.format')}
            </label>
            <p className="text-sm text-gray-500">
              {t('components.render.RenderAdvancedOptionsForm.formatDescription')}
            </p>
            <select
              id="format"
              name="format"
              autoComplete="format"
              className={classNames(
                'mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
                !encodingFormat && 'text-gray-500'
              )}
              defaultValue={encodingFormat || ''}
              onChange={e => setEncodingFormat(e.target.value as OutputFormatType)}
            >
              <option key="" value="">
                {t('components.render.RenderAdvancedOptionsForm.formatSelect')}
              </option>
              {Object.keys(OutputFormatType).map(formatType => (
                <option key={formatType} value={formatType}>
                  {t('components.render.RenderForm.outputFormat', { context: formatType })}
                </option>
              ))}
            </select>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="encodingParams" className="block text-sm font-medium text-gray-700">
              {t('components.render.common.encodingParams')}
            </label>
            <p className="text-sm text-gray-500">
              {t('components.render.RenderAdvancedOptionsForm.encodingParamsDescription')}
            </p>
            <div className="flex items-center">
              <input
                type="text"
                name="encodingParam"
                id="encodingParam"
                key="encodingParam"
                autoComplete="encodingParam"
                placeholder="e.g.: -vcodec libx264 -pix_fmt yuv420p"
                className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={encodingParamsLine}
                onChange={e => setEncodingParamsLine(e.target.value)}
              />
            </div>
          </div>
        </>
      )}
      {type === 'scale' && (
        <div className="sm:col-span-6">
          <label htmlFor="scalingPercentage" className="block text-sm font-medium text-gray-700">
            {t('components.render.RenderAdvancedOptionsForm.scalingPercentage')}
          </label>
          <p className="text-sm text-gray-500">
            {t('components.render.RenderAdvancedOptionsForm.scalingPercentageDesc')}
          </p>
          <div className="relative flex items-center">
            <input
              type="number"
              name="scalingPercentage"
              id="scalingPercentage"
              inputMode="numeric"
              min={1}
              max={400}
              className="block w-full rounded-md border border-gray-300 px-3 py-2 pr-8 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={scalingPercentage}
              onChange={e => {
                const value = parseInt(e.target.value);
                value > 0 ? setScalingPercentage(value) : setScalingPercentage(undefined);
              }}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span id="percentage" className="text-gray-500 sm:text-sm">
                %
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
