import { Trans, useTranslation } from 'react-i18next';

import { SubscriptionResourcesDto } from '@src/models';
import { formatResourceUsage, toMb } from '@src/utils';

type SubscriptionResourcesCardProps = {
  resources: SubscriptionResourcesDto;
};

export const SubscriptionResourcesCard = ({ resources }: SubscriptionResourcesCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.user.SubscriptionResourcesCard.title')}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {t('components.user.SubscriptionResourcesCard.subtitle')}
            </p>
          </div>
        </div>
      </div>
      <div className="border-b border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {resources.storageBytesLimit && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.usage.common.resourceName', { context: 'STORAGE' })}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <span>{formatResourceUsage(toMb(resources.storageBytesLimit), 0)}</span>
                <span className="ml-1">MB</span>
              </dd>
            </div>
          )}
          {resources.renderingSecondsLimit && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.usage.common.resourceName', { context: 'RENDERING_VIDEO_DURATION' })}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <Trans
                  i18nKey={'components.user.SubscriptionResourcesCard.periodResource'}
                  tOptions={{
                    number: formatResourceUsage(resources.renderingSecondsLimit / 60, 0),
                    context: resources.period
                  }}
                >
                  <span></span>
                  <span className="ml-1 italic"></span>
                </Trans>
              </dd>
            </div>
          )}
          {resources.publishersArticleVideosLimit && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.usage.common.resourceName', { context: 'ARTICLE_VIDEOS' })}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <Trans
                  i18nKey={'components.user.SubscriptionResourcesCard.periodResource'}
                  tOptions={{
                    number: formatResourceUsage(resources.publishersArticleVideosLimit, 0),
                    context: resources.period
                  }}
                >
                  <span></span>
                  <span className="ml-1 italic"></span>
                </Trans>
              </dd>
            </div>
          )}
          {resources.renderingRetentionHours && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.user.SubscriptionResourcesCard.renderingRetentionHours')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {t('components.user.SubscriptionResourcesCard.renderingRetentionHoursValue', {
                  value: resources.renderingRetentionHours
                })}
              </dd>
            </div>
          )}
          {resources.renderingMaxParallel && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t('components.user.SubscriptionResourcesCard.renderingMaxParallel')}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{resources.renderingMaxParallel}</dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};
