export const EMPTY_SVG_SRC = `/assets/illustrations/empty.svg`;
export const BLANK_SVG_SRC = `/assets/illustrations/blank.svg`;
export const WARNING_SVG_SRC = `/assets/illustrations/warning.svg`;
export const VIDEO_BUILDING_SVG_SRC = `/assets/illustrations/video-building.svg`;
export const CLEAN_UP_SVG_SRC = `/assets/illustrations/clean-up.svg`;

export const ACTIVE_CAMPAIGN_SRC = `/assets/supportedProducts/active_campaign.svg`;
export const AIRTABLE_SRC = `/assets/supportedProducts/airtable.svg`;
export const AMAZON_LAMBDA_SRC = `/assets/supportedProducts/amazon_lambda.svg`;
export const AMAZON_REDSHIFT_SRC = `/assets/supportedProducts/amazon_redshift.svg`;
export const AMAZON_S3_SRC = `/assets/supportedProducts/amazon_s3.svg`;
export const AMAZON_SNS_SRC = `/assets/supportedProducts/amazon_sns.svg`;
export const APIFY_SRC = `/assets/supportedProducts/apify.svg`;
export const ASANA_SRC = `/assets/supportedProducts/asana.svg`;
export const AZURE_CS_SRC = `/assets/supportedProducts/azure_cs.svg`;
export const BLACKBOARD_SRC = `/assets/supportedProducts/blackboard.png`;
export const CALENDLY_SRC = `/assets/supportedProducts/calendly.png`;
export const CANVAS_SRC = `/assets/supportedProducts/canvas.svg`;
export const CLEARBIT_SRC = `/assets/supportedProducts/clearbit.png`;
export const CONNECTWISE_SRC = `/assets/supportedProducts/connectwise.png`;
export const DEEPAI_SRC = `/assets/supportedProducts/deepai.png`;
export const DOCUSIGN_SRC = `/assets/supportedProducts/docusign.svg`;
export const DROPBOX_SRC = `/assets/supportedProducts/dropbox.svg`;
export const FREE_FOREX_SRC = `/assets/supportedProducts/free_forex.png`;
export const FRESHDESK_SRC = `/assets/supportedProducts/freshdesk.svg`;
export const GITHUB_SRC = `/assets/supportedProducts/github.svg`;
export const GOOGLE_ANALYTICS_SRC = `/assets/supportedProducts/google_analytics.svg`;
export const GOOGLE_BIGQUERY_SRC = `/assets/supportedProducts/google_bigquery.svg`;
export const GOOGLE_CALENDAR_SRC = `/assets/supportedProducts/google_calendar.svg`;
export const GOOGLE_DRIVE_SRC = `/assets/supportedProducts/google_drive.svg`;
export const GOOGLE_GMAIL_SRC = `/assets/supportedProducts/google_gmail.svg`;
export const GOOGLE_SHEETS_SRC = `/assets/supportedProducts/google_sheets.svg`;
export const HIGHRISE_SRC = `/assets/supportedProducts/highrise.svg`;
export const HUBSPOT_SRC = `/assets/supportedProducts/hubspot.png`;
export const JIRA_SRC = `/assets/supportedProducts/jira.png`;
export const MONDAY_SRC = `/assets/supportedProducts/monday.png`;
export const MONGODB_SRC = `/assets/supportedProducts/mongodb.png`;
export const MSSSQL_SRC = `/assets/supportedProducts/mssql.png`;
export const MS_CALENDAR_SRC = `/assets/supportedProducts/ms_calendar.png`;
export const MS_DYNAMICS_365_SRC = `/assets/supportedProducts/ms_dynamics_365.svg`;
export const MS_EXCEL_SRC = `/assets/supportedProducts/ms_excel.svg`;
export const MS_MAIL_SRC = `/assets/supportedProducts/ms_mail.svg`;
export const MS_ONEDRIVE_SRC = `/assets/supportedProducts/ms_onedrive.svg`;
export const MS_SHAREPOINT_SRC = `/assets/supportedProducts/ms_sharepoint.png`;
export const MS_TEAMS_SRC = `/assets/supportedProducts/ms_teams.png`;
export const MYSQL_SRC = `/assets/supportedProducts/mysql.png`;
export const ONESIGNAL_SRC = `/assets/supportedProducts/onesignal.svg`;
export const OPENAI_SRC = `/assets/supportedProducts/openai.svg`;
export const PIPEDRIVE_SRC = `/assets/supportedProducts/pipedrive.png`;
export const PLIVO_SRC = `/assets/supportedProducts/plivo.png`;
export const SALESFORCE_SRC = `/assets/supportedProducts/salesforce.svg`;
export const SCHOOLOGY_SRC = `/assets/supportedProducts/schoology.svg`;
export const SHOPIFY_SRC = `/assets/supportedProducts/shopify.svg`;
export const SLACK_SRC = `/assets/supportedProducts/slack.svg`;
export const SNOWFLAKE_SRC = `/assets/supportedProducts/snowflake.png`;
export const TRELLO_SRC = `/assets/supportedProducts/trello.svg`;
export const TWILIO_SRC = `/assets/supportedProducts/twilio.svg`;
export const VIMEO_SRC = `/assets/supportedProducts/vimeo.svg`;
export const VOYS_SRC = `/assets/supportedProducts/voys.jpeg`;
export const WEBFLOW_SRC = `/assets/supportedProducts/webflow.png`;
export const WORDPRESS_SRC = `/assets/supportedProducts/wordpress.svg`;
export const XERO_SRC = `/assets/supportedProducts/xero.svg`;
export const YOUTUBE_SRC = `/assets/supportedProducts/youtube.svg`;
export const ZENDESK_CHAT_SRC = `/assets/supportedProducts/zendesk_chat.svg`;
export const ZOHO_BOOKS_SRC = `/assets/supportedProducts/zoho_books.svg`;
export const ZOHO_CRM_SRC = `/assets/supportedProducts/zoho_crm.svg`;
