import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ColumnSelectField,
  Loading,
  RenderBatchPassthroughModal,
  RenderBatchPassthroughTable,
  RequiredMarker
} from '@src/components';
import { useValidateUrls } from '@src/hooks';
import { DesignParameter, RenderOptionsDto } from '@src/models';
import { BatchRenderPassthrough, IntegrationsData, WebhookData } from '@src/types';
import { isEmpty } from '@src/utils';

type RenderBatchTableProps = {
  data: Record<string, string>[];
  selectedColumns: { [param: string]: { value: string; isColumnSelect: boolean } };
  setSelectedColumns: (col: { [param: string]: { value: string; isColumnSelect: boolean } }) => void;
  webhookPassthrough: BatchRenderPassthrough;
  setWebhookPassthrough: (pass: BatchRenderPassthrough) => void;
  integrationsPassthrough: BatchRenderPassthrough;
  setIntegrationsPassthrough: (pass: BatchRenderPassthrough) => void;
  parameters: DesignParameter[];
  loading: boolean;
  previousStep: () => void;
  nextStep: () => void;
  defaultParameterValues?: Record<string, string>;
  defaultRenderOptions?: RenderOptionsDto;
  advancedOptions?: RenderOptionsDto;
  setAdvancedOptions: (advancedOptions: RenderOptionsDto) => void;
};

export const RenderBatchTable = ({
  data,
  selectedColumns,
  setSelectedColumns,
  webhookPassthrough,
  setWebhookPassthrough,
  integrationsPassthrough,
  setIntegrationsPassthrough,
  parameters,
  loading,
  previousStep,
  nextStep,
  defaultParameterValues,
  defaultRenderOptions,
  advancedOptions,
  setAdvancedOptions
}: RenderBatchTableProps) => {
  const { t } = useTranslation();
  const [showWebhookModal, setShowWebhookModal] = useState(false);
  const [showIntegrationsModal, setShowIntegrationsModal] = useState(false);
  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const columns = Object.keys(data[0]);

  const getExamples = (param: string) => {
    const examples = selectedColumns[param]
      ? data
          .flatMap(d => d[selectedColumns[param].value] || [])
          .filter((value, index, array) => array.indexOf(value) === index && index < 3)
      : [];
    return examples.length ? examples.join(', ').concat('...') : '';
  };

  const autoLink = () => {
    const newSelectedColumns = { ...selectedColumns };

    parameters.forEach(p => {
      if ((selectedColumns[p.key] || !selectedColumns[p.key]) && columns.includes(p.name)) {
        newSelectedColumns[p.key] = { value: p.name, isColumnSelect: true };
      }
    });

    setSelectedColumns(newSelectedColumns);
  };

  const selectedColumnsRef = useRef(selectedColumns);

  // apply default parameters
  useEffect(() => {
    if (defaultParameterValues) {
      const fromDefault = Object.entries(defaultParameterValues).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: { value, isColumnSelect: false }
        }),
        {}
      );

      // merge the current selected columns with the default values
      const newSelectedColumns = (selectedColumnsRef.current = { ...selectedColumnsRef.current, ...fromDefault });

      setSelectedColumns(newSelectedColumns);
    }
  }, [defaultParameterValues, setSelectedColumns]);

  const advancedOptionsRef = useRef(advancedOptions);

  // apply default render options
  useEffect(() => {
    if (defaultRenderOptions) {
      const newAdvancedOptions = (advancedOptionsRef.current = {
        ...advancedOptionsRef.current,
        ...defaultRenderOptions
      });

      setAdvancedOptions(newAdvancedOptions);
    }
  }, [defaultRenderOptions, setAdvancedOptions]);

  const integrationsPassthroughRef = useRef(integrationsPassthrough);
  const webhookPassthroughRef = useRef(webhookPassthrough);

  // add integrations passthrough from default render options if it exists
  useEffect(() => {
    const { passthrough: integrationsPassthrough } = defaultRenderOptions?.options?.integrations || {};

    if (integrationsPassthrough) {
      let newIntegrationsPassthrough = (integrationsPassthroughRef.current = {
        ...integrationsPassthroughRef.current,
        passthrough: {
          ...integrationsPassthroughRef.current.passthrough,
          [integrationsPassthrough]: {
            value: integrationsPassthrough,
            isColumnSelect: false
          }
        }
      });

      const passthroughKeys = Object.keys(newIntegrationsPassthrough.passthrough);
      newIntegrationsPassthrough = {
        ...newIntegrationsPassthrough,
        sendAsJson: passthroughKeys.length > 1 ? true : newIntegrationsPassthrough.sendAsJson
      };

      setIntegrationsPassthrough(newIntegrationsPassthrough);
    }

    // add webhook passthrough from default render options if it exists
    const { passthrough: webhookPassthrough } = defaultRenderOptions?.webhook || {};
    if (webhookPassthrough) {
      let newWebhookPassthrough = (webhookPassthroughRef.current = {
        ...webhookPassthroughRef.current,
        passthrough: {
          ...webhookPassthroughRef.current.passthrough,
          [webhookPassthrough]: {
            value: webhookPassthrough,
            isColumnSelect: false
          }
        }
      });

      const passthroughKeys = Object.keys(newWebhookPassthrough.passthrough);
      newWebhookPassthrough = {
        ...newWebhookPassthrough,
        sendAsJson: passthroughKeys.length > 1 ? true : newWebhookPassthrough.sendAsJson
      };

      setWebhookPassthrough(newWebhookPassthrough);
    }
  }, [defaultRenderOptions, setIntegrationsPassthrough, setWebhookPassthrough]);

  const webhookDisabled = !isEmpty(Object.keys(webhookPassthrough.passthrough || {})) && !advancedOptions?.webhook?.url;
  const nextDisabled = !urlsValid || webhookDisabled;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!nextDisabled) nextStep();
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div className="-mx-4 sm:-mx-0">
        {loading ? (
          <Loading />
        ) : (
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 py-2">
              <thead className="bg-gray-50">
                <tr>
                  {parameters.length ? (
                    <>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                        {t('components.common.parameterName')}
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <div className="flex items-center gap-2">
                          <span className="hidden sm:block">{t('components.render.RenderBatchTable.column')}</span>
                          <Button
                            small
                            secondary
                            onClick={autoLink}
                            className="whitespace-nowrap"
                            title={t('components.render.RenderBatchTable.autoLinkTooltip')}
                          >
                            {t('components.render.RenderBatchTable.autoLink')}
                          </Button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        {t('components.render.RenderBatchTable.examples')}
                      </th>
                    </>
                  ) : (
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900">
                      {t('components.render.common.emptyForm')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {parameters &&
                  parameters.map((parameter, index) => {
                    return (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 align-top text-sm font-medium text-gray-900">
                          {parameter.name}
                          {!parameter.optional && <RequiredMarker />}
                        </td>
                        <td className="w-1/3 whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <ColumnSelectField
                            required={parameter.optional === false}
                            parameter={parameter}
                            options={columns}
                            onFieldUpdate={(value, isColumnSelect) => {
                              const newSelectedColumns = {
                                ...selectedColumns,
                                [parameter.key]: { value, isColumnSelect }
                              };
                              if (!value && isColumnSelect) {
                                delete newSelectedColumns[parameter.key];
                              }
                              setSelectedColumns(newSelectedColumns);
                            }}
                            value={selectedColumns[parameter.key]?.value}
                            columnSelect={
                              selectedColumns[parameter.key] ? selectedColumns[parameter.key].isColumnSelect : true
                            }
                            onValidation={handleInvalidUrls}
                          />
                        </td>
                        <td className="hidden w-1/3 px-3 py-4 align-top text-sm text-gray-500 [overflow-wrap:anywhere] lg:table-cell">
                          {parameter.key && getExamples(parameter.key)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <RenderBatchPassthroughTable
        title={t('components.render.RenderBatchTable.webhookSetupTitle')}
        passthrough={webhookPassthrough}
        setPassthrough={setWebhookPassthrough}
        data={data}
        onShowModal={() => setShowWebhookModal(true)}
        id="webhookPassthrough"
        webhookData={advancedOptions?.webhook}
        setWebhookData={(newData: WebhookData) => setAdvancedOptions({ ...advancedOptions, webhook: newData })}
      />
      <RenderBatchPassthroughTable
        title={t('components.render.RenderBatchTable.integrationsSetupTitle')}
        passthrough={integrationsPassthrough}
        setPassthrough={setIntegrationsPassthrough}
        data={data}
        onShowModal={() => setShowIntegrationsModal(true)}
        id="integrationsPassthrough"
        integrationsData={advancedOptions?.options?.integrations}
        setIntegrationsData={(newData: IntegrationsData) =>
          setAdvancedOptions({
            ...advancedOptions,
            options: {
              ...advancedOptions?.options,
              integrations: { ...advancedOptions?.options?.integrations, ...newData }
            }
          })
        }
      />
      <div className="flex justify-end">
        <Button secondary className="mr-3" onClick={previousStep}>
          {t('general.action.back')}
        </Button>
        <Button type="submit" disabled={nextDisabled}>
          {t('general.action.next')}
        </Button>
      </div>
      <RenderBatchPassthroughModal
        visible={showWebhookModal}
        closeModal={() => setShowWebhookModal(false)}
        options={columns}
        submit={(key: string, value: string, isColumnSelect: boolean) => {
          const sendAsJson = isEmpty(Object.keys(webhookPassthrough.passthrough)) ? false : true;

          const newPassthrough = {
            sendAsJson: sendAsJson,
            passthrough: { ...webhookPassthrough.passthrough, [key]: { value, isColumnSelect } }
          };

          setWebhookPassthrough(newPassthrough);
        }}
      />
      <RenderBatchPassthroughModal
        visible={showIntegrationsModal}
        closeModal={() => setShowIntegrationsModal(false)}
        options={columns}
        submit={(key: string, value: string, isColumnSelect: boolean) => {
          const sendAsJson = isEmpty(Object.keys(integrationsPassthrough.passthrough)) ? false : true;

          const newPassthrough = {
            sendAsJson: sendAsJson,
            passthrough: { ...integrationsPassthrough.passthrough, [key]: { value, isColumnSelect } }
          };

          setIntegrationsPassthrough(newPassthrough);
        }}
      />
    </form>
  );
};
