import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useVideoNavigationParams, VideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';

export const AssetsModalNavigation = ({ hasSuggested }: { hasSuggested?: boolean }) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const activeView = videoNavigationState.activeView;
  const assetsTab = (activeView === 'scenes' || activeView === 'sm-settings') && videoNavigationState.assetsTab;
  const tabs = [{ name: 'suggested' }, { name: 'search' }, { name: 'manual' }];

  return (
    <>
      {assetsTab && (
        <>
          {/* Mobile view tabs */}
          <div className="p-6 sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              {t('general.action.selectATab')}
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 pl-3 pr-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={assetsTab}
              onChange={e => {
                updateVideoNavigationState((current: VideoNavigationParams) => {
                  return {
                    ...current,
                    assetsTab: e.target.value as 'search' | 'manual' | undefined
                  };
                });
              }}
            >
              {tabs.map(tab => {
                if (hasSuggested === false && tab.name === 'suggested') return null;
                return (
                  <option key={tab.name} value={tab.name}>
                    {t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.tab', {
                      context: tab.name
                    })}
                  </option>
                );
              })}
            </select>
          </div>
          {/* desktop tabs */}
          <div className="hidden sm:block">
            <nav className="isolate flex divide-x divide-gray-200 shadow" aria-label="Tabs">
              {tabs.map(tab => {
                if (hasSuggested === false && tab.name === 'suggested') return null;
                return (
                  <button
                    type="button"
                    key={tab.name}
                    className={classNames(
                      tab.name === assetsTab
                        ? 'bg-white text-gray-900'
                        : 'bg-gray-50 text-gray-500 hover:bg-white hover:text-gray-700',
                      'group relative min-w-0 flex-1 px-4 py-4 text-center text-sm font-medium focus:z-10'
                    )}
                    onClick={() => {
                      updateVideoNavigationState((current: VideoNavigationParams) => {
                        return {
                          ...current,
                          assetsTab: tab.name as 'search' | 'manual' | undefined
                        };
                      });
                    }}
                  >
                    <span>
                      {t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.tab', {
                        context: tab.name
                      })}
                    </span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.name === assetsTab ? 'bg-indigo-500' : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                );
              })}
            </nav>
          </div>
        </>
      )}
    </>
  );
};
