import { useState } from 'react';
import classNames from 'classnames';
import { HexColorPicker } from 'react-colorful';

import { debounce, isValidHexColor } from '@src/utils';

export type PopoverColorPickerProps = {
  color: string;
  onColorChange: (newColor: string) => void;
};

export const PopoverColorPicker = ({ color, onColorChange }: PopoverColorPickerProps) => {
  const [isOpen, toggle] = useState(false);

  const onChange = debounce((newColor: string) => {
    if (!newColor.includes('NaN')) {
      onColorChange(newColor);
    }
  }, 50);

  return (
    <div className="relative">
      <div
        className={classNames(
          'pointer-events-auto h-6 w-6 cursor-pointer rounded-2xl border border-gray-300 focus:outline-none',
          !isValidHexColor(color) && 'color-wheel'
        )}
        style={{ backgroundColor: color }}
        tabIndex={0}
        onClick={() => toggle(!isOpen)}
      />

      {isOpen && (
        <div className="pointer-events-auto absolute bottom-5 right-5 rounded-md shadow-lg">
          <HexColorPicker color={color} onChange={onChange} onBlur={() => toggle(false)} />
        </div>
      )}
    </div>
  );
};
