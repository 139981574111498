export enum SummaryProviderType {
  CHAT_GPT = 'CHAT_GPT'
}

type SummaryProviderSettings<T extends SummaryProviderType> = {
  type: T;
};

export type ChatGptSummaryProviderSettings = SummaryProviderSettings<SummaryProviderType.CHAT_GPT> & {
  writingStyle?: string;
};

export type AnySummaryProviderSettings = ChatGptSummaryProviderSettings;
