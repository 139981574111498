import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import { Button, Header, HelpNotification, RenderBatchButton, RenderPageHelpLinks } from '@src/components';
import { useQueryParams } from '@src/hooks';
import { RENDER_BATCH_CSV_PROJECT, RENDER_FORM } from '@src/routes';

export const RendersHeader = ({
  setShowCancelRenderModal
}: {
  setShowCancelRenderModal: (showCancelRenderModal: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchQuery } = useQueryParams();
  const projectId = searchQuery.get('projectId') || '';
  const templateId = searchQuery.get('templateId') || '';

  const onBatchRender =
    projectId && templateId
      ? () => {
          navigate(
            generatePath(RENDER_BATCH_CSV_PROJECT, {
              projectId: projectId,
              templateId: templateId,
              step: '1'
            })
          );
        }
      : undefined;

  return (
    <Header
      header={t('general.common.renders')}
      actions={
        <>
          {/* Above large screens */}
          <div className="hidden gap-2 lg:flex">
            <HelpNotification type="button" links={RenderPageHelpLinks} />
            <Button secondary onClick={() => setShowCancelRenderModal(true)}>
              {t('general.action.cancelAll')}
            </Button>
            <RenderBatchButton type={onBatchRender ? 'render' : 'download'} onBatchRender={onBatchRender} />
            <Link to={RENDER_FORM}>
              <Button>{t('general.action.render')}</Button>
            </Link>
          </div>
          {/* Smaller then large screens */}
          <div className="flex flex-col gap-2 lg:hidden">
            <div className="flex items-center gap-2">
              <Link to={RENDER_FORM}>
                <Button>{t('general.action.render')}</Button>
              </Link>
              <HelpNotification type="button" links={RenderPageHelpLinks} />
            </div>
            <div className="flex items-center gap-2">
              <Button secondary onClick={() => setShowCancelRenderModal(true)}>
                {t('general.action.cancelAll')}
              </Button>
              <RenderBatchButton type={onBatchRender ? 'render' : 'download'} onBatchRender={onBatchRender} />
            </div>
          </div>
        </>
      }
    />
  );
};
