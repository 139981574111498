import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { DesignAspectRatioIcon, DesignColorPaletteIcon, DesignsVideoPlayer, StyledLink } from '@src/components';
import { ColorPaletteGroup, Design, DesignAspectRatio } from '@src/models';
import * as routes from '@src/routes';

export type DesignsGalleryProps = {
  designs: Design[];
  selectedColors: ColorPaletteGroup[];
  selectedAspectRatios: DesignAspectRatio[];
};

export const DesignsGallery = ({ designs, selectedColors, selectedAspectRatios }: DesignsGalleryProps) => {
  const { t } = useTranslation();
  const storyOnly = selectedAspectRatios.length === 1 && selectedAspectRatios.includes(DesignAspectRatio.STORY);
  const wideOnly = selectedAspectRatios.length === 1 && selectedAspectRatios.includes(DesignAspectRatio.WIDE);
  const sortedDesigns = designs.sort(
    (a, b) => new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime()
  );

  return (
    <section aria-labelledby="product-heading" className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
      <div
        className={classNames(
          'mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 lg:gap-x-8',
          !storyOnly && 'md:grid-cols-3',
          storyOnly && 'md:grid-cols-4',
          wideOnly && 'grid-cols-1 md:grid-cols-2'
        )}
      >
        {sortedDesigns.map(design => (
          <div key={design.id} className="group relative flex h-full flex-col">
            <div className="flex-cols flex flex-grow items-center overflow-hidden rounded-md bg-gray-300 p-0">
              <DesignsVideoPlayer
                design={design}
                selectedColors={selectedColors}
                selectedAspectRatios={selectedAspectRatios}
                singlePreview={design.renderUiDisabled}
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  <StyledLink to={generatePath(routes.DESIGN_DETAILS_URL, { designId: design.id })} className="block">
                    {design.name}
                  </StyledLink>
                </h3>
                <p className="mt-1 flex text-sm text-gray-500">
                  {t('components.designs.common.designCategory', { context: design.category })}
                  {design.app && <CheckBadgeIcon className="ml-1 h-5 w-5 text-green-500" />}
                </p>
                <div className="mt-1 flex">
                  {design.palettes.map(p => (
                    <DesignColorPaletteIcon
                      key={p.group}
                      primaryColor={p.primary}
                      secondaryColor={p.secondary}
                      backgroundColor={p.tertiary}
                      tooltip={t('components.designs.common.colorOption', { context: p.group })}
                    />
                  ))}
                </div>
              </div>
              <div>
                <div className="flex">
                  {design.variants.map((v, index) => (
                    <DesignAspectRatioIcon
                      aspectRatio={v.aspectRatio}
                      key={v.id}
                      isLastItem={index === design.variants.length - 1}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
