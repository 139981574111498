import { useState } from 'react';

import {
  RevisionActionForm,
  RevisionStateInfos,
  SocialMediaFormInputs,
  VideoDetailsHeader,
  VideoPreviewModal,
  VideoSidebar
} from '@src/components';
import { useValidateUrls, useVideoFormReducer, useVideoNavigationParams } from '@src/hooks';
import {
  AnyVideoRevision,
  Article,
  Brand,
  RevisionState,
  SocialMediaRevision,
  SocialMediaRevisionUpdateDto
} from '@src/models';

export const VideoSocialMediaDetails = ({
  currentRevision,
  clonedRevision,
  revisions,
  article,
  brand,
  videoId
}: {
  currentRevision: SocialMediaRevision;
  clonedRevision?: SocialMediaRevision;
  revisions: AnyVideoRevision[];
  article: Article;
  brand: Brand;
  videoId: string;
}) => {
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { videoNavigationState } = useVideoNavigationParams();

  const revisionId = videoNavigationState.revisionId;
  const socialMediaRevisionDto: SocialMediaRevisionUpdateDto = {
    videoType: currentRevision.videoType,
    cta: currentRevision.cta,
    heading: currentRevision.heading || undefined,
    subheading: currentRevision.subheading || undefined,
    asset: currentRevision.asset,
    musicUrl: currentRevision.musicUrl,
    logoUrl: currentRevision.logoUrl
  };
  const { revision, dirtyState, updateRevision, removeRevision } = useVideoFormReducer(
    revisionId,
    socialMediaRevisionDto
  );

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const isValidForm =
    revision &&
    revision.cta !== '' &&
    revision.heading !== '' &&
    revision.subheading !== '' &&
    revision.logoUrl !== '' &&
    urlsValid;

  const canFireUpdate = isValidForm && dirtyState;
  const canFireDiscard = dirtyState;

  const setInputs = (inputs: SocialMediaRevisionUpdateDto) => updateRevision(revisionId, inputs);
  const removeRevisionFromState = () => removeRevision(revisionId);

  const revisionState = currentRevision.state;

  return (
    <>
      <div className="mx-auto max-w-5xl flex-1 space-y-6 px-4 py-6 sm:px-6 lg:px-8">
        <VideoDetailsHeader
          setShowVideoDialog={setShowVideoDialog}
          article={article}
          brand={brand}
          videoId={videoId}
          revisionId={currentRevision.id}
          state={currentRevision.state}
          disabled={dirtyState}
          setShowPreviewModal={setShowPreviewModal}
        />
        <RevisionStateInfos revision={currentRevision} message={currentRevision.message} />
        <RevisionActionForm
          state={currentRevision.state}
          data={revision}
          canFireUpdate={canFireUpdate}
          canFireDiscard={canFireDiscard}
          removeRevisionFromState={removeRevisionFromState}
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <SocialMediaFormInputs
              disabled={revisionState !== RevisionState.DRAFT && revisionState !== RevisionState.ERROR}
              inputs={revision}
              setInputs={setInputs}
              handleInvalidUrls={handleInvalidUrls}
            />
          </div>
        </RevisionActionForm>
      </div>
      <div className="hidden h-0 w-72 lg:block lg:h-screen xl:w-80"></div>
      <VideoSidebar
        originalArticleUrl={article.input.articleUrl}
        revisions={revisions}
        currentRevision={currentRevision}
        showVideoDialog={showVideoDialog}
        setShowVideoDialog={setShowVideoDialog}
      />
      <VideoPreviewModal
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
        currentRevision={currentRevision}
        clonedRevision={clonedRevision}
      />
    </>
  );
};
