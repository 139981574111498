import { RenderState } from '..';

import { AnyAvatarProviderSettings, AnyVoiceoverProviderSettings, StockAsset, StockAssetSearch } from './providers';
import { VideoType } from './videos';

export enum RevisionState {
  DRAFT = 'DRAFT',
  PREVIEW = 'PREVIEW',
  FINAL = 'FINAL',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING'
}

export type ArticleVideoScene = {
  script: string;
  suggestedAssets: StockAssetSearch;
  asset: StockAsset;
  duration?: number;
  headlines: { heading: string; subheading?: string }[];
};

export type RevisionRender = {
  renderId: string;
  renderState: RenderState;
  done: boolean;
};

export type VideoRevision<T extends VideoType> = {
  id: string;
  createdDate: string;
  lastModified: string;
  clonedFrom?: string;
  videoType: T;
  automated: boolean;
  message?: string;
  state: RevisionState;
  previewRender?: RevisionRender;
  finalRender?: RevisionRender;
};

export type SocialMediaRevisionBase = {
  cta: string;
  subheading?: string;
  asset?: StockAsset;
  logoUrl?: string;
  musicUrl?: string;
  heading?: string;
};

export type SocialMediaRevision = VideoRevision<VideoType.SOCIAL_MEDIA> & SocialMediaRevisionBase;

export type SocialMediaRevisionUpdateDto = SocialMediaRevisionBase & {
  videoType: VideoType.SOCIAL_MEDIA;
};

type AvatarOptions = {
  presenterSignature?: string;
  settings: AnyAvatarProviderSettings;
};

type AvatarIn = AvatarOptions & {
  text: string;
};

export type PresenterRevision = VideoRevision<VideoType.PRESENTER> & {
  cta?: string;
  logoUrl?: string;
  avatar: {
    in: AvatarIn;
    out: {
      preview: boolean;
      url: string;
    };
  };
  scenes: ArticleVideoScene[];
};

export type PresenterRevisionUpdateDto = {
  videoType: VideoType.PRESENTER;
  cta?: string;
  logoUrl?: string;
  scenes: ArticleVideoScene[];
  avatarOptions: AvatarOptions;
};

type VoiceoverOptions = {
  languageCode: string;
  settings: AnyVoiceoverProviderSettings;
};

type VoiceoverIn = VoiceoverOptions & {
  paragraphs: {
    text: string;
  }[];
};

export type SummarySlideshowRevision = VideoRevision<VideoType.SUMMARY_SLIDESHOW> & {
  cta?: string;
  logoUrl?: string;
  voiceover: {
    in: VoiceoverIn;
    out?: {
      url: string;
      duration: number;
      paragraphDurations: number[];
    };
  };
  scenes: ArticleVideoScene[];
};

export type SummaryRevisionUpdateDto = {
  videoType: VideoType.SUMMARY_SLIDESHOW;
  cta?: string;
  logoUrl?: string;
  scenes: ArticleVideoScene[];
  voiceoverOptions: VoiceoverOptions;
};

export type AnyVideoRevision = SocialMediaRevision | PresenterRevision | SummarySlideshowRevision;

export type AnyRevisionUpdateDto = SocialMediaRevisionUpdateDto | PresenterRevisionUpdateDto | SummaryRevisionUpdateDto;
