import { StrictMode } from 'react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
// This API is currently prefixed as unstable_ because you may unintentionally add two versions of the history library to your app, the one you have added to your package.json and whatever version React Router uses internally. If it is allowed by your tooling, it's recommended to not add history as a direct dependency and instead rely on the nested dependency from the react-router package. Once we have a mechanism to detect mis-matched versions, this API will remove its unstable_ prefix.
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { App } from '@src/App';
import { useReactQueryClient } from '@src/hooks';
// import i18n (needs to be bundled ;))
import i18n from '@src/i18n/config';
import reportWebVitals from '@src/reportWebVitals';
import { initializeSentry } from '@src/tools/sentry';

import { isProd } from './env';

import './index.css';

const history = createBrowserHistory();

// init the sentry library
initializeSentry();

const onRedirectCallback = (appState?: AppState) => {
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  const path = (appState && appState.returnTo) || window.location.pathname;
  history.replace(path);
};

const rootElement = document.getElementById('root');
const root = rootElement && createRoot(rootElement);
const redirect_uri = { redirect_uri: window.location.origin };

const Root = () => {
  const queryClient = useReactQueryClient();

  return (
    <StrictMode>
      <Auth0Provider
        domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
        authorizationParams={redirect_uri}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={isProd ? true : false}
      >
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <HistoryRouter history={history}>
              <App />
            </HistoryRouter>
          </I18nextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </StrictMode>
  );
};

root?.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
