import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Alert, ScrollingPage, UserOrganizationsList } from '@src/components';
import { UserProfileCard, UserSettingsHeader, UserSettingsNavigation } from '@src/components/user';
import { useQueryParams } from '@src/hooks';

export const UserSettingsPage = () => {
  const { t } = useTranslation();
  const { searchQuery } = useQueryParams();
  const showOrganizationMissing = searchQuery.get('showOrganizationMissing');

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.UserSettingsPage')}</title>
        </Helmet>
        <UserSettingsHeader />
        <Alert
          type={'warn'}
          show={!!showOrganizationMissing}
          alertContent={t('components.user.UserSettingsPage.organizationMissingAlert')}
        />
        <UserSettingsNavigation tab="general" />
        <UserProfileCard />
        <UserOrganizationsList />
      </ScrollingPage>
    </HelmetProvider>
  );
};
