import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppmixerActiveIntegrations, AppmixerProvider, Button } from '@src/components';
import { useQueryParams } from '@src/hooks';
import { Project, Template } from '@src/models';

import './styles/integrationsCard.css';

type AppmixerActiveIntegrationsCardProps = {
  project: Project;
  template?: Template;
};
export const AppmixerActiveIntegrationsCard = ({ project, template }: AppmixerActiveIntegrationsCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();

  return (
    <div id="appmixer-active-integrations-card">
      <AppmixerProvider>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('general.common.integrations')}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t('components.appmixer.AppmixerActiveIntegrationsCard.subtitle', {
                    context: template ? 'template' : 'project'
                  })}
                </p>
              </div>
              <div className="ml-4 mt-2 shrink-0">
                <Button
                  onClick={() =>
                    navigate(
                      withQueryParams(`/dashboard/integrations/templates`, {
                        projectId: project.id,
                        templateId: template?.id
                      })
                    )
                  }
                >
                  {t('components.appmixer.AppmixerActiveIntegrationsCard.addIntegration')}
                </Button>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <AppmixerActiveIntegrations project={project} template={template} fullScreen={false} />
          </div>
        </div>
      </AppmixerProvider>
    </div>
  );
};
