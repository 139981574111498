import { InfiniteData, QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { ErrorOptions, ProjectRenderDto, Render, RenderState, RenderStatsDto } from '@src/models';
import { isEmpty } from '@src/utils';

import { useAxiosModify, useAxiosRead } from '../useAxios';

import { invalidateUsage } from './useOrganizationApi';

const RENDERS_CACHE_ROOT = 'renders';

// refresh render list as long as one is not done, each 15s
export const getRenderListRefreshInterval = (renders?: Render[]): number | false => {
  if (renders) {
    const anyNotDone = renders.some(
      render =>
        render.state &&
        ![RenderState.DONE, RenderState.FAILED, RenderState.INVALID, RenderState.CANCELLED].includes(render.state)
    );
    if (anyNotDone) {
      return 15000;
    }
  }
  return false;
};

// refresh infinite render list as long as one is not done, each 15s
const getInfiniteRendersRefreshInterval = (renders?: InfiniteData<Render[]>): number | false => {
  if (!isEmpty(renders?.pages)) {
    const anyNotDone = renders?.pages
      .flatMap(p => (p && p) || [])
      .some(
        render =>
          render.state &&
          ![RenderState.DONE, RenderState.FAILED, RenderState.INVALID, RenderState.CANCELLED].includes(render.state)
      );
    if (anyNotDone) {
      return 15000;
    }
  }
  return false;
};

// Cache utils

export const rendersCacheAdd = (client: QueryClient, render: Render) => {
  // List of renders
  client.setQueryData<Render[]>(RENDERS_CACHE_ROOT, renders => (renders ? [render, ...renders] : [render]));
  // Render details
  client.setQueryData<Render>([RENDERS_CACHE_ROOT, render.id], render);
  // Reset usage
  invalidateUsage(client);
};

type GetRendersParams = {
  projectId?: string;
  templateId?: string;
  state?: string;
  page?: number;
  size?: number;
  searchTerm?: string;
};

export const useGetRenders = (params: GetRendersParams) => {
  const { get } = useAxiosRead<Render[]>();

  const cacheKey = [RENDERS_CACHE_ROOT, 'list', params];

  const { data, isLoading, error, refetch } = useQuery<Render[]>(
    cacheKey,
    async () => {
      const response = await get('/renders', { params });
      return response.data;
    },
    {
      refetchInterval: getRenderListRefreshInterval
    }
  );

  return { data, isLoading, error, refetch };
};

export const useGetInfiniteRenders = (params: GetRendersParams) => {
  const { get } = useAxiosRead<Render[]>();

  const cacheKey = [RENDERS_CACHE_ROOT, 'list', params];

  const fetchRenders = async (page?: number) => {
    params.page = page && page - 1;
    let response;
    if (params.searchTerm) {
      response = await get('/renders/search', { params });
    } else {
      response = await get('/renders', { params });
    }
    return response.data;
  };

  const { data, isLoading, isSuccess, error, refetch, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<Render[]>(cacheKey, ({ pageParam }) => fetchRenders(pageParam), {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length === params.size ? allPages.length + 1 : undefined;

        return nextPage;
      },
      refetchInterval: getInfiniteRendersRefreshInterval
    });

  return { data, isLoading, isSuccess, error, refetch, hasNextPage, fetchNextPage, isFetchingNextPage };
};

export const useGetRenderDetails = (renderId: string | undefined) => {
  const { get } = useAxiosRead<Render>();

  const cacheKey = [RENDERS_CACHE_ROOT, renderId];

  const { data, isLoading, error } = useQuery<Render>(
    cacheKey,
    async () => {
      const response = await get(`/renders/${renderId}`);
      return response.data;
    },
    {
      refetchInterval: (render?: Render) =>
        render &&
        render.state &&
        ![RenderState.DONE, RenderState.FAILED, RenderState.INVALID, RenderState.CANCELLED].includes(render.state)
          ? 5000
          : false,
      enabled: !!renderId
    }
  );

  return { data, isLoading, error };
};

type RendersStatsParams = {
  days?: number;
};

export const useGetRendersStats = (params: RendersStatsParams) => {
  const { get } = useAxiosRead<RenderStatsDto>();

  const cacheKey = [RENDERS_CACHE_ROOT, 'stats', params];

  const { data, isLoading, error, refetch } = useQuery<RenderStatsDto>(cacheKey, async () => {
    const response = await get('/renders/stats', { params });
    return response.data;
  });

  return { data, isLoading, error, refetch };
};

export const useFireRender = (errorOptions?: ErrorOptions) => {
  const { post } = useAxiosModify<ProjectRenderDto, Render>({}, errorOptions);
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (render: ProjectRenderDto) => {
      const response = await post('/renders', render);

      return response.data;
    },
    onSuccess: render => {
      rendersCacheAdd(queryClient, render);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useFireRenderRetry = () => {
  const { post } = useAxiosModify<void, Render>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (renderId: string) => {
      const response = await post(`/renders/${renderId}/resubmit`);

      return response.data;
    },
    onSuccess: render => {
      rendersCacheAdd(queryClient, render);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useFireRenderWebhookRetry = () => {
  const { post } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (renderId: string) => {
      const response = await post(`/renders/${renderId}/webhook`);

      return response.data;
    },
    onSuccess: (_, renderId) => {
      queryClient.invalidateQueries([RENDERS_CACHE_ROOT, renderId]);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const usePromoteRender = () => {
  const { post } = useAxiosModify<void, Render>();

  const { mutateAsync, isLoading, error } = useMutation(async (renderId: string) => {
    const response = await post(`/renders/${renderId}/promote`);
    return response.data;
  });

  return { mutateAsync, isLoading, error };
};

export const useCancelRender = () => {
  const { post } = useAxiosModify<void, Render>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation<Render, unknown, string>({
    mutationFn: async (renderId: string) => {
      const response = await post(`/renders/${renderId}/cancel`);
      return response.data;
    },
    onSuccess: (_, renderId) => {
      queryClient.invalidateQueries([RENDERS_CACHE_ROOT, renderId]);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useCancelAllRenders = () => {
  const { post } = useAxiosModify<void, Render[]>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation<Render[], unknown>(
    async () => {
      const response = await post('/renders/cancel-all');
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RENDERS_CACHE_ROOT);
      }
    }
  );

  return { mutateAsync, isLoading, error };
};
