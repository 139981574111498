import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  CheckBadgeIcon as ValidUrlIcon,
  EllipsisVerticalIcon as LoadingIcon,
  XCircleIcon as InvalidUrlIcon
} from '@heroicons/react/20/solid';
import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { AssetDisplay } from '@src/components';
import { useValidateAsset } from '@src/hooks';
import { StockAsset } from '@src/models';

export const ManualUploadAssets = ({
  onFieldUpdate,
  value,
  selectedAsset,
  setSelectedAsset,
  onUploadRequested
}: {
  onFieldUpdate: (value: string) => void;
  value?: string;
  selectedAsset?: StockAsset;
  setSelectedAsset: (selectedAsset?: StockAsset) => void;
  onUploadRequested: () => void;
}) => {
  const { t } = useTranslation();
  const { isLoading: isValidating, mutateAsync: validateAsset } = useValidateAsset();
  const [isUrlValid, setIsUrlValid] = useState<boolean>();

  useEffect(() => {
    if (value === '') {
      setIsUrlValid(undefined);
      setSelectedAsset(undefined);
      return;
    }

    if (value) {
      validateAsset({ url: value })
        .then(res => {
          const assetType = res.contentType?.split('/')[0].toUpperCase();
          if (assetType === 'IMAGE' || assetType === 'VIDEO') {
            setIsUrlValid(true);
            setSelectedAsset({ type: 'manual', url: value, assetType: assetType });
          } else {
            setIsUrlValid(false);
            setSelectedAsset(undefined);
          }
        })
        .catch(() => {
          setIsUrlValid(false);
          setSelectedAsset(undefined);
        });
    }
  }, [setSelectedAsset, validateAsset, value]);

  return (
    <>
      <div className="w-full p-6">
        <div className="focus-within::z-10 relative flex flex-grow items-stretch">
          <input
            type="text"
            className="block w-full rounded-none rounded-l-md border-gray-300 pr-10 text-sm placeholder:text-gray-400 focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder={t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.enterUrl')}
            onChange={e => {
              onFieldUpdate(e.target.value);
            }}
            value={value}
          />
          {isValidating && (
            <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
              <LoadingIcon className={classNames('h-5 w-5 animate-spin text-indigo-600')} />
            </div>
          )}
          {!isValidating && (
            <>
              {isUrlValid === false && (
                <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
                  <InvalidUrlIcon className="h-5 w-5 text-red-400" />
                </div>
              )}

              {isUrlValid && value && (
                <div className="pointer-events-none absolute inset-y-0 right-0 mr-3 flex items-center">
                  <ValidUrlIcon className="h-5 w-5 text-green-400" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex max-h-[370px] min-h-[370px] flex-col items-center justify-center p-6">
        {selectedAsset && <AssetDisplay asset={selectedAsset} className="h-[322px] w-full" />}
        {isUrlValid === false && (
          <>
            <XCircleIcon className="h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">{t('components.common.invalidUrl')}</p>
          </>
        )}
        {!value && (
          <>
            <div onClick={onUploadRequested} className="group cursor-pointer">
              <ArrowUpTrayIcon className="h-12 w-12 text-gray-400 group-hover:text-gray-500" />
            </div>
            <p className="mt-2 text-sm text-gray-500">
              {t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.noAssetsManual')}
            </p>
          </>
        )}
      </div>
    </>
  );
};
