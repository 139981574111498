import classNames from 'classnames';

export const SingleParam = ({ title, item, wide }: { title: string; item: string; wide?: boolean }) => {
  return (
    <div className={classNames(wide ? 'sm:col-span-2' : 'sm:col-span-1')}>
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">{item}</dd>
    </div>
  );
};
