import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useParams } from 'react-router-dom';

import {
  RenderBatchAdvancedOptions,
  RenderBatchCsvFileUpload,
  RenderBatchPreviewTable,
  RenderBatchTable
} from '@src/components';
import { useError, useNotifications, useRenderBatchReducer } from '@src/hooks';
import { DesignParameter, RenderOptionsDto } from '@src/models';
import { RENDER_BATCH_CSV_DESIGN, RENDER_BATCH_CSV_PROJECT } from '@src/routes';
import { BatchRenderPassthrough } from '@src/types';

export type RenderBatchStateObject = {
  tableData: Record<string, string>[];
  selectedColumns: { [param: string]: { value: string; isColumnSelect: boolean } };
  webhookPassthrough: BatchRenderPassthrough;
  integrationsPassthrough: BatchRenderPassthrough;
  advancedOptions: RenderOptionsDto;
};

type RenderBatchFormProps = {
  step: number;
  previousStep: () => void;
  nextStep: () => void;
  parameters: DesignParameter[];
  defaultParameterValues?: Record<string, string>;
  defaultRenderOptions?: RenderOptionsDto;
  loading: boolean;
  projectId: string;
  templateId: string;
  isDesign: boolean;
};

export const RenderBatchForm = ({
  step,
  previousStep,
  nextStep,
  parameters,
  defaultParameterValues,
  defaultRenderOptions,
  loading,
  projectId,
  templateId,
  isDesign
}: RenderBatchFormProps) => {
  const [batchRenderId, setBatchRenderId] = useState<string | undefined>();
  const params = useParams();
  const { notifyError } = useNotifications();
  const { t } = useTranslation();
  const { formatMessage } = useError();
  const [
    state,
    updateTableData,
    updateSelectedColumns,
    updateWebhookPassthrough,
    updateIntegrationsPassthrough,
    updateAdvancedOptions,
    clearRenderBatchState
  ] = useRenderBatchReducer();

  const { tableData, selectedColumns, integrationsPassthrough, webhookPassthrough, advancedOptions } = state;

  const setTableData = (tableData: Record<string, string>[]) => {
    try {
      updateTableData(tableData);
    } catch (e) {
      notifyError(t('components.render.RenderBatchCsvFileUpload.uploadError'), formatMessage(e as Error));
      clearRenderBatchState();
    }
  };

  const getContent = () => {
    if (step > 1 && !tableData.length) {
      return (
        <Navigate
          to={
            isDesign
              ? generatePath(RENDER_BATCH_CSV_DESIGN, { ...params, step: '1' })
              : generatePath(RENDER_BATCH_CSV_PROJECT, { ...params, step: '1' })
          }
        />
      );
    }

    switch (step) {
      case 1:
        return (
          <RenderBatchCsvFileUpload
            nextStep={nextStep}
            setTableData={tableData => setTableData(tableData)}
            setBatchRenderId={setBatchRenderId}
          />
        );
      case 2:
        return (
          <RenderBatchTable
            data={tableData}
            selectedColumns={selectedColumns}
            setSelectedColumns={updateSelectedColumns}
            webhookPassthrough={webhookPassthrough}
            setWebhookPassthrough={updateWebhookPassthrough}
            integrationsPassthrough={integrationsPassthrough}
            setIntegrationsPassthrough={updateIntegrationsPassthrough}
            parameters={parameters}
            loading={loading}
            previousStep={previousStep}
            nextStep={nextStep}
            defaultParameterValues={defaultParameterValues}
            defaultRenderOptions={defaultRenderOptions}
            advancedOptions={advancedOptions}
            setAdvancedOptions={updateAdvancedOptions}
          />
        );
      case 3:
        return (
          <RenderBatchAdvancedOptions
            advancedOptions={advancedOptions}
            setAdvancedOptions={updateAdvancedOptions}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        );
      case 4:
        return (
          <RenderBatchPreviewTable
            data={tableData}
            advancedOptions={advancedOptions}
            selectedColumns={selectedColumns}
            webhookPassthrough={webhookPassthrough}
            integrationsPassthrough={integrationsPassthrough}
            projectId={projectId}
            templateId={templateId}
            batchRenderId={batchRenderId}
            previousStep={previousStep}
            cleanup={clearRenderBatchState}
          />
        );
    }
  };

  return <>{getContent()}</>;
};
