import { Key } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, List, Loading, VideoTypesListItem } from '@src/components';
import { AnyVideoSettings, Brand } from '@src/models';

export const VideoTypesList = ({
  loading,
  model: brand,
  setShowModal
}: {
  loading: boolean;
  model: Brand;
  setShowModal: (showModal: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {loading && <Loading className="flex-1" title={t('components.brand.videoTypes.VideoTypesList.loading')} />}
      {!loading && (
        <List
          emptyListMessage={t('components.brand.videoTypes.VideoTypesList.empty')}
          data={brand.settings.videoTypes}
          renderItem={(model: AnyVideoSettings, index?: Key) => {
            return (
              <VideoTypesListItem
                key={index}
                brand={brand}
                model={model}
                isLast={index === brand.settings.videoTypes?.length - 1}
              />
            );
          }}
          headerTitle={t('components.brand.videoTypes.VideoTypesList.videoTypes')}
          headerAction={<Button onClick={() => setShowModal(true)}>{t('general.action.addNew')}</Button>}
        />
      )}
    </>
  );
};
