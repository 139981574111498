import { useTranslation } from 'react-i18next';

import { Button } from '@src/components';

import { Modal } from './Modal';

type ConfirmationModalProps = {
  title: string;
  description: string;
  visible: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void;
  className?: string;
  size?: 'small' | 'normal' | 'large';
  removeOuterPadding?: boolean;
};

export const ConfirmationModal = ({
  title,
  description,
  visible,
  isLoading,
  onConfirm,
  onClose
}: React.PropsWithChildren<ConfirmationModalProps>) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} close={onClose}>
      <form
        className="space-y-4"
        onSubmit={e => {
          e.preventDefault();
          onConfirm();
        }}
      >
        <div>
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            className="inline-flex w-full justify-center rounded-md px-3 py-2 sm:ml-3 sm:w-auto"
          >
            {t('general.action.confirm')}
          </Button>
          <Button
            type="button"
            secondary
            disabled={isLoading}
            className="mt-3 inline-flex w-full justify-center rounded-md sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            {t('general.action.cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
