import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon, PhotoIcon } from '@heroicons/react/20/solid';
import { Button, Loading, Modal } from '@src/components';
import { useGetProjectFile } from '@src/hooks';
import { AeMediaPreview } from '@src/models';

export const ImagePreviewer = ({
  projectId,
  value,
  width,
  height,
  preview,
  onPreviewChange
}: {
  projectId: string;
  value: string;
  width: number;
  height: number;
  preview: AeMediaPreview;
  onPreviewChange: (previewActive: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  const { link, internal } = preview;
  const { isLoading, data: blob, error } = useGetProjectFile(projectId, value, internal);

  const loading = internal && isLoading;
  const showError = internal && error;

  const doShow = () => {
    setShowPreview(true);
    onPreviewChange(true);
  };
  const doHide = () => {
    setShowPreview(false);
    onPreviewChange(false);
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          {showError && (
            <div>
              <p className="text-sm text-gray-900">{value}</p>
              <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                {t('components.project.template.layer.previewer.common.errorLoading', { context: 'image' })}
              </p>
            </div>
          )}
          {!showError && (
            <>
              {showPreview && (
                <Modal visible={showPreview} close={doHide}>
                  <div>
                    <img
                      src={internal && blob ? URL.createObjectURL(blob) : link}
                      width={width}
                      height={height}
                      alt={value}
                      className="h-auto w-auto"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/assets/no-preview-available.png';
                      }}
                    />
                    <p className="mt-1 flex items-center text-xs text-gray-500 md:mt-2">
                      {t('general.common.resolution', { width, height })}
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        doHide();
                      }}
                      className="w-full justify-center"
                    >
                      {t('general.action.close')}
                    </Button>
                  </div>
                </Modal>
              )}
              <>
                <div className="flex w-40 flex-col">
                  <div className="flex shrink place-content-center items-center justify-center rounded-t-lg border border-gray-300 p-2">
                    <div className="place-self-center self-center">
                      <img
                        src={internal && blob ? URL.createObjectURL(blob) : link}
                        className="max-h-40 min-h-16 w-40 object-contain sm:max-h-48 sm:min-h-20"
                        alt={value}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/assets/no-preview-available.png';
                        }}
                      />
                      <p className="mt-1 flex-none text-center text-xs text-gray-500 md:mt-2">
                        {t('general.common.resolution', { width, height })}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      doShow();
                    }}
                    className="inline-flex w-full items-center rounded-b-lg border border-t-0 border-gray-300 px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 hover:bg-gray-100"
                  >
                    <PhotoIcon className="mr-1 h-5 w-5" />
                    {t('components.project.template.layer.previewer.common.fullPreview')}
                  </button>
                </div>
              </>
            </>
          )}
        </>
      )}
    </>
  );
};
