import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  OrganizationSettingsHeader,
  OrganizationSettingsNavigation,
  OrganizationTeamCard,
  ScrollingPage
} from '@src/components';

export const OrganizationTeamPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.OrganizationTeamPage')}</title>
        </Helmet>
        <OrganizationSettingsHeader />
        <OrganizationSettingsNavigation tab="team" />
        <OrganizationTeamCard />
      </ScrollingPage>
    </HelmetProvider>
  );
};
