import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Loading,
  LoadingProps,
  PresenterTypeForm,
  ScrollingPage,
  SocialMediaTypeForm,
  SummarySlideshowTypeForm
} from '@src/components';
import { useGetBrandDetails } from '@src/hooks';

const LoadingBrandDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const VideoTypeEditPage = () => {
  const { t } = useTranslation();
  const { id, videoId } = useParams() as { id: string; videoId: string };

  const { isLoading, data: brand } = useGetBrandDetails(id);
  const videoType = brand?.settings.videoTypes?.find(vt => vt.id === videoId);

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.VideoTypeEditPage', { context: videoType?.videoType })}</title>
        </Helmet>
        <>
          {isLoading && <LoadingBrandDetails title={t('components.brand.BrandDetails.loading')} />}
          {!isLoading && brand && (
            <>
              {videoType?.videoType === 'SOCIAL_MEDIA' && <SocialMediaTypeForm brand={brand} videoType={videoType} />}
              {videoType?.videoType === 'SUMMARY_SLIDESHOW' && (
                <SummarySlideshowTypeForm brand={brand} videoType={videoType} />
              )}
              {videoType?.videoType === 'PRESENTER' && <PresenterTypeForm brand={brand} videoType={videoType} />}
            </>
          )}
        </>
      </ScrollingPage>
    </HelmetProvider>
  );
};
