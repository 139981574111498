import { ModelBaseWithDates } from '..';

export type Article = ModelBaseWithDates & {
  brandId: string;
  input: AnyArticleInput;
  videoCount: number;
};

export enum ArticleInputType {
  WEB_ARTICLE = 'WEB_ARTICLE'
}

export type ArticleInput<T extends ArticleInputType> = {
  type: T;
  image?: string;
  title?: string;
};

export type WebArticleInput = ArticleInput<ArticleInputType.WEB_ARTICLE> &
  CreateWebArticleDto & {
    description?: string;
    metadata?: Record<string, string>;
  };

export type AnyArticleInput = WebArticleInput;

export type CreateArticleDto<T extends ArticleInputType> = {
  type: T;
};

export type CreateWebArticleDto = CreateArticleDto<ArticleInputType.WEB_ARTICLE> & {
  articleUrl: string;
  body: string;
};
