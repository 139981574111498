import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

export type StyledAProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  icon?: ReactNode;
};

export const StyledA = ({ children, icon, className, ...rest }: StyledAProps) => (
  <a
    {...rest}
    className={classNames(
      'inline-flex items-center font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500',
      className && className
    )}
  >
    {icon &&
      isValidElement(icon) &&
      cloneElement(icon as ReactElement, {
        className: classNames('h-5 w-5')
      })}
    {children}
  </a>
);
