import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

type LayerTabsProps = {
  availableShown: boolean;
  onAvailableShownChange: (shown: boolean) => void;
  availableLayers: number;
  parametrizedLayers: number;
};

const getTabLink = (shown: boolean, text: string, layers: number, onClick: () => void) => (
  <button
    onClick={onClick}
    className={classnames(
      'w-1/2 border-b-2 px-1 py-4 text-center text-sm font-medium focus-within:outline-none focus-within:ring-0',
      shown
        ? 'border-indigo-500 text-indigo-600'
        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
    )}
    aria-current={shown && 'page'}
  >
    {text}
    <span
      className={classnames(
        'ml-2 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ',
        shown ? 'bg-indigo-100 text-indigo-800' : 'bg-gray-100 text-gray-700'
      )}
    >
      {layers}
    </span>
  </button>
);

export const LayerTabs = ({
  availableShown,
  onAvailableShownChange,
  availableLayers,
  parametrizedLayers
}: LayerTabsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="px-4 py-2 sm:hidden sm:p-6">
        <label htmlFor="tabs" className="sr-only">
          {t('general.action.selectATab')}
        </label>
        <select
          value={availableShown ? 'available' : 'parametrized'}
          onChange={e => onAvailableShownChange(e.target.value === 'available')}
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value={'available'}>
            {t('components.project.template.layer.LayerTabs.selectOption', {
              count: availableLayers,
              context: 'available'
            })}
          </option>
          <option value={'parametrized'}>
            {t('components.project.template.layer.LayerTabs.selectOption', {
              count: parametrizedLayers,
              context: 'parametrized'
            })}
          </option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {getTabLink(
              availableShown,
              t('components.project.template.layer.LayerTabs.tabOption', { context: 'available' }),
              availableLayers,
              () => onAvailableShownChange(true)
            )}
            {getTabLink(
              !availableShown,
              t('components.project.template.layer.LayerTabs.tabOption', { context: 'parametrized' }),
              parametrizedLayers,
              () => onAvailableShownChange(false)
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};
