import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Clipboard } from 'ts-clipboard';

import { useNotifications } from '@src/hooks';
import { downloadFile } from '@src/utils';

import { Button, ButtonWithDropdown } from '../button';

type ButtonDownloadVideoProps = {
  output: string;
  outputWatermark?: string;
  className?: string;
  fileName?: string;
};

export const ButtonDownloadVideo = ({ output, outputWatermark, className, fileName }: ButtonDownloadVideoProps) => {
  const { t } = useTranslation();
  const { notifyInfo, notifyError } = useNotifications();

  const copyVideoLink = useCallback(() => {
    Clipboard.copy(output);
    notifyInfo(t('components.render.RenderDetails.copyRenderLinkToClipboard'));
  }, [output, notifyInfo, t]);

  const downloadVideo = useCallback(async () => {
    try {
      await downloadFile(output, fileName);
    } catch (error) {
      notifyError(t('components.render.common.failedToDownload', { count: 1 }));
    }
  }, [output, fileName, notifyError, t]);

  const copyWatermarkVideoLink = useCallback(() => {
    if (!outputWatermark) {
      return;
    }

    Clipboard.copy(outputWatermark);
    notifyInfo(t('components.render.RenderDetails.copyRenderLinkToClipboard'));
  }, [outputWatermark, notifyInfo, t]);

  const downloadWatermarkVideo = useCallback(async () => {
    if (!outputWatermark) {
      return;
    }

    try {
      await downloadFile(outputWatermark, fileName && `${fileName}-watermark`);
    } catch (error) {
      notifyError(t('components.render.common.failedToDownload', { count: 1 }));
    }
  }, [outputWatermark, fileName, notifyError, t]);

  if (!outputWatermark) {
    return (
      <>
        <Button className="lg:w-full xl:max-w-fit" secondary onClick={copyVideoLink}>
          {t('components.render.RenderDetails.copyLink')}
        </Button>
        <Button className="ml-2 lg:ml-0 lg:w-full xl:max-w-fit" secondary onClick={downloadVideo}>
          {t('general.action.download')}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="inline-flex rounded-md shadow-sm lg:w-full xl:max-w-fit">
        <ButtonWithDropdown
          className={className}
          labeled={{
            label: t('components.render.RenderDetails.copyLink'),
            action: copyVideoLink
          }}
          buttons={[
            {
              key: 'copyOriginal',
              action: copyVideoLink,
              label: t('components.render.RenderDetails.copyLinkOriginal')
            },
            {
              key: 'copyWithWatermark',
              action: copyWatermarkVideoLink,
              label: t('components.render.RenderDetails.copyLinkWatermark')
            }
          ]}
        />
      </div>
      <div className="inline-flex rounded-md shadow-sm lg:w-full xl:max-w-fit">
        <ButtonWithDropdown
          className={className}
          labeled={{
            label: t('general.action.download'),
            action: downloadVideo
          }}
          buttons={[
            {
              key: 'downloadOriginal',
              action: downloadVideo,
              label: t('general.action.downloadOriginalVideo')
            },
            {
              key: 'downloadWithWatermark',
              action: downloadWatermarkVideo,
              label: t('general.action.downloadWithWatermark')
            }
          ]}
        />
      </div>
    </>
  );
};
