import { useCallback, useEffect, useReducer } from 'react';

import {
  AnyRevisionUpdateDto,
  PresenterRevisionUpdateDto,
  SocialMediaRevisionUpdateDto,
  SummaryRevisionUpdateDto
} from '@src/models';

const storageKey = 'plainly.video-form';

type State = Record<string, AnyRevisionUpdateDto>;

type Action =
  | {
      type: 'update-revision';
      payload: {
        revisionId: string;
        revision: AnyRevisionUpdateDto;
      };
    }
  | {
      type: 'remove-revision';
      payload: {
        revisionId: string;
      };
    };

const initializer = (): State => {
  const storedState = sessionStorage.getItem(storageKey);
  if (storedState) {
    return JSON.parse(storedState) as State;
  } else {
    return {} as State;
  }
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'update-revision':
      return { ...state, [action.payload.revisionId]: action.payload.revision };
    case 'remove-revision': {
      const newState = { ...state };
      delete newState[action.payload.revisionId];
      return newState;
    }
    default:
      return state;
  }
}

export const useVideoFormReducer = <
  T extends SocialMediaRevisionUpdateDto | PresenterRevisionUpdateDto | SummaryRevisionUpdateDto
>(
  revisionId: string,
  backendState: T
) => {
  const [state, dispatch] = useReducer(reducer, initializer());

  useEffect(() => {
    if (state) {
      sessionStorage.setItem(storageKey, JSON.stringify(state));
    } else {
      sessionStorage.removeItem(storageKey);
    }
  });

  const updateRevision = useCallback(
    (revisionId: string, revision: T) => {
      dispatch({ type: 'update-revision', payload: { revisionId, revision } });
    },
    [dispatch]
  );

  const removeRevision = useCallback(
    (revisionId: string) => {
      dispatch({ type: 'remove-revision', payload: { revisionId } });
    },
    [dispatch]
  );

  const sessionRevision = state[revisionId] as T;
  const revision = sessionRevision || backendState;
  const dirtyState = sessionRevision ? JSON.stringify(sessionRevision) !== JSON.stringify(backendState) : false;

  return {
    revision,
    dirtyState,
    updateRevision,
    removeRevision
  };
};
