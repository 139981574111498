import { useTranslation } from 'react-i18next';

export const DesignInputErrors = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation();

  return (
    <div>
      {errors.map((error, index) => (
        <p key={index} className="mt-1 whitespace-nowrap text-sm font-medium text-red-600" id="parameter_name-error">
          {t(error)}
        </p>
      ))}
    </div>
  );
};
