import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import localizationHelper from '@src/i18n';
import { ArticleVideoScene } from '@src/models';

export const VideoSidebarScenes = ({
  scenes,
  setShowVideoDialog
}: {
  scenes: ArticleVideoScene[];
  setShowVideoDialog: (showVideoDialog: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const revisionId = videoNavigationState?.revisionId;
  const isScenes = videoNavigationState?.activeView === 'scenes';

  return (
    <div className="space-y-1 lg:px-2">
      <div className="mx-auto max-w-7xl px-5 lg:px-3">
        <h3 className="inline-flex items-center gap-x-1 text-xs font-semibold uppercase tracking-wider text-gray-600">
          {t('components.common.scenesTitle')}
        </h3>
      </div>
      <ol className="space-y-2 px-5 py-2 lg:px-3">
        {scenes.map((scene, index) => (
          <li
            key={index}
            onClick={() => {
              updateVideoNavigationState({
                revisionId: revisionId,
                activeView: 'scenes',
                sceneId: index + 1,
                headlinesTab: 1,
                assetsTab: 'suggested'
              });
              setShowVideoDialog(false);
            }}
            className="group flex cursor-pointer items-center"
          >
            <div className="relative flex h-4 w-4 flex-shrink-0 items-center justify-center">
              {isScenes && videoNavigationState.sceneId && videoNavigationState.sceneId - 1 === index ? (
                <>
                  <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                  <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
                </>
              ) : (
                <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
              )}
            </div>
            <div
              className={classNames(
                'flex w-full items-center justify-between text-sm font-medium',
                isScenes && videoNavigationState.sceneId && videoNavigationState.sceneId - 1 === index
                  ? 'text-gray-900'
                  : 'text-gray-500 group-hover:text-gray-900'
              )}
            >
              <p className="ml-3">{t('components.article.ArticleVideo.sidebar.position', { context: index + 1 })}</p>
              <span className="flex items-center justify-start gap-1 whitespace-nowrap rounded-full bg-gray-50 px-2.5 py-0.5 text-center text-xs font-medium leading-5">
                {scene.duration &&
                  t('general.common.duration', {
                    duration: localizationHelper
                      .forNumber({ maximumFractionDigits: 1, minimumFractionDigits: 1 })
                      .format(scene.duration)
                  })}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default VideoSidebarScenes;
