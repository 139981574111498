import { useState } from 'react';
import classNames from 'classnames';

import {
  GeneralView,
  RevisionActionForm,
  RevisionStateInfos,
  SceneActions,
  SceneNavigation,
  SceneView,
  VideoDetailsHeader,
  VideoPreviewModal,
  VideoSidebar,
  VoiceoverView
} from '@src/components';
import { useValidateUrls, useVideoFormReducer, useVideoNavigationParams } from '@src/hooks';
import {
  AnyVideoRevision,
  Article,
  Brand,
  RevisionState,
  SummaryRevisionUpdateDto,
  SummarySlideshowRevision
} from '@src/models';

export const VideoSummarySlideshowDetails = ({
  currentRevision,
  clonedRevision,
  revisions,
  article,
  brand,
  videoId
}: {
  currentRevision: SummarySlideshowRevision;
  clonedRevision?: SummarySlideshowRevision;
  revisions: AnyVideoRevision[];
  article: Article;
  brand: Brand;
  videoId: string;
}) => {
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const revisionId = videoNavigationState.revisionId;
  const summaryRevisionDto: SummaryRevisionUpdateDto = {
    videoType: currentRevision.videoType,
    cta: currentRevision.cta,
    logoUrl: currentRevision.logoUrl,
    scenes: currentRevision.scenes,
    voiceoverOptions: {
      settings: currentRevision.voiceover.in.settings,
      languageCode: currentRevision.voiceover.in.languageCode
    }
  };

  const { revision, dirtyState, updateRevision, removeRevision } = useVideoFormReducer(revisionId, summaryRevisionDto);

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const isValidForm =
    revision.scenes.every(input => input.script !== '' && input.headlines.every(headline => headline.heading !== '')) &&
    urlsValid;

  const canFireUpdate = isValidForm && dirtyState;
  const canFireDiscard = dirtyState;

  const setInputs = (inputs: SummaryRevisionUpdateDto) => updateRevision(revisionId, inputs);

  const activeView = videoNavigationState.activeView;
  const sceneId = activeView === 'scenes' && videoNavigationState.sceneId;
  const currentSceneIndex = sceneId ? sceneId - 1 : 0;

  const removeRevisionFromState = () => {
    const diff = summaryRevisionDto.scenes.length - revision.scenes.length;
    if (diff < 0 && sceneId) {
      updateVideoNavigationState({
        revisionId: videoNavigationState.revisionId,
        activeView: 'scenes',
        sceneId: sceneId + diff
      });
    }
    removeRevision(revisionId);
  };

  const voiceoverUrl = currentRevision.voiceover.out?.url || clonedRevision?.voiceover.out?.url;
  const disabled = currentRevision.state !== RevisionState.DRAFT && currentRevision.state !== RevisionState.ERROR;

  return (
    <>
      <div className="mx-auto max-w-5xl flex-1 space-y-6 px-4 py-6 sm:px-6 lg:px-8">
        <VideoDetailsHeader
          setShowVideoDialog={setShowVideoDialog}
          article={article}
          brand={brand}
          videoId={videoId}
          revisionId={currentRevision.id}
          state={currentRevision.state}
          disabled={dirtyState}
          setShowPreviewModal={setShowPreviewModal}
        />
        <RevisionStateInfos revision={currentRevision} message={currentRevision.message} />
        <RevisionActionForm
          state={currentRevision.state}
          data={revision}
          canFireUpdate={canFireUpdate}
          canFireDiscard={canFireDiscard}
          removeRevisionFromState={removeRevisionFromState}
        >
          <div className="space-y-6 bg-white shadow sm:rounded-lg">
            {activeView === 'scenes' && (
              <SceneActions
                disabled={disabled}
                scenes={revision.scenes}
                setScenes={newScenes => setInputs({ ...revision, scenes: newScenes })}
              />
            )}
            <div className={classNames('px-4 py-5 sm:p-6', activeView === 'scenes' && '!mt-0')}>
              <>
                {activeView === 'scenes' && (
                  <SceneView
                    currentScene={revision.scenes[currentSceneIndex]}
                    setCurrentScene={newScene => {
                      const newScenes = [...revision.scenes];
                      newScenes[currentSceneIndex] = newScene;
                      setInputs({ ...revision, scenes: newScenes });
                    }}
                    state={currentRevision.state}
                  />
                )}
                {activeView === 'voiceover' && (
                  <VoiceoverView
                    currentVoiceover={revision.voiceoverOptions.settings}
                    setCurrentVoiceover={newVoiceover =>
                      setInputs({
                        ...revision,
                        voiceoverOptions: {
                          ...revision.voiceoverOptions,
                          settings: { ...revision.voiceoverOptions.settings, ...newVoiceover }
                        }
                      })
                    }
                    voiceoverUrl={voiceoverUrl}
                    state={currentRevision.state}
                    showClonedInfo={clonedRevision && currentRevision.state === RevisionState.DRAFT}
                  />
                )}
                {activeView === 'general' && (
                  <GeneralView
                    inputs={{ cta: revision.cta, logoUrl: revision.logoUrl }}
                    disabled={disabled}
                    setGeneralSettings={newGeneralSettings => {
                      setInputs({
                        ...revision,
                        cta: newGeneralSettings.cta,
                        logoUrl: newGeneralSettings.logoUrl
                      });
                    }}
                    handleInvalidUrls={handleInvalidUrls}
                  />
                )}
              </>
            </div>
            {activeView === 'scenes' && <SceneNavigation scenes={revision.scenes} />}
          </div>
        </RevisionActionForm>
      </div>
      <div className="hidden h-0 w-72 lg:block lg:h-screen xl:w-80"></div>
      <VideoSidebar
        scenes={revision.scenes}
        originalArticleUrl={article.input.articleUrl}
        revisions={revisions}
        currentRevision={currentRevision}
        showVideoDialog={showVideoDialog}
        setShowVideoDialog={setShowVideoDialog}
      />
      <VideoPreviewModal
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
        currentRevision={currentRevision}
        clonedRevision={clonedRevision}
      />
    </>
  );
};
