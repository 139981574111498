import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { decode } from 'js-base64';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Label, Radio, RadioGroup } from '@headlessui/react';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { DesignColorPaletteIcon, DesignForm, DesignsVideoPlayer } from '@src/components';
import { useBreakpoint, useGetDesignDetails, useQueryParams } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ColorPalette, Design, DesignAspectRatio, DesignParameterType, DesignsFilterType } from '@src/models';
import * as routes from '@src/routes';

import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { Alert, Button, Loading, LoadingProps, NotFound } from '../common';

export type DesignDetailsProps = {
  designId: string;
};

const LoadingDesignDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const DesignDetails = ({ designId }: DesignDetailsProps) => {
  const { t } = useTranslation();
  const isLargeScreen = useBreakpoint('lg');
  const { withQueryParams, searchQuery } = useQueryParams();
  const navigate = useNavigate();

  const { isLoading, data: design } = useGetDesignDetails(designId);

  const duration = design?.variants.find(v => v.defaultVariant === true)?.duration;

  const [selectedColor, setSelectedColor] = useState<ColorPalette>();
  const [selectedRatio, setSelectedRatio] = useState<DesignAspectRatio>();
  const selectedVariant = design?.variants.find(v => v.aspectRatio === selectedRatio);

  const hasColors = design?.parameters.some(p => p.type === DesignParameterType.COLOR);
  const showForm = searchQuery.has('render');
  const variantId = searchQuery.get('variantId');
  const encodedRerenderParams = searchQuery.get('rerenderParams');

  // set default color and aspect ratio
  useEffect(() => {
    const defaultColor = design?.palettes.find(p => p.defaultPalette);
    defaultColor && setSelectedColor(defaultColor);

    const defaultRatio = design?.variants.find(v => v.defaultVariant)?.aspectRatio;
    defaultRatio && setSelectedRatio(defaultRatio);

    if (variantId) {
      setSelectedRatio(design?.variants.find(v => v.id === variantId)?.aspectRatio);
    }

    if (encodedRerenderParams) {
      const rerenderParams = JSON.parse(decode(encodedRerenderParams));
      const { colorPrimary, colorSecondary, colorTertiary } = rerenderParams;
      let selectedPalette = undefined;
      design?.palettes.forEach(palette => {
        const primary = palette.primary.slice(1);
        const secondary = palette.secondary?.slice(1);
        const tertiary = palette.tertiary?.slice(1);

        if (colorPrimary && colorSecondary && colorTertiary) {
          if (colorPrimary === primary && colorSecondary === secondary && colorTertiary === tertiary) {
            selectedPalette = palette;
          }
        } else if (colorPrimary && colorSecondary && !colorTertiary) {
          if (colorPrimary === primary && colorSecondary === secondary) {
            selectedPalette = palette;
          }
        } else if (colorPrimary && !colorSecondary && !colorTertiary) {
          if (colorPrimary === primary) {
            selectedPalette = palette;
          }
        }
      });
      setSelectedColor(selectedPalette);
    }
  }, [design, variantId, encodedRerenderParams]);

  return (
    <>
      {isLoading && <LoadingDesignDetails title={t('components.designs.DesignDetails.loading')} />}
      {!isLoading && (
        <>
          {!design && <NotFound title={t('components.designs.DesignDetails.empty')} />}
          {design && (
            <div className="sm:p-6 lg:grid lg:grid-cols-5 lg:gap-20">
              <div className={classNames('md:col-span-2', showForm && 'mb-5 lg:mb-0')}>
                <Breadcrumb>
                  <BreadcrumbItem to={routes.PUBLIC_DESIGNS} label={t('general.common.designs')} />
                  <BreadcrumbItem
                    to={withQueryParams(routes.PUBLIC_DESIGNS, { category: design.category })}
                    label={t('components.designs.common.designCategory', { context: design.category })}
                  />
                </Breadcrumb>
                <div className="mt-4">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{design.name}</h1>
                </div>
                {!showForm && (
                  <>
                    <section aria-labelledby="information-heading" className="mt-4">
                      <h2 id="information-heading" className="sr-only">
                        {t('components.designs.DesignDetails.informationHeading')}
                      </h2>
                      {design.app && (
                        <div className="mb-1 flex">
                          <CheckBadgeIcon className="mr-1 h-5 w-5 text-green-500" />
                          <p className="text-sm text-gray-500">
                            {t('components.designs.DesignDetails.integrationReady')}
                          </p>
                        </div>
                      )}
                      <div className="items-center sm:flex">
                        <p className="text-sm text-gray-500">
                          {t('components.designs.DesignDetails.duration', { duration: duration })}
                        </p>
                        <span className="mx-2 hidden text-sm text-gray-500 sm:flex">|</span>
                        <p className="text-sm text-gray-500">
                          {t('components.designs.DesignDetails.publishedDate', {
                            publishedDate: localizationHelper.forDate().formatDateStringLocally(design.publishedDate)
                          })}
                        </p>
                      </div>
                      <div className="mt-4 space-y-6">
                        <p className="text-base text-gray-500">{design.description}</p>
                      </div>
                    </section>
                    {isLargeScreen && (
                      <>
                        <DesignOptions
                          t={t}
                          selectedColor={selectedColor}
                          setSelectedColor={setSelectedColor}
                          design={design}
                          hasColors={hasColors}
                          showForm={showForm}
                          selectedRatio={selectedRatio}
                          setSelectedRatio={setSelectedRatio}
                        />
                        <div className="mt-10">
                          <Button
                            onClick={() => navigate(generatePath(routes.DESIGN_RENDER, { designId: design.id }))}
                            disabled={design.renderUiDisabled}
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          >
                            {t('general.action.render')}
                          </Button>
                          <Alert
                            className="mt-2"
                            show={design.renderUiDisabled}
                            type="info"
                            alertContent={t('components.designs.common.renderUiDisabled')}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {showForm && (
                  <>
                    <DesignOptions
                      t={t}
                      selectedColor={selectedColor}
                      setSelectedColor={setSelectedColor}
                      design={design}
                      hasColors={hasColors}
                      showForm={showForm}
                      selectedRatio={selectedRatio}
                      setSelectedRatio={setSelectedRatio}
                    />
                    {showForm && (
                      <div className="mt-8 hidden md:block">
                        <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
                          <div
                            className={classNames(
                              'group relative flex justify-start',
                              selectedRatio === DesignAspectRatio.SQUARE && 'w-3/5 lg:w-4/5',
                              selectedRatio === DesignAspectRatio.STORY && 'w-2/5 lg:w-2/3',
                              selectedRatio === DesignAspectRatio.WIDE && 'w-4/5 lg:w-full'
                            )}
                          >
                            <DesignsVideoPlayer
                              design={design}
                              selectedColors={
                                selectedColor !== undefined && selectedColor?.group ? [selectedColor.group] : []
                              }
                              selectedAspectRatios={selectedRatio ? [selectedRatio] : []}
                              singlePreview
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="md:col-span-3">
                {!showForm && (
                  <>
                    <div className="aspect-w-1 aspect-h-1 mt-10 flex justify-center overflow-hidden rounded-lg">
                      <div
                        className={classNames(
                          'group relative flex justify-center',
                          selectedRatio === DesignAspectRatio.SQUARE && 'w-4/5',
                          selectedRatio === DesignAspectRatio.STORY && 'w-2/3',
                          selectedRatio === DesignAspectRatio.WIDE && 'w-full'
                        )}
                      >
                        <DesignsVideoPlayer
                          design={design}
                          selectedColors={
                            selectedColor !== undefined && selectedColor?.group ? [selectedColor.group] : []
                          }
                          selectedAspectRatios={selectedRatio ? [selectedRatio] : []}
                          singlePreview
                        />
                      </div>
                    </div>
                    {!isLargeScreen && (
                      <>
                        <DesignOptions
                          t={t}
                          selectedColor={selectedColor}
                          setSelectedColor={setSelectedColor}
                          design={design}
                          hasColors={hasColors}
                          showForm={showForm}
                          selectedRatio={selectedRatio}
                          setSelectedRatio={setSelectedRatio}
                        />
                        <div className="mt-10">
                          <Button
                            onClick={() => navigate(generatePath(routes.DESIGN_RENDER, { designId: design.id }))}
                            disabled={design.renderUiDisabled}
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          >
                            {t('general.action.render')}
                          </Button>
                          <Alert
                            className="mt-2"
                            show={design.renderUiDisabled}
                            type="info"
                            alertContent={t('components.designs.common.renderUiDisabled')}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {showForm && (
                  <DesignForm
                    design={design}
                    selectedColor={selectedColor}
                    selectedVariant={selectedVariant}
                    closeForm={() => navigate(generatePath(routes.DESIGN_DETAILS_URL, { designId: design.id }))}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const DesignOptions = ({
  t,
  selectedColor,
  setSelectedColor,
  design,
  hasColors,
  showForm,
  selectedRatio,
  setSelectedRatio
}: {
  t: TFunction;
  selectedColor?: ColorPalette;
  setSelectedColor: (color: ColorPalette) => void;
  design: Design;
  hasColors?: boolean;
  showForm: boolean;
  selectedRatio?: DesignAspectRatio;
  setSelectedRatio: (ratio: DesignAspectRatio) => void;
}) => {
  return (
    <>
      <div className="mb-4 mt-10">
        <h2 id="options-heading" className="sr-only">
          {t('components.designs.DesignDetails.optionsHeading')}
        </h2>
        <h2 className="text-sm font-medium text-gray-900">
          {t('components.designs.common.filterLabel', { context: DesignsFilterType.COLOR_PALETTE })}
        </h2>
        <RadioGroup defaultValue={selectedColor} onChange={setSelectedColor} className="mt-2">
          <Label className="sr-only">
            {t('components.designs.common.filterLabel', { context: DesignsFilterType.COLOR_PALETTE })}
          </Label>
          <span className="flex items-center space-x-3">
            {design.palettes.map((color, index) => (
              <Radio
                key={index}
                value={color}
                className={classNames(
                  selectedColor === color && 'ring-2 ring-indigo-500',
                  'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                )}
              >
                <DesignColorPaletteIcon
                  large
                  primaryColor={color.primary}
                  secondaryColor={color.secondary}
                  backgroundColor={color.tertiary}
                  tooltip={t('components.designs.common.colorOption', {
                    context: color.group
                  })}
                />
              </Radio>
            ))}
            {hasColors && (
              <Radio
                disabled={!showForm}
                key={'custom'}
                value={undefined}
                className={classNames(
                  selectedColor === undefined && 'ring-2 ring-indigo-500',
                  !showForm && 'text-gray-400',
                  showForm && 'cursor-pointer',
                  'relative -m-0.5 flex items-center justify-center rounded-full p-0.5 focus:outline-none'
                )}
              >
                <div
                  className="flex"
                  title={showForm ? t('components.designs.DesignColorPalette.addCustomPalette') : ''}
                >
                  <PlusCircleIcon className="h-7 w-7" />
                </div>
              </Radio>
            )}
          </span>
        </RadioGroup>
      </div>
      {/* Aspect ratio picker */}
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-medium text-gray-900">
            {t('components.designs.common.filterLabel', { context: DesignsFilterType.ASPECT_RATIO })}
          </h2>
        </div>
        <RadioGroup defaultValue={selectedRatio} onChange={setSelectedRatio} className="mt-2">
          <Label className="sr-only">
            {t('components.designs.common.filterLabel', { context: DesignsFilterType.ASPECT_RATIO })}
          </Label>
          <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
            {design.variants.map((variant, index) => (
              <Radio
                key={index}
                value={variant.aspectRatio}
                className={({ focus }) =>
                  classNames(
                    'cursor-pointer',
                    focus ? 'ring-2 ring-indigo-500 ring-offset-2' : '',
                    selectedRatio === variant.aspectRatio
                      ? 'border-transparent bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                    'flex items-center justify-center rounded-md border px-3 py-3 text-sm font-medium sm:flex-1'
                  )
                }
              >
                <Label as="span">
                  {t('components.designs.common.aspectRatioOption', { context: variant.aspectRatio })}
                </Label>
              </Radio>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};
