import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScrollingPage, TemplateDetails } from '@src/components';

export const TemplateDetailsPage = () => {
  const { t } = useTranslation();
  const { projectId, templateId } = useParams() as { projectId: string; templateId: string };

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.TemplateDetailsPage')}</title>
        </Helmet>
        <TemplateDetails projectId={projectId} templateId={templateId} />
      </ScrollingPage>
    </HelmetProvider>
  );
};
