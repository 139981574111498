import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Badge, List, Loading } from '@src/components';
import { useGetUserProfile, useSetDefaultOrganizationId, useSwitchOrganization } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { UserDto, UserOrganization } from '@src/models';
import { State, useGlobalState } from '@src/state/store';
import { organizationInitials } from '@src/utils';

export const UserOrganizationsList = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetUserProfile();
  const [organization] = useGlobalState(State.ORGANIZATION);
  const organizations = data?.organizations;
  const defaultOrganizationId = data?.user.defaultOrganizationId;
  const activeOrganizationId = organization?.id;

  if (isLoading) return <Loading className="flex-1" title={t('components.user.UserOrganizationsList.loading')} />;

  return (
    <List
      data={organizations}
      renderItem={(org: UserOrganization) => {
        return (
          <UserOrganizationsListItem
            key={org.id}
            org={org}
            isDefaultOrganization={org.id === defaultOrganizationId}
            isActiveOrganization={org.id === activeOrganizationId}
          />
        );
      }}
      headerTitle={t('components.user.UserOrganizationsList.title')}
    />
  );
};

const UserOrganizationsListItem = ({
  org,
  isDefaultOrganization = false,
  isActiveOrganization
}: {
  org: UserOrganization;
  isDefaultOrganization: boolean;
  isActiveOrganization: boolean;
}) => {
  const { t } = useTranslation();
  const { switchOrg } = useSwitchOrganization();
  const { mutateAsync: setDefault, isLoading } = useSetDefaultOrganizationId();

  const menuItems: { label: string; action: (id: string) => Promise<UserDto> | void; disabled?: boolean }[] = [
    {
      label: t('general.action.setDefault'),
      action: (id: string) => setDefault({ id }),
      disabled: isLoading || isDefaultOrganization
    },
    {
      label: t('general.action.activate'),
      action: (id: string) => switchOrg(id),
      disabled: isLoading || isActiveOrganization
    }
  ];

  return (
    <li key={org.id} className="relative gap-x-6 px-4 py-5 sm:px-6">
      <div className="flex items-center justify-between">
        <div className="flex min-w-0 gap-x-2">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-indigo-500 font-semibold text-gray-100 sm:h-12 sm:w-12">
            <p>{organizationInitials(org.name)}</p>
          </div>
          <div className="flex min-w-0 flex-auto flex-col justify-between">
            <p className="flex text-sm font-semibold leading-4 text-gray-900 sm:text-base sm:leading-6">
              <span>{org.name}</span>
              <span>
                {isActiveOrganization && <Badge label={t('components.common.active')} type={'green'} />}
                {isDefaultOrganization && <Badge label={t('components.common.default')} type={'beta'} />}
              </span>
            </p>
            <p className="flex items-center gap-1 truncate text-xs leading-5 text-gray-500 sm:text-sm">
              <span className="truncate">
                {t('components.user.UserOrganizationsList.Item.memberSince', {
                  date: localizationHelper.forDate().formatDateStringLocally(org.memberSince)
                })}
              </span>
              <span>·</span>
              <span className="truncate">{t('components.user.common.role', { context: org.role })}</span>
            </p>
          </div>
        </div>
        <Menu as="div" className="relative flex-none opacity-100">
          <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            <span className="sr-only">{t('general.action.openMenu')}</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              anchor="bottom end"
              className={classNames(
                'mt-2 w-40 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none',
                isLoading && 'animate-pulse-tailwind'
              )}
            >
              {menuItems.map((item, index) => (
                <MenuItem key={index}>
                  {({ focus, close }) => (
                    <button
                      disabled={item.disabled}
                      onClick={async e => {
                        e.preventDefault();
                        try {
                          await item.action(org.id);
                        } finally {
                          close();
                        }
                      }}
                      className={classNames(
                        focus ? 'bg-gray-50' : '',
                        'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 disabled:cursor-not-allowed disabled:opacity-50'
                      )}
                    >
                      {item.label}
                    </button>
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </li>
  );
};
