import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { User } from '@src/models';

export const useSentryUserUpdate = (user?: User) => {
  useEffect(() => {
    updateSentryUser(user);
  }, [user]);
};

export const updateSentryUser = (user?: User) => {
  if (user) {
    Sentry.setUser({
      id: user.id,
      email: user.email
    });
  } else {
    Sentry.setUser(null);
  }
};
