import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/20/solid';
import { Button } from '@src/components';

export const EmptyState = ({
  icon,
  title,
  subtitle,
  route,
  buttonText,
  children
}: {
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
  route?: string;
  buttonText?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center">
      {icon ? (
        icon
      ) : (
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
      )}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
      {route && (
        <div className={classNames('mt-6 flex flex-col items-center gap-2', children && 'mb-6')}>
          <Link to={route}>
            <Button>
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {buttonText}
            </Button>
          </Link>
        </div>
      )}
      {children}
    </div>
  );
};
