import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const isProduction = `${import.meta.env.NODE_ENV}` === 'production';

i18n

  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)

  // detect user language, see detection init option
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // this makes sure that transaltions are not cached by browser between version
    backend: {
      queryStringParams: { v: import.meta.env.VITE_APP_VERSION }
    },

    // this enables debug in dev
    debug: !isProduction,

    // load lagnuage onlu, failback to english and list of supported langs
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],

    //this enables plurals in ^21.6.16
    compatibilityJSON: 'v3',

    // not needed for react
    interpolation: {
      escapeValue: false
    },

    // no wait for faster rendering, we have no SEO here
    react: {
      useSuspense: false
    },

    // how do we detect users previous language
    detection: {
      order: ['path', 'querystring', 'cookie', 'subdomain'],
      lookupCookie: 'plainly_i18next',
      caches: ['cookie'],
      cookieMinutes: 43200 // one month cookie storage
    }
  });

export default i18n;
