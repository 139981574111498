import classNames from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Alert, RendersOverview, ScrollingPage } from '@src/components';
import { WelcomeHeader } from '@src/components/layout/dashboard/';
import { UsageOverview } from '@src/components/usage';
import { useGetUserInvitations } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { SubscriptionIdentifier, SubscriptionStatus } from '@src/models';
import { ORGANIZATION_SETTINGS_SUBSCRIPTION, USER_INVITES } from '@src/routes';
import { State, useGlobalState } from '@src/state/store';
import { isEmpty } from '@src/utils';

export const DashboardIndex = () => {
  const { t } = useTranslation();
  const [subscription] = useGlobalState(State.SUBSCRIPTION);
  const { data: invitations } = useGetUserInvitations();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t('general.pageTitle.DashboardIndex')}</title>
        </Helmet>
        <WelcomeHeader />
        <ScrollingPage contentHeight={true}>
          <Alert
            className="mb-2"
            show={!isEmpty(invitations)}
            type="info"
            alertContent={
              <div className="flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  {invitations?.length === 1 && (
                    <Trans
                      i18nKey={'components.DashboardIndex.pendingInvites'}
                      tOptions={{ orgName: invitations[0].organizationName, email: invitations[0].inviterEmail }}
                    >
                      You have been invited to join
                      <span className="font-medium">{invitations[0].organizationName}</span> organization, by
                      <span className="font-medium">{invitations[0].inviterEmail}</span>.
                    </Trans>
                  )}
                  {invitations &&
                    invitations?.length > 1 &&
                    t('components.DashboardIndex.pendingInvites', { count: invitations.length })}
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <Link to={USER_INVITES} className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                    {t('components.DashboardIndex.seeInvitations')}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </p>
              </div>
            }
          />
          <Alert
            className="mb-2"
            show={(subscription?.active && subscription?.status === SubscriptionStatus.PAST_DUE) || false}
            type="warn"
            alertContent={
              <div className="flex-1 md:flex md:justify-between">
                <p className="text-sm text-yellow-700">{t('components.DashboardIndex.pastDueWarning')}</p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <Link
                    to={ORGANIZATION_SETTINGS_SUBSCRIPTION}
                    className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600"
                  >
                    {t('components.DashboardIndex.pastDueSubscriptionDetails')}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </p>
              </div>
            }
          />
          <Alert
            className="mb-2"
            show={subscription?.subscriptionIdentifier === SubscriptionIdentifier.FREE_TRIAL || false}
            type={subscription?.active ? 'success' : 'warn'}
            alertContent={
              <div className="flex-1 md:flex md:justify-between">
                <p className={classNames('text-sm', subscription?.active ? 'text-green-700' : 'text-yellow-700')}>
                  {t('components.DashboardIndex.freeTrialInfo', {
                    context: (subscription?.active || false).toString(),
                    date: localizationHelper.forDate().formatDateStringLocally(subscription?.endDate)
                  })}
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <Link
                    to={ORGANIZATION_SETTINGS_SUBSCRIPTION}
                    className={classNames(
                      'whitespace-nowrap font-medium',
                      subscription?.active
                        ? 'text-green-700 hover:text-green-600'
                        : 'text-yellow-700 hover:text-yellow-600'
                    )}
                  >
                    {t('general.action.subscribeNow')}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </p>
              </div>
            }
          />
          <div className="mt-4 space-y-8 md:space-y-16">
            <UsageOverview />
            <RendersOverview />
          </div>
        </ScrollingPage>
      </HelmetProvider>
    </>
  );
};
