import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@src/components';
import { useNotifications } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { Notification } from '@src/types';
import { isEmpty } from '@src/utils';

const NotificationItem = (notification: Notification, hasMore: boolean, onDismiss: () => void) => {
  return (
    <div
      key={notification.id}
      className={classNames('pointer-events-auto flex w-full max-w-md', hasMore && 'border-b border-gray-200')}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="shrink-0">
            {notification.type === 'info' && <InformationCircleIcon className="h-6 w-6 text-blue-400" />}
            {notification.type === 'warning' && <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" />}
            {notification.type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" />}
          </div>

          <div className="ml-3 w-0 flex-1">
            <p className="text-sm font-medium text-gray-900">{notification.message}</p>
            {notification.description && (
              <p className="font-small my-1 text-xs text-gray-900">{notification.description}</p>
            )}
            {notification.errorsList &&
              notification.errorsList.map((e, index) => (
                <p key={index} className="font-small my-1 text-xs text-gray-900">
                  {e}
                </p>
              ))}
            <p className="mb-2 text-xs text-gray-500">
              {localizationHelper.forDate().formatDistanceToNowWithDate(notification.time, true)}
            </p>
          </div>

          <div className="ml-4 flex shrink-0">
            <button
              onClick={onDismiss}
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NotificationsOverlay = () => {
  const { notifications, clearNotification, clearAllNotifications } = useNotifications();
  const { t } = useTranslation();
  const show = !isEmpty(notifications) || false;
  const hasMore = (notifications && notifications.length > 1) || false;

  return (
    <Transition
      as="div"
      className="pointer-events-none fixed inset-0 z-10 flex items-end justify-center px-4 py-6 sm:items-start sm:justify-end sm:p-6"
      show={show}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        {notifications.map(n => NotificationItem(n, hasMore, () => clearNotification(n.id)))}
        {hasMore && (
          <div className="p-4">
            <Button onClick={clearAllNotifications}>
              {t('components.layout.notifications.NotificationsOverlay.dismissAll')}
            </Button>
          </div>
        )}
      </div>
    </Transition>
  );
};
