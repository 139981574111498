import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading, LoadingProps } from '@src/components';
import { isEmpty } from '@src/utils';

import { Button } from '../button';

const EmptyTable = ({ title }: { title: string }) => (
  <tr>
    <td align="center" colSpan={42} className="p-4 text-gray-500">
      {title}
    </td>
  </tr>
);

const TableLoading = ({ title }: LoadingProps) => (
  <tr>
    <td align="center" colSpan={42} className="p-4 text-gray-500">
      <Loading title={title} />
    </td>
  </tr>
);

export type TableProps<T> = {
  data: T[] | null | undefined;
  headerRow: ReactNode;
  renderItem: (item: T) => ReactNode;
  isLoading?: boolean;
  loadingTitle?: string;
  emptyTitle?: string;
  onLoadMore?: () => void;
  pageSize?: number;
};

export const Table = <T,>({
  data,
  headerRow,
  renderItem,
  isLoading,
  loadingTitle,
  emptyTitle,
  onLoadMore,
  pageSize
}: TableProps<T>) => {
  const { t } = useTranslation();

  pageSize = pageSize || 1;
  const isLoadMoreVisible = (data?.length || 0) === pageSize && onLoadMore;

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>{headerRow}</thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {isLoading && isEmpty(data) && <TableLoading title={loadingTitle} />}
          {!isLoading && isEmpty(data) && <EmptyTable title={emptyTitle || t('general.common.emptyList')} />}
          {!isEmpty(data) && (
            <>
              {data?.map(renderItem)}

              {isLoadMoreVisible && (
                <tr>
                  <td align="center" colSpan={42} className="p-4 text-gray-500">
                    <Button
                      secondary
                      onClick={() => onLoadMore && onLoadMore()}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      {t('general.common.loadMore')}
                    </Button>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
