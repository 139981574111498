import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Loading } from '@src/components';
import { useAcceptUserInvitation, useGetUserInvitations, useRejectUserInvitation } from '@src/hooks';

export const UserPendingInvitePage = ({ onResolve }: { onResolve?: (accepted: boolean) => void }) => {
  const { t } = useTranslation();

  const { logout } = useAuth0();
  const executeLogout = useCallback(() => logout({ logoutParams: { returnTo: window.location.origin } }), [logout]);

  const { data, isLoading } = useGetUserInvitations();
  const invite = data && data[0];

  const { mutateAsync: acceptInvite, isLoading: isLoadingAccept } = useAcceptUserInvitation();
  const { mutateAsync: rejectInvite, isLoading: isLoadingReject } = useRejectUserInvitation();
  const actionsDisabled = isLoadingAccept || isLoadingReject;

  const handleAccept = useCallback(() => {
    if (invite) {
      acceptInvite({ invitationId: invite.id }).then(() => {
        if (onResolve) {
          onResolve(true);
        }
      });
    }
  }, [invite, acceptInvite, onResolve]);

  const handleReject = useCallback(() => {
    if (invite) {
      rejectInvite(invite.id).then(() => {
        if (onResolve) {
          onResolve(false);
        }
      });
    }
  }, [invite, rejectInvite, onResolve]);

  return (
    <div className="bg-white">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <img className="mx-auto h-10 w-auto sm:h-12" src="/assets/favicon/favicon-96x96.png" alt="Plainly Logo" />
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold uppercase leading-8 text-indigo-600">
            {t('general.common.welcomeMessage')}
          </p>
          {isLoading && <Loading className="mt-4" />}
          {!isLoading && invite && (
            <>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {t('components.UserPendingInvitePage.title')}
              </h1>
              <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                <Trans
                  i18nKey="components.UserPendingInvitePage.subtitle"
                  tOptions={{
                    organizationName: invite.organizationName,
                    invitedBy: invite.inviterEmail
                  }}
                >
                  You have been invited to join the organization <strong></strong> by a user with the email
                  <strong></strong>. You can accept the invitation and join, or continue with creating a new
                  organization for yourself.
                </Trans>
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button onClick={handleReject} disabled={actionsDisabled} secondary>
                  {t('components.UserPendingInvitePage.reject')}
                </Button>
                <Button onClick={handleAccept} disabled={actionsDisabled}>
                  {t('components.UserPendingInvitePage.accept')}
                </Button>
              </div>
            </>
          )}
          <p className="mt-10 text-xs text-gray-500">
            <Trans i18nKey="general.common.logoutMessage">
              Or{' '}
              <a href="#" onClick={executeLogout} className="underline hover:text-gray-700">
                logout
              </a>{' '}
              from the current Plainly account.
            </Trans>
          </p>
        </div>
      </main>
    </div>
  );
};
