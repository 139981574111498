import { ComponentPropsWithoutRef, CSSProperties, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  AdjustmentsHorizontalIcon,
  DocumentTextIcon,
  FolderIcon,
  PencilIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  SwatchIcon,
  TrashIcon,
  VideoCameraIcon
} from '@heroicons/react/24/solid';
import { LayerEffectType } from '@plainly/types';
import { Button, ParametrizedItem, TemplateCheckMeta } from '@src/components';
import { Layer, LayerType, Template } from '@src/models';
import { isEmpty, toApiParameterName } from '@src/utils';

type LayerParametrizedListItemProps = {
  projectId: string;
  template: Partial<Template>;
  parametrizedItem: ParametrizedItem;
  onDelete: () => void;
  onEdit?: () => void;
  wrapperRef?: (node: HTMLElement | null) => void;
};

const getLayerIcon = (layer: Partial<Layer>) => {
  // TODO fix solid

  const iconClassNames = 'shrink-0 mr-1.5 h-5 w-5 text-gray-400';
  switch (layer.layerType) {
    case LayerType.COMPOSITION:
      return <FolderIcon className={iconClassNames} />;

    case LayerType.DATA:
      return <DocumentTextIcon className={iconClassNames} />;

    case LayerType.DATA_EFFECT:
    case LayerType.SOLID_COLOR:
      return <SwatchIcon className={iconClassNames} />;

    case LayerType.MEDIA:
      switch (layer.mediaType) {
        case 'image':
          return <PhotoIcon className={iconClassNames} />;
        case 'video':
          return <VideoCameraIcon className={iconClassNames} />;
        case 'audio':
          return <SpeakerWaveIcon className={iconClassNames} />;
      }
  }
};

const getEffectIcon = (layer: Partial<Layer>) => {
  if (layer.layerType !== LayerType.DATA_EFFECT) return null;

  switch (layer.effectType) {
    case LayerEffectType.EFFECT_COLOR_CONTROL:
    case LayerEffectType.EFFECT_SLIDER_CONTROL:
      return <AdjustmentsHorizontalIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />;
  }
};

export const LayerParametrizedListItem = ({
  projectId,
  template,
  parametrizedItem: { layer, aeItem },
  onDelete,
  onEdit,
  wrapperRef,
  className,
  ...rest
}: LayerParametrizedListItemProps & ComponentPropsWithoutRef<'li'>) => {
  const { t } = useTranslation();

  const [metaCheckInvalid, setMetaCheckInvalid] = useState(false);

  const getLayerName = useCallback(
    (layer: Partial<Layer>) => {
      switch (layer.layerType) {
        case LayerType.DATA_EFFECT: {
          const effects = aeItem?.effects || [];
          const effect = effects.find(e => e.propertyName === layer.propertyName);

          if (!effect) return layer.layerName;

          return (
            <>
              {layer.layerName}
              {' > '}
              <Trans
                i18nKey={'components.project.template.layer.LayerParametrize.effects.effectType'}
                context={effect.type}
                tOptions={{ effectName: effect.name }}
              >
                {effect.name}
              </Trans>
            </>
          );
        }
        default:
          return layer.layerName;
      }
    },
    [aeItem]
  );

  const layerScripts = useMemo(() => layer.scripting?.scripts, [layer.scripting?.scripts]);

  return (
    <li {...rest} ref={wrapperRef} className={classNames(className)}>
      <div className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <div className="flex items-center truncate text-sm font-medium text-indigo-600">
                  {getLayerIcon(layer)}
                  <span className="mr-1.5 truncate">{getLayerName(layer)}</span>
                  {getEffectIcon(layer)}
                  <TemplateCheckMeta
                    projectId={projectId}
                    template={template}
                    layer={layer}
                    showAs="icon"
                    enabled={true}
                    refetchOnMount={false}
                    getMetaChangedResult={(result: boolean) => setMetaCheckInvalid(result)}
                  />
                </div>
                {layer.compositions?.map((c, index) => (
                  <p
                    key={`${layer.internalId}-${index}-comps`}
                    className={classNames('mt-1 flex items-center text-xs text-gray-500', index === 0 && 'md:mt-2')}
                  >
                    <span className="truncate" title={c.name}>
                      {c.name}
                    </span>
                  </p>
                ))}
              </div>
              <div className="mt-2 md:mt-0">
                <div>
                  {layer.parametrization ? (
                    <>
                      {layer.parametrization.expression && (
                        <p className="flex items-center text-sm text-gray-900">{layer.label}</p>
                      )}
                      <span className="mt-6 text-xs text-gray-500">
                        <Trans
                          i18nKey={'components.project.template.layer.LayerParametrizedListItem.value'}
                          tOptions={{ expression: toApiParameterName(layer.parametrization.value) }}
                          context={layer.parametrization.expression ? 'dynamic' : 'static'}
                        >
                          {layer.parametrization.expression ? 'As dynamic parameter' : 'As static value'}
                          <code className="prose prose-sm text-xs font-medium text-gray-900">
                            {toApiParameterName(layer.parametrization.value)}
                          </code>
                          .
                        </Trans>
                      </span>
                    </>
                  ) : (
                    <p className="flex items-center text-sm text-gray-500">
                      {t('components.project.template.layer.LayerParametrizedListItem.noParametrization')}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-2 md:mt-0">
                <p
                  className={classNames(
                    'flex items-center text-sm',
                    !isEmpty(layerScripts) ? 'text-gray-900' : 'text-gray-500'
                  )}
                >
                  {!isEmpty(layerScripts)
                    ? t('components.project.template.layer.LayerParametrizedListItem.scriptsCount', {
                        count: layerScripts.length
                      })
                    : t('components.project.template.layer.LayerParametrizedListItem.noScripts')}
                </p>
              </div>
            </div>
          </div>
          <div data-no-dnd="true">
            {layer.internalId && layer.compositions && (
              <Button
                className="mr-2"
                icon={<PencilIcon />}
                secondary={true}
                onClick={onEdit}
                disabled={metaCheckInvalid}
                title={
                  metaCheckInvalid
                    ? t('components.project.template.TemplateCheckMeta.metadataChangedTooltip', { context: 'true' })
                    : undefined
                }
              />
            )}
            <Button icon={<TrashIcon />} secondary={true} onClick={onDelete} />
          </div>
        </div>
      </div>
    </li>
  );
};

export const DndLayerParametrizedListItem = ({
  projectId,
  template,
  parametrizedItem,
  onDelete,
  onEdit,
  dndId,
  dndDisabled
}: {
  projectId: string;
  template: Partial<Template>;
  parametrizedItem: ParametrizedItem;
  onDelete: () => void;
  onEdit?: () => void;
  dndId: string;
  dndDisabled?: boolean;
}) => {
  const { attributes, listeners, transform, setNodeRef, isDragging } = useSortable({
    id: dndId,
    disabled: dndDisabled
  });

  const style: CSSProperties = useMemo(() => {
    return {
      transform: CSS.Translate.toString(transform), //let dnd-kit do its thing
      opacity: isDragging ? 0.4 : 1,
      zIndex: isDragging ? 1 : 0,
      position: 'relative'
    };
  }, [isDragging, transform]);

  const getCursor = useMemo(() => {
    if (dndDisabled) return 'cursor-auto';
    if (isDragging) return 'cursor-grabbing';
    if (!isDragging) return 'cursor-grab';
  }, [dndDisabled, isDragging]);

  const dndAttributes = useMemo(() => {
    return {
      ...attributes,
      ...listeners,
      style
    };
  }, [attributes, listeners, style]);

  return (
    <LayerParametrizedListItem
      projectId={projectId}
      template={template}
      parametrizedItem={parametrizedItem}
      onDelete={onDelete}
      onEdit={onEdit}
      wrapperRef={setNodeRef}
      className={getCursor}
      {...dndAttributes}
    />
  );
};
