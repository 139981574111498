import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BrandCreateForm, Loading, ScrollingPage } from '@src/components';
import { useGetBrandDetails } from '@src/hooks';

export const BrandEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  const { isLoading, data: brand } = useGetBrandDetails(id);

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.BrandEditPage')}</title>
        </Helmet>
        {isLoading && <Loading title={t('components.brand.BrandDetails.loading')} />}
        {!isLoading && <BrandCreateForm brand={brand} />}
      </ScrollingPage>
    </HelmetProvider>
  );
};
