import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlainlyCombobox } from '@src/components/common';
import { useGetProjectMetadata } from '@src/hooks';
import { CompositionAeItem } from '@src/models';
import { collectCompositions, compositionSorter } from '@src/utils';

export const TargetComposition = ({
  targetCompositionName,
  setTargetCompositionName,
  context,
  projectId
}: {
  targetCompositionName?: string;
  setTargetCompositionName: (name: string | undefined) => void;
  context: 'all' | 'prefix';
  projectId: string;
}) => {
  const { t } = useTranslation();
  const { isLoading: isLoadingMeta, data: meta, isRefetching } = useGetProjectMetadata(projectId, true, true);

  const isLoading = isLoadingMeta || isRefetching;

  const compositions = useMemo(() => {
    const result: CompositionAeItem[] = [];
    meta?.sort(compositionSorter).forEach(rootComposition => {
      collectCompositions(rootComposition).forEach(c => {
        if (result.findIndex(existing => existing.id === c.id) < 0) {
          result.push(c);
        }
      });
    });

    return result;
  }, [meta]);

  const data = useMemo(
    () =>
      compositions.map(c => ({
        name: c.name,
        item: c,
        label: c.name,
        selected: c.name === targetCompositionName
      })),
    [compositions, targetCompositionName]
  );

  return (
    <div>
      <label htmlFor="targetCompositionName" className="block text-sm font-medium text-gray-700">
        {t('components.project.autoGenerateTemplates.common.targetCompositionName')}
        <p className="text-sm font-normal text-gray-500">
          {t('components.project.autoGenerateTemplates.common.targetCompositionNameDesc', { context: context })}
        </p>
      </label>
      <PlainlyCombobox
        loading={isLoading}
        hasSearch
        className="mt-1"
        data={data}
        onSelectionChange={item => setTargetCompositionName(item?.name)}
      />
    </div>
  );
};
