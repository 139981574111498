import { useTranslation } from 'react-i18next';

import { ButtonDownloadVideo, VideoPlayer, VideoPlayerError } from '@src/components';
import { CLEAN_UP_SVG_SRC, VIDEO_BUILDING_SVG_SRC } from '@src/icons';
import { Render } from '@src/models';

export const RenderVideoInfo = ({
  renderingFinished,
  render,
  className
}: {
  renderingFinished: boolean | null | undefined;
  render: Render;
} & React.ComponentProps<'section'>) => {
  const { t } = useTranslation();

  return (
    <section aria-labelledby="video-preview-title" className={className}>
      <div className="bg-white pt-5 shadow sm:rounded-lg">
        <h2 id="timeline-title" className="px-4 text-lg font-medium text-gray-900 sm:px-6">
          {t('components.render.RenderDetails.videoPreview')}
        </h2>
        <div className="rounded p-4">
          {!renderingFinished && (
            <div className="mt-6 inline-flex h-full w-full grow flex-col place-content-center items-center">
              <div className="flex flex-col items-center">
                <img className="h-56 w-56 md:h-64 md:w-64" src={VIDEO_BUILDING_SVG_SRC} alt="Empty"></img>
                <span className="my-6 text-center">{t('components.render.RenderDetails.videoRendering')}</span>
              </div>
            </div>
          )}
          {renderingFinished && (
            <>
              {!render.expired && (
                <>
                  {render.output && <VideoPlayer src={render.output} srcWatermark={render.outputWatermark} />}
                  {!render.output && (
                    <VideoPlayerError title={t('components.render.RenderDetails.videoRenderingFailed')} />
                  )}
                </>
              )}
              {render.expired && (
                <div className="mt-6 inline-flex h-full w-full grow flex-col place-content-center items-center">
                  <div className="flex flex-col items-center">
                    <img className="h-56 w-56 md:h-64 md:w-64" src={CLEAN_UP_SVG_SRC} alt="Expired"></img>
                    <span className="my-6 text-center">{t('components.render.RenderDetails.videoExpired')}</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!render.expired && render.output && (
          <div className="flex items-center justify-end gap-2 border-t border-gray-200 bg-gray-50 px-4 py-4 sm:rounded-b-lg sm:px-6 lg:flex-col xl:flex-row">
            <ButtonDownloadVideo
              output={render.output}
              outputWatermark={render.outputWatermark}
              fileName={render.outputFormat?.attachmentFileName}
              className="w-full truncate whitespace-nowrap"
            />
          </div>
        )}
      </div>
    </section>
  );
};
