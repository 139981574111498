import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DesignInputErrors, StyledA } from '@src/components';
import { isEmpty } from '@src/utils';

type TextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export type DesignStringInputProps = TextAreaProps &
  InputProps & {
    onFieldUpdate: (value: string) => void;
    errors?: string[];
  };

export const DesignStringInput = ({ value, onFieldUpdate, errors, defaultValue, ...rest }: DesignStringInputProps) => {
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);
  const [isMultiline, toggleMultiline] = useState(false);

  const moveCursorToEnd = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.currentTarget as HTMLInputElement | HTMLTextAreaElement;
    if (!target) return;

    target.setSelectionRange(target.value.length, target.value.length);
  };

  const fieldValue = value || defaultValue || '';

  return (
    <div className="flex flex-col">
      {isMultiline ? (
        <textarea
          autoFocus={focus}
          onFocus={moveCursorToEnd}
          className="block w-full resize-y rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          autoComplete="off"
          onChange={e => onFieldUpdate(e.target.value)}
          value={fieldValue}
          {...rest}
        />
      ) : (
        <input
          autoFocus={focus}
          onFocus={moveCursorToEnd}
          className="block w-full resize-y rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          autoComplete="off"
          type="text"
          onChange={e => onFieldUpdate(e.target.value)}
          value={fieldValue}
          {...rest}
        />
      )}
      <div className="flex items-start justify-between gap-2">
        {!isEmpty(errors) && <DesignInputErrors errors={errors} />}
        <StyledA
          onClick={() => {
            toggleMultiline(!isMultiline);
            setFocus(true);
          }}
          className="ml-auto mt-1 cursor-pointer truncate whitespace-nowrap text-sm font-medium text-gray-700"
        >
          {t('components.render.RenderForm.textInputMultiLineSwitch', { context: `${isMultiline}` })}
        </StyledA>
      </div>
    </div>
  );
};
