import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { decode, encode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Description, Field, Label, Switch } from '@headlessui/react';
import { DesignAppForm, DesignParametersForm, Form } from '@src/components';
import { useQueryParams } from '@src/hooks';
import { ColorPalette, Design, DesignApp, DesignVariant, RenderOptionsDto } from '@src/models';
import { RENDER_BATCH_CSV_DESIGN } from '@src/routes';

export type DesignFormProps = {
  design: Design;
  selectedColor: ColorPalette | undefined;
  selectedVariant: DesignVariant | undefined;
  closeForm: () => void;
};

export const DesignForm = ({ design, selectedColor, selectedVariant, closeForm }: DesignFormProps) => {
  const { t } = useTranslation();
  const [advancedOptions, setAdvancedOptions] = useState<RenderOptionsDto>();
  const navigate = useNavigate();
  const { withQueryParams, searchQuery, updateQueryParams } = useQueryParams();
  const rawRender = searchQuery.has('rawRender');
  const encodedRerenderOptions = searchQuery.get('rerenderOptions');

  useEffect(() => {
    if (encodedRerenderOptions) {
      const rerenderOptions = JSON.parse(decode(encodedRerenderOptions));
      setAdvancedOptions(rerenderOptions);
    }
  }, [encodedRerenderOptions]);

  const defaultParameters =
    selectedColor &&
    encode(
      JSON.stringify({
        colorPrimary: selectedColor.primary,
        colorSecondary: selectedColor.secondary,
        colorTertiary: selectedColor.tertiary
      })
    );
  const onBatchRender = useCallback(() => {
    navigate(
      withQueryParams(
        generatePath(RENDER_BATCH_CSV_DESIGN, {
          projectId: design.id,
          templateId: selectedVariant?.id,
          step: '1'
        }),
        { defaultParameters }
      )
    );
  }, [defaultParameters, design.id, navigate, selectedVariant?.id, withQueryParams]);

  return (
    <>
      <Form
        onAdvancedOptionsChange={setAdvancedOptions}
        advancedOptions={advancedOptions}
        onBatchRender={onBatchRender}
      >
        {design.app && (
          <div className="space-y-6 bg-white px-4 pt-6 sm:px-6">
            <Field as="div" className="flex items-center">
              <Switch
                checked={rawRender}
                onChange={(value: boolean) => {
                  navigate({ search: updateQueryParams(window.location, { rawRender: value ? value : undefined }) });
                }}
                className={classNames(
                  rawRender ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    rawRender ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <div className="ml-3 flex flex-col items-baseline justify-center">
                <Label as="span">
                  <span className="block text-sm font-medium text-gray-700">
                    {t('components.designs.DesignDetails.rawRender')}
                  </span>
                </Label>
                <Description as="span" className="text-sm text-gray-500">
                  {t('components.designs.DesignDetails.rawRenderDescription')}
                </Description>
              </div>
            </Field>
          </div>
        )}
        {design.app && !rawRender ? (
          <DesignAppForm
            design={design as DesignApp}
            selectedColor={selectedColor}
            selectedVariant={selectedVariant}
            closeForm={closeForm}
            advancedOptions={advancedOptions}
          />
        ) : (
          <DesignParametersForm
            design={design}
            selectedColor={selectedColor}
            selectedVariant={selectedVariant}
            closeForm={closeForm}
            advancedOptions={advancedOptions}
          />
        )}
      </Form>
    </>
  );
};
