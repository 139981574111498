import { useState } from 'react';
import classNames from 'classnames';
import { decode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button, Loading, Modal, StyledLink } from '@src/components/common';
import { useGetBatchRendersById, useNotifications, useQueryParams } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { RenderState } from '@src/models';
import * as routes from '@src/routes';
import { RENDER_DETAILS } from '@src/routes';
import { downloadFile, isEmpty } from '@src/utils';

import { getRenderStateBadge } from '../RendersTable';

type ListItem = {
  context: string;
  value: number;
};

export const RenderBatchDownloadPreview = () => {
  const { notifyError, notifyInfo } = useNotifications();
  const { t } = useTranslation();
  const { withQueryParams, searchQuery } = useQueryParams();
  const encodedBatchRender = searchQuery.get('batchRender');
  const decodedBatchRender: { batchRenderId: string } | undefined = encodedBatchRender
    ? JSON.parse(decode(encodedBatchRender))
    : undefined;

  const [downloaded, setDownloaded] = useState(0);
  const { data, isLoading, isRefetching } = useGetBatchRendersById(decodedBatchRender?.batchRenderId);

  const loading = isLoading || isRefetching;

  const successfulRenders = data?.filter(r => r.state === RenderState.DONE && !r.expired);
  const pendingRenders = data?.filter(r =>
    [RenderState.PENDING, RenderState.THROTTLED, RenderState.QUEUED, RenderState.IN_PROGRESS].includes(r.state)
  ).length;
  const failedOrCanceledRenders = data?.filter(
    r => [RenderState.INVALID, RenderState.FAILED, RenderState.CANCELLED].includes(r.state) || r.expired
  ).length;

  const disabled = loading || !data || isEmpty(successfulRenders) || downloaded === successfulRenders?.length;

  const listItems: ListItem[] = [
    { context: 'total', value: data?.length ? data.length : 0 },
    { context: 'ready', value: successfulRenders ? successfulRenders.length : 0 },
    { context: 'pending', value: pendingRenders ? pendingRenders : 0 },
    { context: 'notCompleted', value: failedOrCanceledRenders ? failedOrCanceledRenders : 0 }
  ];

  const isSafeToDownload =
    (!failedOrCanceledRenders || failedOrCanceledRenders === 0) && (!pendingRenders || pendingRenders === 0);
  const [showModal, setShowModal] = useState(false);

  const downloadFiles = async () => {
    if (!successfulRenders) return;

    if (!isSafeToDownload && showModal === false) {
      setShowModal(true);
      return;
    }

    let directoryHandle: FileSystemDirectoryHandle | undefined = undefined;
    if (window.showDirectoryPicker) {
      try {
        directoryHandle = await window.showDirectoryPicker();
      } catch (error) {
        // if it is AbortError do nothing
        if (error instanceof DOMException) {
          if (error.ABORT_ERR) return;
        }

        // else throw
        throw error;
      }
    }

    let totalFailed = 0;
    let totalDownloaded = 0;

    for (const render of successfulRenders) {
      const { output, outputFormat } = render;

      try {
        if (!output) continue;

        const fileName =
          outputFormat && outputFormat.attachmentFileName
            ? `${outputFormat.attachmentFileName}.${outputFormat.ext}`
            : undefined;
        await downloadFile(output, fileName, directoryHandle);
        totalDownloaded++;
      } catch (error) {
        totalFailed++;
      } finally {
        setDownloaded(prev => prev + 1);
      }
    }

    if (totalDownloaded > 0) {
      notifyInfo(t('components.render.common.finishedDownloading', { count: totalDownloaded }));
    }

    if (totalFailed > 0) {
      notifyError(t('components.render.common.failedToDownload', { count: totalFailed }));
    }
  };

  return (
    <>
      <div className="mx-auto flex flex-col gap-6">
        {isLoading && <Loading className="mx-auto" />}
        {!isLoading && (
          <>
            <BatchDownloadInfoCard stats={listItems} />
            <div className="flex flex-col gap-6">
              <div className="max-h-[400px] overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 py-2">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        #
                      </th>
                      <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('general.common.date')}
                      </th>
                      <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('general.common.file')}
                      </th>
                      <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('general.common.status')}
                      </th>
                      <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {t('components.common.expired')}
                      </th>
                      <th scope="col" className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">{t('components.render.RenderBatchPreviewTable.link')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 overflow-auto bg-white">
                    {data?.map((d, index) => (
                      <tr key={index}>
                        <td className="relative whitespace-nowrap py-3.5 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {index + 1}
                        </td>
                        <td className="relative whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500">
                          {localizationHelper.forDate().formatDateTimeStringLocally(d.createdDate)}
                        </td>
                        <td className="relative whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500">
                          {decodedBatchRender?.batchRenderId.split('|')[1]}
                        </td>
                        <td className="relative whitespace-nowrap px-3 py-3.5 text-sm font-medium text-gray-500">
                          {getRenderStateBadge(d.state, d.retried || false, t)}
                        </td>
                        <td className="relative whitespace-nowrap px-3 py-3.5 text-sm font-medium text-gray-500">
                          {d.expired ? t('general.common.yes') : t('general.common.no')}
                        </td>
                        <td className="relative px-3 py-3.5 text-right text-sm text-gray-500">
                          {data[index] && (
                            <StyledLink to={generatePath(RENDER_DETAILS, { id: data[index].id })}>
                              {t('components.render.RenderBatchPreviewTable.renderDetails')}
                            </StyledLink>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <BatchDownloadProgressCard downloaded={downloaded} total={successfulRenders?.length} />
              <div className="flex justify-end">
                <Link
                  to={withQueryParams(generatePath(routes.RENDER_BATCH_DOWNLOAD, { step: '1' }), {
                    batchRender: encodedBatchRender
                  })}
                >
                  <Button secondary className="mr-3">
                    {t('general.action.back')}
                  </Button>
                </Link>
                <Button disabled={disabled} loading={loading} onClick={downloadFiles}>
                  {t('general.action.download')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal visible={showModal}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {t('components.render.batchDownload.RenderBatchDownloadPreview.Modal.title', {
                context:
                  pendingRenders && !failedOrCanceledRenders
                    ? 'pending'
                    : !pendingRenders && failedOrCanceledRenders
                      ? 'invalid'
                      : 'both'
              })}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {t('components.render.batchDownload.RenderBatchDownloadPreview.Modal.desc', {
                  context:
                    pendingRenders && !failedOrCanceledRenders
                      ? 'pending'
                      : !pendingRenders && failedOrCanceledRenders
                        ? 'invalid'
                        : 'both'
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2 sm:flex-row-reverse">
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            onClick={() => {
              downloadFiles();
              setShowModal(false);
            }}
            className="w-full sm:w-auto"
          >
            {t('general.action.download')}
          </Button>
          <Button secondary type="button" onClick={() => setShowModal(false)} className="w-full sm:w-auto">
            {t('general.action.dismiss')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const BatchDownloadInfoCard = ({ stats }: { stats: ListItem[] }) => {
  const { t } = useTranslation();

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        {stats.map(item => (
          <div key={item.context} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">
              {t('components.render.batchDownload.RenderBatchDownloadPreview.item', { context: item.context })}
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.value}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const BatchDownloadProgressCard = ({ downloaded, total }: { downloaded: number; total?: number }) => {
  const { t } = useTranslation();
  const percentage = total && (downloaded / total) * 100;

  return (
    <div className="h-fit bg-white shadow sm:rounded-lg">
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <p className="mb-2 text-sm font-medium text-gray-900">
          {t('components.render.batchDownload.RenderBatchDownloadPreview.downloadProgress')}
        </p>
        <div className="flex flex-col gap-2">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div className="h-2 rounded-full bg-indigo-600" style={{ width: `${percentage}%` }} />
          </div>
          <div className="text-center text-sm font-medium text-gray-600">
            <div className={classNames(downloaded ? 'text-indigo-600' : 'text-gray-600')}>
              {downloaded} / {total}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
