import { useTranslation } from 'react-i18next';

import { Header, HelpNotification, VideoGeniusArticlesPageHelpLinks } from '@src/components/common';

export const ArticlesHeader = () => {
  const { t } = useTranslation();

  return (
    <Header
      header={t('general.common.articles')}
      subheader={t('components.article.ArticlesHeader.subheader')}
      actions={<HelpNotification type="button" links={VideoGeniusArticlesPageHelpLinks} />}
    />
  );
};
