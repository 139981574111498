import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Loading, LoadingProps, RendersTimelineItem } from '@src/components';
import { useGetRenders, useQueryParams } from '@src/hooks';
import { BLANK_SVG_SRC } from '@src/icons';
import * as routes from '@src/routes';
import { isEmpty } from '@src/utils';

const EmptyRendersList = ({ title }: { title: string }) => (
  <div className="mt-6 inline-flex h-full w-full grow flex-col place-content-center items-center">
    <div className="flex flex-col items-center">
      <img className="h-56 w-56" src={BLANK_SVG_SRC} alt="Empty" />
      <span className="my-6 px-6 text-sm sm:px-4">{title}</span>
    </div>
  </div>
);

const LoadingRenders = ({ title }: LoadingProps) => (
  <div className="mt-6 inline-flex w-full grow flex-col">
    <Loading title={title} />
  </div>
);

export type RendersTimelineProps = {
  projectId: string;
  maxItems: number;
  templateId?: string;
};

export const RendersTimeline = ({ projectId, maxItems, templateId }: RendersTimelineProps) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();

  const { isLoading, data: renders = [], refetch: fetchRenders } = useGetRenders({ projectId, templateId });
  const empty = isEmpty(renders);

  if (renders) {
    renders.splice(maxItems);
  }

  return (
    <div className="divide-y divide-gray-200 bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('general.common.renders')}
        </h2>
      </div>
      <div className="flow-root pt-6">
        {isLoading && <LoadingRenders title={t('components.render.common.loading')} />}
        {!isLoading && (
          <>
            {empty && <EmptyRendersList title={t('components.render.RendersTimeline.empty')} />}

            {!empty && (
              <ul className="px-4 sm:px-6">
                {renders?.map((render, index) => (
                  <RendersTimelineItem key={render.id} model={render} isLastItem={index === renders.length - 1} />
                ))}
              </ul>
            )}
            <div className="flex items-center justify-end border-t border-gray-200 bg-gray-50 px-4 py-4 sm:rounded-b-lg sm:px-6">
              <Button secondary onClick={() => fetchRenders()} loading={isLoading}>
                {t('general.action.update')}
              </Button>
              {!empty && (
                <Link to={withQueryParams(routes.RENDERS_LIST, { projectId, templateId })}>
                  <Button className="ml-2">{t('general.action.showAll')}</Button>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
