import { useState } from 'react';
import classNames from 'classnames';

import {
  AvatarView,
  GeneralView,
  RevisionActionForm,
  RevisionStateInfos,
  SceneActions,
  SceneNavigation,
  SceneView,
  VideoDetailsHeader,
  VideoPreviewModal,
  VideoSidebar
} from '@src/components';
import { useValidateUrls, useVideoFormReducer, useVideoNavigationParams } from '@src/hooks';
import {
  AnyVideoRevision,
  Article,
  Brand,
  PresenterRevision,
  PresenterRevisionUpdateDto,
  RevisionState
} from '@src/models';

export const VideoPresenterDetails = ({
  currentRevision,
  clonedRevision,
  revisions,
  article,
  brand,
  videoId
}: {
  currentRevision: PresenterRevision;
  clonedRevision?: PresenterRevision;
  revisions: AnyVideoRevision[];
  article: Article;
  brand: Brand;
  videoId: string;
}) => {
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const revisionId = videoNavigationState.revisionId;
  const presenterRevisionDto: PresenterRevisionUpdateDto = {
    videoType: currentRevision.videoType,
    cta: currentRevision.cta,
    logoUrl: currentRevision.logoUrl,
    scenes: currentRevision.scenes,
    avatarOptions: {
      settings: currentRevision.avatar.in.settings,
      presenterSignature: currentRevision.avatar.in.presenterSignature
    }
  };
  const { revision, dirtyState, updateRevision, removeRevision } = useVideoFormReducer(
    revisionId,
    presenterRevisionDto
  );

  // TODO: this one will change
  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const isValidForm = urlsValid;
  const canFireUpdate = dirtyState && isValidForm;

  const canFireDiscard = dirtyState;

  const setInputs = (inputs: PresenterRevisionUpdateDto) => updateRevision(revisionId, inputs);
  const removeRevisionFromState = () => {
    const diff = presenterRevisionDto.scenes.length - revision.scenes.length;
    if (diff < 0 && sceneId) {
      updateVideoNavigationState({
        revisionId: videoNavigationState.revisionId,
        activeView: 'scenes',
        sceneId: sceneId + diff
      });
    }
    removeRevision(revisionId);
  };

  const activeView = videoNavigationState.activeView;
  const sceneId = activeView === 'scenes' && videoNavigationState.sceneId;
  const currentSceneIndex = sceneId ? sceneId - 1 : 0;

  return (
    <>
      <div className="mx-auto max-w-5xl flex-1 space-y-6 px-4 py-6 sm:px-6 lg:px-8">
        <VideoDetailsHeader
          setShowVideoDialog={setShowVideoDialog}
          article={article}
          brand={brand}
          videoId={videoId}
          revisionId={currentRevision.id}
          state={currentRevision.state}
          disabled={dirtyState}
          setShowPreviewModal={setShowPreviewModal}
        />
        <RevisionStateInfos revision={currentRevision} message={currentRevision.message} />
        <RevisionActionForm
          state={currentRevision.state}
          data={revision}
          canFireUpdate={canFireUpdate}
          canFireDiscard={canFireDiscard}
          removeRevisionFromState={removeRevisionFromState}
        >
          <div className="space-y-6 bg-white shadow sm:rounded-lg">
            {activeView === 'scenes' && (
              <SceneActions
                disabled={
                  currentRevision.state !== RevisionState.DRAFT && currentRevision.state !== RevisionState.ERROR
                }
                scenes={revision.scenes}
                setScenes={newScenes => setInputs({ ...revision, scenes: newScenes })}
              />
            )}
            <div className={classNames('px-4 py-5 sm:p-6', activeView === 'scenes' && '!mt-0')}>
              <>
                {activeView === 'scenes' && (
                  <SceneView
                    currentScene={revision.scenes[currentSceneIndex]}
                    setCurrentScene={newScene => {
                      const newScenes = [...revision.scenes];
                      newScenes[currentSceneIndex] = newScene;
                      setInputs({ ...revision, scenes: newScenes });
                    }}
                    state={currentRevision.state}
                  />
                )}
                {activeView === 'avatar' && <AvatarView />}
                {activeView === 'general' && (
                  <GeneralView
                    inputs={{ cta: revision.cta, logoUrl: revision.logoUrl }}
                    disabled={
                      currentRevision.state !== RevisionState.DRAFT && currentRevision.state !== RevisionState.ERROR
                    }
                    setGeneralSettings={newGeneralSettings => {
                      setInputs({
                        ...revision,
                        cta: newGeneralSettings.cta,
                        logoUrl: newGeneralSettings.logoUrl
                      });
                    }}
                    handleInvalidUrls={handleInvalidUrls}
                  />
                )}
              </>
            </div>
            {activeView === 'scenes' && <SceneNavigation scenes={revision.scenes} />}
          </div>
        </RevisionActionForm>
      </div>
      <div className="hidden h-0 w-72 lg:block lg:h-screen xl:w-80"></div>
      <VideoSidebar
        scenes={revision.scenes}
        originalArticleUrl={article.input.articleUrl}
        revisions={revisions}
        currentRevision={currentRevision}
        showVideoDialog={showVideoDialog}
        setShowVideoDialog={setShowVideoDialog}
      />
      <VideoPreviewModal
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
        currentRevision={currentRevision}
        clonedRevision={clonedRevision}
      />
    </>
  );
};
