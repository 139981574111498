import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { SignalIcon } from '@heroicons/react/24/solid';
import {
  Alert,
  AppmixerActiveIntegrationsCard,
  AutoGenerateTemplatesModal,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CopyIdAction,
  CreatedByInformation,
  ErrorCard,
  FeatureGating,
  getJobError,
  HeaderWithBreadcrumbs,
  Loading,
  LoadingProps,
  NotFound,
  ProjectDeleteAction,
  ProjectSharingCard,
  ProjectUpgradeAction,
  RendersTimeline,
  StyledA,
  TemplateList,
  UpdatableString
} from '@src/components';
import { LATEST_SUPPORTED_AE_VERSION } from '@src/constants';
import { useDownloadProjectZip, useEditProject, useGetProjectDetails } from '@src/hooks';
import localizationHelper from '@src/i18n';
import { ErrorCode, PlainlyPackage, Project } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';
import { downloadBlob, toMb } from '@src/utils';

import { ProjectReanalyzeAction } from './ProjectReanalyzeAction';

const LoadingProjectDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

const AnalysisFailedAlert = ({ message, error }: { message: string; error?: Record<string, unknown> }) => {
  const { t } = useTranslation();
  return (
    <Alert
      type="error"
      show={true}
      alertContent={
        <div className="text-sm font-medium">
          <p>{message}</p>
          {error && (
            <p className="mt-2 w-full overflow-auto whitespace-pre rounded-md border border-red-100 p-2 font-mono text-xs">
              {JSON.stringify(error, null, 2)}
            </p>
          )}
          <div className="mt-2 flex justify-end">
            <StyledA
              href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#analysis-issues`}
              target="_blank"
            >
              {t('general.action.troubleshootFailure')}
            </StyledA>
            <StyledA href="https://www.plainlyvideos.com/contact" target="_blank" className="ml-2">
              {t('general.action.contactSupport')}
            </StyledA>
          </div>
        </div>
      }
    />
  );
};

type ProjectInformationProps = PropsWithModel<Project>;

const ProjectInformation = ({ model: project }: ProjectInformationProps) => {
  const { t } = useTranslation();
  const { isLoading, mutateAsync: updateProject } = useEditProject();
  const { refetch: refetchProject } = useGetProjectDetails(project.id);

  const analysisFinished = project.analysis?.done || project.analysis?.failed;

  const updateProjectName = useCallback(
    async (value: string) => {
      const { data: freshProject, error } = await refetchProject();

      if (freshProject && !error) {
        const newProject = { ...freshProject, name: value };
        await updateProject({ project: newProject, projectId: freshProject.id });
      }
    },
    [refetchProject, updateProject]
  );

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="project-information-title" className="text-lg font-medium leading-6 text-gray-900">
          {t('components.project.ProjectDetails.projectInformation')}
        </h2>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="flex items-center gap-1 text-sm font-medium text-gray-500">{t('general.common.name')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <UpdatableString value={project.name} loading={isLoading} onUpdate={updateProjectName} />
              <CopyIdAction className="mt-1" id={project.id} type="project" />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.createdOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(project.createdDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.size')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {t('components.project.common.fileSize', {
                size: localizationHelper
                  .forNumber({ style: 'decimal', maximumFractionDigits: 1 })
                  .format(toMb(project.size))
              })}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.lastModifiedOn')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {localizationHelper.forDate().formatDateTimeStringLocally(project.lastModified)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.common.createdBy')}</dt>
            <dd className="mt-1 flex flex-col text-sm text-gray-900">
              <CreatedByInformation userId={project.createdBy} />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.status')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <div className="flex">
                {!analysisFinished && <SignalIcon className="mr-1.5 h-5 w-5 shrink-0 animate-spin text-gray-400" />}
                <span>
                  {t('components.project.common.analysis', {
                    context: project.analysis?.done ? 'done' : project.analysis?.failed ? 'failed' : 'pending'
                  })}
                </span>
              </div>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.project.common.aeVersion')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {(project.aeVersion && t('components.project.common.aeVersion', { context: project.aeVersion })) || '-'}
            </dd>
          </div>
          {project.revisionHistory && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">{t('components.common.revisions')}</dt>
              <dd className="mt-1 text-sm text-gray-900">{project.revisionHistory.length}</dd>
            </div>
          )}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('components.project.common.tags')}</dt>
            <dd className="mt-1 text-sm text-gray-900">{project.attributes?.tags?.join(', ') || '-'}</dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">{t('general.common.description')}</dt>
            <dd className="mt-1 text-sm text-gray-900">{project.description || '-'}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export type ProjectDetailsProps = {
  projectId: string;
};

export const ProjectDetails = ({ projectId }: ProjectDetailsProps) => {
  const [showAutoGenerateModal, setShowAutoGenerateModal] = useState(false);
  const { isLoading, data: project } = useGetProjectDetails(projectId);
  const { isLoading: isDownloading, refetch: downloadProject } = useDownloadProjectZip(projectId);

  const { t } = useTranslation();

  const allowEdit = project && (project.analysis?.done || project.analysis?.failed);

  const analysisFinished = project?.analysis?.done || project?.analysis?.failed;
  const jobError = project?.analysis.error && getJobError(project.analysis.error);
  const upgradeJobError = project?.analysis.upgradeError && getJobError(project.analysis.upgradeError);

  const upgradeDisabled =
    !analysisFinished || !project.analysis.done || project.aeVersion === LATEST_SUPPORTED_AE_VERSION;

  return (
    <>
      {isLoading && <LoadingProjectDetails title={t('components.project.ProjectDetails.loading')} />}
      {!isLoading && (
        <>
          {!project && <NotFound title={t('components.project.ProjectDetails.empty')} />}
          {project && (
            <div className="space-y-6">
              <HeaderWithBreadcrumbs
                breadcrumbs={
                  <Breadcrumb>
                    <BreadcrumbItem to={routes.PROJECTS_LIST} label={t('general.common.projects')} />
                    <BreadcrumbItem to={window.location.pathname} label={project.name} />
                  </Breadcrumb>
                }
                header={project.name}
                actions={
                  <>
                    <FeatureGating
                      enabledPackages={[PlainlyPackage.STARTER, PlainlyPackage.TEAM, PlainlyPackage.PRO]}
                      freeTrialAllowed={false}
                      message={t(`general.errorCode.${ErrorCode.FEATURE_GATING_NOT_ALLOWED_PROJECT_DOWNLOAD}`)}
                    >
                      <Button
                        disabled={isDownloading}
                        loading={isDownloading}
                        secondary
                        className="mr-2"
                        onClick={async () => {
                          const result = await downloadProject();
                          if (result.data) {
                            downloadBlob(result.data, `${projectId}.zip`);
                          }
                        }}
                      >
                        {t('general.action.downloadProject')}
                      </Button>
                    </FeatureGating>

                    <Link to={generatePath(routes.PROJECT_EDIT, { id: project.id })}>
                      <Button disabled={!allowEdit}>{t('general.action.edit')}</Button>
                    </Link>
                  </>
                }
              />
              {!jobError && project.analysis?.failed && (
                <AnalysisFailedAlert
                  message={t('components.project.ProjectDetails.analysisFailed')}
                  error={project.analysis.error}
                />
              )}
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                <div className="space-y-6 xl:col-span-2 xl:col-start-1">
                  {jobError && (
                    <section aria-labelledby="analysis-error">
                      <ErrorCard error={jobError} analysis />
                    </section>
                  )}
                  {upgradeJobError && (
                    <section aria-labelledby="upgrade-error">
                      <ErrorCard error={upgradeJobError} analysis upgradeError />
                    </section>
                  )}
                  <section aria-labelledby="project-information-title">
                    <ProjectInformation model={project} />
                  </section>
                  <section aria-labelledby="project-template-title">
                    <TemplateList
                      model={project}
                      loading={isLoading}
                      onAutoGenerateClick={() => setShowAutoGenerateModal(true)}
                    />
                  </section>
                  <section aria-labelledby="project-active-integrations">
                    <AppmixerActiveIntegrationsCard project={project} />
                  </section>
                  <section aria-labelledby="project-sharing-card">
                    <ProjectSharingCard model={project} loading={isLoading} />
                  </section>
                </div>
                <section aria-labelledby="project-render-title" className="xl:col-span-1 xl:col-start-3">
                  <RendersTimeline projectId={projectId} maxItems={6} />
                </section>
              </div>
              <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3">
                <div className="space-y-6 xl:col-span-2 xl:col-start-1">
                  <ProjectReanalyzeAction projectId={projectId} disabled={!analysisFinished} />
                  <ProjectUpgradeAction projectId={projectId} disabled={upgradeDisabled} />
                  <ProjectDeleteAction projectId={projectId} projectName={project.name} disabled={!analysisFinished} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <AutoGenerateTemplatesModal
        visible={showAutoGenerateModal}
        close={() => setShowAutoGenerateModal(false)}
        projectId={projectId}
      />
    </>
  );
};
