import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from '@src/components/common';
import { useCloneArticleVideoRevision, useUpdateVideoRevision } from '@src/hooks';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import { AnyRevisionUpdateDto, RevisionState } from '@src/models';

export const RevisionActionForm = ({
  canFireUpdate,
  canFireDiscard,
  state,
  data,
  removeRevisionFromState,
  children
}: {
  canFireUpdate?: boolean;
  canFireDiscard?: boolean;
  state: RevisionState;
  data: AnyRevisionUpdateDto;
  removeRevisionFromState: () => void;
} & React.ComponentProps<'form'>) => {
  const { t } = useTranslation();
  const { brandId, articleId, videoId } = useParams() as { brandId: string; articleId: string; videoId: string };
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const revisionId = videoNavigationState.revisionId;

  const { isLoading: isLoadingClone, mutateAsync: cloneRevision } = useCloneArticleVideoRevision();
  const { isLoading: isLoadingUpdate, mutateAsync: updateVideoRevision } = useUpdateVideoRevision();

  const fireSaveDraft = async () => {
    await updateVideoRevision({ brandId, articleId, videoId, revisionId, data });
    removeRevisionFromState();
  };

  const fireDiscardChanges = () => removeRevisionFromState();

  const fireCloneRevision = async () => {
    const response = await cloneRevision({ brandId, articleId, videoId, revisionId });

    const revisions = response.data.revisions;
    if (revisions) {
      if (response.data.videoType === 'SOCIAL_MEDIA') {
        updateVideoNavigationState({
          revisionId: revisions[revisions.length - 1].id,
          activeView: 'sm-settings',
          assetsTab: 'search'
        });
      } else {
        updateVideoNavigationState({
          revisionId: revisions[revisions.length - 1].id,
          activeView: 'scenes',
          sceneId: 1,
          headlinesTab: 1,
          assetsTab: 'suggested'
        });
      }
    }
  };

  return (
    <form
      className="space-y-6"
      onSubmit={e => {
        e.preventDefault();
        fireSaveDraft();
      }}
    >
      {children}
      <div className="flex justify-end">
        {(state === RevisionState.DRAFT || state === RevisionState.ERROR) && (
          <>
            <Button type="button" secondary disabled={!canFireDiscard} onClick={fireDiscardChanges}>
              {t('components.article.ArticleVideo.settings.discardChanges')}
            </Button>
            <Button
              className="ml-3"
              type="submit"
              loading={isLoadingUpdate}
              disabled={!canFireUpdate || isLoadingUpdate}
            >
              {t('components.article.ArticleVideo.settings.saveDraft')}
            </Button>
          </>
        )}
        {state !== RevisionState.DRAFT && state !== RevisionState.ERROR && (
          <Button
            secondary
            type="button"
            disabled={isLoadingClone || state === RevisionState.PROCESSING}
            loading={isLoadingClone}
            onClick={e => {
              e.preventDefault();
              fireCloneRevision();
            }}
          >
            {t('components.article.ArticleVideo.settings.editRevision')}
          </Button>
        )}
      </div>
    </form>
  );
};
