import { PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';
import { Button, RenderAdvancedOptionsForm } from '@src/components';
import { useValidateUrls } from '@src/hooks';
import { RenderOptionsDto } from '@src/models';

export type FormProps = {
  advancedOptions?: RenderOptionsDto;
  onAdvancedOptionsChange: (advancedOptions: RenderOptionsDto) => void;
  onBatchRender?: () => void;
};

export const Form = ({
  children,
  advancedOptions,
  onAdvancedOptionsChange,
  onBatchRender
}: PropsWithChildren<FormProps>) => {
  const { t } = useTranslation();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);

  const { urlsValid, handleInvalidUrls } = useValidateUrls();

  return (
    <>
      <div className="shadow sm:rounded-md">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {!showAdvancedOptions && <span>{t('components.designs.DesignAppForm.title')}</span>}
                {showAdvancedOptions && (
                  <div className="inline-flex items-center">
                    <AdjustmentsVerticalIcon className="h-5 w-5" />
                    <span className="ml-2">{t('components.designs.DesignAppForm.advancedOptions')}</span>
                  </div>
                )}
              </h3>
            </div>
            <div className="align-center ml-4 mt-2 flex flex-shrink-0">
              {onBatchRender && (
                <Button
                  secondary
                  className={classNames('mr-2', showAdvancedOptions && 'invisible')}
                  onClick={onBatchRender}
                >
                  {t('components.common.batchRender')}
                </Button>
              )}
              <Button
                className={classNames(showAdvancedOptions && 'invisible')}
                secondary={true}
                icon={<AdjustmentsVerticalIcon />}
                onClick={() => {
                  setShowAdvancedOptions(!showAdvancedOptions);
                }}
              >
                {t('components.designs.DesignAppForm.advancedOptions')}
              </Button>
            </div>
          </div>
        </div>
        {showAdvancedOptions && (
          <RenderAdvancedOptionsForm
            onBack={() => setShowAdvancedOptions(false)}
            onSave={(newRenderOptions: RenderOptionsDto) => {
              setShowAdvancedOptions(false);
              onAdvancedOptionsChange(newRenderOptions);
            }}
            onValidation={handleInvalidUrls}
            canSave={urlsValid}
            advancedOptions={advancedOptions}
          />
        )}
        <div className={classNames(showAdvancedOptions && 'hidden')}>{children}</div>
      </div>
    </>
  );
};
