import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import localizationHelper from '@src/i18n';
import { Article } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

export type ArticlesTimelineItemProps = PropsWithModel<Article> & {
  brandId: string;
  isLastItem: boolean;
};

export const ArticlesTimelineItem = ({ brandId, model: article, isLastItem }: ArticlesTimelineItemProps) => {
  const { t } = useTranslation();

  return (
    <li>
      <div className="relative pb-8">
        {!isLastItem && <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
        <div className="relative flex space-x-3">
          {article.input.image ? (
            <img
              src={article.input.image}
              className="flex h-10 w-10 items-center justify-center rounded-md bg-gray-400 object-cover ring-8 ring-white"
              alt={article.input.title}
            />
          ) : (
            <div className="flex h-10 w-12 items-center justify-center rounded-md border border-gray-200 bg-gray-50 ring-8 ring-white"></div>
          )}
          <Link
            className="-mt-2 flex w-full flex-1 cursor-pointer justify-between gap-2 rounded-md p-1 hover:bg-gray-50"
            to={generatePath(routes.ARTICLE_DETAILS, { brandId, articleId: article.id })}
          >
            <div className="w-2/3">
              {article.input.title && <p className="line-clamp-2 text-sm text-gray-900">{article.input.title}</p>}
              <p className="text-sm text-gray-400">
                {t('components.brand.ArticlesTimeline.videoCount', { count: article.videoCount })}
              </p>
            </div>
            <div className="w-fit text-right text-xs">
              <time className="w-24">
                {localizationHelper.forDate().formatDistanceToNowWithDateString(article.createdDate, true)}
              </time>
            </div>
          </Link>
        </div>
      </div>
    </li>
  );
};
