import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Header, HelpNotification, VideoGeniusBrandsPageHelpLinks } from '@src/components';
import { BRANDS_CREATE } from '@src/routes';

export const BrandsHeader = () => {
  const { t } = useTranslation();

  return (
    <Header
      header={t('general.common.brands')}
      actions={
        <div className="flex flex-row-reverse md:flex-row">
          <HelpNotification type="button" links={VideoGeniusBrandsPageHelpLinks} className="ml-2 md:ml-0" />
          <Link to={BRANDS_CREATE}>
            <Button className="md:ml-2">{t('components.brand.BrandsHeader.create')}</Button>
          </Link>
        </div>
      }
    />
  );
};
