import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { DesignsCategoryTabs, DesignsHeader, DesignsMainSection, Loading, ScrollingPage } from '@src/components';
import { useGetDesigns, useQueryParams } from '@src/hooks';
import { DesignCategory, DesignsFilter, DesignsFilterType } from '@src/models';

const categories: DesignCategory[] = Object.values(DesignCategory);

export const DesignsPage = () => {
  const { t } = useTranslation();
  const { searchQuery } = useQueryParams();
  const { isLoading, data: designs, error } = useGetDesigns();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const category = searchQuery.get('category');
  const selectedCategory = categories.find(c => c === category);

  const filteredDesigns =
    designs && designs.filter(d => selectedCategory === undefined || d.category === selectedCategory);

  const colorOptions = filteredDesigns
    ?.flatMap(d => d.palettes)
    .flatMap(p => p.group)
    .filter((v, i, a) => a.indexOf(v) === i);

  const aspectRatios = filteredDesigns
    ?.flatMap(d => d.variants)
    .flatMap(v => v.aspectRatio)
    .filter((v, i, a) => a.indexOf(v) === i);

  const filters: DesignsFilter[] = [
    {
      type: DesignsFilterType.COLOR_PALETTE,
      options: colorOptions || []
    },
    {
      type: DesignsFilterType.ASPECT_RATIO,
      options: aspectRatios || []
    }
  ];

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.DesignsPage')}</title>
        </Helmet>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {error && (
              <div>
                <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                  <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                  {t('components.designs.DesignsPage.errorLoading')}
                </p>
              </div>
            )}
            {!error && !designs && (
              <div>
                <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                  {t('components.designs.DesignsPage.noDesigns')}
                </p>
              </div>
            )}
            {designs && (
              <>
                <DesignsHeader />
                <DesignsCategoryTabs
                  selectedCategory={selectedCategory}
                  categories={categories}
                  setMobileFiltersOpen={setMobileFiltersOpen}
                />
                <DesignsMainSection
                  designs={filteredDesigns || []}
                  filters={filters}
                  mobileFiltersOpen={mobileFiltersOpen}
                  setMobileFiltersOpen={setMobileFiltersOpen}
                />
              </>
            )}
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
