import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { TimeActionExtendScript } from '@src/models';

type ExtendScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: TimeActionExtendScript | undefined) => void;
  editScript?: TimeActionExtendScript;
};

export const ExtendScriptingForm = ({ setFormValid, setFormScript, editScript }: ExtendScriptingFormProps) => {
  const { t } = useTranslation();
  const [maxDuration, setMaxDuration] = useState<number | undefined>(editScript?.maxDuration || undefined);

  useEffect(() => {
    setFormValid(true);
    setFormScript({
      scriptType: ScriptType.EXTEND,
      maxDuration: maxDuration
    });
  }, [maxDuration, setFormScript, setFormValid]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset>
        <div>
          <label htmlFor="maxDuration" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.ExtendScriptingForm.maxDuration')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.ExtendScriptingForm.maxDurationDescription')}
            </p>
          </label>
          <input
            id="maxDuration"
            name="maxDuration"
            type="number"
            inputMode="decimal"
            step="any"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={e => {
              const value = parseFloat(e.target.value);
              value > 0 ? setMaxDuration(value) : setMaxDuration(undefined);
            }}
            defaultValue={maxDuration}
          />
        </div>
      </fieldset>
    </div>
  );
};
