import { useTranslation } from 'react-i18next';

import { StyledA } from '@src/components';

export const SentryErrorFallbackPage = () => {
  const { t } = useTranslation();

  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {t('components.SentryErrorFallbackPage.title')}
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">{t('components.SentryErrorFallbackPage.subtitle')}</p>
      <div className="mt-10">
        <StyledA href="/">
          <span aria-hidden="true">&larr;</span> {t('components.SentryErrorFallbackPage.backToHome')}
        </StyledA>
      </div>
    </main>
  );
};
