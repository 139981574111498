import { Children, cloneElement, isValidElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ChevronLeftIcon as BackIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export type BreadcrumbItemProps = {
  to: string;
  label: string;
  isFirstItem?: boolean;
  truncate?: boolean;
  rightSidebar?: boolean;
};

export const BreadcrumbItem = ({ to, label, isFirstItem, truncate, rightSidebar }: BreadcrumbItemProps) => {
  return (
    <li>
      <div className="flex items-center">
        {!isFirstItem && <ChevronRightIcon className="h-5 w-5 shrink-0 text-gray-400" />}
        <Link
          to={to}
          className={classNames(
            'text-sm font-medium text-gray-500 hover:text-gray-700',
            !isFirstItem && 'ml-4',
            truncate && 'w-72 truncate md:w-52 lg:w-[240px] xl:w-[450px]',
            rightSidebar && 'lg:w-[160px] xl:w-[400px]'
          )}
        >
          {label}
        </Link>
      </div>
    </li>
  );
};

export const Breadcrumb = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <nav className="sm:hidden" aria-label="Back">
        {navigate.length > 0 && (
          <button
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            onClick={() => navigate(-1)}
          >
            <BackIcon className="-ml-1 mr-1 h-5 w-5 shrink-0 text-gray-400" />
            {t('general.action.back')}
          </button>
        )}
      </nav>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {Children.map(children, (child, index) => {
            if (isValidElement(child)) {
              if (index === 0) return cloneElement(child as React.ReactElement, { isFirstItem: true });
              return child;
            }
          })}
        </ol>
      </nav>
    </>
  );
};
