import { useState } from 'react';

export const useValidateUrls = () => {
  const [invalidUrls, setInvalidUrls] = useState(new Map<string, string>());

  const handleInvalidUrls = (key: string) => (url: string, valid: boolean) => {
    if (valid) {
      if (invalidUrls.has(key)) {
        const newInvalidUrls = new Map(invalidUrls);
        newInvalidUrls.delete(key);
        setInvalidUrls(newInvalidUrls);
      }
    } else {
      if (!invalidUrls.has(key)) {
        setInvalidUrls(prev => new Map(prev.set(key, url)));
      }
    }
  };

  const urlsValid: boolean = invalidUrls.size === 0;
  return { urlsValid, handleInvalidUrls };
};
