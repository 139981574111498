import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';

export const PresenterSettings = ({
  setShowVideoDialog
}: {
  setShowVideoDialog: (showVideoDialog: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const isAvatar = videoNavigationState.activeView === 'avatar';
  const isGeneral = videoNavigationState.activeView === 'general';

  return (
    <>
      <li
        onClick={() => {
          updateVideoNavigationState({
            revisionId: videoNavigationState.revisionId,
            activeView: 'general'
          });
          setShowVideoDialog(false);
        }}
        className="group flex cursor-pointer items-center"
      >
        <div className="relative flex h-4 w-4 flex-shrink-0 items-center justify-center">
          {isGeneral ? (
            <>
              <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
              <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
            </>
          ) : (
            <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
          )}
        </div>
        <div
          className={classNames(
            'flex w-full items-center justify-between text-sm font-medium',
            isGeneral ? 'text-gray-900' : 'text-gray-500 group-hover:text-gray-900'
          )}
        >
          <p className="ml-3">{t('components.publishers.common.generalSettings')}</p>
        </div>
      </li>
      <li
        onClick={() => {
          updateVideoNavigationState({
            revisionId: videoNavigationState.revisionId,
            activeView: 'avatar'
          });
          setShowVideoDialog(false);
        }}
        className="group flex cursor-pointer items-center"
      >
        <div className="relative flex h-4 w-4 flex-shrink-0 items-center justify-center">
          {isAvatar ? (
            <>
              <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
              <span className="relative block h-2 w-2 rounded-full bg-indigo-600" />
            </>
          ) : (
            <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
          )}
        </div>
        <div
          className={classNames(
            'flex w-full items-center justify-between text-sm font-medium',
            isAvatar ? 'text-gray-900' : 'text-gray-500 group-hover:text-gray-900'
          )}
        >
          <p className="ml-3">{t('components.article.ArticleVideo.common.avatarSettings')}</p>
        </div>
      </li>
    </>
  );
};
