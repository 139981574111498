import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlainlyCombobox } from '@src/components';
import { useQueryParams } from '@src/hooks';
import { RenderState } from '@src/models';

const states = Object.values(RenderState);

export type RenderStateComboboxProps = {
  selectedState?: RenderState;
  notSelectedLabel?: string;
};

export const RenderStateCombobox = ({ notSelectedLabel, selectedState }: RenderStateComboboxProps) => {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();
  const { t } = useTranslation();

  return (
    <PlainlyCombobox
      notSelectedLabel={notSelectedLabel}
      data={states.map(state => ({
        name: state,
        item: state,
        label: t('components.render.common.renderState', { context: state?.toLocaleLowerCase() }),
        selected: state === selectedState
      }))}
      onSelectionChange={state => {
        navigate({
          search: `?${updateQueryParams(window.location, { state: state })}`
        });
      }}
    />
  );
};
