import classNames from 'classnames';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';

type ModalProps = {
  visible: boolean;
  close?: () => void;
  className?: string;
  size?: 'small' | 'normal' | 'large';
  removeOuterPadding?: boolean;
};

export const Modal = ({
  visible,
  close,
  className,
  children,
  size = 'small',
  removeOuterPadding
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <Dialog open={visible} className="relative z-10" onClose={() => close?.()}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[closed]:data-[leave]:duration-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={classNames(
              'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[closed]:data-[leave]:duration-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out sm:my-8 sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
              removeOuterPadding ? 'p-0' : 'px-4 pb-4 pt-5 sm:p-6',
              size === 'small' && 'sm:max-w-sm',
              size === 'normal' && 'sm:max-w-xl',
              size === 'large' && 'sm:max-w-5xl',
              className
            )}
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
