import { useMutation, useQueryClient } from 'react-query';

import { projectCacheReplace, useAxiosModify } from '@src/hooks';
import { AnyAutoCreateTemplateDto, CreateTemplateDto, Project, RenderOptionsDto, UpdateTemplateDto } from '@src/models';

export const useCreateProjectTemplate = () => {
  const { post } = useAxiosModify<CreateTemplateDto, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, template }: { projectId: string; template: CreateTemplateDto }) => {
      const response = await post(`/projects/${projectId}/templates`, template);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useEditProjectTemplate = () => {
  const { put } = useAxiosModify<UpdateTemplateDto, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({
      projectId,
      templateId,
      template
    }: {
      projectId: string;
      templateId: string;
      template: UpdateTemplateDto;
    }) => {
      const response = await put(`/projects/${projectId}/templates/${templateId}`, template);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useSetDefaultProjectTemplate = () => {
  const { post } = useAxiosModify<void, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, templateId }: { projectId: string; templateId: string }) => {
      const response = await post(`/projects/${projectId}/templates/${templateId}/default`);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useSetProjectTemplateOptions = () => {
  const { post } = useAxiosModify<RenderOptionsDto, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({
      projectId,
      templateId,
      options
    }: {
      projectId: string;
      templateId: string;
      options: RenderOptionsDto;
    }) => {
      const response = await post(`/projects/${projectId}/templates/${templateId}/options`, options);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useAutoGenerateProjectTemplates = () => {
  const { post } = useAxiosModify<AnyAutoCreateTemplateDto, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({
      projectId,
      autoCreateTemplateDto
    }: {
      projectId: string;
      autoCreateTemplateDto: AnyAutoCreateTemplateDto;
    }) => {
      const response = await post(`/projects/${projectId}/templates/auto-generate`, autoCreateTemplateDto);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useCloneTemplate = () => {
  const { post } = useAxiosModify<void, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, templateId }: { projectId: string; templateId: string }) => {
      const response = await post(`/projects/${projectId}/templates/${templateId}/clone`);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteProjectTemplate = () => {
  const { delete: deleteTemplate } = useAxiosModify<void, Project>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ projectId, templateId }: { projectId: string; templateId: string }) => {
      const response = await deleteTemplate(`/projects/${projectId}/templates/${templateId}`);

      return response.data;
    },
    onSuccess: edited => {
      projectCacheReplace(queryClient, edited);
    }
  });

  return { mutateAsync, isLoading, error };
};
