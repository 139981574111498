import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScriptType, TimeActionScriptType } from '@plainly/types';
import {
  CompositionIdentifier,
  TimeActionShiftInScript,
  TimeActionShiftOutScript,
  TimeActionTrimInScript,
  TimeActionTrimOutScript
} from '@src/models';

import { LayerCombobox } from './LayerCombobox';

type TimeActionScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (
    script:
      | TimeActionShiftInScript
      | TimeActionShiftOutScript
      | TimeActionTrimInScript
      | TimeActionTrimOutScript
      | undefined
  ) => void;
  compositions: CompositionIdentifier[] | undefined;
  scriptType:
    | TimeActionScriptType.SHIFT_IN
    | TimeActionScriptType.SHIFT_OUT
    | TimeActionScriptType.TRIM_IN
    | TimeActionScriptType.TRIM_OUT;
  editScript?: TimeActionShiftInScript | TimeActionShiftOutScript | TimeActionTrimInScript | TimeActionTrimOutScript;
};

export function TimeActionScriptingForm({
  setFormValid,
  setFormScript,
  compositions,
  scriptType,
  editScript
}: TimeActionScriptingFormProps) {
  const { t } = useTranslation();

  const [target, setTarget] = useState<string>('');
  const [toPoint, setToPoint] = useState<'in-point' | 'out-point'>('in-point');
  const [overlap, setOverlap] = useState<number>(0);
  const valid = target !== undefined && toPoint !== undefined;
  const shiftScript = scriptType === ScriptType.SHIFT_IN || scriptType === ScriptType.SHIFT_OUT;

  useEffect(() => {
    if (editScript?.scriptType === ScriptType.SHIFT_IN || editScript?.scriptType === ScriptType.SHIFT_OUT) {
      setTarget(editScript.shiftTarget);
      setToPoint(editScript.shiftsTo);
      setOverlap(editScript.shiftOverlap);
    } else if (editScript?.scriptType === ScriptType.TRIM_IN || editScript?.scriptType === ScriptType.TRIM_OUT) {
      setTarget(editScript.trimTarget);
      setToPoint(editScript.trimsTo);
      setOverlap(editScript.trimOverlap);
    }
  }, [editScript]);

  useEffect(() => {
    setFormValid(valid);

    valid &&
      shiftScript &&
      setFormScript({
        scriptType: scriptType,
        shiftTarget: target,
        shiftsTo: toPoint,
        shiftOverlap: overlap
      });

    valid &&
      !shiftScript &&
      setFormScript({
        scriptType: scriptType,
        trimTarget: target,
        trimsTo: toPoint,
        trimOverlap: overlap
      });

    !valid && setFormScript(undefined);
  }, [valid, target, overlap, toPoint, setFormValid, shiftScript, setFormScript, scriptType]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset>
        <LayerCombobox
          label={t('components.project.template.layer.scripts.TimeActionScriptingForm.target', { context: scriptType })}
          description={t('components.project.template.layer.scripts.TimeActionScriptingForm.targetDescription', {
            context: scriptType
          })}
          input={target}
          onChange={setTarget}
          compositions={compositions}
        />
        <div className="mb-5">
          <label htmlFor="shiftsTo" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.TimeActionScriptingForm.toPoint', { context: scriptType })}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.TimeActionScriptingForm.toPointDescription', {
                context: scriptType
              })}
            </p>
          </label>
          <select
            id="shiftsTo"
            name="shiftsTo"
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={e => {
              if (e.target.value === 'in-point') {
                setToPoint('in-point');
              } else if (e.target.value === 'out-point') {
                setToPoint('out-point');
              }
            }}
            value={toPoint}
          >
            <option value="in-point">{t('components.project.common.inPointLabel')}</option>
            <option value="out-point">{t('components.project.common.outPointLabel')}</option>
          </select>
        </div>
        <div>
          <label htmlFor="shiftOverlap" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.TimeActionScriptingForm.overlap', { context: scriptType })}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.common.overlapDescription')}
            </p>
          </label>
          <input
            id="shiftOverlap"
            name="shiftOverlap"
            type="number"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={e => setOverlap(parseInt(e.target.value))}
            value={overlap}
          />
        </div>
      </fieldset>
    </div>
  );
}
