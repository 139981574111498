import { useEffect, useState } from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Loading,
  OrganizationSettingsHeader,
  OrganizationSettingsNavigation,
  ScrollingPage
} from '@src/components';
import { SubscriptionOverviewCard, SubscriptionPlanUpgrade, SubscriptionResourcesCard } from '@src/components/user';
import { PaddleOverrideSlideover } from '@src/components/user/PaddleOverrideSlideover';
import { PaymentsListCard } from '@src/components/user/PaymentsListCard';
import { SubscriptionExistingPlanUpgrade } from '@src/components/user/SubscriptionExistingPlanUpgrade';
import { useGetSubscription, useQueryParams } from '@src/hooks';
import { OrganizationRole, Subscription, SubscriptionIdentifier, SubscriptionStatus } from '@src/models';
import { State, useGlobalState } from '@src/state/store';
import { includesSafe } from '@src/utils';

type PlansUpgrade = {
  visible: boolean;
  subscription?: Subscription;
};

type CancelOrUpdateSubscription = {
  visible: boolean;
  url?: string;
};

const setupPaddle = () => {
  if (import.meta.env.VITE_APP_PADDLE_SANDBOX === 'true') {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Environment.set('sandbox');
  }
  if (import.meta.env.VITE_APP_PADDLE_VENDOR_ID) {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Setup({ vendor: parseInt(import.meta.env.VITE_APP_PADDLE_VENDOR_ID) });
  }
};

export const OrganizationSubscriptionPage = () => {
  const { t } = useTranslation();
  const { searchQuery } = useQueryParams();
  const openPlans = !!searchQuery.get('openPlans');

  const [globalSubscription, setGlobalSubscription] = useGlobalState(State.SUBSCRIPTION);
  const { isLoading, data: updateData, refetch: getSubscription } = useGetSubscription();

  const [plansUpgrade, setPlansUpgrade] = useState<PlansUpgrade>({ visible: false });
  const togglePlansUpgradeVisible = () => {
    const visible = !plansUpgrade.visible;
    setPlansUpgrade({ ...plansUpgrade, visible });
    if (!visible) {
      getSubscription();
    }
  };

  const [existingPlansUpgrade, setExistingPlansUpgrade] = useState<PlansUpgrade>({ visible: false });
  const toggleExistingPlansUpgradeVisible = () => {
    const visible = !existingPlansUpgrade.visible;
    setExistingPlansUpgrade({ ...existingPlansUpgrade, visible });
    if (!visible) {
      getSubscription();
    }
  };

  // ensure we refresh the subscription
  // if it's not existing globally
  useEffect(() => {
    if (!globalSubscription) {
      getSubscription();
    }
  }, [getSubscription, globalSubscription]);

  const [cancelSubscription, setCancelSubscription] = useState<CancelOrUpdateSubscription>({ visible: false });
  const toggleCancelSubscriptionVisible = () => {
    setCancelSubscription({ ...cancelSubscription, visible: !cancelSubscription.visible });
    getSubscription();
  };

  const [updateSubscription, setUpdateSubscription] = useState<CancelOrUpdateSubscription>({ visible: false });
  const toggleUpdateSubscriptionVisible = () => {
    setUpdateSubscription({ ...updateSubscription, visible: !updateSubscription.visible });
    getSubscription();
  };

  // is postpaid, works only for single org per user
  const [organization] = useGlobalState(State.ORGANIZATION);
  const isOrganizationOwner = organization?.role === OrganizationRole.OWNER;

  const internallyManaged = globalSubscription && globalSubscription.internallyManaged;
  const internallyManagedActive = internallyManaged && globalSubscription.status === SubscriptionStatus.ACTIVE;

  const paidSubscription =
    globalSubscription &&
    !internallyManaged &&
    !includesSafe(
      [SubscriptionIdentifier.FREE, SubscriptionIdentifier.FREE_TRIAL],
      globalSubscription.subscriptionIdentifier
    );

  // script loader
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const PaddleScriptLoader = makeAsyncScriptLoader('https://cdn.paddle.com/paddle/paddle.js')(Loading);

  useEffect(() => {
    if (openPlans && !internallyManagedActive) {
      switch (globalSubscription?.subscriptionIdentifier) {
        case SubscriptionIdentifier.FREE:
        case SubscriptionIdentifier.FREE_TRIAL:
          setPlansUpgrade({ visible: true });
          break;
        case SubscriptionIdentifier.STARTER:
        case SubscriptionIdentifier.STARTER_YEARLY:
        case SubscriptionIdentifier.TEAM:
        case SubscriptionIdentifier.TEAM_YEARLY:
          setExistingPlansUpgrade({ visible: true });
          break;
      }
    }
  }, [globalSubscription?.subscriptionIdentifier, openPlans, internallyManagedActive]);

  useEffect(() => {
    if (updateData) {
      setGlobalSubscription(updateData);
    }
  }, [updateData, setGlobalSubscription]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('general.pageTitle.UserSubscriptionPage')}</title>
      </Helmet>
      {!scriptLoaded && (
        <>
          <PaddleScriptLoader
            asyncScriptOnLoad={() => {
              setupPaddle();
              setScriptLoaded(true);
            }}
          />
        </>
      )}
      {scriptLoaded && isOrganizationOwner && (
        <>
          <SubscriptionPlanUpgrade visible={plansUpgrade.visible} onClose={togglePlansUpgradeVisible} />
          {globalSubscription && (
            <SubscriptionExistingPlanUpgrade
              visible={existingPlansUpgrade.visible}
              onClose={toggleExistingPlansUpgradeVisible}
              subscription={globalSubscription}
            />
          )}
          <PaddleOverrideSlideover
            visible={cancelSubscription.visible}
            url={cancelSubscription.url}
            onClose={toggleCancelSubscriptionVisible}
            title={t('components.user.common.cancelSubscription')}
            subtitle={t('components.user.PaddleOverrideSlideover.CancelSubscription.subtitle')}
          />
          <PaddleOverrideSlideover
            visible={updateSubscription.visible}
            url={updateSubscription.url}
            onClose={toggleUpdateSubscriptionVisible}
            title={t('components.user.PaddleOverrideSlideover.UpdateSubscription.title')}
            subtitle={t('components.user.PaddleOverrideSlideover.UpdateSubscription.subtitle')}
          />
        </>
      )}
      <ScrollingPage className="space-y-6">
        <OrganizationSettingsHeader />
        <OrganizationSettingsNavigation tab="subscription" />
        {internallyManagedActive && (
          <Alert type="success" show={true} alertContent={t('components.paddle.customAlert')} />
        )}
        {globalSubscription && (
          <>
            <SubscriptionOverviewCard
              subscription={globalSubscription}
              showPlansUpgrade={subscription => setPlansUpgrade({ subscription, visible: true })}
              showExistingPlanUpgrade={subscription => setExistingPlansUpgrade({ subscription, visible: true })}
              showCancelSubscription={url => {
                setCancelSubscription({ url, visible: true });
              }}
              showUpdateSubscription={url => {
                setUpdateSubscription({ url, visible: true });
              }}
              loading={isLoading}
              readOnly={!isOrganizationOwner}
            />
            <SubscriptionResourcesCard resources={globalSubscription.resources} />
            {paidSubscription && isOrganizationOwner && <PaymentsListCard />}
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
