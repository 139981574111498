import { AssetsGrid } from '@src/components';
import { StockAssetSearchOut } from '@src/models';

export const SuggestedAssets = ({
  assets,
  selectedAsset,
  setSelectedAsset
}: {
  assets?: StockAssetSearchOut[];
  selectedAsset?: StockAssetSearchOut;
  setSelectedAsset: (selectedAsset?: StockAssetSearchOut, close?: boolean) => void;
}) => {
  return <AssetsGrid assets={assets} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} />;
};
