import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FilmIcon, PhotoIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { AssetDisplay, Button, Loading } from '@src/components';
import { StockAssetSearchOut } from '@src/models';

export const AssetsGrid = ({
  assets,
  isLoading,
  isRefetching,
  isLoadMoreDisabled,
  selectedAsset,
  setSelectedAsset,
  loadMore
}: {
  assets?: StockAssetSearchOut[];
  isLoading?: boolean;
  isRefetching?: boolean;
  isLoadMoreDisabled?: boolean;
  selectedAsset?: StockAssetSearchOut;
  setSelectedAsset: (selectedAsset?: StockAssetSearchOut, close?: boolean) => void;
  loadMore?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ul
      onClick={() => setSelectedAsset(undefined)}
      className={classNames(
        'max-h-vh-300 min-h-[370px] w-full scroll-py-3 gap-6 overflow-y-auto p-6',
        !assets ? 'flex items-center justify-center' : 'grid grid-cols-1 sm:grid-cols-3'
      )}
      id="options"
      role="listbox"
    >
      {!assets?.length && !isLoading && (
        <li>
          <div className="flex flex-col items-center justify-center">
            <PhotoIcon className="h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">
              {t('components.article.ArticleVideo.sceneSettings.sceneForm.modal.noAssetsSearch')}
            </p>
          </div>
        </li>
      )}
      {isLoading && <Loading />}
      {!isLoading &&
        assets?.map((asset, index) => (
          <li key={index} className="relative">
            <AssetDisplay
              out={asset}
              className={classNames('h-48 w-full', selectedAsset === asset && 'ring-2 ring-indigo-600 ring-offset-2')}
              actions={
                <>
                  <Button
                    disabled
                    icon={asset.assetType === 'IMAGE' ? <PhotoIcon /> : <FilmIcon />}
                    secondary
                    small
                    className="pointer-events-none absolute left-3 top-3 text-xs"
                  >
                    {t('components.article.ArticleVideo.sceneSettings.sceneForm.provider', {
                      context: asset.provider
                    })}
                  </Button>
                  <Button
                    small
                    className="absolute right-3 top-3 text-xs focus:ring-0 focus:ring-offset-0"
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedAsset(asset, true);
                    }}
                  >
                    {t('general.action.select')}
                  </Button>
                </>
              }
              onClick={e => {
                e.stopPropagation();
                setSelectedAsset(asset);
              }}
            />
          </li>
        ))}
      {assets && loadMore && (
        <button
          disabled={isLoadMoreDisabled}
          onClick={loadMore}
          type="button"
          className="relative flex h-48 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 p-12 text-center text-gray-300 hover:border-gray-400 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {isRefetching && <Loading small />}
          {!isRefetching && (
            <>
              <PlusCircleIcon className="mx-auto h-8 w-8 " />
              <span className="mt-2 block text-sm font-semibold text-gray-900">{t('general.common.loadMore')}</span>
            </>
          )}
        </button>
      )}
    </ul>
  );
};
