import { useQuery, useQueryClient } from 'react-query';

import { ErrorOptions, PlainlyApiError } from '@src/models';

import { useAxiosRead } from '../useAxios';

type AppmixerCredentials = {
  baseUrl: string;
  token: string;
};

export const useAppmixerCredentials = (errorOptions?: ErrorOptions) => {
  const { get } = useAxiosRead<AppmixerCredentials>({}, errorOptions);
  const queryClient = useQueryClient();

  const cacheKey = ['integrations', 'appmixer'];
  const invalidate = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<AppmixerCredentials, PlainlyApiError>(
    cacheKey,
    async () => {
      const response = await get('/integrations/appmixer/credentials');
      return response.data;
    },
    {
      refetchInterval: 1000 * 60 * 24 // every day refresh the token
    }
  );

  return { data, isLoading, error, invalidate };
};
