import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useGetSubscriptionInvoices } from '@src/hooks';
import localizationHelper from '@src/i18n';

import { Loading } from '../common';

export const PaymentsListCard = () => {
  const { t } = useTranslation();

  const { isLoading, data: payments } = useGetSubscriptionInvoices();
  const priceFormatter = localizationHelper.forNumber({
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.user.PaymentsListCard.title')}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('components.user.PaymentsListCard.subtitle')}</p>
          </div>
        </div>
      </div>
      <div className="border-b border-t border-gray-200">
        {isLoading && (
          <div className="px-4 py-5 sm:px-6">
            <Loading />
          </div>
        )}
        {!isLoading && payments && (
          <ul className="divide-y divide-gray-200">
            {payments.map(payment => (
              <li key={payment.id} className="block">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0 self-start">
                      <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="truncate text-sm font-medium text-indigo-600">
                          {t('components.user.PaymentsListCard.priceDetails', {
                            price: priceFormatter.format(payment.amount),
                            currency: payment.currency
                          })}
                        </p>
                      </div>
                      <div className="">
                        <p className="truncate text-sm text-gray-900">
                          {t('components.user.PaymentsListCard.dueDate', {
                            date: localizationHelper.forDate().formatDateStringLocally(payment.payoutDate)
                          })}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {payment.paid ? (
                            <CheckCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          )}
                          {t('components.user.PaymentsListCard.isPaid', {
                            context: payment.paid
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm md:text-right">
                    <a
                      href={payment.receiptUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={classNames(
                        'inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50',
                        !payment.receiptUrl && 'invisible'
                      )}
                    >
                      {t('components.user.PaymentsListCard.receipt')}
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
