import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import * as routes from '@src/routes';

export const VideoSidebarResources = ({
  originalArticleUrl,
  renderId
}: {
  originalArticleUrl: string;
  renderId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-1 lg:px-2">
      <div className="mx-auto max-w-7xl px-5 lg:px-3">
        <h3 className="inline-flex items-center gap-x-1 text-xs font-semibold uppercase tracking-wider text-gray-600">
          {t('components.common.resources')}
        </h3>
      </div>
      <ul className="mx-2 space-y-1 lg:mx-0">
        <li>
          <a href={originalArticleUrl} target="_blank" rel="noreferrer" className="cursor-pointer">
            <p className="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 hover:bg-gray-200/30 hover:text-gray-700">
              {t('components.article.ArticleVideo.sidebar.originalArticle')}
            </p>
          </a>
        </li>
        {renderId && (
          <li>
            <Link to={generatePath(routes.RENDER_DETAILS, { id: renderId })}>
              <p className="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 hover:bg-gray-200/30 hover:text-gray-700">
                {t('components.common.renderDetails')}
              </p>
            </Link>
          </li>
        )}
        <li>
          <a
            href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#revisions`}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
          >
            <p className="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 hover:bg-gray-200/30 hover:text-gray-700">
              {t('components.layout.sidebar.Sidebar.userManual')}
            </p>
          </a>
        </li>
      </ul>
    </div>
  );
};
