import { FormEvent, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Button, Loading } from '@src/components';
import { useEditUserDetails, useGetUserProfile } from '@src/hooks';
import * as routes from '@src/routes';

const UserOnboardingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [organizationName, setOrganizationName] = useState<string>();
  const {
    data: userProfile,
    isLoading: userLoading,
    error: userError
  } = useGetUserProfile({ silentNotifications: true });
  const { isLoading, mutateAsync: editUserDetails } = useEditUserDetails();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (name || organizationName) {
      editUserDetails({ name, organizationName }).then(() => navigate(routes.DASHBOARD));
    } else {
      navigate(routes.DASHBOARD);
    }
  };

  useEffect(() => {
    // if user error is there always navigate to dashboard
    if (!userLoading && userError) {
      navigate(routes.DASHBOARD);
    }
  }, [userLoading, userError, navigate]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('general.pageTitle.UserOnboardingPage')}</title>
      </Helmet>
      <div className="flex h-screen">
        <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="mx-auto h-10 w-auto sm:h-12" src="/assets/favicon/favicon-96x96.png" alt="Plainly Logo" />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                {t('general.common.welcomeMessage')}
              </h2>
            </div>
            {userLoading && <Loading />}
            {!userLoading && userProfile && (
              <div className="mt-6">
                <div>
                  <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-white px-2 text-gray-500">{t('components.UserOnboardingPage.subtitle')}</span>
                    </div>
                  </div>
                </div>
                <form className="mt-6 space-y-6" onSubmit={e => onSubmit(e)}>
                  <div className="space-y-1">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t('components.user.common.fullName')}
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="name"
                        autoComplete="name"
                        defaultValue={userProfile.user.name}
                        className="block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={e => setName(e.target.value)}
                        autoFocus
                        disabled={userLoading}
                      />
                    </div>
                  </div>
                  <div className="mt-6">
                    <div>
                      <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('components.common.organizationName')}
                      </label>
                      <div className="mt-2">
                        <input
                          id="organization"
                          name="organization"
                          type="organization"
                          autoComplete="organization"
                          defaultValue={`${userProfile.user.name} Organization`}
                          className="block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={e => setOrganizationName(e.target.value)}
                          disabled={userLoading}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <Button
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading || userLoading}
                        className="w-full justify-center"
                      >
                        {t('general.action.continue')}
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Button secondary onClick={() => navigate(routes.DASHBOARD)} className="w-full justify-center">
                        {t('general.action.skip')}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export const ProtectedUserOnboarding = withAuthenticationRequired(UserOnboardingPage);
