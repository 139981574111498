import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

export type StyledLinkProps = LinkProps & {
  icon?: ReactNode;
};

export const StyledLink = ({ children, icon, className, ...rest }: StyledLinkProps) => (
  <Link
    {...rest}
    className={classNames(
      'font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500',
      className
    )}
  >
    {icon &&
      isValidElement(icon) &&
      cloneElement(icon as ReactElement, {
        className: classNames('h-5 w-5')
      })}
    {children}
  </Link>
);
