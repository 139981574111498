import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { DailyUsageTable, Loading, ScrollingPage, UsageDetailsHeader, UsageDetailsStats } from '@src/components';
import { useGetDetailedResourceUsage, useQueryParams } from '@src/hooks';

export const UsageDetailsPage = () => {
  const { searchQuery } = useQueryParams();
  const resourceType = searchQuery.get('resourceType')?.toLocaleUpperCase();
  const startDate = searchQuery.get('bp');
  const { t } = useTranslation();

  const { isLoading, data, error } = useGetDetailedResourceUsage({
    resourceType: resourceType,
    date: startDate
  });

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6" contentHeight={true}>
        <Helmet>
          <title>{t('general.pageTitle.UsageDetailsPage')}</title>
        </Helmet>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {error && (
              <div>
                <p className="mt-1 flex items-center text-xs text-red-900 md:mt-2">
                  <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-red-400" />
                  {t('components.project.template.layer.previewer.common.errorLoading', { context: 'usage-details' })}
                </p>
              </div>
            )}
            {!error && data && (
              <>
                <UsageDetailsHeader startDate={data.startDate} endDate={data.endDate} resourceType={resourceType} />
                <UsageDetailsStats
                  grandTotal={data.grandTotal}
                  periodTotal={data.periodTotal}
                  resourceType={resourceType}
                />
                <DailyUsageTable dailyUsages={data.dailyUsages} loading={isLoading} resourceType={resourceType} />
              </>
            )}
          </>
        )}
      </ScrollingPage>
    </HelmetProvider>
  );
};
