import { createGlobalState } from 'react-hooks-global-state';

import { Subscription, User, UserOrganization } from '@src/models';
import { Notification } from '@src/types';

export enum State {
  USER = 'user',
  IS_LIGHT_THEME = 'is-light-theme',
  NOTIFICATIONS = 'notifications',
  SUBSCRIPTION = 'subscription',
  ORGANIZATION = 'organization'
}

/*
Global states are set:
 1. USER - api/useOrganizationApi.ts - useGetUserProfile
 2. ORGANIZATION - api/useOrganizationApi.ts - useGetUserProfile
 3. SUBSCRIPTION - api/useOrganizationApi.ts - useGetSubscription
 4. NOTIFICATIONS - api/useNotifications.ts - newNotification
*/

const initialState = {
  [State.USER]: undefined as User | undefined,
  [State.IS_LIGHT_THEME]: true,
  [State.NOTIFICATIONS]: [] as Notification[],
  [State.SUBSCRIPTION]: undefined as Subscription | undefined,
  [State.ORGANIZATION]: undefined as UserOrganization | undefined
};

const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(initialState);

function resetGlobalState() {
  setGlobalState(State.USER, undefined);
  setGlobalState(State.IS_LIGHT_THEME, true);
  setGlobalState(State.NOTIFICATIONS, []);
  setGlobalState(State.SUBSCRIPTION, undefined);
  setGlobalState(State.ORGANIZATION, undefined);
}

export { getGlobalState, resetGlobalState, useGlobalState };
