import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { CompositionIdentifier, TimeActionStretchScript } from '@src/models';

import { LayerCombobox } from './LayerCombobox';

type StretchScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: TimeActionStretchScript | undefined) => void;
  compositions: CompositionIdentifier[] | undefined;
  editScript?: TimeActionStretchScript;
};

export function StretchScriptingForm({
  setFormValid,
  setFormScript,
  compositions,
  editScript
}: StretchScriptingFormProps) {
  const { t } = useTranslation();

  const [stretchTarget, setStretchTarget] = useState<string | undefined>();
  const [stretchesTo, setStretchesTo] = useState<'in-point' | 'out-point' | 'end-of-comp'>('in-point');
  const [stretchOverlap, setStretchOverlap] = useState<number>(0);
  const validEndOfComp = stretchesTo === 'end-of-comp';
  const valid = stretchTarget !== undefined && stretchesTo !== undefined;

  useEffect(() => {
    if (editScript && editScript.stretchesTo !== 'end-of-comp') {
      setStretchTarget(editScript.stretchTarget);
      setStretchesTo(editScript.stretchesTo);
      setStretchOverlap(editScript.stretchOverlap);
    } else if (editScript) {
      setStretchesTo(editScript.stretchesTo);
    }
  }, [editScript]);

  useEffect(() => {
    setFormValid(valid || validEndOfComp);

    if (valid) {
      setFormScript({
        scriptType: ScriptType.STRETCH,
        stretchTarget: stretchTarget,
        stretchesTo: stretchesTo,
        stretchOverlap: stretchOverlap
      });
    } else if (validEndOfComp) {
      setFormScript({
        scriptType: ScriptType.STRETCH,
        stretchesTo: stretchesTo
      });
    }

    !(valid || validEndOfComp) && setFormScript(undefined);
  }, [valid, validEndOfComp, stretchTarget, stretchOverlap, stretchesTo, setFormValid, setFormScript]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset>
        <LayerCombobox
          label={t('components.project.template.layer.scripts.StretchScriptingForm.target')}
          description={t('components.project.template.layer.scripts.StretchScriptingForm.targetDescription')}
          input={stretchTarget}
          onChange={setStretchTarget}
          compositions={compositions}
          disabled={stretchesTo === 'end-of-comp'}
        />
        <div className="mb-5">
          <label htmlFor="shiftsTo" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.StretchScriptingForm.toPoint')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.StretchScriptingForm.toPointDescription')}
            </p>
          </label>
          <select
            id="shiftsTo"
            name="shiftsTo"
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            onChange={e => {
              if (e.target.value === 'in-point') {
                setStretchesTo('in-point');
              } else if (e.target.value === 'out-point') {
                setStretchesTo('out-point');
              } else if (e.target.value === 'end-of-comp') {
                setStretchesTo('end-of-comp');
              }
            }}
            value={stretchesTo}
          >
            <option value="in-point">{t('components.project.common.inPointLabel')}</option>
            <option value="out-point">{t('components.project.common.outPointLabel')}</option>
            <option value="end-of-comp">
              {t('components.project.template.layer.scripts.StretchScriptingForm.endOfCompLabel')}
            </option>
          </select>
        </div>
        <div>
          <label htmlFor="shiftOverlap" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.StretchScriptingForm.overlap')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.common.overlapDescription')}
            </p>
          </label>
          <input
            id="shiftOverlap"
            name="shiftOverlap"
            type="number"
            className={classNames(
              'mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
              stretchesTo === 'end-of-comp' && 'cursor-not-allowed opacity-50'
            )}
            onChange={e => setStretchOverlap(parseInt(e.target.value))}
            value={stretchOverlap}
            disabled={stretchesTo === 'end-of-comp'}
          />
        </div>
      </fieldset>
    </div>
  );
}
