import * as Sentry from '@sentry/react';
import { PlainlyApiError } from '@src/models';

export const initializeSentry = () => {
  if (import.meta.env.VITE_APP_SENTRY_DNS) {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_DNS,
      release: import.meta.env.VITE_APP_VERSION,
      maxBreadcrumbs: 100,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration()
      ],
      tracesSampleRate: 0.1,
      normalizeDepth: 10
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportExceptionToSentry = (e: any) => {
  if (e instanceof PlainlyApiError) {
    if (e.shouldReport()) {
      const { errorMessage, errors } = e;
      const errorsJson = errors && JSON.stringify(errors);

      Sentry.captureException(e, {
        extra: { errorMessage, errorsJson }
      });
    }
  } else {
    Sentry.captureException(e);
  }
};
