import classNames from 'classnames';
import { decode } from 'js-base64';
import { useTranslation } from 'react-i18next';

import { useQueryParams } from '@src/hooks';

export const PaddleProductCheckout = ({ productId }: { productId: string }) => {
  const { t } = useTranslation();
  const { searchQuery } = useQueryParams();
  const dataEncoded = searchQuery.get('data');
  const data: {
    amount?: string;
    email?: string;
  } = (dataEncoded && JSON.parse(decode(dataEncoded))) || undefined;

  // fires on the pay button click
  const doPay = () => {
    // @ts-expect-error Cannot find name 'Paddle'
    Paddle.Checkout.open({
      product: productId,
      email: data?.email,
      allowQuantity: false
    });
  };

  return (
    <div className="bg-white">
      {/* Background color split screen for large screens */}
      <div className="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />
      <div className="fixed right-0 top-0 hidden h-full w-1/2 bg-indigo-900 lg:block" aria-hidden="true" />

      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
        <h1 className="sr-only">{t('components.paddle.heading')}</h1>

        {data && (
          <section
            aria-labelledby="summary-heading"
            className={classNames(
              'bg-indigo-900 py-12 text-indigo-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0'
            )}
          >
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
              <h2 id="summary-heading" className="sr-only">
                {t('components.paddle.orderSummaryHeading')}
              </h2>

              <dl className={classNames(!data.amount && 'hidden')}>
                <dt className="text-sm font-medium">{t('components.paddle.amountDue')}</dt>
                <dd className="mt-1 text-3xl font-extrabold text-white">{data.amount}</dd>
              </dl>

              <dl className={classNames('pt-6', !data.email && 'hidden')}>
                <dt className="text-sm font-medium">{t('components.paddle.customer')}</dt>
                <dd className="mt-1 text-3xl font-extrabold text-white">{data.email}</dd>
              </dl>
            </div>
          </section>
        )}

        <section
          aria-labelledby="payment-and-shipping-heading"
          className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0"
        >
          <h2 id="payment-and-shipping-heading" className="sr-only">
            {t('components.paddle.paymentDetailsHeading')}
          </h2>

          <form>
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
              <div>
                <h3 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                  {t('components.paddle.postpaidPaymentHeading')}
                </h3>
                <p className="mt-4 text-sm">{t('components.paddle.description')}</p>
              </div>

              <div className="mt-10 flex justify-end border-t border-gray-200 pt-6">
                <button
                  onClick={doPay}
                  type="button"
                  className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  {t('components.paddle.payNow')}
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};
