import { Fragment } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { Alert } from '@src/components/common';
import localizationHelper from '@src/i18n';
import { Article } from '@src/models';
import * as routes from '@src/routes';

export const ArticleItem = ({ article }: { article: Article }) => {
  const { t } = useTranslation();

  return (
    <>
      <Link
        to={generatePath(routes.ARTICLE_DETAILS, { brandId: article.brandId, articleId: article.id })}
        className="flex flex-col justify-end overflow-hidden rounded-2xl border border-gray-300"
      >
        <div className="relative h-40 border-b border-gray-200">
          {article.input.image && article.input.title ? (
            <>
              <img
                src={article.input.image}
                alt={article.input.title}
                className="absolute -z-10 h-full w-full object-cover"
              />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <h3 className="md:text-md absolute bottom-5 left-6 right-6 line-clamp-3 text-base font-medium leading-6 text-white sm:line-clamp-2">
                {article.input.title}
              </h3>
            </>
          ) : (
            <>
              <div className="absolute -z-10 h-full w-full bg-missing-article-data-image bg-repeat object-cover opacity-5" />
              <h3 className="md:text-md absolute bottom-5 left-6 w-3/4 truncate text-base font-medium leading-6 text-gray-500">
                {t('components.article.ArticlesList.item.missingTitle')}
              </h3>
            </>
          )}
        </div>
        <div className="divide-y divide-gray-200 px-6 text-xs leading-6 xl:text-sm">
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('general.common.lastModifiedOn')}</p>
            <p className="text-gray-700">{localizationHelper.forDate().formatDateStringLocally(article.createdDate)}</p>
          </div>
          <div className="flex justify-between py-3">
            <p className="text-gray-500">{t('components.article.ArticlesList.item.numberOfVideos')}</p>
            <p className="text-gray-700">{article.videoCount}</p>
          </div>
        </div>
        {(!article.input.title || !article.input.image) && (
          <Alert
            className="rounded-none"
            type="warn"
            show
            alertContent={t('components.article.ArticlesList.item.titleOrImageMissing')}
          />
        )}
      </Link>
      <Menu as="div" className="absolute right-5 top-5">
        <MenuButton className="group float-right cursor-pointer">
          <EllipsisHorizontalIcon className="h-5 w-5 rounded-lg bg-gray-100 text-gray-400 group-hover:bg-gray-200 group-hover:text-gray-500" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="mt-2 w-fit rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
          >
            <MenuItem>
              {({ focus }) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={article.input.articleUrl}
                  className={classNames(focus ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}
                >
                  {t('components.article.common.viewOriginalArticle')}
                </a>
              )}
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
};
