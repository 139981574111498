import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { AssetFormSection, HeadlinesFormSection, RequiredMarker } from '@src/components';
import { useBreakpoint } from '@src/hooks';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import { ArticleVideoScene } from '@src/models';

export const SceneForm = ({
  currentScene,
  setCurrentScene,
  disabled
}: {
  currentScene: ArticleVideoScene;
  setCurrentScene: (inputs: ArticleVideoScene) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState } = useVideoNavigationParams();
  const isMediumScreen = useBreakpoint('md');

  const activeView = videoNavigationState.activeView;
  const headlinesIndex = activeView === 'scenes' && videoNavigationState.headlinesTab;
  const currentHeadlinesIndex = headlinesIndex ? headlinesIndex - 1 : 0;

  const currentHeadlines = useMemo(() => currentScene.headlines, [currentScene]);
  const currentAsset = useMemo(() => currentScene.asset, [currentScene]);

  const assetDurationWarning =
    currentAsset.type === 'stock' &&
    currentAsset.out.duration &&
    currentScene.duration &&
    currentAsset.out.duration < currentScene.duration;

  return (
    <>
      <div className="space-y-6">
        <div>
          <label htmlFor="script" className="block text-sm font-medium text-gray-700">
            {t('components.article.ArticleVideo.sceneSettings.sceneForm.scriptLabel')}
            <RequiredMarker />
          </label>
          <p className="text-sm text-gray-500">
            {t('components.article.ArticleVideo.sceneSettings.sceneForm.scriptLabelDescription')}
          </p>
          <textarea
            disabled={disabled}
            id="script"
            name="script"
            rows={!isMediumScreen ? 5 : 3}
            className="mt-1 block w-full rounded-md border border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
            onChange={e => setCurrentScene({ ...currentScene, script: e.target.value })}
            value={currentScene.script}
            required
          />
          {!disabled && (
            <div className="mt-1 flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <p className="ml-1 max-w-full text-sm text-gray-500">
                {t('components.article.ArticleVideo.sceneSettings.sceneForm.scriptLabelPauses')}
              </p>
            </div>
          )}
        </div>
        <AssetFormSection
          currentAsset={currentAsset}
          setCurrentAsset={newAsset => setCurrentScene({ ...currentScene, asset: newAsset })}
          disabled={disabled}
          assetDurationWarning={assetDurationWarning}
          suggestedAssets={currentScene.suggestedAssets.outs}
        />
      </div>
      <HeadlinesFormSection
        currentHeadlines={currentHeadlines}
        setCurrentHeadlines={newHeadlines => setCurrentScene({ ...currentScene, headlines: newHeadlines })}
        disabled={disabled}
        currentHeadlinesIndex={currentHeadlinesIndex}
      />
    </>
  );
};
