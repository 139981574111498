import classnames from 'classnames';

type ScrollingPageProps = React.ComponentProps<'div'> & {
  contentHeight?: boolean;
};

/**
 * Page that has a fixed height to the screen size on medium devices.
 */
export const ScrollingPage = ({ children, contentHeight, className, ...rest }: ScrollingPageProps) => (
  <div
    {...rest}
    className={classnames('mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 ', !contentHeight && 'min-h-full', className)}
  >
    {children}
  </div>
);
