import { useTranslation } from 'react-i18next';

import { RequiredMarker } from '@src/components/common';

export const HeadlineFormInputs = ({
  disabled,
  currentHeadline,
  setCurrentHeadlines
}: {
  disabled?: boolean;
  currentHeadline: { heading: string; subheading?: string };
  setCurrentHeadlines: (currentHeadline: { heading: string; subheading?: string }) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full">
        <label htmlFor="heading" className="mt-3 block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.sceneSettings.sceneForm.headline')}
          <RequiredMarker />
        </label>
        <input
          disabled={disabled}
          type="text"
          name="heading"
          id="heading"
          className="mt-1 block w-full rounded-md border border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
          onChange={e => setCurrentHeadlines({ ...currentHeadline, heading: e.target.value })}
          value={currentHeadline.heading}
          required
        />
      </div>
      <div className="w-full">
        <label htmlFor="subheading" className="mt-3 block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.sceneSettings.sceneForm.subheading')}
        </label>
        <input
          disabled={disabled}
          type="text"
          name="subheading"
          id="subheading"
          className="mt-1 block w-full rounded-md border border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
          onChange={e => setCurrentHeadlines({ ...currentHeadline, subheading: e.target.value })}
          value={currentHeadline.subheading ? currentHeadline.subheading : ''}
        />
      </div>
    </>
  );
};
