import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
  TransitionChild
} from '@headlessui/react';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ColorPaletteGroup, DesignAspectRatio, DesignsFilter, DesignsFilterType } from '@src/models';

export type DesignsAsideMenuProps = {
  filters: DesignsFilter[];
  selectedColors: ColorPaletteGroup[];
  addSelectedColor: (color: ColorPaletteGroup) => void;
  removeSelectedColor: (color: ColorPaletteGroup) => void;
  selectedAspectRatios: DesignAspectRatio[];
  addSelectedAspectRatio: (aspectRatio: DesignAspectRatio) => void;
  removeSelectedAspectRatio: (aspectRatio: DesignAspectRatio) => void;
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (open: boolean) => void;
};

export const DesignsAsideMenu = ({
  filters,
  selectedColors,
  addSelectedColor,
  removeSelectedColor,
  selectedAspectRatios,
  addSelectedAspectRatio,
  removeSelectedAspectRatio,
  mobileFiltersOpen,
  setMobileFiltersOpen
}: DesignsAsideMenuProps) => {
  const { t } = useTranslation();

  const handleFilterChange = (
    filterType: DesignsFilterType,
    option: ColorPaletteGroup | DesignAspectRatio,
    checked: boolean
  ) => {
    switch (filterType) {
      case DesignsFilterType.COLOR_PALETTE:
        checked ? addSelectedColor(option as ColorPaletteGroup) : removeSelectedColor(option as ColorPaletteGroup);
        break;

      case DesignsFilterType.ASPECT_RATIO:
        checked
          ? addSelectedAspectRatio(option as DesignAspectRatio)
          : removeSelectedAspectRatio(option as DesignAspectRatio);
        break;
    }
  };

  return (
    <>
      <div>
        {/* Mobile filter dialog */}
        <Transition show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div className="fixed inset-0 z-40 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">{t('components.designs.common.filters')}</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">{t('general.action.closeMenu')}</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    {filters.map((section, index) => (
                      <Disclosure as="div" key={index} className="border-t border-gray-200 px-4 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <DisclosureButton className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {t('components.designs.common.filterLabel', {
                                    context: section.type
                                  })}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                  )}
                                </span>
                              </DisclosureButton>
                            </h3>
                            <DisclosurePanel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={optionIdx} className="flex items-center">
                                    <input
                                      id={`filter-mobile-${section.type}-${optionIdx}`}
                                      name={`${section.type}[]`}
                                      type="checkbox"
                                      checked={
                                        selectedColors.includes(option as ColorPaletteGroup) ||
                                        selectedAspectRatios.includes(option as DesignAspectRatio)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={e => handleFilterChange(section.type, option, e.target.checked)}
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.type}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {section.type === DesignsFilterType.COLOR_PALETTE
                                        ? t('components.designs.common.colorOption', {
                                            context: option
                                          })
                                        : t('components.designs.common.aspectRatioOption', {
                                            context: option
                                          })}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </DisclosurePanel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>

        <section aria-labelledby="products-heading">
          <div>
            {/* Filters */}
            <form className="hidden lg:block">
              {filters.map((section, index) => (
                <Disclosure as="div" key={index} className="border-b border-gray-200 py-6">
                  {({ open }) => (
                    <>
                      <h3 className="-my-3 flow-root">
                        <DisclosureButton className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-900">
                            {t('components.designs.common.filterLabel', { context: section.type })}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <PlusIcon className="h-5 w-5" aria-hidden="true" />
                            )}
                          </span>
                        </DisclosureButton>
                      </h3>
                      <DisclosurePanel className="pt-6">
                        <div className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div key={optionIdx} className="flex items-center">
                              <input
                                id={`filter-${section.type}-${optionIdx}`}
                                name={`${section.type}[]`}
                                type="checkbox"
                                checked={
                                  selectedColors.includes(option as ColorPaletteGroup) ||
                                  selectedAspectRatios.includes(option as DesignAspectRatio)
                                }
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={e => handleFilterChange(section.type, option, e.target.checked)}
                              />
                              <label
                                htmlFor={`filter-${section.type}-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {section.type === DesignsFilterType.COLOR_PALETTE
                                  ? t('components.designs.common.colorOption', { context: option })
                                  : t('components.designs.common.aspectRatioOption', {
                                      context: option
                                    })}
                              </label>
                            </div>
                          ))}
                        </div>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              ))}
            </form>
          </div>
        </section>
      </div>
    </>
  );
};
