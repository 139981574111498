import { useTranslation } from 'react-i18next';

import { Button } from '@src/components';
import { ErrorAlert } from '@src/components/common/alert';

export const ErrorPage = (props: { error: Error; logout?: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen flex-col place-content-center items-center">
      <ErrorAlert closable={false} error={props.error} />
      {props.logout && (
        <Button secondary onClick={props.logout} className="mt-2">
          {t('general.action.logout')}
        </Button>
      )}
    </div>
  );
};
