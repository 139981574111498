export enum VoiceoverProviderType {
  GCP = 'GCP'
}
type VoiceoverProviderSettings<T extends VoiceoverProviderType> = {
  type: T;
};

export type GcpVoiceoverProviderSettings = VoiceoverProviderSettings<VoiceoverProviderType.GCP> & {
  audioConfigPitch?: number;
  audioConfigSpeakingRate?: number;
  voiceName?: string;
};

export type AnyVoiceoverProviderSettings = GcpVoiceoverProviderSettings;
