import { useEffect, useMemo, useState } from 'react';
import { decode } from 'js-base64';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button, ButtonCopyCurl, DesignFormField, SwitchButton } from '@src/components';
import { useFireDesignRender, useNotifications, useQueryParams, useValidateUrls } from '@src/hooks';
import { useDesignCurl } from '@src/hooks/utils/useDesignCurl';
import {
  ColorPalette,
  Design,
  DesignParameterType,
  DesignRender,
  DesignVariant,
  RenderOptionsDto,
  RenderParameters
} from '@src/models';
import { RENDER_DETAILS } from '@src/routes';
import { addAdvancedOptions, getSampleData, isEmpty } from '@src/utils';

export type DesignParametersFormProps = {
  design: Design;
  selectedColor: ColorPalette | undefined;
  selectedVariant: DesignVariant | undefined;
  advancedOptions?: RenderOptionsDto;
  closeForm: () => void;
};

export const DesignParametersForm = ({
  design,
  selectedColor,
  selectedVariant,
  advancedOptions,
  closeForm
}: DesignParametersFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyInfo } = useNotifications();
  const { searchQuery } = useQueryParams();
  const { parameters } = design;
  const defaultVariant = design.variants.find(v => v.defaultVariant)?.id || '';

  const [showSampleData, setShowSampleData] = useState<string[]>([]);
  const [render, setRender] = useState<DesignRender>({
    designId: design.id,
    variantId: selectedVariant?.id || defaultVariant,
    colorPalette: selectedColor?.group,
    parameters: {}
  });

  const { isLoading, mutateAsync: postRender } = useFireDesignRender();

  useEffect(() => {
    setRender(prev => ({
      ...prev,
      variantId: selectedVariant?.id || prev.variantId,
      colorPalette: selectedColor?.group
    }));
  }, [selectedColor, selectedVariant]);

  useEffect(() => {
    const colorParameters =
      render.parameters &&
      Object.keys(render.parameters).flatMap(p => {
        const existsInDesignAsColor = design.parameters.find(
          dp => dp.type === DesignParameterType.COLOR && dp.key === p
        );
        return existsInDesignAsColor ? [p] : [];
      });

    // If color is selected, remove all other color parameters, so the length size is correct
    if (selectedColor && !isEmpty(colorParameters)) {
      const newParameters = { ...render.parameters };
      Object.keys(newParameters).forEach(key => {
        if (colorParameters.includes(key)) {
          delete newParameters[key];
        }
      });
      setRender(prev => ({ ...prev, parameters: newParameters }));
    }
  }, [design.parameters, render.parameters, selectedColor]);

  useEffect(() => {
    if (advancedOptions) {
      setRender(prev => addAdvancedOptions(prev, advancedOptions));
    }
  }, [advancedOptions]);

  const encodedRerenderParams = useMemo(() => searchQuery.get('rerenderParams'), [searchQuery]);

  useEffect(() => {
    let renderParameters: RenderParameters = {};
    if (encodedRerenderParams) {
      renderParameters = JSON.parse(decode(encodedRerenderParams));
    } else {
      renderParameters = parameters
        .filter(param => param.defaultValue)
        .reduce((obj, param) => {
          obj[param.key] = param.defaultValue;
          return obj;
        }, {} as RenderParameters);
    }
    setRender(prev => ({ ...prev, parameters: renderParameters }));
  }, [design, encodedRerenderParams, parameters]);

  const parametersWithoutColor = parameters.filter(p => p.type !== DesignParameterType.COLOR);
  const sortedParameters = parameters.sort(a => {
    if (a.type === DesignParameterType.COLOR) {
      return -1;
    }
    return 0;
  });

  const onFormFieldUpdate = (key: string, value: string | boolean) => {
    const newRender = {
      ...render,
      parameters: {
        ...render.parameters,
        [key]: value
      }
    };

    if (!value) {
      delete newRender.parameters[key];
    }

    delete showSampleData[showSampleData.indexOf(key)];
    setRender(newRender);
  };

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const canRender =
    (render && parameters.filter(p => !p.optional).every(p => (render.parameters || {})[p.key]) && urlsValid) || false;

  const fireRender = () => {
    if (canRender) {
      postRender(render).then(response => {
        if (!response) {
          return;
        }
        notifyInfo(t('components.render.RenderForm.renderSubmitted'));
        navigate(generatePath(RENDER_DETAILS, { id: response.id }));
      });
    }
  };

  const mandatoryParams = parameters
    .filter(p => !p.optional)
    .flatMap(p => p.key)
    .reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});

  const { curlWithApiKey, curlWithoutApiKey } = useDesignCurl({
    designId: render.designId || '',
    variantId: render.variantId || '',
    colorPalette: render.colorPalette || '',
    parameters: { ...mandatoryParams, ...render.parameters },
    outputFormat: advancedOptions?.outputFormat || render.outputFormat,
    webhook: advancedOptions?.webhook,
    options: advancedOptions?.options
  });

  const handleSampleDataChange = (show: boolean) => {
    if (!show) {
      const newRender = { ...render };
      showSampleData.forEach(key => {
        newRender?.parameters && delete newRender.parameters[key];
      });
      setRender(newRender);
      setShowSampleData([]);
    } else {
      const sampleData: Record<string, unknown> = {};
      const sampleDataKeys: string[] = [];
      sortedParameters.forEach(p => {
        const { key } = p;
        if (!render.parameters || !Object.keys(render.parameters).includes(key)) {
          sampleDataKeys.push(key);
          sampleData[key] = getSampleData(p);
        }
        const newRender = { ...render, parameters: { ...render.parameters, ...sampleData } };
        setShowSampleData(sampleDataKeys);
        setRender(newRender);
      });
    }
  };

  const switchButtonDisabled = useMemo(
    () =>
      showSampleData.length === 0 &&
      !!render.parameters &&
      Object.keys(render.parameters).length === (selectedColor ? parametersWithoutColor.length : parameters.length),
    [parameters.length, parametersWithoutColor.length, render.parameters, selectedColor, showSampleData.length]
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        fireRender();
      }}
    >
      <div className="space-y-6 bg-white px-4 py-6 sm:px-6">
        <SwitchButton
          checked={!isEmpty(showSampleData)}
          onChange={(show: boolean) => handleSampleDataChange(show)}
          label={t('components.common.addSampleData')}
          description={t('components.common.addSampleDataDescription')}
          disabled={isEmpty(parameters) || switchButtonDisabled}
        />
        {selectedColor?.group !== undefined
          ? parametersWithoutColor.map((p, index) => (
              <DesignFormField
                key={index}
                parameter={p}
                onChange={onFormFieldUpdate}
                value={render.parameters ? render.parameters[p.key] : undefined}
                onValidation={handleInvalidUrls}
              />
            ))
          : sortedParameters.map((p, index) => (
              <DesignFormField
                key={index}
                parameter={p}
                onChange={onFormFieldUpdate}
                value={render.parameters ? render.parameters[p.key] : undefined}
                onValidation={handleInvalidUrls}
              />
            ))}
      </div>
      <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
        <ButtonCopyCurl curlWithApiKey={curlWithApiKey} curlWithoutApiKey={curlWithoutApiKey} />
        <div>
          <Button secondary className="mr-3" onClick={closeForm}>
            {t('general.action.cancel')}
          </Button>
          <Button type="submit" loading={isLoading} disabled={isLoading || !canRender}>
            {t('general.action.render')}
          </Button>
        </div>
      </div>
    </form>
  );
};
