import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Alert, BrandsHeader, BrandsList, ScrollingPage } from '@src/components';

export const BrandsListPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="flex flex-col flex-nowrap space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.BrandsListPage')}</title>
        </Helmet>
        <BrandsHeader />
        <Alert type="info" show alertContent={t('components.publishers.common.info')} />
        <BrandsList />
      </ScrollingPage>
    </HelmetProvider>
  );
};
