import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AssetsModalNavigation,
  Button,
  FilestackUpload,
  ManualUploadAssets,
  Modal,
  SearchAssets,
  SuggestedAssets
} from '@src/components';
import { useNotifications } from '@src/hooks';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import { StockAsset, StockAssetSearchOut } from '@src/models';

type AssetsModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  assets?: StockAssetSearchOut[];
  setCurrentAsset: (currentAsset: StockAsset) => void;
};

const AssetsModalContent = ({
  setShowModal,
  assets,
  setCurrentAsset,
  uploadOpen,
  setUploadOpen
}: Omit<AssetsModalProps, 'showModal'> & {
  uploadOpen: boolean;
  setUploadOpen: (uploadOpen: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState } = useVideoNavigationParams();
  const { notifyError } = useNotifications();

  const activeView = videoNavigationState.activeView;
  const assetsTab = (activeView === 'scenes' || activeView === 'sm-settings') && videoNavigationState.assetsTab;

  const [selectedAsset, setSelectedAsset] = useState<StockAsset>();
  const [assetUrl, setAssetUrl] = useState<string>();

  const fireUpdate = (asset?: StockAsset) => {
    if (selectedAsset || asset) {
      if (asset) {
        setCurrentAsset(asset);
      } else if (selectedAsset) {
        setCurrentAsset(selectedAsset);
      }
      setShowModal(false);
      setSelectedAsset(undefined);
      setAssetUrl(undefined);
    }
  };

  const canFireUpdate =
    ((assetsTab === 'suggested' || assetsTab === 'search') && selectedAsset?.type === 'stock') ||
    (assetsTab === 'manual' && selectedAsset?.type === 'manual');

  const handleAssetSelection = (asset?: StockAssetSearchOut, close?: boolean) => {
    if (close && asset) {
      fireUpdate({ type: 'stock', out: asset });
    } else if (asset) {
      setSelectedAsset({ type: 'stock', out: asset });
    } else {
      setSelectedAsset(undefined);
    }
  };

  return (
    <>
      <AssetsModalNavigation hasSuggested={assets !== undefined} />
      {assetsTab === 'suggested' && assets && (
        <SuggestedAssets
          assets={assets}
          selectedAsset={selectedAsset?.type === 'stock' ? selectedAsset.out : undefined}
          setSelectedAsset={(asset, close) => handleAssetSelection(asset, close)}
        />
      )}
      {assetsTab === 'search' && (
        <SearchAssets
          selectedAsset={selectedAsset?.type === 'stock' ? selectedAsset.out : undefined}
          setSelectedAsset={(asset, close) => handleAssetSelection(asset, close)}
        />
      )}
      {assetsTab === 'manual' && (
        <ManualUploadAssets
          onFieldUpdate={value => setAssetUrl(value)}
          onUploadRequested={() => setUploadOpen(true)}
          value={assetUrl}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      )}
      <div className="flex justify-end gap-2 p-3">
        <Button secondary onClick={() => setShowModal(false)}>
          {t('general.action.dismiss')}
        </Button>
        <Button disabled={!canFireUpdate} onClick={() => fireUpdate()}>
          {t('general.action.update')}
        </Button>
      </div>
      <FilestackUpload
        accept={['video/*', 'image/*']}
        uploadOpen={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onFieldUpdate={value => setAssetUrl(value)}
        onFileUploadFailed={value => {
          setAssetUrl(value);
          setUploadOpen(false);
          notifyError(t('components.common.input.FilestackUpload.error'));
        }}
      />
    </>
  );
};

export const AssetsModal = ({ showModal, setShowModal, assets, setCurrentAsset }: AssetsModalProps) => {
  const [uploadOpen, setUploadOpen] = useState(false);

  return (
    <Modal visible={showModal && !uploadOpen} size="large" removeOuterPadding>
      <AssetsModalContent
        setShowModal={setShowModal}
        assets={assets}
        setCurrentAsset={setCurrentAsset}
        uploadOpen={uploadOpen}
        setUploadOpen={setUploadOpen}
      />
    </Modal>
  );
};
