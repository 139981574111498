import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ArticleCreateForm, Loading, LoadingProps, ScrollingPage } from '@src/components';
import { useGetBrandDetails } from '@src/hooks';

const LoadingBrandDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const ArticleCreatePage = () => {
  const { t } = useTranslation();

  const { brandId } = useParams() as { brandId: string };
  const { isLoading, data: brand } = useGetBrandDetails(brandId);

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.ArticleCreatePage')}</title>
        </Helmet>
        <>
          {isLoading && <LoadingBrandDetails title={t('components.brand.BrandDetails.loading')} />}
          {!isLoading && brand && <ArticleCreateForm brand={brand} />}
        </>
      </ScrollingPage>
    </HelmetProvider>
  );
};
