import { useCallback, useEffect, useState } from 'react';

import { useNotifications } from './useNotifications';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const { notifyInfo } = useNotifications();

  const [localStorageError, setLocalStorageError] = useState<Error>();
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      if (error instanceof Error) {
        setLocalStorageError(error);
      } else {
        console.log(error);
      }
      return initialValue;
    }
  });

  const setItem = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
      } catch (error) {
        if (error instanceof Error) {
          setLocalStorageError(error);
        } else {
          console.log(error);
        }
      }
    },
    [key]
  );

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        try {
          const newValue = e.newValue ? JSON.parse(e.newValue) : null;
          setStoredValue(newValue);
        } catch (error) {
          if (error instanceof Error) {
            setLocalStorageError(error);
          } else {
            console.log(error);
          }
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  useEffect(() => {
    if (localStorageError) {
      notifyInfo(localStorageError.message);
    }
  }, [localStorageError, notifyInfo]);

  return { storedValue, setItem };
};
