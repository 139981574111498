export type NotificationLinks = {
  apiReference?: string;
  documentation?: string;
  videoTutorial?: string;
};

export const DesignsHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/Designs',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#designs`,
  videoTutorial: 'https://www.youtube.com/watch?v=bVTD7MXn_4Y'
};

export const ProjectsHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/Projects',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#custom-projects`,
  videoTutorial: 'https://www.youtube.com/watch?v=hqihdA868Xw'
};

export const ParametrizationHelpLinks: NotificationLinks = {
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#parametrization`,
  videoTutorial: 'https://www.youtube.com/watch?v=6Wr3YefUU00'
};
export const ScriptingHelpLinks: NotificationLinks = {
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#scripting`,
  videoTutorial: 'https://www.youtube.com/watch?v=uQgIa4FBrGc'
};

export const RenderFormHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/Renders',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#rendering`,
  videoTutorial: 'https://www.youtube.com/watch?v=ySC2VXeTb-U'
};

export const RenderPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#issues-that-might-come-up`
};

export const OutputFormatPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#render-formats`
};

export const WebhookPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#webhook`
};

export const IntegrationsPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#integrations`
};

export const IntegrationsPassthroughPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#integration-passthrough`
};

export const ThumbnailsPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#thumbnails-export`
};

export const WatermarkPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#watermark`
};

export const ProjectFilesPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#project-files-download`
};

export const CaptionsPageHelpLinks: NotificationLinks = {
  apiReference: 'https://www.plainlyvideos.com/documentation/api-reference#tag/Renders/operation/render',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#captions`
};

export const VideoGeniusBrandsPageHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/VideoGenius-Brands',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#creating-a-brand`
};

export const VideoGeniusArticlesPageHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/VideoGenius-Articles',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#video-creation`
};

export const VideoGeniusArticleDetailsPageHelpLinks: NotificationLinks = {
  apiReference: 'https://plainlyvideos.com/documentation/api-reference#tag/VideoGenius-Videos',
  documentation: `${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#video-editing`
};
