import { useEffect, useState } from 'react';

import { Loading } from '../common';

import { useAppmixerContext } from './AppmixerContext';

import './styles/integrationsLogs.css';

export const AppmixerIntegrationsLogs = () => {
  const appmixerContext = useAppmixerContext();
  const [logsWidget, setLogsWidget] = useState<any>(); // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const showLoading = loading || !appmixerContext.ready;

  useEffect(() => {
    // define setup
    const setup = () => {
      // do nothing if not ready
      if (!appmixerContext.ready) {
        return;
      }

      setLoading(true);

      // read from content
      const appmixer = appmixerContext.appmixer;

      // eslint-disable-next-line
      function getCustomFlowsFilter(params: any) {
        return {
          ...params,
          filter: [
            ...(params.filter || []),
            // Exclude flows shared by other users.
            `userId:${appmixer.get('user').id}`
          ]
        };
      }

      const integrationsLogs = appmixer.ui.InsightsLogs({
        el: '#integrations-logs',
        api: {
          getFlows(params: unknown) {
            return appmixer.api.getFlows(getCustomFlowsFilter(params));
          },
          getFlowsCount(params: unknown) {
            return appmixer.api.getFlowsCount(getCustomFlowsFilter(params));
          }
        }
      });

      // Exclude logs of flows shared by other users.
      integrationsLogs.state('query', {
        userId: appmixer.get('user').id
      });

      // Open the widget to render UI.
      integrationsLogs.open();

      setLogsWidget(integrationsLogs);
      setLoading(false);
    };

    // call setup
    setup();
  }, [appmixerContext, setLoading]);

  //always reload when we become active
  useEffect(() => {
    if (logsWidget) {
      logsWidget.reload();
    }
  }, [logsWidget]);

  return (
    <div className="flex">
      {showLoading && <Loading small />}
      <div id="integrations-logs" />
    </div>
  );
};
