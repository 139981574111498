import { FC, ReactElement } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { DashboardWithUser } from './DashboardWithUser';

const isSignup = window.location.href.includes('signup=true');

export const ProtectedDashboard: FC<{ children: ReactElement }> = withAuthenticationRequired(DashboardWithUser, {
  loginOptions: { authorizationParams: { screen_hint: (isSignup && 'signup') || undefined } }
});
