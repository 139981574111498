import { ComponentPropsWithoutRef, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Clipboard } from 'ts-clipboard';

import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useNotifications } from '@src/hooks';

type CopyIdActionProps = {
  id: string;
  type: 'project' | 'template' | 'render' | 'organization' | 'user' | 'brand' | 'article';
} & ComponentPropsWithoutRef<'div'>;

export const CopyIdAction = ({ id, type, className }: CopyIdActionProps) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();

  const copyToClipboard = useCallback(() => {
    Clipboard.copy(id);
    notifyInfo(t('components.common.action.CopyIdAction.copiedNotification', { context: type }));
  }, [id, notifyInfo, t, type]);

  return (
    <div
      className={classNames('group flex max-w-fit cursor-pointer items-center gap-1', className)}
      onClick={copyToClipboard}
    >
      <span className="prose truncate rounded-sm bg-gray-200 px-1 text-xs leading-4 duration-150 group-hover:bg-gray-100 prose-code:before:content-none prose-code:after:content-none">
        <code className="font-thin duration-150 group-hover:text-gray-600">{id}</code>
      </span>
      <div className="group flex items-center justify-center">
        <DocumentDuplicateIcon className="h-4 w-4 text-gray-900 duration-150 group-hover:text-gray-600" />
      </div>
    </div>
  );
};
