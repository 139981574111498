import classnames from 'classnames';

/**
 * Normal page that scrolls as content is added.
 */
export const ScreenPage = ({ children, className, ...rest }: React.ComponentProps<'div'>) => (
  <div
    {...rest}
    className={classnames('md:overflow-y-none mx-auto max-w-7xl px-4 py-6 sm:px-6 md:h-screen lg:px-8', className)}
  >
    {children}
  </div>
);
