import { useTranslation } from 'react-i18next';

import { Header } from '@src/components/common';

export const RenderBatchDownloadHeader = () => {
  const { t } = useTranslation();

  return (
    <Header
      header={t('components.render.RenderBatchDownloadPage.title')}
      subheader={t('components.render.RenderBatchDownloadPage.subtitle')}
    />
  );
};
