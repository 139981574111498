import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Badge } from '@src/components/common';

export type SidebarItemProps = {
  name: string;
  route: string;
  icon: ReactNode;
  beta?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  sidebarDesktopFullyCollapsed?: boolean;
};

export const SidebarItem = (props: SidebarItemProps) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames(
        'group flex items-center rounded-md px-2 py-2 text-base font-medium',
        props.isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
      )}
      to={props.route}
      onClick={props.onClick}
    >
      <>
        {isValidElement(props.icon) &&
          cloneElement(props.icon as ReactElement, {
            title: props.name,
            className: classNames(
              'h-6 w-6',
              props.isActive ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
              !props.sidebarDesktopFullyCollapsed && 'mr-4'
            )
          })}
        {!props.sidebarDesktopFullyCollapsed && (
          <>
            {props.name}
            {props.beta && <Badge label={t('general.common.beta')} type="beta" pill />}
          </>
        )}
      </>
    </Link>
  );
};
