import { useCallback } from 'react';

import { getGlobalState, State, useGlobalState } from '@src/state/store';
import { Notification, NotificationType } from '@src/types';
import { randId } from '@src/utils';

export const useNotifications = () => {
  const [notifications, setNotifications] = useGlobalState(State.NOTIFICATIONS);

  const getNotifications = useCallback(() => getGlobalState(State.NOTIFICATIONS), []);

  const newNotification = useCallback(
    (message: string, type: NotificationType, description?: string, errorsList?: string[]) => {
      const notification: Notification = {
        message,
        description,
        type,
        id: '_' + randId(),
        time: new Date(),
        errorsList
      };

      setNotifications([...getNotifications(), notification]);
      return notification;
    },
    [getNotifications, setNotifications]
  );

  const clearNotification = useCallback(
    (id: string) => {
      const updatedNotifications = getNotifications().filter(n => n.id !== id);
      setNotifications(updatedNotifications);
    },
    [getNotifications, setNotifications]
  );

  const clearAllNotifications = () => setNotifications([]);

  const notifyInfo = useCallback(
    (message: string, description?: string) => {
      const notification = newNotification(message, 'info', description);
      setTimeout(() => {
        clearNotification(notification.id);
      }, 5000);
    },
    [clearNotification, newNotification]
  );

  const notifyWarn = useCallback(
    (message: string, description?: string) => newNotification(message, 'warning', description),
    [newNotification]
  );

  const notifyError = useCallback(
    (message: string, description?: string, errorsList?: string[]) =>
      newNotification(message, 'error', description, errorsList),
    [newNotification]
  );

  return {
    notifications,
    notifyInfo,
    notifyWarn,
    notifyError,
    clearNotification,
    clearAllNotifications
  };
};
