import classNames from 'classnames';

export type DesignColorPaletteIconProps = {
  large?: boolean;
  primaryColor: string;
  secondaryColor?: string;
  backgroundColor?: string;
  tooltip: string;
};

export const DesignColorPaletteIcon = ({
  large,
  primaryColor,
  secondaryColor,
  backgroundColor,
  tooltip
}: DesignColorPaletteIconProps) => {
  const getColorIcon = () => {
    if (primaryColor && !secondaryColor && !backgroundColor) {
      return (
        <span
          className={classNames('shadow-xxl rounded-xl', !large && 'mr-2 h-4 w-4', large && 'h-6 w-6')}
          style={{ backgroundColor: primaryColor }}
        ></span>
      );
    } else if (primaryColor && secondaryColor && !backgroundColor) {
      return (
        <>
          <span
            className={classNames(
              'align-center shadow-xxl flex justify-center rounded-xl',
              !large && 'mr-2 h-4 w-4',
              large && 'h-6 w-6'
            )}
            style={{ backgroundColor: 'transparent' }}
          >
            <span className="h-full w-1/2 rounded-l-xl" style={{ backgroundColor: primaryColor }}></span>
            <span className="h-full w-1/2 rounded-r-xl" style={{ backgroundColor: secondaryColor }}></span>
          </span>
        </>
      );
    } else if (primaryColor && secondaryColor && backgroundColor) {
      return (
        <>
          <span
            className={classNames(
              'align-center shadow-xxl flex justify-center rounded-xl p-[3px]',
              !large && 'mr-2 h-4 w-4',
              large && 'h-6 w-6'
            )}
            style={{ backgroundColor: backgroundColor }}
          >
            <span className="h-full w-1/2 rounded-l-xl" style={{ backgroundColor: primaryColor }}></span>
            <span className="h-full w-1/2 rounded-r-xl" style={{ backgroundColor: secondaryColor }}></span>
          </span>
        </>
      );
    }
  };

  return (
    <div title={tooltip} className="flex">
      {getColorIcon()}
    </div>
  );
};
