import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useCancelAllRenders, useCancelRender, useNotifications } from '@src/hooks';

import { Button, Modal } from '../common';

export const RenderCancelModal = ({
  showModal,
  setShowModal,
  renderId
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  renderId?: string;
}) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();
  const { mutateAsync: cancelRender, isLoading: isLoadingSingleCancel } = useCancelRender();
  const { mutateAsync: cancelAllRenders, isLoading: isLoadingAllCancel } = useCancelAllRenders();

  const isLoading = renderId ? isLoadingSingleCancel : isLoadingAllCancel;

  const onCancel = async () => {
    if (renderId) {
      await cancelRender(renderId);
      setShowModal(false);
    } else {
      await cancelAllRenders();
      notifyInfo(t('components.render.common.cancelAllRendersSuccessInfo'));
      setShowModal(false);
    }
  };

  return (
    <Modal visible={showModal}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {renderId ? t('components.render.common.cancelRender') : t('components.render.common.cancelAllRenders')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {renderId
                ? t('components.render.common.cancelSingleRenderDescription')
                : t('components.render.common.cancelRendersDescription')}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:gap-3">
        <Button danger type="button" loading={isLoading} disabled={isLoading} onClick={onCancel}>
          {renderId ? t('general.action.cancel') : t('general.action.cancelAll')}
        </Button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setShowModal(false)}
        >
          {t('general.action.dismiss')}
        </button>
      </div>
    </Modal>
  );
};
