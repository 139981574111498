import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { LanguageIcon } from '@heroicons/react/24/solid';
import { Brand } from '@src/models';
import * as routes from '@src/routes';
import { PropsWithModel } from '@src/types';

export type BrandListItemProps = PropsWithModel<Brand>;

export const BrandListItem = ({ model }: BrandListItemProps) => {
  const { t } = useTranslation();

  const countEnabledVideoTypes = model.settings.videoTypes.filter(videoType => videoType.enabled).length;

  return (
    <li>
      <Link to={generatePath(routes.BRAND_DETAILS, { id: model.id })} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <img
            className="hidden h-12 w-12 flex-none rounded-full bg-gray-50 object-contain sm:block"
            src={model.settings.logoUrl}
            alt={model.settings.name}
          />
          <div className="ml-0 flex w-full flex-col justify-between sm:ml-4">
            <div className="flex items-center gap-2">
              <img
                className="block h-12 w-12 flex-none rounded-full bg-gray-50 object-contain sm:hidden"
                src={model.settings.logoUrl}
                alt={model.settings.name}
              />
              <p className="truncate text-sm font-medium text-indigo-600">{model.settings.name}</p>
            </div>
            <div className="mt-2 flex items-center justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <LanguageIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" />
                  {model.settings.language}
                </p>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:hidden sm:flex-row">
                {!countEnabledVideoTypes && (
                  <p className="text-xs leading-5 text-gray-500">
                    {t('components.brand.BrandsList.noActiveVideoTypes')}
                  </p>
                )}
                {countEnabledVideoTypes > 0 && (
                  <p className="text-xs leading-5 text-gray-500">
                    {t('components.brand.BrandsList.activeVideoTypesCount', {
                      count: countEnabledVideoTypes
                    })}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="ml-2 hidden shrink-0 flex-col gap-2 sm:flex sm:flex-row">
            {!countEnabledVideoTypes && (
              <p className="text-xs leading-5 text-gray-500">{t('components.brand.BrandsList.noActiveVideoTypes')}</p>
            )}
            {countEnabledVideoTypes > 0 && (
              <p className="text-xs leading-5 text-gray-500">
                {t('components.brand.BrandsList.activeVideoTypesCount', {
                  count: countEnabledVideoTypes
                })}
              </p>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};
