import { useMemo } from 'react';
import classNames from 'classnames';

export const Badge = ({
  label,
  type,
  pill,
  title
}: {
  label: string;
  type: 'beta' | 'deprecated' | 'green';
  pill?: boolean;
  title?: string;
}) => {
  const styling = useMemo(() => {
    switch (type) {
      case 'beta':
        return 'bg-indigo-100 text-indigo-800';
      case 'deprecated':
        return 'bg-yellow-100 text-yellow-800';
      case 'green':
        return 'bg-green-100 text-green-800';
      default:
        return null;
    }
  }, [type]);

  return (
    <span
      title={title}
      className={classNames(
        'ml-1 inline-flex items-center px-2.5 py-0.5 text-xs font-medium',
        pill ? 'rounded-full' : 'rounded-md',
        styling,
        title && 'cursor-help'
      )}
    >
      {label}
    </span>
  );
};
