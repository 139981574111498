import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button, MediaInput, PlainlyCombobox } from '@src/components/common';
import { useCreateBrand, useEditBrand, useValidateUrls } from '@src/hooks';
import { Brand } from '@src/models';
import * as routes from '@src/routes';
import { LANGUAGES } from '@src/tools/publishers/utils';
import { isValidUrl } from '@src/utils';

const EN_US_index = LANGUAGES.findIndex(language => language.code === 'en-US');

export type BrandCreateFormProps = {
  brand?: Brand;
};

export const BrandCreateForm = ({ brand }: BrandCreateFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editMode = brand !== undefined;

  const { isLoading: isLoadingCreate, mutateAsync: createBrand } = useCreateBrand();
  const { isLoading: isLoadingEdit, mutateAsync: editBrand } = useEditBrand();

  const isLoading = isLoadingCreate || isLoadingEdit;

  const [inputs, setInputs] = useState({
    name: brand?.settings.name || '',
    logoUrl: brand?.settings.logoUrl || '',
    language: brand?.settings.language || LANGUAGES[EN_US_index].code,
    baseUrl: brand?.settings.baseUrl || '',
    videoTypes: brand?.settings.videoTypes || []
  });

  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const isValidForm =
    inputs.logoUrl.trim() !== '' &&
    inputs.name.trim() !== '' &&
    isValidUrl(inputs.baseUrl) &&
    inputs.language &&
    urlsValid;
  const canFireCreate = isValidForm && !isLoading;

  const fireCreate = async () => {
    let newBrand = null;

    if (editMode) newBrand = await editBrand({ brandId: brand.id, settings: inputs });
    if (!editMode) newBrand = await createBrand(inputs);

    if (newBrand) navigate(generatePath(routes.BRAND_DETAILS, { id: newBrand.id }));
  };

  const onChangeBaseUrl = (value: string) => {
    if (value && value.indexOf('.') !== -1 && !value.startsWith('http') && !value.startsWith('https')) {
      setInputs({ ...inputs, baseUrl: `https://${value}` });
    } else {
      setInputs({ ...inputs, baseUrl: value });
    }
  };

  return (
    <form
      className="space-y-6"
      onSubmit={e => {
        e.preventDefault();
        fireCreate();
      }}
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {editMode
                ? t('components.brand.BrandCreateForm.editMode.title')
                : t('components.brand.BrandCreateForm.title')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {editMode
                ? t('components.brand.BrandCreateForm.editMode.description')
                : t('components.brand.BrandCreateForm.description')}
            </p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="space-y-6">
              <div>
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    {t('general.common.name')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.brand.BrandCreateForm.brandNameDesc')}</p>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={e => setInputs({ ...inputs, name: e.target.value.trim() })}
                    defaultValue={inputs.name}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="logoUrl" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.publishers.forms.logoUrl')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.brand.BrandCreateForm.brandLogoUrlDesc')}</p>
                  <div className="mt-1">
                    <MediaInput
                      required
                      onFieldUpdate={value => setInputs({ ...inputs, logoUrl: value })}
                      value={inputs.logoUrl}
                      onValidation={handleInvalidUrls('logoUrl')}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="language" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('general.common.language')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.brand.BrandCreateForm.brandLanguageDesc')}</p>
                  <PlainlyCombobox
                    hasSearch
                    className="mt-1"
                    data={LANGUAGES.map(language => ({
                      name: language,
                      item: language,
                      label: t('general.languages.lang', { context: language.code }),
                      selected: language.code === inputs.language
                    }))}
                    onSelectionChange={item => setInputs({ ...inputs, language: item?.code as string })}
                  />
                </div>
                <div>
                  <label htmlFor="baseUrl" className="mt-3 block text-sm font-medium text-gray-700">
                    {t('components.brand.BrandCreateForm.baseUrlLabel')}
                  </label>
                  <p className="text-sm text-gray-500">{t('components.brand.BrandCreateForm.baseUrlDesc')}</p>
                  <input
                    type="url"
                    name="baseUrl"
                    id="baseUrl"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="www.example.com"
                    onChange={e => onChangeBaseUrl(e.target.value)}
                    value={inputs.baseUrl}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {navigate.length > 0 && (
          <Button secondary={true} onClick={() => navigate(-1)}>
            {t('general.action.back')}
          </Button>
        )}
        <Button className="ml-3" type="submit" loading={isLoading} disabled={!canFireCreate}>
          {(editMode && t('general.action.update')) || t('general.action.create')}
        </Button>
      </div>
    </form>
  );
};
