import { MediaInput, MediaInputProps } from '@src/components/common';
import { DesignInputErrors } from '@src/components/designs';
import { isEmpty } from '@src/utils';

export const MediaLayerInput = (props: MediaInputProps) => {
  return (
    <>
      <MediaInput {...props} />
      {!isEmpty(props.errors) && <DesignInputErrors errors={props.errors} />}
    </>
  );
};
