export const mockArticles: { articleUrl: string; body: string }[] = [
  {
    articleUrl: 'https://edition.cnn.com/2024/02/01/investing/premarket-stocks-trading-earnings-consumer/index.html',
    body: `Earnings season is in full swing, and that means investors get a chance to hear from multinational companies about the state of the global economy. So far, executives are cautiously optimistic.

While the benchmark S&P 500 index has reached several record highs in recent weeks, investors are monitoring the unknowns that could throw a wrench into the market’s ascent, from the Federal Reserve’s interest rate decisions to geopolitical strife to a potential recession.

Some of the United States’ biggest companies are in the hot seat to answer questions about the economy, and where it could be headed.

Here’s what they have to say.

Consumers remain resilient, for now. Consumers have flexed their spending power throughout the Federal Reserve’s battle against inflation. US gross domestic product rose at a seasonally and inflation-adjusted annualized rate of 3.3% during the fourth quarter. Consumer spending makes up about two-thirds of the US economy.

“The consumer picture … is somewhat mixed. Employment remains strong. Wage growth is up. But I think it’s also probably fair to say from our side that the full effects of all the rate hikes and all the economic policy impacts are not fully materialized in the consumer,” said Michael Hsu, CEO at Kleenex-parent firm Kimberly-Clark, on a conference call.

Like the rest of the US, companies are watching whether the economy could still tip into a recession as interest rates hover around a 23-year high. Achieving a soft landing, or a situation in which inflation comes down without an economic downturn, looks likely, some companies said.

“Most consumer segments are healthy, corporate balance sheets are strong and credit fundamentals remain solid,” said Blackstone CEO Stephen Schwarzman on the company’s earnings call. “We see a resilient economy, albeit one that is decelerating. What we’re seeing is consistent with a soft landing.”

Americans are continuing to spend at restaurants and pulling back on travel. Since pandemic restrictions have lifted in the US, Americans have broadly shifted their spending from goods to experiences like concerts, dining out and vacations. But there are changes in how consumers are spending even within the experiences category.

For example, American Express CFO Christophe Le Caillec noted during the company’s post-earnings call on January 26 that restaurant spending, its largest travel and entertainment category, reached $100 billion for the full year for the first time.

Airline spending growth slowed during the fourth quarter, according to the credit card firm. That’s in line with airlines’ warnings late last year that travel demand is softening as it returns to pre-pandemic levels.

Still, that doesn’t mean that Americans’ appetite for travel has completely tapered off.

“Demand remains strong, and we have seen robust bookings to start the year, as travel trends have begun to normalize across entities. We’re also very encouraged by the trends we’re seeing in business travel,” said American Airlines CEO Robert Isom during a call with analysts.

Geopolitical strife is a continued risk. Companies are watching several sources of geopolitical risk this year, from war in the Middle East to the Red Sea crisis to the US presidential election.

“For 2024, demand growth remains the biggest unknown in the face of global economic uncertainty and heightened geopolitical risk,” said Lorenzo Simonelli, CEO of oilfield services firm Baker Hughes, on the company’s post-earnings call.

Some companies are already taking steps to protect themselves against escalations in geopolitical tensions.

“The world has never been more active than it is now … and so I can’t speak for anybody else but we’re reacting to those things in our pricing,” said Alan Schnitzer, chief executive of Travelers Companies, on a January 19 call with analysts.

The Fed is fed up with data revisions
Federal Reserve officials have said countless times they take a “data-dependent approach” to their policy decisions, including their current conundrum of when to slash interest rates. But what if the data isn’t as dependable as it once was?

That’s what appears to be happening — and it’s making central bankers’ jobs a lot harder, reports my colleague Elisabeth Buchwald.

“We have to make decisions in real time,” Fed Governor Christopher Waller said late last year. “Whatever data is released, that’s the data I have to use. The problem with data is it gets revised.”

That wouldn’t necessarily be so much of an issue if the revisions, which can come months after initial reports are released, were relatively small. However, many revisions over the past few years have been game-changers.

For instance, Waller pointed out that initial monthly headline employment numbers for 2021 led him to believe that the job market was “okay, but it’s not really great.” Even though inflation was at a 40-year high, he and other Fed officials were under the impression that they’d need to proceed very carefully with raising interest rates, fearing it could lead to job losses, Waller said.

But along came the revisions.

Brexit is finally coming for UK food imports. Prices could rise
New border controls on some of Britain’s food imports from the European Union came into force Wednesday for the first time since Brexit, increasing red tape for businesses and threatening to drive up prices for consumers, reports my colleague Hanna Ziady.

Meat, eggs, fish and dairy are among a raft of fresh produce that will now require “export health certificates” and other paperwork before entering the United Kingdom.

According to the UK government’s own estimates, the checks — including physical inspections from April — will cost British businesses about £330 million ($419 million) annually and increase food inflation by about 0.2 percentage points over three years. Some industry experts are warning of a greater impact on inflation.

The new controls mark the first time EU food producers must face the hassle of post-Brexit border bureaucracy since Britain exited the bloc’s vast internal market and customs union in January 2021. The country quit the EU a year earlier, in January 2020, following a divisive referendum in 2016.`
  },
  {
    articleUrl: 'https://edition.cnn.com/2024/01/25/investing/premarket-stocks-trading-bitcoin-etf/index.html',
    body: `Major cryptocurrencies were on a tear at the end of last year, driven higher by anticipation that US regulators would approve bitcoin exchange-traded funds, broadening the pool of potential investors.

Once those funds received the go-ahead, bitcoin fell. What gives?

Bitcoin topped $47,000 early this month for the first time since April 2022, as investors anticipated that the US Securities and Exchange Commission would approve the first ever exchange-traded funds tracking the spot price of bitcoin.

But the cryptocurrency declined after the favorable SEC decision duly came on January 10. And bitcoin edged below $40,000 earlier this week for the first time since December.

The price of the cryptocurrency was trading at roughly $39,519 a coin as of 4 pm ET on Wednesday.

So, why is the price of bitcoin falling, even after investors got what they wanted? Part of the reason is likely a “buy the rumor, sell the news” mentality, according to Antoni Trenchev, co-founder of crypto lender Nexo. That’s the idea that the price of an asset often rises in anticipation of an announcement and then falls as investors take profits once the news actually comes out.

Interestingly, bitcoin ETF activity actually shows net buying.

Investors have pulled about $4.4 billion from the Grayscale Bitcoin Trust since it was converted to an ETF on January 11, according to Coinshares data through Wednesday. Greyscale’s fund is the largest bitcoin ETF, holding roughly $20 billion in assets.

But those flows have been offset by about $5.3 billion poured into nine other spot bitcoin ETFs.

ETFs hold just a small portion of the bitcoin that’s traded, says Eric Balchunas, senior ETF analyst at Bloomberg Intelligence. That means “the call is coming from inside the house. Someone who pre-owned the crypto is doing the selling,” he said.

Bitcoin has made its way higher over the last year since plummeting to below $17,000 in late 2022, after cryptocurrency exchange FTX filed for bankruptcy and set off a firestorm in crypto trading. Still, it remains well below its record high of roughly $69,000 reached in November 2021.

“We’re used to this kind of volatility in the space. What matters now is what’s coming next that could generate excitement around cryptos and deliver further gains,” wrote Craig Erlam, senior market analyst at OANDA, in a note on Tuesday.

Your 401(k), gas prices and homes: 3 surprising reasons to be happy
Americans have been fed up with an economy that many feel isn’t working for them, and the 2024 election may turn out to be a referendum on that gloomy sentiment. But the winds may be changing a bit: 401(k) plans are on fire. Consumer sentiment is surging. Gas prices have fallen. The country’s economic vibes are finally vibing again, according to an analysis by my colleague Bryan Mena.

The years-long economic funk was largely driven by the unforgiving pain of high inflation. A tough housing market and exorbitant child care costs continue to bedevil budgets, but the inflation pendulum is finally swinging in the other direction.

Inflation is down substantially from its four-decade peak in the summer of 2022. A powerful US stock rally is now back on track, mortgage rates have eased a bit in recent weeks and the job market remains in great shape, with unemployment near historic lows.

None of this guarantees the good times will continue, of course. Conflicts abroad could push up prices for oil and other consumer goods, for example.

But consumer surveys have begun to capture a shifting, more upbeat sentiment among Americans. Here are three reasons to be happy about the current state of the US economy. (At least for now.)

Read more here.

It’s still too hard to be a working parent in America, Janet Yellen says
In a major speech set to be delivered Thursday in Chicago, Treasury Secretary Janet Yellen plans to detail her vision for helping middle-class families overcome affordability challenges, according to excerpts shared first with CNN.

“Our economic agenda is far from finished. There’s much more the President and I would like to do to support the middle class,” Yellen plans to say during the speech at the Economic Club of Chicago.

Treasury officials have billed the speech as one of the most significant Yellen plans to deliver this year and an effort to set the tone for her domestic agenda during 2024, reports my colleague Matt Egan.

Yellen plans to balance the Biden administration taking credit for the economic recovery from Covid-19 with acknowledging that many Americans are frustrated with how expensive it is to buy a house, raise children and save money for college.

“It is still too hard to be a working parent. We need to get American families access to affordable child care and other support for their children,” Yellen is set to say, according to the excerpts.`
  },
  {
    articleUrl: 'https://edition.cnn.com/2024/01/30/health/how-to-choose-a-personal-trainer-wellness/index.html',
    body: `Working one-on-one with a trainer used to be the province of elite athletes, or the rich and famous. No more. Personal training has been rising in popularity of late, with the fitness training market predicted to grow 14% from 2022 to 2032, according to the US Bureau of Labor Statistics. That growth is a much faster rate than the average for all occupations, the agency said.

The popularity of personal trainers isn’t limited to the United States, either. The global personal fitness trainer market was worth about $41.8 billion in 2023, and market research firm Future Market Insights predicts it will soar to $65.5 billion by 2033.

Why is personal training so widespread today? Experts say it’s due to a variety of factors, including rising obesity rates, the growing awareness of the benefits of being fit and the large number of people who find it difficult to maintain an exercise regimen on their own. A growing body of research also suggests working out with a personal trainer has a variety of benefits.

Gym members who trained under a personal trainer rather than solo saw significantly greater improvements in lean body mass and other aspects of fitness, according to one 2014 study published in the Journal of Strength and Conditioning Research.

Older veterans with numerous diseases and medical conditions reduced their medication usage while under the tutelage of a personal trainer, according to a 2020 study published in Gerontology and Geriatric Medicine. And a 2017 study found that participants in small-group exercise led by a shared personal trainer — who also provided individualized training — reported more energy, better health, greater self-confidence with exercising and improved life satisfaction.

The two biggest benefits from hiring a personal trainer, though, whether you’re an exercise newbie or a pro, are accountability and motivation, said Mary Wing, a certified personal trainer and performance coach with the fitness app Future.

“Having someone there to motivate you and hold you accountable to show up and complete your workouts is huge,” said Wing, who is based in Sanford, North Carolina, but trains her clients virtually.

How to get started
Intrigued? Before rushing to sign up for some sessions, do your research. Personal training can be done in a gym, outdoors, in your home or virtually, so think about which option works best with your personality and lifestyle.

Gyms require a membership, for example, and personal training is typically an additional charge. Outdoor or home-based training may be more to your liking and convenience, while virtual training offers the most flexibility, as it can be done anywhere, even while traveling.

The trainer you select should be certified through a reputable credentialing agency, such as the National Academy of Sports Medicine, the American College of Sports Medicine or the American Council on Exercise. These groups require several weeks of education and training, after which you must pass a test. Lesser-quality organizations may simply require a few hours of reading, followed by an online test.

“Double-check their certifications and do some diving into their background,” Wing said. Many coaches are certified in multiple areas, for example — think nutrition or prenatal and postnatal fitness — providing an extra benefit.

It’s also wise to inquire about a coach’s experience. “If you’re older, have diabetes or have issues with your joints or heart, it’s worth asking if they’ve trained anybody like you,” said Allan Misner, a certified personal trainer in Bocas del Toro, Panama.

Misner, who specializes in training older adults and is host of the “40+ Fitness” podcast, said it can be challenging to find a personal trainer in a big-box gym who has a training specialty. “That’s because they have to work with whoever walks in the door,” he said. “But people over 40 might have achy knees or backs, or muscle imbalances, that take care and understanding.”

You’ll also need to feel comfortable with your trainer, as the experience is somewhat intimate. Interview a few before selecting one. The trainer should always ask about your goals and listen carefully to your responses. Someone hoping to run a marathon, for example, will need different coaching than someone who is hoping to sit on the floor and play with a grandchild.

It’s also important to know your training style. Are you looking for someone who will hold your hand through your workouts, or someone who is more of a drill sergeant? You, in turn, need to be clear about your preferences and expectations.

“These are customized programs, so clients have to let me know what they’re looking for,” Wing said. “I can’t guess. Do you need more motivation? A daily check-in? You’re paying the money, so you should be open about it.”

Weight loss is the top goal for most of their clients, said Wing and Misner. If that’s yours as well, you’ll need to be realistic. It’s not healthy to attempt to lose, say, five pounds a week, Wing said. And exercise alone won’t cause you to lose weight, Misner added.

“I can’t exercise the weight off of you, especially if you’re in your 50s or 60s,” he said. “If weight loss is your goal, it’s better to see a nutritionist.”

Beware of these red flags
Once you’ve selected your trainer, there are a few red flags to watch for. While you want a trainer who will push you, it has to be within reason.

“Gentle nudges that get you slightly outside your comfort zone are good,” Misner said. “But if they’re trying to push you harder and harder, that can be a problem.”

Wing said a good trainer will always listen to feedback, too. If clients say an exercise was uncomfortable or they just didn’t like it, the trainer should not keep prescribing that exercise.

In the end, though, hiring a personal trainer can be a safe and effective way to achieve a healthier life, which is the main goal.

“Real fitness isn’t about how much you can lift or how fast you can run,” Misner said. “I want to be able to open my own jars, wipe my own butt when I’m 105 and do all of the things I enjoy doing for the rest of my life. Real fitness is about living the life you deserve to live.”`
  }
];
