import { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';

import { HeadlineFormInputs } from './HeadlineFormInputs';

export const HeadlinesFormSection = ({
  currentHeadlines,
  setCurrentHeadlines,
  disabled,
  currentHeadlinesIndex
}: {
  currentHeadlines: { heading: string; subheading?: string }[];
  setCurrentHeadlines: (currentHeadlines: { heading: string; subheading?: string }[]) => void;
  disabled?: boolean;
  currentHeadlinesIndex: number;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const isScene = videoNavigationState.activeView === 'scenes';
  const sceneId = isScene && videoNavigationState.sceneId;
  const assetsTab = isScene && videoNavigationState.assetsTab;

  const currentHeadline = useMemo(
    () => currentHeadlines[currentHeadlinesIndex],
    [currentHeadlines, currentHeadlinesIndex]
  );

  const addTab = () => {
    const newHeadlineTab = currentHeadlines.length + 1;
    setCurrentHeadlines([...currentHeadlines, { heading: '', subheading: '' }]);

    sceneId &&
      assetsTab &&
      updateVideoNavigationState({
        revisionId: videoNavigationState.revisionId,
        activeView: 'scenes',
        sceneId: sceneId,
        headlinesTab: newHeadlineTab,
        assetsTab: assetsTab
      });
  };

  const removeTab = () => {
    setCurrentHeadlines(currentHeadlines.filter((_, index) => index !== currentHeadlinesIndex));

    sceneId &&
      assetsTab &&
      updateVideoNavigationState({
        revisionId: videoNavigationState.revisionId,
        activeView: 'scenes',
        sceneId: sceneId,
        headlinesTab: Math.max(1, currentHeadlinesIndex),
        assetsTab: assetsTab
      });
  };

  return (
    <div>
      <label htmlFor="heading" className="block text-sm font-medium text-gray-700">
        {t('components.common.headlinesTitle')}
      </label>
      <p className="text-sm text-gray-500">
        {t('components.article.ArticleVideo.sceneSettings.sceneForm.headlinesDescription')}
      </p>
      {/* Mobile view tabs */}
      <div className="mt-1 flex w-full gap-2 sm:hidden">
        <label htmlFor="headlines" className="sr-only">
          {t('general.action.selectATab')}
        </label>
        <select
          id="headlines"
          name="headlines"
          className="block w-full rounded-md border-gray-300 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          value={currentHeadlinesIndex}
          onChange={e => {
            sceneId &&
              assetsTab &&
              updateVideoNavigationState({
                revisionId: videoNavigationState.revisionId,
                activeView: 'scenes',
                sceneId: sceneId,
                headlinesTab: Number(e.target.value) + 1,
                assetsTab: assetsTab
              });
          }}
        >
          {currentHeadlines.map((headline, index) => (
            <option value={index} key={index}>
              {index + 1}
            </option>
          ))}
        </select>
        <button
          disabled={disabled}
          type="button"
          className="disabled:cursor-not-allowed disabled:opacity-50"
          onClick={addTab}
        >
          <PlusCircleIcon className="h-6 w-6 text-indigo-600 hover:text-indigo-700" aria-hidden="true" />
        </button>
        <button
          disabled={disabled || currentHeadlines.length === 1}
          type="button"
          className="disabled:cursor-not-allowed disabled:opacity-50"
          onClick={removeTab}
        >
          <TrashIcon className="h-6 w-6 text-indigo-600 hover:text-indigo-700" aria-hidden="true" />
        </button>
      </div>
      {/* Desktop view tabs */}
      <div className="mt-1 hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex justify-between" aria-label="Tabs">
            <div className="-mb-px flex space-x-2">
              {currentHeadlines.map((headline, index) => (
                <div
                  className={classNames(
                    currentHeadlinesIndex === index
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'group flex w-12 cursor-pointer items-center justify-center gap-2 border-b-2 py-1 text-sm font-medium'
                  )}
                  onClick={() => {
                    sceneId &&
                      assetsTab &&
                      updateVideoNavigationState({
                        revisionId: videoNavigationState.revisionId,
                        activeView: 'scenes',
                        sceneId: sceneId,
                        headlinesTab: index + 1,
                        assetsTab: assetsTab
                      });
                  }}
                  key={index}
                >
                  <p>{index + 1}</p>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={disabled}
                type="button"
                className="disabled:cursor-not-allowed disabled:opacity-50"
                onClick={addTab}
                title={t('components.article.ArticleVideo.sceneSettings.sceneForm.addHeadline')}
              >
                <PlusCircleIcon className="h-6 w-6 text-indigo-600 hover:text-indigo-700" aria-hidden="true" />
              </button>
              <button
                disabled={disabled || currentHeadlines.length === 1}
                type="button"
                className="disabled:cursor-not-allowed disabled:opacity-50"
                onClick={removeTab}
                title={t('components.article.ArticleVideo.sceneSettings.sceneForm.removeHeadline')}
              >
                <TrashIcon className="h-6 w-6 text-indigo-600 hover:text-indigo-700" aria-hidden="true" />
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <HeadlineFormInputs
          disabled={disabled}
          currentHeadline={currentHeadline}
          setCurrentHeadlines={newHeadline => {
            const newInputs = [...currentHeadlines];
            newInputs[currentHeadlinesIndex] = newHeadline;
            setCurrentHeadlines(newInputs);
          }}
        />
      </div>
    </div>
  );
};
