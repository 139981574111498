import { useTranslation } from 'react-i18next';

export const StepNavigation = ({
  steps,
  activeStep
}: {
  steps: { value: number; name: string }[];
  activeStep: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map(step => (
            <li key={step.name} className="md:flex-1">
              {step.value < activeStep ? (
                <div className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">
                    {t('components.render.RenderBatchNavigation.step', { context: step.value.toString() })}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              ) : step.value === activeStep ? (
                <div className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-sm font-medium text-indigo-600">
                    {t('components.render.RenderBatchNavigation.step', { context: step.value.toString() })}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              ) : (
                <div className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    {t('components.render.RenderBatchNavigation.step', { context: step.value.toString() })}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
