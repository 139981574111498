import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { ErrorAlert, Loading } from '../common';

import { useAppmixerContext } from './AppmixerContext';
import { categoryCustomField, projectIdCustomField, templateIdCustomField, typeCustomField } from './constants';

export type AppmixerTemplatesProps = {
  project?: {
    id: string;
    name: string;
  };
  template?: { id: string; name: string };
  category?: string;
  type?: string;
  hidden?: boolean;
  onIntegrationStarted: () => void;
};

const getPrefix = (p?: { name: string }, t?: { id: string; name: string }) => {
  if (p) {
    if (t) {
      return `[${p.name}/${t.name}] `;
    } else {
      return `[${p.name}] `;
    }
  }
};

export const AppmixerTemplates = ({
  project,
  template,
  category,
  type,
  hidden,
  onIntegrationStarted
}: AppmixerTemplatesProps) => {
  const { t } = useTranslation();
  const appmixerContext = useAppmixerContext();
  const [loading, setLoading] = useState(false);
  const showLoading = !hidden && (loading || !appmixerContext.ready);
  const showError = !hidden && !loading && !appmixerContext.ready && appmixerContext.error;

  useEffect(() => {
    // define setup
    const setup = () => {
      // do nothing if not ready
      if (!appmixerContext.ready) {
        return;
      }

      setLoading(true);

      // read from content
      const appmixer = appmixerContext.appmixer;
      const domain = appmixerContext.domain;

      const prefix = getPrefix(project, template);
      const wizard = appmixer.ui.Wizard();

      const customFilter: Record<string, string> = {
        'sharedWith.domain': domain,
        templateId: '!'
      };
      if (category) {
        customFilter[`customFields.${categoryCustomField}`] = category;
      }

      if (type) {
        customFilter[`customFields.${typeCustomField}`] = type;
      }

      const integrations = appmixer.ui.Integrations({
        el: '#appmixer-templates',
        options: {
          showHeader: false,
          customFilter
        }
      });
      integrations.open();

      integrations.on('integration:create', async (templateId: string) => {
        wizard.close();

        // Create integration flow as a clone of the template. Disconnect
        // accounts because they might not be shared with the end user.
        const integrationId = await appmixer.api.cloneFlow(templateId, {
          connectAccounts: false,
          prefix
        });

        // get flow, so we can include existing custom fields
        const flow = await appmixer.api.getFlow(integrationId);

        // construct custom fields
        const customFields: Record<string, string> = flow?.customFields || {};
        if (project) {
          customFields[projectIdCustomField] = project.id;
        }
        if (template) {
          customFields[templateIdCustomField] = template.id;
        }

        // 1. Identify the clone as an integration with template id
        // 2. Add the custom fields here
        // 3. Share with no one
        await appmixer.api.updateFlow(integrationId, {
          templateId,
          customFields,
          sharedWith: []
        });

        wizard.set('flowId', integrationId);
        wizard.open();
      });

      wizard.on('flow:start-after', async () => {
        wizard.close();
        onIntegrationStarted && onIntegrationStarted();
      });

      setLoading(false);
    };

    // call setup
    setup();
  }, [appmixerContext, project, template, category, type, setLoading, onIntegrationStarted]);

  return (
    <>
      {!hidden && (
        <div className="ml-3 mt-2 flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <p className="ml-2 max-w-full text-sm text-gray-500">{t('components.appmixer.AppmixerTemplates.info')}</p>
        </div>
      )}
      {!showError && showLoading && <Loading small />}
      {showError && <ErrorAlert closable={false} error={appmixerContext.error} />}
      <div id="appmixer-templates" className={classNames(hidden && 'hidden')} />
    </>
  );
};
