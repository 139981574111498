/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { ErrorAlert, Loading } from '../common';

import { useAppmixerContext } from './AppmixerContext';
import { categoryCustomField, projectIdCustomField, templateIdCustomField, typeCustomField } from './constants';

export type AppmixerActiveIntegrationsProps = {
  project?: {
    id: string;
    name: string;
  };
  template?: { id: string; name: string };
  category?: string;
  type?: string;
  hidden?: boolean;
  fullScreen: boolean;
};

export const AppmixerActiveIntegrations = ({
  project,
  template,
  category,
  type,
  hidden,
  fullScreen
}: AppmixerActiveIntegrationsProps) => {
  const { t } = useTranslation();
  const appmixerContext = useAppmixerContext();
  const [integrationWidget, setIntegrationWidget] = useState<any>();
  const [loading, setLoading] = useState(false);
  const showLoading = !hidden && (loading || !appmixerContext.ready);
  const showError = !hidden && !loading && !appmixerContext.ready && appmixerContext.error;

  useEffect(() => {
    // define setup
    const setup = () => {
      // do nothing if not ready
      if (!appmixerContext.ready) {
        return;
      }

      setLoading(true);

      // read from content
      const appmixer = appmixerContext.appmixer;

      const customFilter: Record<string, any> = {
        // Show only my instances. Not all flows that have been possibly shared with me in the Studio.
        userId: appmixer.get('user').id,

        // Show Integration instances only. Instances have `templateId`
        // to reference the template they were created from.
        templateId: '>0'
      };

      // then include our custom fields
      if (project) {
        customFilter[`customFields.${projectIdCustomField}`] = project.id;
      }

      // then include our custom fields
      if (template) {
        customFilter[`customFields.${templateIdCustomField}`] = template.id;
      }

      // then include our custom fields
      if (category) {
        customFilter[`customFields.${categoryCustomField}`] = category;
      }

      // then include our custom fields
      if (type) {
        customFilter[`customFields.${typeCustomField}`] = type;
      }

      const wizard = appmixer.ui.Wizard();
      const integrations = appmixer.ui.Integrations({
        el: '#appmixer-active-integrations',
        options: {
          showHeader: false,
          customFilter
        }
      });

      integrations.on('integration:edit', (integrationId: any) => {
        wizard.close();
        wizard.set('flowId', integrationId);
        wizard.open();
      });

      wizard.on('close', async () => {
        integrations.reload();
      });

      integrations.open();

      setIntegrationWidget(integrations);
      setLoading(false);
    };

    // call setup
    setup();
  }, [appmixerContext, project, template, category, type, setIntegrationWidget, setLoading]);

  // always reload when we become active
  useEffect(() => {
    if (!hidden && integrationWidget) {
      integrationWidget.reload();
    }
  }, [integrationWidget, hidden]);

  return (
    <>
      {!hidden && fullScreen && (
        <div className="ml-3 mt-2 flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <p className="ml-2 max-w-full text-sm text-gray-500">
            {t('components.appmixer.AppmixerActiveIntegrations.info')}
          </p>
        </div>
      )}
      {!showError && showLoading && <Loading small />}
      {showError && <ErrorAlert closable={false} error={appmixerContext.error} />}
      <div id="appmixer-active-integrations" className={classNames(hidden && 'hidden')} />
    </>
  );
};
