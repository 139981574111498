import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { FunnelIcon } from '@heroicons/react/20/solid';
import { useQueryParams } from '@src/hooks';
import { DesignCategory } from '@src/models';
import { PUBLIC_DESIGNS } from '@src/routes';

export type DesignsCategoryTabsProps = {
  categories: DesignCategory[];
  selectedCategory: DesignCategory | undefined;
  setMobileFiltersOpen: (open: boolean) => void;
};

export const DesignsCategoryTabs = ({
  categories,
  selectedCategory,
  setMobileFiltersOpen
}: DesignsCategoryTabsProps) => {
  const { t } = useTranslation();
  const { updateQueryParams } = useQueryParams();
  const navigate = useNavigate();

  return (
    <div className="pb-5 lg:pb-0">
      <div className="mt-3 lg:mt-6">
        <div className="flex lg:hidden">
          <label htmlFor="current-tab" className="sr-only">
            {t('general.action.selectATab')}
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={selectedCategory}
            onChange={e =>
              navigate({
                search: `?${updateQueryParams(window.location, {
                  category: e.target.value
                })}`
              })
            }
          >
            <option key={-1}>{t('components.designs.DesignsCategoryTabs.allCategories')}</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {t('components.designs.common.designCategory', { context: category })}
              </option>
            ))}
          </select>
          <div className="flex items-center justify-end">
            <button
              type="button"
              className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <span className="sr-only">{t('components.designs.common.filters')}</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              <Link
                to={PUBLIC_DESIGNS}
                key={0}
                className={classNames(
                  selectedCategory === undefined
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current={selectedCategory === undefined ? 'page' : undefined}
              >
                {t('components.designs.DesignsCategoryTabs.allCategories')}
              </Link>
              {categories.map(category => (
                <Link
                  to={`?${updateQueryParams(window.location, {
                    category: category
                  })}`}
                  key={category}
                  className={classNames(
                    selectedCategory === category
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                  aria-current={selectedCategory === category ? 'page' : undefined}
                >
                  {t('components.designs.common.designCategory', { context: category })}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
