import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

export const UserNotVerifiedPage = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();
  const executeLogout = useCallback(() => logout({ logoutParams: { returnTo: window.location.origin } }), [logout]);

  return (
    <div className="bg-white">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <img className="mx-auto h-10 w-auto sm:h-12" src="/assets/favicon/favicon-96x96.png" alt="Plainly Logo" />
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold uppercase leading-8 text-indigo-600">
            {t('general.common.welcomeMessage')}
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t('components.UserNotVerifiedPage.title')}
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
            {t('components.UserNotVerifiedPage.subtitle', { email: user?.email })}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://plainlyvideos.com/contact/"
              target="_blank"
              rel="noreferrer"
              className="text-sm font-semibold text-gray-900"
            >
              {t('general.action.contactSupport')}
              <span aria-hidden="true" className="ml-1">
                &rarr;
              </span>
            </a>
          </div>
          <p className="mt-10 text-xs text-gray-500">
            <Trans i18nKey="general.common.logoutMessage">
              Or{' '}
              <a href="#" onClick={executeLogout} className="underline hover:text-gray-700">
                logout
              </a>{' '}
              from the current Plainly account.
            </Trans>
          </p>
        </div>
      </main>
    </div>
  );
};
