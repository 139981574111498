import { ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type InfiniteListProps<T> = {
  emptyListMessage?: string;
  data: T[][] | null | undefined;
  renderItem: (item: T) => ReactNode;
  headerTitle?: string;
  headerAction?: ReactNode;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isSuccess: boolean;
};

export const InfiniteList = <T,>({
  data,
  renderItem,
  headerTitle,
  headerAction,
  hasNextPage,
  fetchNextPage,
  isSuccess
}: InfiniteListProps<T>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const content =
    isSuccess &&
    data?.map((page, index) => {
      const isLastPage = index === data.length - 1;
      return page.map((render, i) => {
        const isLastInLastPage = isLastPage && i === page.length - 1;
        return (
          <li key={i} ref={isLastInLastPage ? ref : undefined}>
            {renderItem(render)}
          </li>
        );
      });
    });

  const plainList = (
    <div>
      <div className="inline-block w-full bg-white shadow sm:rounded-md">
        <ul className="flex flex-col divide-y divide-gray-300">{content}</ul>
      </div>
    </div>
  );

  if (!headerTitle && !headerAction) return plainList;

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-lg font-medium leading-6 text-gray-900">{headerTitle}</h2>
        </div>
        <div className="flex pt-4 md:block md:pt-0">{headerAction}</div>
      </div>
      <div className="border-t border-gray-200">{plainList}</div>
    </div>
  );
};
