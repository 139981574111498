import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum LayerParametrizeTabsValue {
  TEMPLATE_VALUE = 'TEMPLATE_VALUE',
  SCRIPTING = 'SCRIPTING'
}

export type LayerParametrizeTabsProps = {
  activeTab?: string;
  setActiveTab: (activeTab: string | undefined) => void;
  hideParametrization?: boolean;
  isEffect: boolean;
  numberOfScripts?: number;
};

export const LayerParametrizeTabs = ({
  activeTab,
  setActiveTab,
  hideParametrization,
  isEffect,
  numberOfScripts
}: LayerParametrizeTabsProps) => {
  const { t } = useTranslation();

  const isTabHidden = (tabValue: LayerParametrizeTabsValue) => {
    if (hideParametrization && tabValue === LayerParametrizeTabsValue.TEMPLATE_VALUE) {
      return true;
    }

    if (isEffect && tabValue === LayerParametrizeTabsValue.SCRIPTING) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t('general.action.selectATab')}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={e => setActiveTab(e.currentTarget.value)}
          value={activeTab}
        >
          {Object.values(LayerParametrizeTabsValue).map(tabValue => {
            return isTabHidden(tabValue) ? null : (
              <option value={tabValue} key={tabValue}>
                {t('components.project.template.layer.LayerParametrizeTabs.title', { context: tabValue })}
              </option>
            );
          })}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {Object.values(LayerParametrizeTabsValue).map(tabValue => {
              return isTabHidden(tabValue) ? null : (
                <div
                  className={classNames(
                    'cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                    activeTab === tabValue && 'border-indigo-500 text-indigo-600',
                    activeTab !== tabValue &&
                      'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  )}
                  onClick={() => setActiveTab(tabValue)}
                  key={tabValue}
                >
                  <span className="flex items-center gap-2">
                    {t('components.project.template.layer.LayerParametrizeTabs.title', { context: tabValue })}
                    {!!numberOfScripts && tabValue === LayerParametrizeTabsValue.SCRIPTING && (
                      <div
                        className={classNames(
                          'flex h-5 w-5 items-center justify-center rounded-full border',
                          activeTab === tabValue ? 'border-indigo-500' : 'borer-gray-200 hover:border-gray-300'
                        )}
                      >
                        <span className="text-xs">{numberOfScripts}</span>
                      </div>
                    )}
                  </span>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};
