import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScrollingPage } from '@src/components';
import { DesignDetails } from '@src/components/designs/DesignDetails';

export const DesignDetailsPage = () => {
  const { t } = useTranslation();
  const { designId } = useParams() as { designId: string };

  return (
    <HelmetProvider>
      <ScrollingPage>
        <Helmet>
          <title>{t('general.pageTitle.DesignDetailsPage')}</title>
        </Helmet>
        <DesignDetails designId={designId} />
      </ScrollingPage>
    </HelmetProvider>
  );
};
