import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import { Article, CreateWebArticleDto } from '@src/models';

import { useAxiosModify, useAxiosRead } from '../useAxios';

const ARTICLES_CACHE_ROOT = 'articles';

export const articlesCacheAdd = (client: QueryClient, article: Article) => {
  client.setQueryData<Article[]>(ARTICLES_CACHE_ROOT, articles => (articles ? [...articles, article] : [article]));
  client.setQueryData([ARTICLES_CACHE_ROOT, article.id], article);
};

export const useGetArticles = (brandId: string, enabled = true) => {
  const { get } = useAxiosRead<Article[]>();

  const cacheKey = [ARTICLES_CACHE_ROOT, brandId];

  const { data, isLoading, error, refetch } = useQuery<Article[]>(
    cacheKey,
    async () => {
      const response = await get(`/video-genius/brands/${brandId}/articles`);
      return response.data;
    },
    {
      enabled
    }
  );

  return { data, isLoading, error, refetch };
};

type GetArticlesParams = {
  page?: number;
  size?: number;
};

export const useGetInfiniteArticles = (brandId: string, params: GetArticlesParams) => {
  const { get } = useAxiosRead<Article[]>();

  const cacheKey = [ARTICLES_CACHE_ROOT, brandId, params];

  const fetchArticles = async (page?: number) => {
    params.page = page && page - 1;
    const response = await get(`/video-genius/brands/${brandId}/articles`, { params });
    return response.data;
  };

  const { data, isLoading, isSuccess, error, refetch, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery<Article[]>(cacheKey, ({ pageParam }) => fetchArticles(pageParam), {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage.length === params.size ? allPages.length + 1 : undefined;

        return nextPage;
      }
    });

  return { data, isLoading, isSuccess, error, refetch, hasNextPage, fetchNextPage, isFetchingNextPage };
};

export const useGetArticleDetails = (brandId: string | undefined, articleId: string | undefined) => {
  const { get } = useAxiosRead<Article>();

  const cacheKey = [ARTICLES_CACHE_ROOT, brandId, articleId];

  const { data, isLoading, error } = useQuery<Article>(
    cacheKey,
    async () => {
      const response = await get(`/video-genius/brands/${brandId}/articles/${articleId}`);
      return response.data;
    },
    {
      enabled: !!brandId && !!articleId
    }
  );

  return { data, isLoading, error };
};

export const useCreateWebArticle = () => {
  const { post } = useAxiosModify<CreateWebArticleDto, Article>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ brandId, article }: { brandId: string; article: CreateWebArticleDto }) => {
      const response = await post(`/video-genius/brands/${brandId}/articles`, article);
      return response.data;
    },
    onSuccess: article => {
      articlesCacheAdd(queryClient, article);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useDeleteArticle = () => {
  const { delete: deleteArticle } = useAxiosModify<void, void>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async ({ brandId, articleId }: { brandId: string; articleId: string }) => {
      const response = await deleteArticle(`/video-genius/brands/${brandId}/articles/${articleId}`);

      return response.data;
    },
    onSuccess: (_, { brandId, articleId }) => {
      queryClient.setQueryData<Article[]>(
        [ARTICLES_CACHE_ROOT, brandId],
        articles => articles?.filter(article => article.id !== articleId) ?? []
      );
      queryClient.removeQueries([ARTICLES_CACHE_ROOT, brandId, articleId]);
    }
  });

  return { mutateAsync, isLoading, error };
};
