import { useTranslation } from 'react-i18next';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useHide } from '@src/hooks';

type WideSlideoverProps = {
  visible: boolean;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  onClose: () => void;
  unmount?: boolean;
};

export const WideSlideover = ({
  visible,
  title,
  subtitle,
  onClose,
  unmount = true,
  children
}: React.PropsWithChildren<WideSlideoverProps>) => {
  const { t } = useTranslation();

  const completelyHidden = useHide(visible, 300);

  return (
    <Dialog open={!completelyHidden && visible} onClose={onClose} className="relative z-10" unmount={unmount}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-2xl transform transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    {title && (
                      <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                        {title}
                      </h2>
                    )}
                    {!title && <div />}
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        onClick={onClose}
                        className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="sr-only">{t('components.wideSlideover.closePanel')}</span>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  {subtitle && (
                    <div className="mt-1">
                      <p className="text-sm text-indigo-300">{subtitle}</p>
                    </div>
                  )}
                </div>
                <div className="relative flex-1">{children}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
