import { useState } from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import { useParams } from 'react-router-dom';

import { Loading } from '@src/components';
import { PaddleProductCheckout } from '@src/components/payment/PaddleProductCheckout';

const setupPaddle = () => {
  if (import.meta.env.VITE_APP_PADDLE_SANDBOX === 'true') {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Environment.set('sandbox');
  }
  if (import.meta.env.VITE_APP_PADDLE_VENDOR_ID) {
    // @ts-expect-error Cannot find name 'Paddle'.
    Paddle.Setup({ vendor: parseInt(import.meta.env.VITE_APP_PADDLE_VENDOR_ID) });
  }
};

export const CustomPaymentPage = () => {
  // script loader
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const PaddleScriptLoader = makeAsyncScriptLoader('https://cdn.paddle.com/paddle/paddle.js')(Loading);

  const { productId } = useParams() as { productId: string };

  return (
    <>
      {!scriptLoaded && (
        <>
          <PaddleScriptLoader
            asyncScriptOnLoad={() => {
              setupPaddle();
              setScriptLoaded(true);
            }}
          />
        </>
      )}
      {scriptLoaded && (
        <>
          <PaddleProductCheckout productId={productId} />
        </>
      )}
    </>
  );
};
