import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { VideoSidebarOptions } from '@src/components';
import { AnyVideoRevision, ArticleVideoScene } from '@src/models';

export const VideoSidebar = ({
  originalArticleUrl,
  revisions,
  currentRevision,
  showVideoDialog,
  setShowVideoDialog,
  scenes
}: {
  originalArticleUrl: string;
  revisions: AnyVideoRevision[];
  currentRevision: AnyVideoRevision;
  showVideoDialog: boolean;
  setShowVideoDialog: (showVideoDialog: boolean) => void;
  scenes?: ArticleVideoScene[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="block lg:hidden">
        <Transition show={showVideoDialog} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={() => setShowVideoDialog(false)}>
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div className="fixed inset-0 z-40 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {t('components.article.ArticleVideo.videoOptions')}
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setShowVideoDialog(false)}
                    >
                      <span className="sr-only">{t('general.action.closeMenu')}</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-4 space-y-8 border-t border-gray-200">
                    <VideoSidebarOptions
                      originalArticleUrl={originalArticleUrl}
                      revisions={revisions}
                      currentRevision={currentRevision}
                      setShowVideoDialog={setShowVideoDialog}
                      scenes={scenes}
                    />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      </div>
      <div className="fixed right-0 top-0 ml-auto hidden h-0 lg:block lg:h-screen lg:border-l lg:border-gray-300">
        <div className="w-72 space-y-12 xl:w-80">
          <VideoSidebarOptions
            originalArticleUrl={originalArticleUrl}
            revisions={revisions}
            currentRevision={currentRevision}
            setShowVideoDialog={setShowVideoDialog}
            scenes={scenes}
          />
        </div>
      </div>
    </>
  );
};
