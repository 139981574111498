import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { Button, CommonVideoTypeInputs, SceneBasedVideoTypeSettings, VoiceoverFormInputs } from '@src/components';
import { useEditBrand, useGetBrandDetails, useValidateUrls } from '@src/hooks';
import {
  Brand,
  CommonVideoSettings,
  DesignCategory,
  StockAssetPreference,
  SummaryProviderType,
  SummaryVideoSettings,
  VideoType,
  VoiceoverProviderType
} from '@src/models';
import * as routes from '@src/routes';
import { deepEqual, omitKeys, transformNullsToUndefined } from '@src/utils';

export const SummarySlideshowTypeForm = ({ brand, videoType }: { brand: Brand; videoType?: SummaryVideoSettings }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, mutateAsync: editVideoTypes } = useEditBrand();
  const { refetch: refetchBrand } = useGetBrandDetails(brand.id, false);

  const [inputs, setInputs] = useState<SummaryVideoSettings>({
    videoType: VideoType.SUMMARY_SLIDESHOW,
    projectId: videoType?.projectId || '',
    templateId: videoType?.templateId || '',
    design: videoType?.design || false,
    enabled: videoType?.enabled || true,
    targetLanguage: videoType?.targetLanguage || brand.settings.language,
    stockAssetSettings: {
      assetPreference: videoType?.stockAssetSettings.assetPreference || StockAssetPreference.VIDEO
    },
    summaryProviderSettings: {
      type: videoType?.summaryProviderSettings.type || SummaryProviderType.CHAT_GPT,
      writingStyle: videoType?.summaryProviderSettings.writingStyle || undefined
    },
    voiceoverProviderSettings: {
      type: videoType?.voiceoverProviderSettings.type || VoiceoverProviderType.GCP,
      audioConfigPitch: videoType?.voiceoverProviderSettings.audioConfigPitch || undefined,
      audioConfigSpeakingRate: videoType?.voiceoverProviderSettings.audioConfigSpeakingRate || undefined,
      voiceName: videoType?.voiceoverProviderSettings.voiceName || undefined
    },
    defaultLogoUrl: videoType?.defaultLogoUrl || brand.settings.logoUrl,
    defaultCta: videoType?.defaultCta || ''
  });

  const [badProvider, setBadProvider] = useState(false);
  const { urlsValid, handleInvalidUrls } = useValidateUrls();
  const isValidForm =
    inputs.projectId !== '' && inputs.templateId !== '' && inputs.targetLanguage && !badProvider && urlsValid;
  const canFireUpdate = isValidForm && !isLoading;

  const editMode = videoType !== undefined;
  const canSaveEdit = useMemo(
    () => editMode && !deepEqual(transformNullsToUndefined(omitKeys(videoType, ['id'])), inputs),
    [editMode, inputs, videoType]
  );

  const disabled = editMode ? !(canFireUpdate && canSaveEdit) : !canFireUpdate;

  const fireUpdate = async () => {
    const freshBrand = (await refetchBrand()).data;
    if (freshBrand) {
      let newVideoType = null;
      if (editMode) {
        const index = freshBrand.settings.videoTypes.findIndex(vt => vt.id === videoType?.id);
        if (index < 0) return;

        // Check if defaultLogoUrl is provided or not, if not then use the brand's logoUrl
        if (!inputs.defaultLogoUrl) {
          freshBrand.settings.videoTypes[index].defaultLogoUrl = freshBrand.settings.logoUrl;
        }

        freshBrand.settings.videoTypes[index] = inputs;
        newVideoType = await editVideoTypes({ brandId: freshBrand.id, settings: freshBrand.settings });
      } else {
        // Check if defaultLogoUrl is provided or not, if not then use the brand's logoUrl
        if (!inputs.defaultLogoUrl) {
          inputs.defaultLogoUrl = freshBrand.settings.logoUrl;
        }

        freshBrand.settings.videoTypes.push(inputs);
        newVideoType = await editVideoTypes({ brandId: freshBrand.id, settings: freshBrand.settings });
      }

      if (newVideoType) navigate(generatePath(routes.BRAND_DETAILS, { id: newVideoType.id }));
    }
  };

  const onUpdateCommonSettings = useCallback((commonSettings: (prev: CommonVideoSettings) => CommonVideoSettings) => {
    setInputs(prev => ({ ...prev, ...commonSettings(prev) }));
  }, []);

  return (
    <form
      className="space-y-12"
      onSubmit={e => {
        e.preventDefault();
        fireUpdate();
      }}
    >
      <div className="bg-white py-5 shadow sm:rounded-lg sm:py-6">
        <div className="grid gap-12 md:grid-cols-3">
          <div className="border-b border-gray-300 pb-6 md:col-span-3">
            <h3 className="px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6">
              {t('components.publishers.common.summarySlideshow')}
            </h3>
            <p className="mt-1 px-4 text-sm text-gray-500 sm:px-6">
              {t('components.brand.videoTypes.modal.summarySlideshowDescription')}
            </p>
          </div>
          <div className="mx-4 grid gap-6 border-b border-dashed border-gray-300 pb-12 sm:mx-6 md:col-span-3 md:grid-cols-3">
            <CommonVideoTypeInputs
              designCategory={DesignCategory.SUMMARY}
              inputs={inputs}
              onUpdate={onUpdateCommonSettings}
              onSelectionChange={item =>
                setInputs({
                  ...inputs,
                  targetLanguage: item?.code as string
                })
              }
            />
          </div>
          <SceneBasedVideoTypeSettings
            inputs={inputs}
            setInputs={commonSettings => setInputs({ ...inputs, ...commonSettings })}
            handleInvalidUrls={handleInvalidUrls}
          />
          <div className="grid gap-6 px-4 pb-6 sm:px-6 md:col-span-3 md:grid-cols-3">
            <div className="md:col-span-1">
              <h3 className="text-md font-normal leading-6 text-gray-900">
                {t('components.publishers.forms.voiceover.voiceoverProviderTitle')}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t('components.publishers.forms.voiceover.voiceoverProviderDescription')}
              </p>
            </div>
            <div className="space-y-6 md:col-span-2">
              <VoiceoverFormInputs
                inputs={{ ...inputs.voiceoverProviderSettings }}
                setInputs={voiceoverProviderSettings =>
                  setInputs({
                    ...inputs,
                    voiceoverProviderSettings: {
                      ...inputs.voiceoverProviderSettings,
                      ...voiceoverProviderSettings
                    }
                  })
                }
                selectedLang={inputs.targetLanguage}
                onLanguageChange={setBadProvider}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {navigate.length > 0 && (
          <Button secondary={true} onClick={() => navigate(-1)}>
            {t('general.action.back')}
          </Button>
        )}
        <Button className="ml-3" type="submit" loading={isLoading} disabled={disabled}>
          {editMode ? t('general.action.update') : t('general.action.create')}
        </Button>
      </div>
    </form>
  );
};
