import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Loading } from '@src/components';
import { WARNING_SVG_SRC } from '@src/icons';

export const VideoPlayerError = ({ title }: { title: string }) => (
  <div className="mt-6 inline-flex h-full w-full grow flex-col place-content-center items-center">
    <div className="flex flex-col items-center">
      <img className="h-56 w-56 md:h-64 md:w-64" src={WARNING_SVG_SRC} alt="Empty"></img>
      <span className="my-6">{title}</span>
    </div>
  </div>
);

export type VideoPlayerProps = {
  src: string;
  srcWatermark?: string;
};

export const VideoPlayer = ({ src, srcWatermark, ...rest }: VideoPlayerProps) => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [showWatermark, setShowWatermark] = useState(false);

  const isGif = src.endsWith('gif');
  const hasWatermark = !!srcWatermark;

  const onLoadError = () => {
    setIsError(true);
    setIsLoading(false);
  };
  const onLoaded = () => setIsLoading(false);

  return (
    <>
      {isError && <VideoPlayerError title={t('components.videoPlayer.VideoPlayer.error')} />}
      {loading && <Loading />}
      {!isError && (
        <div className="relative flex justify-center overflow-hidden rounded-md bg-gray-900">
          {isGif && (
            <img
              className={classNames('rounded-md', loading && 'hidden')}
              src={showWatermark ? srcWatermark : src}
              alt=""
              onError={onLoadError}
              onLoad={onLoaded}
            />
          )}
          {!isGif && (
            <video
              className={classNames('rounded-md', loading && 'hidden')}
              controls
              {...rest}
              onError={onLoadError}
              onLoadedData={onLoaded}
              src={showWatermark ? srcWatermark : src}
              preload="true"
            >
              {t('components.videoPlayer.VideoPlayer.notSupported')}
            </video>
          )}
          {hasWatermark && (
            <Button
              small
              secondary
              className="absolute right-3 top-3 text-xs opacity-50 focus:ring-0 focus:ring-offset-0"
              type="button"
              onClick={e => {
                e.stopPropagation();
                setShowWatermark(!showWatermark);
              }}
            >
              {t('components.videoPlayer.VideoPlayer.watermarkShown', { context: `${showWatermark}` })}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
