import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowsPointingInIcon, Square2StackIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useBreakpoint, useVideoNavigationParams } from '@src/hooks';
import { ArticleVideoScene } from '@src/models';

export const SceneActions = ({
  scenes,
  setScenes,
  disabled
}: {
  scenes: ArticleVideoScene[];
  setScenes: (scenes: ArticleVideoScene[]) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();
  const isSmallScreen = useBreakpoint('sm');

  const activeView = videoNavigationState.activeView;
  const sceneId = activeView === 'scenes' && videoNavigationState.sceneId;
  const currentSceneIndex = sceneId ? sceneId - 1 : 0;

  const duplicateScene = () => {
    setScenes([
      ...scenes.slice(0, currentSceneIndex + 1),
      scenes[currentSceneIndex],
      ...scenes.slice(currentSceneIndex + 1)
    ]);

    updateVideoNavigationState({
      revisionId: videoNavigationState.revisionId,
      activeView: 'scenes',
      sceneId: currentSceneIndex + 2,
      headlinesTab: 1,
      assetsTab: 'suggested'
    });
  };

  const mergeScenes = () => {
    const currentScene = scenes[currentSceneIndex];
    const nextScene = scenes[currentSceneIndex + 1];

    const mergedScene = {
      ...currentScene,
      script: `${currentScene.script} ${nextScene.script}`,
      headlines: [...currentScene.headlines, ...nextScene.headlines]
    };

    setScenes([...scenes.slice(0, currentSceneIndex), mergedScene, ...scenes.slice(currentSceneIndex + 2)]);
  };

  const deleteScene = () => {
    setScenes([...scenes].filter((_, index) => index !== currentSceneIndex));

    updateVideoNavigationState({
      revisionId: videoNavigationState.revisionId,
      activeView: 'scenes',
      sceneId: sceneId && sceneId > 1 ? sceneId - 1 : 1,
      headlinesTab: 1,
      assetsTab: 'suggested'
    });
  };

  type ActionType = {
    action: () => void;
    label: string;
    icon: ReactElement;
    disabled: boolean;
    tooltip: string;
  };

  const actions: ActionType[] = [
    {
      action: duplicateScene,
      label: t('components.article.ArticleVideo.sceneSettings.sceneActions.duplicateScene'),
      icon: <Square2StackIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />,
      disabled: false,
      tooltip: t('components.article.ArticleVideo.sceneSettings.sceneActions.duplicateSceneTooltip')
    },
    {
      action: mergeScenes,
      label: t('components.article.ArticleVideo.sceneSettings.sceneActions.mergeScenes'),
      icon: <ArrowsPointingInIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />,
      disabled: scenes.length === 1 || currentSceneIndex === scenes.length - 1,
      tooltip: t('components.article.ArticleVideo.sceneSettings.sceneActions.mergeScenesTooltip')
    },
    {
      action: deleteScene,
      label: t('components.article.ArticleVideo.sceneSettings.sceneActions.deleteScene'),
      icon: <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />,
      disabled: scenes.length === 1,
      tooltip: t('components.article.ArticleVideo.sceneSettings.sceneActions.deleteSceneTooltip')
    }
  ];

  return (
    <div className="border-b border-gray-300 px-4 py-3 sm:rounded-t-md">
      <div className="flex items-center gap-1 text-sm font-medium text-gray-700">
        {!isSmallScreen && <p className="mr-1">Scenes:</p>}
        {actions.map((action, index) => (
          <button
            disabled={disabled || action.disabled}
            key={index}
            type="button"
            className="group flex w-fit items-center gap-1 rounded-md p-1 hover:bg-gray-100 hover:text-gray-900 disabled:cursor-not-allowed disabled:opacity-50"
            onClick={action.action}
            title={action.tooltip}
          >
            {action.icon}
            {isSmallScreen && action.label}
          </button>
        ))}
      </div>
    </div>
  );
};
