import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { arrayMove } from '@dnd-kit/sortable';
import {
  Button,
  HelpNotification,
  LayerAvailableList,
  LayerFilter,
  LayerParametrize,
  LayerParametrizedList,
  LayerTabs,
  ParametrizationHelpLinks
} from '@src/components';
import { AnyAeItem, CompositionAeItem, CompositionIdentifier, Layer, Template } from '@src/models';

import { collectLayers } from './utils';

export type TemplateLayersFormProps = {
  edit?: boolean;
  projectId: string;
  template: Partial<Template>;
  composition: CompositionAeItem;
  onSave: () => void;
  onUpdate: (layers: Partial<Layer>[]) => void;
  saveInProgress?: boolean;
  onBack: () => void;
};

export type AeItemWithCompositions = {
  layer: AnyAeItem;
  compositions: CompositionIdentifier[][];
};

export type EditableAeItemWithCompositions = AeItemWithCompositions & {
  plainlyLayer?: Layer;
};

export type ParametrizedItem = {
  layer: Partial<Layer>;
  aeItem: AnyAeItem | undefined;
};

export const TemplateLayersForm = ({
  projectId,
  template,
  composition,
  onSave,
  onUpdate: onLayersUpdate,
  saveInProgress,
  onBack
}: TemplateLayersFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parametrizedLayers = useMemo(() => template.layers || [], [template.layers]);
  const [showAvailable, setShowAvailable] = useState(true);
  const [inParametrization, setInParametrization] = useState<EditableAeItemWithCompositions | undefined>();

  const [filterFunction, setFilterFunction] = useState<(ae: AeItemWithCompositions) => boolean>(() => () => true);
  const onFilterChange = useCallback((f: (ae: AeItemWithCompositions) => boolean) => setFilterFunction(() => f), []);

  const [inEditParametrization, setInEditParametrization] = useState<Partial<Layer> | undefined>();

  const allLayers = collectLayers(composition);
  const filteredLayers = useMemo(() => allLayers.filter(filterFunction), [allLayers, filterFunction]);
  const onItemClick = (item: AeItemWithCompositions) => setInParametrization(item);

  // Should this check be something else? Or just remove completely?
  const canSave = true;

  const getCompositions = (layer: Partial<Layer>) => {
    const aeItem = allLayers.find(l => layer.internalId === l.layer.internalId);
    return {
      ...aeItem,
      plainlyLayer: layer
    } as EditableAeItemWithCompositions;
  };

  const parametrizedItems = useMemo(() => {
    return parametrizedLayers.map(layer => {
      const aeItem = allLayers.find(l => layer.internalId === l.layer.internalId);
      return {
        layer,
        aeItem: aeItem?.layer
      };
    });
  }, [parametrizedLayers, allLayers]);

  const onDragAndDrop = useCallback(
    (oldIndex: number, newIndex: number) => onLayersUpdate(arrayMove(parametrizedLayers, oldIndex, newIndex)),
    [onLayersUpdate, parametrizedLayers]
  );

  return (
    <>
      <div className="flex flex-col space-y-6 md:h-full">
        <div className="flex flex-col overflow-y-hidden bg-white shadow sm:rounded-lg md:h-full">
          <div>
            <div className="flex flex-wrap items-center justify-between px-4 py-5 sm:flex-nowrap sm:p-6">
              <div>
                <h3 className="flex text-lg font-medium leading-6 text-gray-900">
                  {t('components.project.template.TemplateLayersForm.title')}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t('components.project.template.TemplateLayersForm.description')}
                </p>
              </div>
              <div className="mt-2 shrink-0 sm:ml-4">
                <HelpNotification type="button" links={ParametrizationHelpLinks} />
              </div>
            </div>
            <LayerFilter onChange={onFilterChange} />
            <LayerTabs
              availableShown={showAvailable}
              onAvailableShownChange={setShowAvailable}
              availableLayers={allLayers.length}
              parametrizedLayers={parametrizedLayers.length}
            />
          </div>
          {showAvailable && (
            <LayerAvailableList
              projectId={projectId}
              items={filteredLayers}
              onItemClick={onItemClick}
              parametrizedLayers={parametrizedLayers}
            />
          )}
          {!showAvailable && (
            <LayerParametrizedList
              projectId={projectId}
              template={template}
              parametrizedItems={parametrizedItems}
              onDelete={layer => onLayersUpdate(parametrizedLayers.filter(l => l !== layer))}
              onEdit={layer => setInEditParametrization(layer)}
              onDragAndDrop={onDragAndDrop}
            />
          )}
        </div>
        <div className="flex justify-end">
          {navigate.length > 0 && (
            <Button
              secondary={true}
              onClick={() => {
                onBack();
                navigate(-1);
              }}
            >
              {t('general.action.back')}
            </Button>
          )}
          <Button className="ml-3" type="submit" loading={saveInProgress} disabled={!canSave} onClick={onSave}>
            {t('general.action.save')}
          </Button>
        </div>
      </div>
      <LayerParametrize
        item={inEditParametrization ? getCompositions(inEditParametrization) : inParametrization}
        editMode={inEditParametrization !== undefined}
        onHide={() => {
          setInParametrization(undefined);
          setInEditParametrization(undefined);
        }}
        onSave={layer => onLayersUpdate([...parametrizedLayers, layer])}
        onUpdate={layer => {
          const newState = parametrizedLayers.map(l => {
            if (l === inEditParametrization) {
              return layer;
            } else {
              return l;
            }
          });
          onLayersUpdate(newState);
        }}
      />
    </>
  );
};
