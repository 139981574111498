import classNames from 'classnames';

import './style.css';

export type LoadingProps = {
  title?: string;
  className?: string;
  small?: boolean;
};

export const Loading = ({ title, className, small }: LoadingProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center',
        !small && ' h-full overflow-hidden',
        className
      )}
    >
      <div className="pl-loader"></div>
      {title && <div>{title}</div>}
    </div>
  );
};
