import { useTranslation } from 'react-i18next';

import { MediaInput, PlainlyCombobox, RequiredMarker } from '@src/components';
import { SceneBasedVideoSettings, StockAssetPreference, SummaryProviderType } from '@src/models';

type SceneBasedVideoTypeSettingsProps = {
  inputs: SceneBasedVideoSettings;
  setInputs: (inputs: SceneBasedVideoSettings) => void;
  handleInvalidUrls: (key: string) => (url: string, valid: boolean) => void;
};

export const SceneBasedVideoTypeSettings = ({
  inputs,
  setInputs,
  handleInvalidUrls
}: SceneBasedVideoTypeSettingsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mx-4 grid gap-6 border-b border-dashed border-gray-300 pb-12 sm:mx-6 md:col-span-3 md:grid-cols-3">
        <div className="md:col-span-1">
          <h3 className="text-md font-normal leading-6 text-gray-900">
            {t('components.publishers.common.generalSettings')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('components.brand.videoTypes.commonInputs.generalSettingsDescription')}
          </p>
        </div>
        <div className="space-y-6 md:col-span-2">
          <div>
            <label htmlFor="defaultCta" className="block text-sm font-medium text-gray-700">
              {t('components.brand.videoTypes.commonInputs.defaultCtaLabel')}
              <p className="text-sm font-normal text-gray-500">
                {t('components.brand.videoTypes.commonInputs.defaultCtaDescription')}
              </p>
            </label>
            <input
              type="text"
              name="defaultCta"
              id="defaultCta"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={e =>
                setInputs({
                  ...inputs,
                  defaultCta: e.target.value
                })
              }
              defaultValue={inputs.defaultCta}
              placeholder={t('components.brand.videoTypes.commonInputs.defaultCtaPlaceholder')}
            />
          </div>
          <div>
            <label htmlFor="defaultLogoUrl" className="block text-sm font-medium text-gray-700">
              {t('components.publishers.forms.logoUrl')}
              <p className="text-sm font-normal text-gray-500">
                {t('components.brand.videoTypes.commonInputs.defaultLogoUrlDescription')}
              </p>
            </label>
            <div className="mt-1">
              <MediaInput
                onFieldUpdate={value => setInputs({ ...inputs, defaultLogoUrl: value })}
                value={inputs.defaultLogoUrl}
                onValidation={handleInvalidUrls('defaultLogoUrl')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 grid gap-6 border-b border-dashed border-gray-300 pb-12 sm:mx-6 md:col-span-3 md:grid-cols-3">
        <div className="md:col-span-1">
          <h3 className="text-md font-normal leading-6 text-gray-900">
            {t('components.brand.videoTypes.commonInputs.stockAssetsTitle')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('components.brand.videoTypes.commonInputs.stockAssetsDescription')}
          </p>
        </div>
        <div className="space-y-6 md:col-span-2">
          <div>
            <label htmlFor="assetPreference" className="block text-sm font-medium text-gray-700">
              {t('components.brand.videoTypes.commonInputs.assetPreference')}
              <RequiredMarker />
              <p className="text-sm font-normal text-gray-500">
                {t('components.brand.videoTypes.commonInputs.assetPreferenceDescription')}
              </p>
            </label>
            <PlainlyCombobox
              className="mt-1"
              data={Object.values(StockAssetPreference).map(preference => ({
                name: preference,
                item: preference,
                label: t('components.publishers.common.type', { context: preference }),
                selected: preference === inputs.stockAssetSettings?.assetPreference
              }))}
              clearDisabled
              onSelectionChange={item =>
                setInputs({
                  ...inputs,
                  stockAssetSettings: {
                    ...inputs.stockAssetSettings,
                    assetPreference: item as StockAssetPreference
                  }
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="mx-4 grid gap-6 border-b border-dashed border-gray-300 pb-12 sm:mx-6 md:col-span-3 md:grid-cols-3">
        <div className="md:col-span-1">
          <h3 className="text-md font-normal leading-6 text-gray-900">
            {t('components.brand.videoTypes.commonInputs.summaryProviderTitle')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('components.brand.videoTypes.commonInputs.summaryProviderDescription')}
          </p>
        </div>
        <div className="space-y-6 md:col-span-2">
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
              {t('components.publishers.common.provider')}
              <RequiredMarker />
              <p className="text-sm font-normal text-gray-500">
                {t('components.brand.videoTypes.commonInputs.summaryProviderDescription')}
              </p>
            </label>
            <PlainlyCombobox
              className="mt-1"
              data={Object.values(SummaryProviderType).map(type => ({
                name: type,
                item: type,
                label: t('components.brand.videoTypes.commonInputs.type', { context: type }),
                selected: type === inputs.summaryProviderSettings?.type
              }))}
              clearDisabled
              onSelectionChange={item =>
                setInputs({
                  ...inputs,
                  summaryProviderSettings: {
                    ...inputs.summaryProviderSettings,
                    type: item as SummaryProviderType
                  }
                })
              }
            />
          </div>
          <div>
            <label htmlFor="writingStyle" className="block text-sm font-medium text-gray-700">
              {t('components.brand.videoTypes.commonInputs.writingStyle')}
              <p className="text-sm font-normal text-gray-500">
                {t('components.brand.videoTypes.commonInputs.writingStyleDescription')}
              </p>
            </label>
            <textarea
              id="writingStyle"
              name="writingStyle"
              rows={6}
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm placeholder:text-gray-400 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={e =>
                setInputs({
                  ...inputs,
                  summaryProviderSettings: {
                    ...inputs.summaryProviderSettings,
                    writingStyle: e.target.value
                  }
                })
              }
              placeholder={t('components.brand.videoTypes.commonInputs.writingStylePlaceholder')}
              defaultValue={inputs.summaryProviderSettings?.writingStyle}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};
