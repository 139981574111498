import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AppmixerAccounts, AppmixerProvider, Breadcrumb, BreadcrumbItem, ScrollingPage } from '@src/components';
import * as routes from '@src/routes';

export const AppmixerAccountsPage = () => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.AppmixerAccountsPage')}</title>
        </Helmet>
        <div>
          <Breadcrumb>
            <BreadcrumbItem to={routes.INTEGRATIONS} label={t('general.common.integrations')} />
            <BreadcrumbItem
              to={routes.INTEGRATIONS_ACCOUNTS}
              label={t('components.appmixer.AppmixerAccountsPage.title')}
            />
          </Breadcrumb>
        </div>
        <AppmixerProvider>
          <AppmixerAccounts />
        </AppmixerProvider>
      </ScrollingPage>
    </HelmetProvider>
  );
};
