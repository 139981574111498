import { ModelBaseWithDates } from '..';

import { PresenterRevision, SocialMediaRevision, SummarySlideshowRevision, VideoRevision } from './revision';
import {
  PresenterVideoSettings,
  SocialMediaVideoSettings,
  SummaryVideoSettings,
  VideoSettings,
  VideoType
} from './videos';

type VideoBase = ModelBaseWithDates & {
  articleId: string;
  brandId: string;
};

export type ArticleVideo = VideoBase & {
  data: AnyVideoData;
};

export type VideoData<T extends VideoType> = {
  videoType: T;
  revisions: VideoRevision<T>[];
  settings: VideoSettings<T>;
};

export enum VideoDataErrorCode {
  SUMMARY_FAILED = 'SUMMARY_FAILED'
}

export type VideoDataError = {
  message: string;
  code: VideoDataErrorCode;
};

export type SummaryArticleData = VideoData<VideoType.SUMMARY_SLIDESHOW> & {
  revisions: SummarySlideshowRevision[];
  settings: SummaryVideoSettings;
  videoDataError?: VideoDataError;
};
export type SocialMediaArticleData = VideoData<VideoType.SOCIAL_MEDIA> & {
  revisions: SocialMediaRevision[];
  settings: SocialMediaVideoSettings;
  videoDataError?: never;
};
export type PresenterArticleData = VideoData<VideoType.PRESENTER> & {
  revisions: PresenterRevision[];
  settings: PresenterVideoSettings;
  videoDataError?: VideoDataError;
};

export type AnyVideoData = SummaryArticleData | SocialMediaArticleData | PresenterArticleData;
