import { ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Badge } from '@src/components';

export type HeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  header: string;
  actions?: ReactNode;
  subheader?: string;
  beta?: boolean;
  logo?: string;
};

export const Header = ({ header, actions, subheader, beta, className, logo, ...rest }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div {...rest} className={classnames('md:flex md:items-center md:justify-between', className)}>
        <div className="min-w-0 flex-1">
          <div className="flex items-center gap-4">
            {logo && (
              <img src={logo} alt={header} className="h-12 w-12 flex-none rounded-full bg-gray-50 object-contain" />
            )}
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              {header}
              {beta && <Badge label={t('general.common.beta')} type="beta" pill />}
            </h2>
          </div>
          {subheader && <p className="mt-1 text-sm text-gray-500">{subheader}</p>}
        </div>
        {actions && <div className="mt-4 flex md:ml-4 md:mt-0 md:block">{actions}</div>}
      </div>
    </>
  );
};
