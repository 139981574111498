import { useCallback } from 'react';

const STORAGE_KEY = 'plainly.active-organization-id';

export const useActiveOrganizationId = () => {
  const getActiveOrganizationId = useCallback(() => {
    const activeOrganizationId = window.localStorage.getItem(STORAGE_KEY);
    return activeOrganizationId;
  }, []);

  const setActiveOrganizationId = useCallback((id: string) => {
    window.localStorage.setItem(STORAGE_KEY, id);
  }, []);

  const removeActiveOrganizationId = useCallback(() => {
    window.localStorage.removeItem(STORAGE_KEY);
  }, []);

  return { getActiveOrganizationId, setActiveOrganizationId, removeActiveOrganizationId };
};
