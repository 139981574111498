import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Clipboard } from 'ts-clipboard';

import { useNotifications } from '@src/hooks';

import { Button } from '../button';
import { Modal } from '../modal';

type CodeModalProps = {
  content: string;
  showModal: boolean;
  onClose: () => void;
  showCopyAction?: boolean;
  size: 'md' | 'lg';
  wide?: boolean;
};

const CodeModalContent = ({ content, onClose, showCopyAction, size, wide }: Omit<CodeModalProps, 'showModal'>) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();
  const copyToClipboard = () => {
    Clipboard.copy(content);
    notifyInfo(t('components.common.CodeModal.copiedNotification'));
  };

  return (
    <>
      <div className={classNames('prose', wide && 'min-w-full')}>
        <pre className={classNames(size === 'md' ? 'max-h-96' : 'max-h-full', 'w-full')}>
          <code>{content}</code>
        </pre>
      </div>
      <div className="mt-5 flex justify-end sm:mt-6">
        <Button secondary onClick={onClose}>
          {t('general.action.dismiss')}
        </Button>
        {showCopyAction && (
          <Button
            onClick={() => {
              copyToClipboard();
              onClose();
            }}
            className="ml-2"
          >
            {t('general.action.copyToClipboard')}
          </Button>
        )}
      </div>
    </>
  );
};

export const CodeModal = ({ content, showModal, onClose, showCopyAction, size, wide }: CodeModalProps) => {
  return (
    <Modal visible={showModal} close={onClose} size="normal">
      <CodeModalContent content={content} onClose={onClose} showCopyAction={showCopyAction} size={size} wide={wide} />
    </Modal>
  );
};
