import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { RenderCancelModal, RendersHeader, RendersPreview, ScrollingPage } from '@src/components';

export const RendersPreviewPage = () => {
  const [showCancelRenderModal, setShowCancelRenderModal] = useState(false);
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.RendersPreviewPage')}</title>
        </Helmet>
        <RendersHeader setShowCancelRenderModal={setShowCancelRenderModal} />
        <RendersPreview />
      </ScrollingPage>
      <RenderCancelModal showModal={showCancelRenderModal} setShowModal={setShowCancelRenderModal} />
    </HelmetProvider>
  );
};
