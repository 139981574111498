import { useState } from 'react';

import { DesignsAsideMenu, DesignsGallery } from '@src/components';
import { ColorPaletteGroup, Design, DesignAspectRatio, DesignsFilter } from '@src/models';
import { isEmpty } from '@src/utils';

export type DesignsMainSectionProps = {
  designs: Design[];
  filters: DesignsFilter[];
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (open: boolean) => void;
};

export const DesignsMainSection = ({
  designs,
  filters,
  mobileFiltersOpen,
  setMobileFiltersOpen
}: DesignsMainSectionProps) => {
  const [selectedColors, setSelectedColors] = useState<ColorPaletteGroup[]>([]);
  const addSelectedColor = (color: ColorPaletteGroup) => {
    const oldState = selectedColors;
    const newState = [...oldState, color];
    setSelectedColors(newState);
  };
  const removeSelectedColor = (color: ColorPaletteGroup) => {
    const oldState = selectedColors;
    const newState = oldState.filter(c => c !== color);
    setSelectedColors(newState);
  };
  const [selectedAspectRatios, setSelectedAspectRatios] = useState<DesignAspectRatio[]>([]);
  const addSelectedAspectRatio = (aspectRatio: DesignAspectRatio) => {
    const oldState = selectedAspectRatios;
    const newState = [...oldState, aspectRatio];
    setSelectedAspectRatios(newState);
  };
  const removeSelectedAspectRatio = (aspectRatio: DesignAspectRatio) => {
    const oldState = selectedAspectRatios;
    const newState = oldState.filter(ar => ar !== aspectRatio);
    setSelectedAspectRatios(newState);
  };

  const colorsFiltered = !isEmpty(selectedColors);
  const aspectsFiltered = !isEmpty(selectedAspectRatios);
  const filteredDesigns = designs.filter(d => {
    const matchingVariants = d.variants.filter(v => {
      const colorIncluded = Object.keys(v.examples).some(palette =>
        selectedColors.includes(palette as ColorPaletteGroup)
      );
      const aspectIncluded = selectedAspectRatios.includes(v.aspectRatio);
      if (colorsFiltered && aspectsFiltered) {
        return colorIncluded && aspectIncluded;
      } else if (colorsFiltered) {
        return colorIncluded;
      } else if (aspectsFiltered) {
        return aspectIncluded;
      } else {
        return true;
      }
    });

    return !isEmpty(matchingVariants);
  });

  return (
    <div>
      <main className="mx-auto max-w-2xl lg:max-w-7xl lg:pr-8">
        <div className="pb-24 pt-10 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <DesignsAsideMenu
            filters={filters}
            selectedColors={selectedColors}
            addSelectedColor={addSelectedColor}
            removeSelectedColor={removeSelectedColor}
            selectedAspectRatios={selectedAspectRatios}
            addSelectedAspectRatio={addSelectedAspectRatio}
            removeSelectedAspectRatio={removeSelectedAspectRatio}
            mobileFiltersOpen={mobileFiltersOpen}
            setMobileFiltersOpen={setMobileFiltersOpen}
          />
          <DesignsGallery
            designs={filteredDesigns}
            selectedColors={selectedColors}
            selectedAspectRatios={selectedAspectRatios}
          />
        </div>
      </main>
    </div>
  );
};
