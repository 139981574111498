import { useTranslation } from 'react-i18next';

import { useValidateUrls } from '@src/hooks';
import { RenderOptionsDto } from '@src/models';

import { Button } from '../common';

import { RenderAdvancedOptionsForm } from './RenderAdvancedOptionsForm';

type RenderBatchAdvancedOptionsProps = {
  previousStep: () => void;
  nextStep: () => void;
  advancedOptions?: RenderOptionsDto;
  setAdvancedOptions: (advancedOptions: RenderOptionsDto) => void;
};

export const RenderBatchAdvancedOptions = ({
  previousStep,
  nextStep,
  advancedOptions,
  setAdvancedOptions
}: RenderBatchAdvancedOptionsProps) => {
  const { t } = useTranslation();

  const { urlsValid, handleInvalidUrls } = useValidateUrls();

  return (
    <RenderAdvancedOptionsForm
      className="shadow"
      advancedOptions={advancedOptions}
      onSave={(advancedOptions: RenderOptionsDto) => {
        setAdvancedOptions(advancedOptions);
        nextStep();
      }}
      isBatchRender={true}
      onValidation={handleInvalidUrls}
      formButtons={
        <div className="mt-6 flex justify-end">
          <Button secondary className="mr-3" onClick={previousStep}>
            {t('general.action.back')}
          </Button>
          <Button type="submit" disabled={!urlsValid}>
            {t('general.action.next')}
          </Button>
        </div>
      }
    />
  );
};
