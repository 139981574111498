import { useCallback, useState } from 'react';

import { DragEndEvent, DragStartEvent, useSensor, useSensors } from '@dnd-kit/core';
import { KeyboardSensor, MouseSensor, TouchSensor } from '@src/types/dragable';

export const useDragAndDrop = () => {
  const [activeId, setActiveId] = useState<string | null>(null);

  // on drag start we are going to set the active id, so we can use it inside the <DragOverlay /> component
  const handleDragStart = useCallback((event: DragStartEvent) => setActiveId(event.active.id.toString()), []);

  // on drag end we are going to take the oldIndex, which is the index of the active item, and
  // newIndex which is the place where we are positioned in the list, and insert the item at the new index in array
  const handleDragEnd = useCallback(
    (event: DragEndEvent, indexesArray: string[], onDragAndDrop: (oldIndex: number, newIndex: number) => void) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        const oldIndex = indexesArray.indexOf(active.id.toString());
        const newIndex = indexesArray.indexOf(over.id.toString());
        return onDragAndDrop(oldIndex, newIndex);
      }
    },
    []
  );

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  return { activeId, handleDragStart, handleDragEnd, sensors };
};
