import { ComponentPropsWithoutRef, CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ScriptType } from '@plainly/types';
import { Script } from '@src/models';

import ScriptingDescription from './scripts/ScriptingDescription';
import { getScriptIcon } from './LayerParametrizeScriptingModal';

export const LayerParametrizeScriptingItem = ({
  script,
  editScript,
  removeScript,
  wrapperRef,
  className,
  ...rest
}: {
  script: Script;
  editScript: (script: Script) => void;
  removeScript: (script: Script) => void;
  wrapperRef?: (node: HTMLElement | null) => void;
} & ComponentPropsWithoutRef<'li'>) => {
  const { t } = useTranslation();

  return (
    <li {...rest} ref={wrapperRef} className={classNames(className, 'group flex items-center justify-between py-3')}>
      <span className="flex h-8 w-8 items-center justify-center rounded-lg bg-indigo-500">
        {getScriptIcon(script.scriptType, { className: 'h-5 w-5 text-white' })}
      </span>
      <div className="flex grow flex-col md:flex-row">
        <div className="flex w-full items-center md:w-1/3">
          <p className="ml-4 text-sm font-medium text-gray-900 group-hover:text-indigo-500">
            {t('components.project.template.layer.LayerParametrizeScriptingModal.name', {
              context: script.scriptType
            })}
          </p>
        </div>
        <div className="w-full: md:w-2/3">
          <ScriptingDescription script={script} />
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <button
          data-no-dnd="true"
          type="button"
          className={classNames(
            'ml-6 rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 hover:underline focus:outline-none',
            script.scriptType === ScriptType.SPREAD_LAYERS && 'hidden'
          )}
          onClick={() => editScript(script)}
        >
          {t('general.action.edit')}
        </button>

        <button
          data-no-dnd="true"
          type="button"
          className="rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 hover:underline focus:outline-none md:ml-6"
          onClick={() => removeScript(script)}
        >
          {t('general.action.remove')}
        </button>
      </div>
    </li>
  );
};

export const DndLayerParametrizeScriptingItem = ({
  script,
  editScript,
  removeScript,
  dndId,
  dndDisabled
}: {
  script: Script;
  editScript: (script: Script) => void;
  removeScript: (script: Script) => void;
  dndId: string;
  dndDisabled?: boolean;
}) => {
  const { attributes, listeners, transform, setNodeRef, isDragging } = useSortable({
    id: dndId,
    disabled: dndDisabled
  });

  const style: CSSProperties = useMemo(() => {
    return {
      transform: CSS.Translate.toString(transform), //let dnd-kit do its thing
      opacity: isDragging ? 0.4 : 1,
      zIndex: isDragging ? 1 : 0,
      position: 'relative'
    };
  }, [isDragging, transform]);

  const getCursor = useMemo(() => {
    if (dndDisabled) return 'cursor-auto';
    if (isDragging) return 'cursor-grabbing';
    if (!isDragging) return 'cursor-grab';
  }, [dndDisabled, isDragging]);

  const dndAttributes = useMemo(() => {
    return {
      ...attributes,
      ...listeners,
      style
    };
  }, [attributes, listeners, style]);

  return (
    <LayerParametrizeScriptingItem
      script={script}
      editScript={editScript}
      removeScript={removeScript}
      wrapperRef={setNodeRef}
      className={getCursor}
      {...dndAttributes}
    />
  );
};
