import { useState } from 'react';

import { Alert } from '@src/components';
import { useError } from '@src/hooks';

export type ErrorAlertProps = {
  error?: Error;
  closable?: boolean;
};

export const ErrorAlert = (props: ErrorAlertProps) => {
  const { formatMessage } = useError();
  const [lastErrorCleared, setLastErrorCleared] = useState<Error | undefined>(undefined);
  const { error, closable } = props;

  // only return if we have an error in props that is not as the same one we cleared last
  const show = error !== undefined && error !== lastErrorCleared;
  const message = error && formatMessage(error);
  const onDismiss = () => setLastErrorCleared(error);

  return <Alert type="error" show={show} alertContent={message} onClose={(closable && onDismiss) || undefined} />;
};
