import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useNotifications } from '@src/hooks';
import { RENDER_BATCH_DOWNLOAD } from '@src/routes';

import { ButtonWithDropdown } from '../common';

export const RenderBatchButton = ({
  type,
  onBatchRender
}: {
  type: 'render' | 'download';
  onBatchRender: (() => void) | undefined;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifyWarn } = useNotifications();

  const onBatchDownload = useCallback(() => navigate(generatePath(RENDER_BATCH_DOWNLOAD, { step: '1' })), [navigate]);
  const actionBatchRender = useCallback(() => {
    if (!onBatchRender) {
      notifyWarn(t('components.render.RenderBatchButton.renderDisabled'));
    } else {
      onBatchRender();
    }
  }, [notifyWarn, onBatchRender, t]);

  const labeledButtonAction = useCallback(() => {
    if (type === 'render') {
      actionBatchRender();
    } else {
      onBatchDownload();
    }
  }, [actionBatchRender, onBatchDownload, type]);

  return (
    <div className="flex">
      <ButtonWithDropdown
        labeled={{
          label: type === 'render' ? t('components.common.batchRender') : t('components.render.common.batchDownload'),
          action: labeledButtonAction
        }}
        buttons={[
          {
            key: 'batchRender',
            action: actionBatchRender,
            label: t('components.common.batchRender')
          },
          {
            key: 'batchDownload',
            action: onBatchDownload,
            label: t('components.render.common.batchDownload')
          }
        ]}
      />
    </div>
  );
};
