import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { useVideoNavigationParams } from '@src/hooks/utils/useVideoNavigationParams';
import { ArticleVideoScene } from '@src/models';

import { formatDuration, sceneStartTime, sceneWidthPercentage, upToSceneWidthPercentage } from '../utils';

export const SceneNavigation = ({ scenes }: { scenes: ArticleVideoScene[] }) => {
  const { t } = useTranslation();
  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  const sceneId = videoNavigationState.activeView === 'scenes' && videoNavigationState.sceneId;
  const currentSceneIndex = sceneId ? sceneId - 1 : 0;

  const currentSceneDuration = scenes[currentSceneIndex].duration;
  const totalDuration = scenes.reduce((total, scene) => (scene.duration ? total + scene.duration : 0), 0);

  // this is needed for the progress bar
  // currentPrecentage is for the width of blue color
  // previousPercentage is for the margin-left of all the previous scenes
  const currentScenePercentage = currentSceneDuration && (currentSceneDuration / totalDuration) * 100;
  const previousScenesPercentage = upToSceneWidthPercentage(totalDuration, currentSceneIndex, scenes);

  return (
    <div className="space-y-6 px-4 pb-5 sm:px-6 sm:pb-6">
      <div aria-hidden="true" className="mt-3 space-y-1 text-gray-400 ">
        <div className="flex text-xs">
          {scenes.map((scene, index, array) => (
            <div key={index} style={{ width: `${sceneWidthPercentage(totalDuration, index, scenes)}%` }}>
              {index !== 0 && index % 2 !== 0 && (
                <p className="border-l border-dashed border-gray-300 pl-1">
                  {scene.duration && sceneStartTime(index, scenes)}
                </p>
              )}
              {index === array.length - 1 && index % 2 === 0 && (
                <p className="border-r border-dashed border-gray-300 pr-1 text-right">
                  {formatDuration(totalDuration)}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-indigo-600 transition-all duration-300 ease-in-out"
            style={{
              marginLeft: previousScenesPercentage ? `${previousScenesPercentage}%` : '0',
              width: `${currentScenePercentage}%`
            }}
          />
        </div>
        <div className="flex text-xs">
          {scenes.map((scene, index, array) => (
            <div key={index} style={{ width: `${sceneWidthPercentage(totalDuration, index, scenes)}%` }}>
              {index === 0 && (
                <p
                  style={{ width: `${sceneWidthPercentage(totalDuration, index, scenes)}%` }}
                  className="border-l border-dashed border-gray-300 pl-1"
                >
                  {sceneStartTime(0, scenes)}
                </p>
              )}
              {index !== 0 && index % 2 !== 1 && (
                <p
                  style={{ width: `${sceneWidthPercentage(totalDuration, index, scenes)}%` }}
                  className="border-l border-dashed border-gray-300 pl-1"
                >
                  {scene.duration && sceneStartTime(index, scenes)}
                </p>
              )}
              {index === array.length - 1 && index % 2 !== 0 && (
                <p className="border-r border-dashed border-gray-300 pr-1 text-right">
                  {formatDuration(totalDuration)}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <nav className="flex items-center justify-center text-sm">
        <button
          disabled={currentSceneIndex === 0}
          type="button"
          className={classNames(
            currentSceneIndex !== 0 && 'group text-gray-500 hover:text-gray-700',
            'inline-flex items-center disabled:cursor-not-allowed disabled:opacity-50'
          )}
          onClick={() => {
            updateVideoNavigationState({
              revisionId: videoNavigationState.revisionId,
              activeView: 'scenes',
              sceneId: sceneId ? sceneId - 1 : 1,
              headlinesTab: 1,
              assetsTab: 'suggested'
            });
          }}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          {t('general.action.prev')}
        </button>
        <p className="w-28 text-center text-gray-700 sm:w-36">
          <Trans
            i18nKey={'components.article.ArticleVideo.sceneSettings.scenes'}
            tOptions={{ sceneId: sceneId, length: scenes.length }}
          >
            Scene <span>{sceneId?.toString()}</span> of <span>{scenes.length.toString()}</span>
          </Trans>
        </p>
        <button
          disabled={currentSceneIndex === scenes.length - 1}
          type="button"
          className={classNames(
            currentSceneIndex !== scenes.length - 1 && 'group text-gray-500 hover:text-gray-700',
            'inline-flex items-center disabled:cursor-not-allowed disabled:opacity-50'
          )}
          onClick={() => {
            updateVideoNavigationState({
              revisionId: videoNavigationState.revisionId,
              activeView: 'scenes',
              sceneId: sceneId ? sceneId + 1 : 1,
              headlinesTab: 1,
              assetsTab: 'suggested'
            });
          }}
        >
          {t('general.action.next')}
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        </button>
      </nav>
    </div>
  );
};
