import { useCallback, useReducer } from 'react';

import { AnyProjectItem, AnyRenderItem } from '@src/types';

type State = {
  projectItem?: AnyProjectItem;
  renderItem?: AnyRenderItem;
};

type Action =
  | { type: 'update-project-item'; payload: AnyProjectItem }
  | { type: 'clear-project-item' }
  | { type: 'update-render-item'; payload: AnyRenderItem }
  | { type: 'clear-render-item' };

function projectReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'update-project-item':
      return { ...state, projectItem: action.payload };

    case 'clear-project-item':
      return { ...state, projectItem: undefined };

    case 'update-render-item':
      return { ...state, renderItem: action.payload };

    case 'clear-render-item':
      return { ...state, renderItem: undefined };

    default:
      return state;
  }
}

export const useAnyProjectItemReducer = () => {
  const [state, dispatch] = useReducer(projectReducer, {
    projectItem: undefined,
    renderItem: undefined
  });

  const updateProjectItem = useCallback((payload?: AnyProjectItem) => {
    if (payload) {
      dispatch({ type: 'update-project-item', payload });
    } else {
      dispatch({ type: 'clear-project-item' });
    }
  }, []);

  const updateRenderItem = useCallback((payload?: AnyRenderItem) => {
    if (payload) {
      dispatch({ type: 'update-render-item', payload });
    } else {
      dispatch({ type: 'clear-render-item' });
    }
  }, []);

  return [state, updateProjectItem, updateRenderItem] as const;
};
