import { useMutation, useQuery } from 'react-query';

import { ClientSideApiError, StockAssetPreference, StockAssetSearch, StockProviderType } from '@src/models';

import { useAxiosModify, useAxiosRead } from '../useAxios';

type Asset = {
  url: string;
};

export const useValidateAsset = () => {
  const { post } = useAxiosModify<Asset, { valid: boolean; contentType?: string }>(
    {},
    { silentNotifications: true, sentryErrorFilter: error => !(error instanceof ClientSideApiError) }
  );

  const { mutateAsync, isLoading, error } = useMutation(async (asset: Asset) => {
    const response = await post('/assets/check-supported', asset);

    return response.data;
  });

  return { mutateAsync, isLoading, error };
};

const STOCK_ASSETS_CACHE_ROOT = 'stock-assets';

export type StockAssetsSearchParams = {
  query: string;
  preference?: StockAssetPreference;
  providers?: StockProviderType[];
  size?: number;
};

export const useGetStockAssets = (params: StockAssetsSearchParams) => {
  const { get } = useAxiosRead<StockAssetSearch>();

  const cacheKey = [STOCK_ASSETS_CACHE_ROOT, params];

  const { data, isLoading, error, isRefetching } = useQuery<StockAssetSearch>(
    cacheKey,
    async () => {
      const response = await get('/video-genius/stock-assets', {
        params: params
      });
      return response.data;
    },
    {
      enabled: params.query !== '' && params.query.length > 0,
      keepPreviousData: true
    }
  );

  return { data, isLoading, error, isRefetching };
};
