export enum AvatarProviderType {
  D_ID = 'D_ID'
}

type AvatarProviderSettings<T extends AvatarProviderType> = {
  type: T;
};

export type DidAvatarProviderSettings = AvatarProviderSettings<AvatarProviderType.D_ID>;

export type AnyAvatarProviderSettings = DidAvatarProviderSettings;
