import { useCallback, useMemo } from 'react';

import { closestCenter, DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndLayerParametrizedListItem, LayerParametrizedListItem, ParametrizedItem } from '@src/components';
import { useDragAndDrop } from '@src/hooks';
import { Layer, Template } from '@src/models';

type LayerAvailableListProps = {
  projectId: string;
  template: Partial<Template>;
  parametrizedItems: ParametrizedItem[];
  onDelete: (l: Partial<Layer>) => void;
  onEdit: (l: Partial<Layer>) => void;
  onDragAndDrop: (oldIndex: number, newIndex: number) => void;
};

export const LayerParametrizedList = ({
  projectId,
  template,
  parametrizedItems,
  onDelete,
  onEdit,
  onDragAndDrop
}: LayerAvailableListProps) => {
  const { activeId, handleDragStart, handleDragEnd, sensors } = useDragAndDrop();
  const parametrizedItemsIds = useMemo(
    () => parametrizedItems.map(item => item.layer.internalId || ''),
    [parametrizedItems]
  );

  const activeItem = useMemo(
    () => parametrizedItems.find(item => item.layer.internalId === activeId),
    [activeId, parametrizedItems]
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => handleDragEnd(event, parametrizedItemsIds, onDragAndDrop),
    [handleDragEnd, onDragAndDrop, parametrizedItemsIds]
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToWindowEdges]}
      onDragEnd={onDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <nav className="relative overflow-y-auto" aria-label="Directory">
        <ul className="relative z-0 divide-y divide-gray-200">
          <SortableContext items={parametrizedItemsIds} strategy={verticalListSortingStrategy}>
            {parametrizedItems.map((item, index) => {
              const dndId = item.layer.internalId;

              return (
                <>
                  {dndId ? (
                    <DndLayerParametrizedListItem
                      key={`param-item-text-${index}`}
                      projectId={projectId}
                      template={template}
                      parametrizedItem={item}
                      onDelete={() => onDelete(item.layer)}
                      onEdit={() => onEdit(item.layer)}
                      dndId={dndId}
                      dndDisabled={parametrizedItems.length <= 1}
                    />
                  ) : (
                    <LayerParametrizedListItem
                      key={`param-item-text-${index}`}
                      projectId={projectId}
                      template={template}
                      parametrizedItem={item}
                      onDelete={() => onDelete(item.layer)}
                      onEdit={() => onEdit(item.layer)}
                    />
                  )}
                </>
              );
            })}
          </SortableContext>

          <DragOverlay>
            {activeItem ? (
              <LayerParametrizedListItem
                projectId={projectId}
                template={template}
                parametrizedItem={activeItem}
                onDelete={() => onDelete(activeItem.layer)}
                onEdit={() => onEdit(activeItem.layer)}
                className="cursor-grabbing"
              />
            ) : null}
          </DragOverlay>
        </ul>
      </nav>
    </DndContext>
  );
};
