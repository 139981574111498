import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DesignAspectRatio } from '@src/models';

export type DesignAspectRatioIconProps = {
  aspectRatio: DesignAspectRatio;
  isLastItem: boolean;
};

export const DesignAspectRatioIcon = ({ aspectRatio, isLastItem }: DesignAspectRatioIconProps) => {
  const { t } = useTranslation();
  const getAspectRatioIcon = () => {
    switch (aspectRatio) {
      case DesignAspectRatio.SQUARE:
        return <span className={classNames('h-4 w-4 rounded border-2 border-gray-500', !isLastItem && 'mr-2')} />;
      case DesignAspectRatio.STORY:
        return <span className={classNames('h-4 w-3 rounded border-2 border-gray-500', !isLastItem && 'mr-2')} />;
      case DesignAspectRatio.WIDE:
        return <span className={classNames('h-3 w-4 rounded border-2 border-gray-500', !isLastItem && 'mr-2')} />;
    }
  };
  return (
    <div className="flex" title={t('components.designs.common.aspectRatioOption', { context: aspectRatio })}>
      {getAspectRatioIcon()}
    </div>
  );
};
