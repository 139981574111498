import classNames from 'classnames';

import { Description, Field, Label, Switch } from '@headlessui/react';

type SwitchProps = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
  description?: string;
};

export const SwitchButton = ({ label, checked, onChange, disabled, description }: SwitchProps) => {
  return (
    <Field as="div" className="flex items-center">
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <div className="ml-3 flex flex-col items-baseline justify-center">
        <Label as="span">
          <span className="block text-sm font-medium text-gray-700">{label}</span>
        </Label>
        {description && (
          <Description as="span" className="text-sm text-gray-500">
            {description}
          </Description>
        )}
      </div>
    </Field>
  );
};
