import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { PlayIcon } from '@heroicons/react/20/solid';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Breadcrumb, BreadcrumbItem, Button, HeaderWithBreadcrumbs } from '@src/components';
import { useSubmitVideoRevision, useVideoNavigationParams } from '@src/hooks';
import { Article, Brand, RevisionState } from '@src/models';
import * as routes from '@src/routes';

export const VideoDetailsHeader = ({
  setShowVideoDialog,
  article,
  brand,
  videoId,
  revisionId,
  state,
  disabled,
  setShowPreviewModal
}: {
  setShowVideoDialog: (showVideoDialog: boolean) => void;
  article: Article;
  brand: Brand;
  videoId: string;
  revisionId: string;
  state: RevisionState;
  disabled?: boolean;
  setShowPreviewModal: (showPreviewModal: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { videoNavigationState } = useVideoNavigationParams();

  const activeView = videoNavigationState?.activeView;

  const { isLoading: isLoadingSubmit, mutateAsync: submitVideoRevision } = useSubmitVideoRevision();

  const fireSubmitRender = async () =>
    await submitVideoRevision({ brandId: brand.id, articleId: article.id, videoId, revisionId });

  return (
    <>
      <Helmet>
        <title>{t('general.pageTitle.VideoDetailsPage', { context: activeView })}</title>
      </Helmet>
      <HeaderWithBreadcrumbs
        breadcrumbs={
          <Breadcrumb>
            <BreadcrumbItem to={generatePath(routes.ARTICLES)} label={t('general.common.articles')} />
            <BreadcrumbItem
              to={generatePath(routes.ARTICLES_BRAND_LIST, { brandId: article.brandId })}
              label={brand.settings.name}
            />
            {article.input.title && (
              <BreadcrumbItem
                to={generatePath(routes.ARTICLE_DETAILS, { brandId: article.brandId, articleId: article.id })}
                label={article.input.title}
                truncate
                rightSidebar
              />
            )}
          </Breadcrumb>
        }
        header={t('components.article.ArticleVideo.view', { context: activeView })}
        actions={
          <div className="flex w-full flex-col gap-2 sm:w-auto sm:flex-row">
            <div className="flex w-full gap-2 sm:w-auto sm:gap-0">
              <Button
                icon={<Bars3Icon />}
                secondary
                className="flex-1 sm:flex-none lg:hidden"
                onClick={() => setShowVideoDialog(true)}
              >
                {t('components.article.ArticleVideo.menu')}
              </Button>
              <Button
                className="ml-0 flex-1 sm:ml-2 sm:flex-none"
                icon={<PlayIcon />}
                secondary
                onClick={() => setShowPreviewModal(true)}
              >
                {t('components.common.preview')}
              </Button>
            </div>
            {(state === RevisionState.DRAFT || state === RevisionState.PREVIEW || state === RevisionState.ERROR) && (
              <Button
                type="button"
                loading={isLoadingSubmit}
                disabled={isLoadingSubmit || disabled}
                onClick={e => {
                  e.preventDefault();
                  fireSubmitRender();
                }}
                title={disabled ? t('components.article.ArticleVideo.settings.submitRenderDisabled') : undefined}
              >
                {t('components.article.ArticleVideo.settings.submitRender', { context: state })}
              </Button>
            )}
          </div>
        }
      />
    </>
  );
};
