import { useTranslation } from 'react-i18next';

import { formatResourceUsage, toMb } from '@src/utils';

export type UsageDetailsStatsProps = {
  grandTotal: number;
  periodTotal: number;
  resourceType: string | undefined;
};

export const UsageDetailsStats = ({ grandTotal, periodTotal, resourceType }: UsageDetailsStatsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            {t('components.usage.UsageDetails.grandTotal')}
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {t('components.usage.common.resourceUsage', {
              context: resourceType,
              count: Math.round(grandTotal),
              decimal: formatResourceUsage(grandTotal),
              minutes: formatResourceUsage(grandTotal / 60),
              megabytes: formatResourceUsage(toMb(grandTotal))
            })}
          </dd>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            {t('components.usage.UsageDetails.periodTotal')}
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {t('components.usage.common.resourceUsage', {
              context: resourceType,
              count: Math.round(periodTotal),
              decimal: formatResourceUsage(periodTotal),
              minutes: formatResourceUsage(periodTotal / 60),
              megabytes: formatResourceUsage(toMb(periodTotal))
            })}
          </dd>
        </div>
      </dl>
    </div>
  );
};
