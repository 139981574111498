import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorCode, PlainlyApiError } from '@src/models';

export const useError = () => {
  const { t } = useTranslation();

  const formatMessage = useCallback(
    (error: Error) => {
      if (error instanceof PlainlyApiError) {
        // if general, usually we need both generic message and error message
        if (error.generalError) {
          let message = t(`general.errorCode.${error.code}`);
          if (error.errorMessage) {
            message = `${message} Cause: ${error.errorMessage}`;
          }
          return message;
        } else {
          return error.errorMessage || t(`general.errorCode.${error.code}`);
        }
      } else {
        return error.toLocaleString();
      }
    },
    [t]
  );

  const isApiErrorCode = useCallback((error: Error, errorCode: ErrorCode) => {
    if (error instanceof PlainlyApiError) {
      return error.code === errorCode;
    }
    return false;
  }, []);

  return {
    formatMessage,
    isApiErrorCode
  };
};
