import { StyledA } from '@src/components/common/link';

export const Log = ({
  title,
  actionText,
  linkAction,
  children
}: {
  title: string;
  actionText: string;
  linkAction: () => void;
} & React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 flex flex-col text-sm text-gray-900">
        <span>
          <StyledA href="#" onClick={linkAction}>
            {actionText}
          </StyledA>
        </span>
      </dd>
      {children}
    </div>
  );
};
