import { ReactNode } from 'react';

export const SideInfoHeader = ({ title, action }: { title: string; action?: ReactNode }) => {
  return (
    <div className="px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
        </div>
        {action}
      </div>
    </div>
  );
};
