import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { closestCenter, DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PlusIcon as PlusSmallIcon } from '@heroicons/react/20/solid';
import { HelpNotification, ScriptingHelpLinks } from '@src/components/common';
import { useDragAndDrop } from '@src/hooks';
import { Script } from '@src/models';
import { encodeJSON } from '@src/utils';

import { SaveValidation } from './LayerParametrize';
import { DndLayerParametrizeScriptingItem, LayerParametrizeScriptingItem } from './LayerParametrizeScriptingItem';

export type LayerParametrizeScriptingProps = {
  setShowScriptingModal: (showScriptingModal: boolean) => void;
  isActiveTab: boolean;
  scripts: Script[];
  editLayerScripts?: Script[];
  setScripts: (scripts: Script[]) => void;
  setScriptValidation: (validation: SaveValidation) => void;
  setEditScript: (script: Script) => void;
  onDragAndDrop: (oldIndex: number, newIndex: number) => void;
};

export const LayerParametrizeScripting = ({
  setShowScriptingModal,
  isActiveTab,
  scripts,
  setScripts,
  editLayerScripts,
  setScriptValidation,
  setEditScript,
  onDragAndDrop
}: LayerParametrizeScriptingProps) => {
  const { t } = useTranslation();

  const removeScript = (script: Script) => {
    const newScripts = scripts.filter(s => s !== script);
    setScripts(newScripts);
  };

  const editScript = (script: Script) => {
    setEditScript(script);
    setShowScriptingModal(true);
  };

  useEffect(() => {
    if (editLayerScripts) {
      setScripts(editLayerScripts);
    }
  }, [editLayerScripts, setScripts]);

  useEffect(() => {
    if (scripts && scripts.length) {
      setScriptValidation({ hasData: true, isValid: true });
    } else {
      setScriptValidation({ hasData: false });
    }
  }, [scripts, setScriptValidation]);

  // Drag and Drop script
  const { activeId, handleDragStart, handleDragEnd, sensors } = useDragAndDrop();
  const scriptsIds = useMemo(() => scripts.map(script => encodeJSON(script)), [scripts]);
  const activeItem = useMemo(() => scripts.find(script => encodeJSON(script) === activeId), [scripts, activeId]);
  const onDragEnd = useCallback(
    (event: DragEndEvent) => handleDragEnd(event, scriptsIds, onDragAndDrop),
    [handleDragEnd, onDragAndDrop, scriptsIds]
  );

  return (
    <>
      {isActiveTab && (
        <div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToWindowEdges]}
            onDragEnd={onDragEnd}
            onDragStart={handleDragStart}
            sensors={sensors}
          >
            <ul className="mt-2 divide-y divide-gray-200 border-b border-gray-200">
              <SortableContext items={scriptsIds} strategy={verticalListSortingStrategy}>
                {scripts.map((script, index) => (
                  <DndLayerParametrizeScriptingItem
                    key={index}
                    script={script}
                    editScript={() => editScript(script)}
                    removeScript={() => removeScript(script)}
                    dndId={encodeJSON(script)}
                    dndDisabled={scripts.length <= 1}
                  />
                ))}
              </SortableContext>

              <DragOverlay>
                {activeItem ? (
                  <LayerParametrizeScriptingItem
                    script={activeItem}
                    editScript={() => editScript(activeItem)}
                    removeScript={() => removeScript(activeItem)}
                    className="cursor-grabbing"
                  />
                ) : null}
              </DragOverlay>

              <li className="flex items-center justify-between py-2">
                <button
                  type="button"
                  className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setShowScriptingModal(true)}
                >
                  <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                    <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                    {t('components.project.template.layer.LayerParametrizeScripting.addNewScript')}
                  </span>
                </button>
                <HelpNotification type="badge" links={ScriptingHelpLinks} />
              </li>
            </ul>
          </DndContext>
        </div>
      )}
    </>
  );
};
