import React from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { ProtectedDashboard, SignupDashboard } from '@src/components';
import {
  AppmixerAccountsPage,
  AppmixerIntegrationsLogsPage,
  AppmixerIntegrationsPage,
  ArticleCreatePage,
  ArticleDetailsPage,
  ArticlesBrandListPage,
  ArticlesListPage,
  BrandCreatePage,
  BrandDetailsPage,
  BrandEditPage,
  BrandsListPage,
  CustomPaymentPage,
  DashboardIndex,
  DesignDetailsPage,
  DesignsPage,
  ErrorPage,
  LoadingPage,
  OrganizationSettingsPage,
  OrganizationSubscriptionPage,
  OrganizationTeamPage,
  ProjectCreatePage,
  ProjectDetailsPage,
  ProjectEditPage,
  ProjectsListPage,
  ProtectedUserOnboarding,
  RenderBatchCsvPage,
  RenderBatchDownloadPage,
  RenderDetailsPage,
  RenderFormPage,
  RendersPreviewPage,
  SentryErrorFallbackPage,
  TemplateCreatePage,
  TemplateDetailsPage,
  TemplateEditPage,
  UsageDetailsPage,
  UserInvitesPage,
  UserSettingsPage,
  VideoDetailsPage,
  VideoTypeCreatePage,
  VideoTypeEditPage
} from '@src/pages';

import './App.css';

const appRoutesConfig = [
  // Home
  { path: '/', element: <Navigate replace to={'/dashboard'} /> },
  // Dashboard
  {
    path: '/dashboard',
    element: (
      <ProtectedDashboard>
        <Outlet />
      </ProtectedDashboard>
    ),
    children: [
      { path: '', element: <Navigate replace to={'/dashboard/start'} /> },
      { path: '/dashboard/start', element: <DashboardIndex /> },
      // Projects
      { path: '/dashboard/projects/create', element: <ProjectCreatePage /> },
      { path: '/dashboard/projects/:id/edit', element: <ProjectEditPage /> },
      { path: '/dashboard/projects/:id', element: <ProjectDetailsPage /> },
      { path: '/dashboard/projects', element: <ProjectsListPage /> },
      // Templates
      { path: '/dashboard/projects/:projectId/templates/create/:step', element: <TemplateCreatePage /> },
      { path: '/dashboard/projects/:projectId/templates/create/', element: <TemplateCreatePage /> },
      { path: '/dashboard/projects/:projectId/templates/:templateId', element: <TemplateDetailsPage /> },
      {
        path: '/dashboard/projects/:projectId/templates/:templateId/edit/:step',
        element: <TemplateEditPage />
      },
      {
        path: '/dashboard/projects/:projectId/templates/:templateId/edit/',
        element: <TemplateEditPage />
      },
      // Renders
      { path: '/dashboard/renders', element: <RendersPreviewPage /> },
      { path: '/dashboard/renders/new', element: <RenderFormPage /> },
      { path: '/dashboard/renders/:id', element: <RenderDetailsPage /> },
      {
        path: '/dashboard/renders/batch/project/:projectId/:templateId/csv/:step',
        element: <RenderBatchCsvPage isDesign={false} />
      },
      {
        path: '/dashboard/renders/batch/design/:projectId/:templateId/csv/:step',
        element: <RenderBatchCsvPage isDesign={true} />
      },
      { path: '/dashboard/renders/batch/download/:step', element: <RenderBatchDownloadPage /> },
      // Organization
      { path: '/dashboard/user/settings/general', element: <OrganizationSettingsPage /> },
      { path: '/dashboard/user/settings/team', element: <OrganizationTeamPage /> },
      { path: '/dashboard/user/settings/subscription', element: <OrganizationSubscriptionPage /> },
      {
        path: '/dashboard/user/settings',
        element: <Navigate replace to={'/dashboard/user/settings/general'} />
      },
      // User
      { path: '/dashboard/user/profile/general', element: <UserSettingsPage /> },
      { path: '/dashboard/user/profile/invites', element: <UserInvitesPage /> },
      { path: '/dashboard/user/profile', element: <Navigate replace to={'/dashboard/user/profile/general'} /> },
      // Usage
      { path: '/dashboard/usage/details', element: <UsageDetailsPage /> },
      // Public designs
      { path: '/dashboard/designs', element: <DesignsPage /> },
      { path: '/dashboard/designs/:designId', element: <DesignDetailsPage /> },
      // Integrations
      { path: '/dashboard/integrations', element: <AppmixerIntegrationsPage /> },
      { path: '/dashboard/integrations/:tab', element: <AppmixerIntegrationsPage /> },
      { path: '/dashboard/integrations/logs', element: <AppmixerIntegrationsLogsPage /> },
      { path: '/dashboard/integrations/accounts', element: <AppmixerAccountsPage /> },

      // Non-matched dashboard routes
      { path: '*', element: <Navigate replace to={'/dashboard/start'} /> }
    ]
  },
  // VideoGenius
  {
    path: '/video-genius',
    element: (
      <ProtectedDashboard>
        <Outlet />
      </ProtectedDashboard>
    ),
    children: [
      { path: '', element: <Navigate replace to={'/video-genius/articles'} /> },
      { path: '/video-genius/brands', element: <BrandsListPage /> },
      { path: '/video-genius/brands/create', element: <BrandCreatePage /> },
      { path: '/video-genius/brands/:id', element: <BrandDetailsPage /> },
      { path: '/video-genius/brands/:id/edit', element: <BrandEditPage /> },
      { path: '/video-genius/brands/:id/create', element: <VideoTypeCreatePage /> },
      { path: '/video-genius/brands/:id/:videoId/edit', element: <VideoTypeEditPage /> },
      // articles
      { path: '/video-genius/articles', element: <ArticlesListPage /> },
      { path: '/video-genius/articles/:brandId/create', element: <ArticleCreatePage /> },
      { path: '/video-genius/articles/:brandId/all', element: <ArticlesBrandListPage /> },
      { path: '/video-genius/articles/:brandId/:articleId', element: <ArticleDetailsPage /> },
      // videos
      {
        path: '/video-genius/articles/:brandId/:articleId/videos/:videoId',
        element: <VideoDetailsPage />
      }
    ]
  },
  // Payment
  { path: '/payment/custom/:productId', element: <CustomPaymentPage /> },
  // Onboarding
  { path: '/onboarding', element: <ProtectedUserOnboarding /> },
  // Sign-up
  { path: '/sign-up', element: <SignupDashboard /> },
  // Non-matched root routes
  { path: '*', element: <Navigate replace to={'/dashboard'} /> }
];

export const App = React.memo(function App() {
  const { isLoading, error, logout } = useAuth0();
  const appRoutes = useRoutes(appRoutesConfig);

  // if loading, return loading page
  if (isLoading) return <LoadingPage />;

  // if error, return error page
  if (error) return <ErrorPage error={error} logout={logout} />;

  return (
    <Sentry.ErrorBoundary showDialog fallback={<SentryErrorFallbackPage />}>
      {appRoutes}
    </Sentry.ErrorBoundary>
  );
});
