import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ScrollingPage, UserIncomingInvitesCard, UserSettingsHeader, UserSettingsNavigation } from '@src/components';

export const UserInvitesPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.UserInvitesPage')}</title>
        </Helmet>
        <UserSettingsHeader />
        <UserSettingsNavigation tab="invites" />
        <UserIncomingInvitesCard />
      </ScrollingPage>
    </HelmetProvider>
  );
};
