import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@src/components/common';
import { RenderAdvancedOptionsForm } from '@src/components/render';
import { useSetProjectTemplateOptions, useValidateUrls } from '@src/hooks';
import { RenderOptionsDto, Template } from '@src/models';

export const RenderingOptionsModal = ({
  visible,
  setShowModal,
  template,
  projectId
}: {
  visible: boolean;
  setShowModal: (showModal: boolean) => void;
  template: Template;
  projectId: string;
}) => {
  return (
    <Modal visible={visible} close={() => setShowModal(false)} className="!p-0 sm:max-w-lg md:max-w-2xl lg:max-w-4xl">
      <RenderingOptionsModalContent setShowModal={setShowModal} template={template} projectId={projectId} />
    </Modal>
  );
};

export const RenderingOptionsModalContent = ({
  setShowModal,
  template,
  projectId
}: {
  setShowModal: (showModal: boolean) => void;
  template: Template;
  projectId: string;
}) => {
  const { mutateAsync: setOptions, isLoading } = useSetProjectTemplateOptions();
  const templateRenderOptions = template.defaultRenderOptions || {};
  const { t } = useTranslation();

  const onClose = () => {
    setShowModal(false);
  };

  const { urlsValid, handleInvalidUrls } = useValidateUrls();

  return (
    <>
      <div className="border-gray-20 border-b px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t('components.project.template.TemplateDetails.templateDefaultRenderOptionsUpdate')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t('components.project.template.TemplateDetails.templateDefaultRenderOptionsDesc')}
        </p>
      </div>
      <RenderAdvancedOptionsForm
        className="max-h-vh-300 overflow-auto"
        onSave={(newRenderOptions: RenderOptionsDto) => {
          setOptions({ projectId, templateId: template.id, options: newRenderOptions }).then(() => {
            setShowModal(false);
          });
        }}
        advancedOptions={templateRenderOptions}
        onValidation={handleInvalidUrls}
        formButtons={
          <div className="flex justify-end rounded-b-md border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
            <Button secondary onClick={onClose} disabled={isLoading}>
              {t('general.action.cancel')}
            </Button>
            <Button type="submit" className="ml-2" disabled={isLoading || !urlsValid} loading={isLoading}>
              {t('general.action.save')}
            </Button>
          </div>
        }
      />
    </>
  );
};
