import { createContext, useContext, useEffect, useState } from 'react';
import makeAsyncScriptLoader from 'react-async-script';

import { useAppmixerCredentials } from '@src/hooks/api/useIntegrationsApi';
import { ErrorCode, PlainlyApiError } from '@src/models';
import { appmixerInitializer } from '@src/tools/appmixer';

import { Loading } from '../common';

import './styles/default.css';

export declare type AppmixerContextType =
  | {
      ready: false;
      error?: PlainlyApiError;
    }
  | {
      ready: true;
      domain: string;
      appmixer: any; // eslint-disable-line
    };

const initialContext: AppmixerContextType = { ready: false };

const AppmixerContext = createContext<AppmixerContextType>(initialContext);

const EmptyPlaceholder = () => <></>;

export const useAppmixerContext = () => {
  const context = useContext(AppmixerContext);
  return context;
};

export const AppmixerProvider = (props: React.PropsWithChildren<{ showLoading?: boolean }>) => {
  const [context, setContext] = useState<AppmixerContextType>(initialContext);

  // app mixer user credentials
  const { data: credentials, isLoading, error } = useAppmixerCredentials({ silentNotifications: true });

  // script loader
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const AppmixerScriptLoader = makeAsyncScriptLoader(import.meta.env.VITE_APP_APPMIXER_SCRIPT_URL)(
    props.showLoading ? Loading : EmptyPlaceholder
  );

  useEffect(() => {
    if (credentials && scriptLoaded && !isLoading) {
      if (context.ready) {
        // if we already have an instance, only update the access token
        context.appmixer.set('accessToken', credentials.token);
      } else {
        // init the whole widget
        try {
          const initiatedAppmixer = appmixerInitializer(credentials.baseUrl, credentials.token);
          setContext({
            ready: true,
            domain: import.meta.env.VITE_APP_APPMIXER_DOMAIN,
            appmixer: initiatedAppmixer
          });
        } catch (error) {
          setContext({
            ready: false,
            error: new PlainlyApiError(
              ErrorCode.INTEGRATION_SERVICE_FAILURE,
              {},
              false,
              'Failed to initialize the integration service SDK.'
            )
          });
        }
      }
    }
  }, [scriptLoaded, isLoading, credentials, context, setContext]);

  useEffect(() => {
    if (error) {
      setContext({ ready: false, error: error });
    }
  }, [error, setContext]);

  return (
    <>
      {!context.ready && (
        <AppmixerScriptLoader
          asyncScriptOnLoad={() => {
            setScriptLoaded(true);
          }}
        />
      )}
      <AppmixerContext.Provider value={context}>{props.children}</AppmixerContext.Provider>
    </>
  );
};
