import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { HandThumbUpIcon, IdentificationIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { Button, Modal } from '@src/components';
import { useQueryParams } from '@src/hooks';
import { Brand } from '@src/models';
import * as routes from '@src/routes';

const items = [
  {
    id: 1,
    name: 'components.common.socialMedia',
    description: 'components.brand.videoTypes.modal.socialMediaDescription',
    color: 'bg-indigo-500',
    icon: HandThumbUpIcon,
    temporaryDisabled: false,
    type: 'SOCIAL_MEDIA'
  },
  {
    id: 2,
    name: 'components.publishers.common.presenter',
    description: 'components.brand.videoTypes.modal.presenterDescription',
    color: 'bg-green-500',
    icon: IdentificationIcon,
    temporaryDisabled: true,
    type: 'PRESENTER'
  },
  {
    id: 3,
    name: 'components.publishers.common.summarySlideshow',
    description: 'components.brand.videoTypes.modal.summarySlideshowDescription',
    color: 'bg-yellow-500',
    icon: ViewColumnsIcon,
    temporaryDisabled: false,
    type: 'SUMMARY_SLIDESHOW'
  }
];

type VideoTypeCreateModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  brand: Brand;
};

const VideoTypeCreateModalContent = ({ setShowModal, brand }: Omit<VideoTypeCreateModalProps, 'showModal'>) => {
  const { t } = useTranslation();
  const { withQueryParams } = useQueryParams();

  return (
    <>
      <h3 className="p-6 text-lg font-medium leading-6 text-gray-900">
        {t('components.brand.BrandDetails.videoTypeCreateModal.heading')}
      </h3>
      <ul className="max-h-96 min-h-[200px] scroll-py-3 overflow-y-auto p-3" id="options" role="listbox">
        {items.map((item, index) => (
          <Link
            key={index}
            to={withQueryParams(generatePath(routes.VIDEO_TYPE_CREATE, { id: brand.id }), {
              type: item.type
            })}
            className={classNames(item.temporaryDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto')}
          >
            <li
              className="group flex cursor-pointer select-none rounded-xl p-3 hover:bg-gray-100"
              key={index}
              id={`option-${index}`}
              tabIndex={-1}
            >
              <div
                className={classNames('flex h-10 w-10 flex-none items-center justify-center rounded-lg', item.color)}
              >
                <item.icon className="h-6 w-6 text-white" />
              </div>
              <div className="ml-4 flex-auto">
                <p className="text-sm font-medium text-gray-700">{t(item.name)}</p>
                <p className="text-sm text-gray-500">{t(item.description)}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      <div className="flex justify-end p-3">
        <Button secondary onClick={() => setShowModal(false)}>
          {t('general.action.cancel')}
        </Button>
      </div>
    </>
  );
};

export const VideoTypeCreateModal = ({ showModal, setShowModal, brand }: VideoTypeCreateModalProps) => {
  return (
    <Modal visible={showModal} close={() => setShowModal(false)} size="normal" removeOuterPadding>
      <VideoTypeCreateModalContent setShowModal={setShowModal} brand={brand} />
    </Modal>
  );
};
