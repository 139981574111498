import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AppRender, Design, DesignRender, ErrorOptions, Render } from '@src/models';

import { relativizeUrl, useAxiosModify, useAxiosRead } from '../useAxios';

import { rendersCacheAdd } from './useRenderApi';

const DESIGNS_CACHE_ROOT = 'designs';

export const useGetDesigns = () => {
  const { get } = useAxiosRead<Design[]>();

  const { data, isLoading, error } = useQuery<Design[]>(DESIGNS_CACHE_ROOT, async () => {
    const response = await get('/designs');
    return response.data;
  });

  return { data, isLoading, error };
};

export const useGetDesignDetails = (designId: string | undefined, enabled = true, errorOptions?: ErrorOptions) => {
  const { get } = useAxiosRead<Design>({}, errorOptions);

  const cacheKey = [DESIGNS_CACHE_ROOT, designId];

  const { data, isLoading, error, refetch } = useQuery<Design>(
    cacheKey,
    async () => {
      const response = await get(`/designs/${designId}`);
      return response.data;
    },
    {
      enabled: !!designId && enabled
    }
  );

  return { data, isLoading, error, refetch };
};

export const useFireDesignRender = () => {
  const { post } = useAxiosModify<Partial<DesignRender>, Render>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (render: Partial<DesignRender>) => {
      const response = await post('/designs', render);

      return response.data;
    },
    onSuccess: render => {
      rendersCacheAdd(queryClient, render);
    }
  });

  return { mutateAsync, isLoading, error };
};

export const useFireDesignAppRender = (webApiUrl: string) => {
  const { post } = useAxiosModify<Partial<AppRender>, Render>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: async (render: Partial<AppRender>) => {
      const url = relativizeUrl(webApiUrl);
      const response = await post(url, render);

      return response.data;
    },
    onSuccess: render => {
      rendersCacheAdd(queryClient, render);
    }
  });

  return { mutateAsync, isLoading, error };
};
