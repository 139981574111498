export enum ErrorCode {
  API_MAINTENANCE = 'API_MAINTENANCE',

  /**
   * Project V2 API
   */
  PROJECT_DOES_NOT_EXIST = 'PROJECT_DOES_NOT_EXIST',
  PROJECT_TEMPLATE_DOES_NOT_EXIST = 'PROJECT_TEMPLATE_DOES_NOT_EXIST',
  PROJECT_NOT_OWNED = 'PROJECT_NOT_OWNED',
  PROJECT_ALREADY_UPLOADED = 'PROJECT_ALREADY_UPLOADED',
  PROJECT_UPLOAD_AEP_FILE_DUPLICATE = 'PROJECT_UPLOAD_AEP_FILE_DUPLICATE',
  PROJECT_UPLOAD_AEP_FILE_NOT_FOUND = 'PROJECT_UPLOAD_AEP_FILE_NOT_FOUND',
  PROJECT_UPLOAD_AEP_FILE_EMPTY = 'PROJECT_UPLOAD_AEP_FILE_EMPTY',
  PROJECT_UPLOAD_CHAR_ENCODING_WRONG = 'PROJECT_UPLOAD_CHAR_ENCODING_WRONG',
  PROJECT_UPLOAD_ASSET_PATH_WRONG = 'PROJECT_UPLOAD_ASSET_PATH_WRONG',
  PROJECT_UPLOAD_PROCESSING_FAILED = 'PROJECT_UPLOAD_PROCESSING_FAILED',
  PROJECT_UPLOAD_ZIP_FILE_INVALID = 'PROJECT_UPLOAD_ZIP_FILE_INVALID',
  PROJECT_RE_UPLOAD_BACKUP_FAILED = 'PROJECT_RE_UPLOAD_BACKUP_FAILED',
  PROJECT_RE_UPLOAD_HAS_ACTIVE_RENDERS = 'PROJECT_RE_UPLOAD_HAS_ACTIVE_RENDERS',
  PROJECT_NOT_ANALYZED = 'PROJECT_NOT_ANALYZED',
  PROJECT_ANALYSIS_PENDING = 'PROJECT_ANALYSIS_PENDING',
  PROJECT_METADATA_COMPOSITION_DOES_NOT_EXIST = 'PROJECT_METADATA_COMPOSITION_DOES_NOT_EXIST',
  PROJECT_SHARING_NOT_ENABLED = 'PROJECT_SHARING_NOT_ENABLED',
  PROJECT_ANALYSIS_REANALYZE_LIMIT = 'PROJECT_ANALYSIS_REANALYZE_LIMIT',
  PROJECT_HAS_ACTIVE_RENDERS = 'PROJECT_HAS_ACTIVE_RENDERS',
  PROJECT_DOWNLOAD_FAILED = 'PROJECT_DOWNLOAD_FAILED',
  PROJECT_UPGRADE_ALREADY_FAILED = 'PROJECT_UPGRADE_ALREADY_FAILED',

  /**
   * Render V2 API
   */
  RENDER_DOES_NOT_EXIST = 'RENDER_DOES_NOT_EXIST',
  RENDER_NOT_OWNED = 'RENDER_NOT_OWNED',
  RENDER_EXPIRED = 'RENDER_EXPIRED',
  RENDER_PROJECT_NOT_UPLOADED = 'RENDER_PROJECT_NOT_UPLOADED',
  RENDER_PROJECT_NOT_ANALYZED = 'RENDER_PROJECT_NOT_ANALYZED',
  RENDER_PROJECT_NOT_TEMPLATED = 'RENDER_PROJECT_NOT_TEMPLATED',
  RENDER_MEDIA_ASSET_NOT_VALID_URL = 'RENDER_MEDIA_ASSET_NOT_VALID_URL',
  RENDER_RESUBMIT_PARAMETRIZATION_ERROR = 'RENDER_RESUBMIT_PARAMETRIZATION_ERROR',
  RENDER_NOT_FINISHED = 'RENDER_NOT_FINISHED',
  RENDER_DOES_NOT_HAVE_WEBHOOK = 'RENDER_DOES_NOT_HAVE_WEBHOOK',
  RENDER_INFRASTRUCTURE_ERROR = 'RENDER_INFRASTRUCTURE_ERROR',
  RENDER_SCRIPTS_SCENE_MANAGEMENT_TOO_MANY_SCENES = 'RENDER_SCRIPTS_SCENE_MANAGEMENT_TOO_MANY_SCENES',
  RENDER_SCRIPTS_SCENE_MANAGEMENT_PARAMETER_INVALID = 'RENDER_SCRIPTS_SCENE_MANAGEMENT_PARAMETER_INVALID',
  RENDER_SCRIPTS_SCENE_MANAGEMENT_PARAMETER_MISSING = 'RENDER_SCRIPTS_SCENE_MANAGEMENT_PARAMETER_MISSING',
  RENDER_SCRIPTS_IMAGE_SEQUENCE_PARAMETER_INVALID = 'RENDER_SCRIPTS_IMAGE_SEQUENCE_PARAMETER_INVALID',
  RENDER_SCRIPTS_IMAGE_SEQUENCE_PARAMETER_EMPTY = 'RENDER_SCRIPTS_IMAGE_SEQUENCE_PARAMETER_EMPTY',
  RENDER_OUTPUT_MODULE_NOT_SUPPORTED_BY_AFTER_EFFECTS_VERSION = 'RENDER_OUTPUT_MODULE_NOT_SUPPORTED_BY_AFTER_EFFECTS_VERSION',
  RENDER_SETTINGS_TEMPLATE_NOT_SUPPORTED_BY_AFTER_EFFECTS_VERSION = 'RENDER_SETTINGS_TEMPLATE_NOT_SUPPORTED_BY_AFTER_EFFECTS_VERSION',
  RENDER_ORGANIZATION_QUEUE_FULL = 'RENDER_ORGANIZATION_QUEUE_FULL',
  RENDER_NOT_THROTTLED = 'RENDER_NOT_THROTTLED',

  /**
   * User API
   */
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
  USER_NOT_CREATED = 'USER_NOT_CREATED',
  USER_EMAIL_NOT_VERIFIED = 'USER_EMAIL_NOT_VERIFIED',
  USER_AUTHENTICATION_MISSING = 'USER_AUTHENTICATION_MISSING',
  USER_AUTHENTICATION_SERVICE_ERROR = 'USER_AUTHENTICATION_SERVICE_ERROR',
  USER_ORGANIZATION_DOES_NOT_EXIST = 'USER_ORGANIZATION_DOES_NOT_EXIST',
  USER_ORGANIZATION_ROLE_INSUFFICIENT = 'USER_ORGANIZATION_ROLE_INSUFFICIENT',
  USER_ORGANIZATION_ALREADY_MEMBER = 'USER_ORGANIZATION_ALREADY_MEMBER',
  USER_ORGANIZATION_INVITE_MULTIPLE_USERS = 'USER_ORGANIZATION_INVITE_MULTIPLE_USERS',
  USER_ORGANIZATION_INVITE_DUPLICATE = 'USER_ORGANIZATION_INVITE_DUPLICATE',
  USER_ORGANIZATION_INVITE_NOT_FOUND = 'USER_ORGANIZATION_INVITE_NOT_FOUND',
  USER_ORGANIZATION_INVITE_NOT_OWNED = 'USER_ORGANIZATION_INVITE_NOT_OWNED',
  USER_ORGANIZATION_INVITE_PENDING = 'USER_ORGANIZATION_INVITE_PENDING',
  USER_ORGANIZATION_REMOVAL_NOT_ALLOWED = 'USER_ORGANIZATION_REMOVAL_NOT_ALLOWED',
  USER_ORGANIZATION_ROLE_CHANGE_NOT_ALLOWED = 'USER_ORGANIZATION_ROLE_CHANGE_NOT_ALLOWED',

  /**
   * Usage API
   */
  USAGE_RESOURCE_NOT_AVAILABLE = 'USAGE_RESOURCE_NOT_AVAILABLE',
  USAGE_RESOURCE_AUTH_LIMIT = 'USAGE_RESOURCE_AUTH_LIMIT',
  USAGE_RESOURCE_CHANGE_MISMATCH = 'USAGE_RESOURCE_CHANGE_MISMATCH',
  USAGE_DETAILS_NOT_AVAILABLE = 'USAGE_DETAILS_NOT_AVAILABLE',
  USAGE_RESOURCE_DECREASE_INSUFFICIENT = 'USAGE_RESOURCE_DECREASE_INSUFFICIENT',
  USAGE_RESOURCE_DECREASE_REFERENCE_MISSING = 'USAGE_RESOURCE_DECREASE_REFERENCE_MISSING',

  /**
   * Subscription API
   */
  SUBSCRIPTION_NOT_ACTIVE = 'SUBSCRIPTION_NOT_ACTIVE',
  SUBSCRIPTION_UPGRADE_PLAN_NOT_VALID = 'SUBSCRIPTION_UPGRADE_PLAN_NOT_VALID',
  SUBSCRIPTION_UPGRADE_SERVICE_ERROR = 'SUBSCRIPTION_UPGRADE_SERVICE_ERROR',
  SUBSCRIPTION_PAST_DUE = 'SUBSCRIPTION_PAST_DUE',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',

  /**
   * Designs
   */
  DESIGN_DOES_NOT_EXIST = 'DESIGN_DOES_NOT_EXIST',
  DESIGN_VARIANT_DOES_NOT_EXIST = 'DESIGN_VARIANT_DOES_NOT_EXIST',

  /**
   * Twitter App
   */
  APP_DESIGN_NOT_SUPPORTED = 'APP_DESIGN_NOT_SUPPORTED',
  APP_TWITTER_TWEET_URL_NOT_VALID = 'APP_TWITTER_TWEET_URL_NOT_VALID',
  APP_TWITTER_TWEET_NOT_FOUND = 'APP_TWITTER_TWEET_NOT_FOUND',
  APP_TWITTER_TWEET_FETCH_FAILED = 'APP_TWITTER_TWEET_FETCH_FAILED',
  APP_TWITTER_FORBIDDEN = 'APP_TWITTER_FORBIDDEN',
  APP_TWITTER_GATEWAY_ERROR = 'APP_TWITTER_GATEWAY_ERROR',

  /**
   * Brand
   */
  BRAND_ARTICLE_DOES_NOT_EXIST = 'BRAND_ARTICLE_DOES_NOT_EXIST',
  BRAND_ARTICLE_NOT_OWNED = 'BRAND_ARTICLE_NOT_OWNED',
  BRAND_ARTICLE_VIDEO_DOES_NOT_EXIST = 'BRAND_ARTICLE_VIDEO_DOES_NOT_EXIST',
  BRAND_ARTICLE_VIDEO_REVISION_DOES_NOT_EXIST = 'BRAND_ARTICLE_VIDEO_REVISION_DOES_NOT_EXIST',
  BRAND_ARTICLE_VIDEO_REVISION_STATE_INVALID = 'BRAND_ARTICLE_VIDEO_REVISION_STATE_INVALID',
  BRAND_ARTICLE_URL_NOT_FOUND = 'BRAND_ARTICLE_URL_NOT_FOUND',
  BRAND_DOES_NOT_EXIST = 'BRAND_DOES_NOT_EXIST',
  BRAND_NOT_OWNED = 'BRAND_NOT_OWNED',

  /**
   * Integrations (not user facing errors)
   */
  INTEGRATION_NOT_OWNED = 'INTEGRATION_NOT_OWNED',
  INTEGRATION_DOES_NOT_EXIST = 'INTEGRATION_DOES_NOT_EXIST',
  INTEGRATION_DUPLICATED_ACCOUNT = 'INTEGRATION_DUPLICATED_ACCOUNT',
  INTEGRATION_SERVICE_FAILURE = 'INTEGRATION_SERVICE_FAILURE',

  /**
   * Feature gating
   */
  FEATURE_GATING_NOT_ALLOWED_SHARING_LINKS = 'FEATURE_GATING_NOT_ALLOWED_SHARING_LINKS',
  FEATURE_GATING_NOT_ALLOWED_INTEGRATIONS = 'FEATURE_GATING_NOT_ALLOWED_INTEGRATIONS',
  FEATURE_GATING_NOT_ALLOWED_TEAMS = 'FEATURE_GATING_NOT_ALLOWED_TEAMS',
  FEATURE_GATING_NOT_ALLOWED_PROJECT_DOWNLOAD = 'FEATURE_GATING_NOT_ALLOWED_PROJECT_DOWNLOAD',

  /**
   * Summarization service
   */
  SUMMARIZATION_SERVICE_ERROR = 'SUMMARIZATION_SERVICE_ERROR',
  VOICEOVER_SERVICE_ERROR = 'VOICEOVER_SERVICE_ERROR',
  VOICEOVER_DURATION_NOT_READABLE = 'VOICEOVER_DURATION_NOT_READABLE',

  /**
   * Client-side errors (not sent by the backend)
   */
  GENERAL_NO_INTERNET_CONNECTION = 'GENERAL_NO_INTERNET_CONNECTION',
  GENERAL_NO_ACCESS_TOKEN = 'GENERAL_NO_ACCESS_TOKEN',
  GENERAL_CLIENT_SIDE_ERROR = 'GENERAL_CLIENT_SIDE_ERROR',
  GENERAL_SERVER_SIDE_ERROR = 'GENERAL_SERVER_SIDE_ERROR',
  GENERAL_COMMUNICATION_ERROR = 'GENERAL_COMMUNICATION_ERROR',
  GENERAL_TOO_MANY_REQUESTS = 'GENERAL_TOO_MANY_REQUESTS',
  GENERAL_UNAUTHORIZED = 'GENERAL_UNAUTHORIZED',
  GENERAL_FORBIDDEN = 'GENERAL_FORBIDDEN'
}

export type ErrorOptions = {
  silentNotifications?: boolean;
  throwOnError?: boolean;
  sentryErrorFilter?: (error: Error | PlainlyApiError) => boolean;
};

export type SubError = {
  defaultMessage?: string;
  field?: string;
};

/**
 * Main api error class.
 */
export class PlainlyApiError extends Error {
  public code: ErrorCode;
  public options: ErrorOptions;
  public generalError?: boolean;
  public errorMessage?: string;
  public errors?: (SubError & Record<string, unknown>)[];
  public shouldRetry?: boolean;

  constructor(
    code: ErrorCode,
    options: ErrorOptions,
    generalError?: boolean,
    errorMessage?: string,
    errors?: (SubError & Record<string, unknown>)[],
    name = 'PlainlyApiError',
    shouldRetry?: boolean
  ) {
    super(errorMessage || code.toString());
    this.code = code;
    this.options = options;
    this.generalError = generalError;
    this.errorMessage = errorMessage;
    this.name = `${name}[${code.toString()}]`;
    this.shouldRetry = shouldRetry;
    this.errors = errors;

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, PlainlyApiError.prototype);
  }

  /**
   * If error should be reported to a error service like Sentry
   */
  public shouldReport() {
    switch (this.code) {
      // all ignorable errors that should not be reported as severe
      // for now we report everything
      // api
      case ErrorCode.API_MAINTENANCE:
      // general
      // eslint-disable-next-line no-fallthrough
      case ErrorCode.GENERAL_NO_INTERNET_CONNECTION:
        return false;

      default:
        return true;
    }
  }
}

/**
 * This is error that denotes there is no internet connection.
 */
export class NoInternetConnectionApiError extends PlainlyApiError {
  constructor(options: ErrorOptions) {
    super(ErrorCode.GENERAL_NO_INTERNET_CONNECTION, options, true, 'NoInternetConnectionApiError');

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, NoInternetConnectionApiError.prototype);
  }
}

/**
 * This is error that denotes there is no access token.
 */
export class NoAccessTokenApiError extends PlainlyApiError {
  constructor(options: ErrorOptions) {
    super(ErrorCode.GENERAL_NO_ACCESS_TOKEN, options, true, 'NoAccessTokenApiError');

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, NoAccessTokenApiError.prototype);
  }
}

/**
 * This is error that denotes there is a 4xx response with an acceptable status like (f.e. 401).
 */
export class AcceptableClientSideApiError extends PlainlyApiError {
  public statusCode: number;

  constructor(errorCode: ErrorCode, options: ErrorOptions, statusCode: number) {
    super(errorCode, options, true, undefined, undefined, 'AcceptableClientSideApiError');
    this.statusCode = statusCode;

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, AcceptableClientSideApiError.prototype);
  }
}

/**
 * This is error that denotes there is a 4xx response.
 */
export class ClientSideApiError extends PlainlyApiError {
  public statusCode: number;

  constructor(options: ErrorOptions, statusCode: number, errorMessage?: string, errors?: SubError[]) {
    super(ErrorCode.GENERAL_CLIENT_SIDE_ERROR, options, true, errorMessage, errors, 'ClientSideApiError', true);
    this.statusCode = statusCode;

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ClientSideApiError.prototype);
  }
}

/**
 * This is error that denotes there is a 5xx response.
 */
export class ServerSideApiError extends PlainlyApiError {
  public statusCode: number;

  constructor(options: ErrorOptions, statusCode: number, errorMessage?: string, errors?: SubError[]) {
    super(ErrorCode.GENERAL_SERVER_SIDE_ERROR, options, true, errorMessage, errors, 'ServerSideApiError', true);
    this.statusCode = statusCode;

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ServerSideApiError.prototype);
  }
}

/**
 * This is error that denotes there is a 5xx response.
 */
export class GeneralCommunicationApiError extends PlainlyApiError {
  constructor(options: ErrorOptions, errorMessage?: string, errors?: SubError[]) {
    super(
      ErrorCode.GENERAL_COMMUNICATION_ERROR,
      options,
      true,
      errorMessage,
      errors,
      'GeneralCommunicationApiError',
      true
    );

    // Set the prototype explicitly.
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, GeneralCommunicationApiError.prototype);
  }
}
