import { Key, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@src/components';
import { isEmpty } from '@src/utils';

const EmptyList = ({ title }: { title: string }) => (
  <div className="inline-flex h-full w-full grow flex-col place-content-center items-center">
    <div className="flex flex-col items-center">
      <span className="my-6 text-sm">{title}</span>
    </div>
  </div>
);

type HeaderProps =
  | {
      header?: ReactNode;
      headerTitle?: never;
      headerAction?: never;
    }
  | {
      header?: never;
      headerTitle?: string;
      headerAction?: ReactNode;
    };

export type ListProps<T> = HeaderProps & {
  emptyListMessage?: string;
  data: T[] | null | undefined;
  renderItem: (item: T, index?: Key) => ReactNode;
  onLoadMore?: () => void;
  pageSize?: number;
};

export const List = <T,>({
  emptyListMessage,
  data,
  renderItem,
  pageSize,
  onLoadMore,
  header,
  headerAction,
  headerTitle
}: ListProps<T>) => {
  const { t } = useTranslation();

  pageSize = pageSize || 1;
  const isLoadMoreVisible = (data?.length || 0) === pageSize && onLoadMore;

  const plainList = (
    <>
      {isEmpty(data) && emptyListMessage && <EmptyList title={emptyListMessage || t('general.common.emptyList')} />}
      {!isEmpty(data) && (
        <div>
          <div className="inline-block w-full bg-white shadow sm:rounded-md">
            {header}
            <ul className="flex flex-col divide-y divide-gray-300">{data?.map(renderItem)}</ul>
          </div>
          {isLoadMoreVisible && (
            <div className="my-4 flex w-full place-content-center">
              <Button secondary onClick={() => onLoadMore && onLoadMore()}>
                {t('general.common.loadMore')}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      {headerAction || headerTitle ? (
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-lg font-medium leading-6 text-gray-900">{headerTitle}</h2>
            </div>
            <div className="flex pt-4 md:block md:pt-0">{headerAction}</div>
          </div>
          <div className="border-t border-gray-200">{plainList}</div>
        </div>
      ) : (
        plainList
      )}
    </>
  );
};
