import { useTranslation } from 'react-i18next';

import { AssetFormSection, MediaInput, RequiredMarker } from '@src/components';
import { SocialMediaRevisionUpdateDto } from '@src/models';

export const SocialMediaFormInputs = ({
  inputs,
  setInputs,
  disabled,
  handleInvalidUrls
}: {
  inputs: SocialMediaRevisionUpdateDto;
  setInputs: (inputs: SocialMediaRevisionUpdateDto) => void;
  disabled?: boolean;
  handleInvalidUrls: (key: string) => (url: string, valid: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="heading" className="block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.socialMediaSettings.form.heading')}
          <RequiredMarker />
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.headingDescription')}
        </p>
        <textarea
          id="heading"
          name="heading"
          rows={3}
          className="mt-1 block w-full rounded-md border border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm"
          onChange={e => setInputs({ ...inputs, heading: e.target.value })}
          value={inputs.heading}
          required
          disabled={disabled}
        />
      </div>
      <div>
        <label htmlFor="subheading" className="block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.socialMediaSettings.form.subheading')}
          <RequiredMarker />
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.subheadingDescription')}
        </p>
        <textarea
          id="subheading"
          name="subheading"
          rows={6}
          className="mt-1 block w-full rounded-md border border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm"
          onChange={e => setInputs({ ...inputs, subheading: e.target.value })}
          value={inputs.subheading}
          required
          disabled={disabled}
        />
      </div>
      <div>
        <label htmlFor="cta" className="block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.socialMediaSettings.form.cta')}
          <RequiredMarker />
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.ctaDescription')}
        </p>
        <input
          disabled={disabled}
          type="text"
          name="cta"
          id="cta"
          className="mt-1 block w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm"
          onChange={e => setInputs({ ...inputs, cta: e.target.value })}
          value={inputs.cta}
          required
        />
      </div>
      <AssetFormSection
        currentAsset={inputs.asset}
        setCurrentAsset={asset => setInputs({ ...inputs, asset })}
        disabled={disabled}
        socialMedia
      />
      <div>
        <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700">
          {t('components.publishers.forms.logoUrl')}
          <RequiredMarker />
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.socialMediaSettings.form.logoUrlDescription')}
        </p>
        <div className="mt-1">
          <MediaInput
            disabled={disabled}
            required
            onFieldUpdate={value => setInputs({ ...inputs, logoUrl: value })}
            value={inputs.logoUrl}
            onValidation={handleInvalidUrls('logoUrl')}
          />
        </div>
      </div>
      <div>
        <label htmlFor="musicUrl" className="block text-sm font-medium text-gray-700">
          {t('components.article.ArticleVideo.socialMediaSettings.form.musicUrl')}
          <p className="text-sm font-normal text-gray-500">
            {t('components.article.ArticleVideo.socialMediaSettings.form.musicUrlDescription')}
          </p>
        </label>
        <div className="mt-1">
          <MediaInput
            disabled={disabled}
            onFieldUpdate={value => setInputs({ ...inputs, musicUrl: value || undefined })}
            value={inputs.musicUrl}
            onValidation={handleInvalidUrls('musicUrl')}
          />
        </div>
      </div>
    </div>
  );
};
