const isProduction = `${import.meta.env.NODE_ENV}` === 'production';

/**
 * Appmixer initializer, must be called only once the Appmixer script has been loaded.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appmixerInitializer = (baseUrl: string, token: string): any => {
  /**
   * Create a new Appmixer instance.
   * https://docs.appmixer.com/appmixer/appmixer-sdk/constructor
   *
   * Theme selectors: https://my.appmixer.com/appmixer/package/theme-light.json
   */
  // @ts-expect-error Cannot find name 'Appmixer'. Did you mean 'appmixer'?
  const appmixer = new Appmixer({
    debug: !isProduction,
    baseUrl,
    theme: {
      variables: {
        font: {
          family: 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
          size: 15,
          weightRegular: 400,
          weightMedium: 500,
          weightSemibold: 600
        },
        colors: {}
      },
      ui: {
        '#Integrations': {
          background: 'transparent',
          '#integration': {
            '#title': {
              whiteSpace: 'pre-wrap'
            }
          }
        }
      }
    }
  });

  appmixer.set('accessToken', token);

  return appmixer;
};
