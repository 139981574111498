import { useEffect, useState } from 'react';

import { DesignInputErrors, PopoverColorPicker } from '@src/components';
import { isEmpty } from '@src/utils';

export type DesignColorInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onFieldUpdate: (value: string) => void;
  errors?: string[];
};

export const DesignColorInput = ({ type, value, onFieldUpdate, errors, ...rest }: DesignColorInputProps) => {
  const [color, setColor] = useState<string>('');

  useEffect(() => {
    setColor(value?.toString() || '');
  }, [value]);

  return (
    <>
      <div className="relative rounded-md shadow-sm">
        <input
          className="block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          type={type || 'text'}
          value={color}
          autoComplete="off"
          pattern="^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
          onChange={e => onFieldUpdate(e.target.value)}
          {...rest}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <PopoverColorPicker
            color={color}
            onColorChange={newColor => {
              if (!newColor.includes('NaN')) {
                setColor(newColor);
                onFieldUpdate(newColor);
              }
            }}
          />
        </div>
      </div>
      {!isEmpty(errors) && <DesignInputErrors errors={errors} />}
    </>
  );
};
