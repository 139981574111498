import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { Button, Header, HelpNotification, ProjectsHelpLinks } from '@src/components';
import { PROJECTS_CREATE } from '@src/routes';

import { ProjectUpgradeAllAction } from './ProjectUpgradeAllAction';

export const ProjectsHeader = () => {
  const { t } = useTranslation();

  const menuLink = useMemo(() => {
    return {
      label: t('components.common.templateForm'),
      link: 'https://airtable.com/appwjfPdIwIxZzLn3/shrQziJgM5FopOhiA',
      icon: <Bars3BottomLeftIcon className="mr-2 h-5 w-5" />
    };
  }, [t]);

  return (
    <Header
      header={t('general.common.projects')}
      actions={
        <div className="flex flex-row-reverse md:flex-row">
          <HelpNotification
            type="button"
            links={ProjectsHelpLinks}
            className="ml-2 md:ml-0"
            additionalItems={[menuLink]}
          />
          <ProjectUpgradeAllAction />

          <Link to={PROJECTS_CREATE}>
            <Button className="md:ml-2">{t('components.project.ProjectsHeader.upload')}</Button>
          </Link>
        </div>
      }
    />
  );
};
