import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScreenPage } from '@src/components';
import { TemplateEdit } from '@src/components/project/template/TemplateEdit';

export const TemplateEditPage = () => {
  const { t } = useTranslation();
  const { projectId, templateId, step } = useParams() as { projectId: string; templateId: string; step?: 'layers' };

  return (
    <HelmetProvider>
      <ScreenPage>
        <Helmet>
          <title>{t('general.pageTitle.TemplateEditPage')}</title>
        </Helmet>
        <TemplateEdit projectId={projectId} templateId={templateId} step={step} />
      </ScreenPage>
    </HelmetProvider>
  );
};
