import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FullPage,
  Loading,
  LoadingProps,
  NotFound,
  VideoPresenterDetails,
  VideoSocialMediaDetails,
  VideoSummarySlideshowDetails
} from '@src/components';
import { useGetArticleDetails, useGetArticleVideo, useGetBrandDetails, useVideoNavigationParams } from '@src/hooks';
import { AnyVideoRevision } from '@src/models';
import { isEmpty } from '@src/utils';

const LoadingVideoDetails = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const VideoDetailsPage = () => {
  const { t } = useTranslation();
  const { brandId, articleId, videoId } = useParams() as {
    brandId: string;
    articleId: string;
    videoId: string;
  };

  const { isLoading: isLoadingBrand, data: brand } = useGetBrandDetails(brandId);
  const { isLoading: isLoadingArticle, data: article } = useGetArticleDetails(brandId, articleId);
  const { isLoading: isLoadingVideo, data: video } = useGetArticleVideo(brandId, articleId, videoId);

  const isLoading = isLoadingBrand || isLoadingArticle || isLoadingVideo;
  const revisions: AnyVideoRevision[] | undefined = video?.data.revisions;

  const { videoNavigationState, updateVideoNavigationState } = useVideoNavigationParams();

  useEffect(() => {
    if (!videoNavigationState && !isEmpty(revisions)) {
      const revisionId = revisions[revisions.length - 1].id;
      if (video?.data.videoType === 'SOCIAL_MEDIA') {
        updateVideoNavigationState({
          revisionId: revisionId,
          activeView: 'sm-settings',
          assetsTab: 'search'
        });
      } else {
        updateVideoNavigationState({
          revisionId: revisionId,
          activeView: 'scenes',
          sceneId: 1,
          headlinesTab: 1,
          assetsTab: 'suggested'
        });
      }
    }
  }, [videoNavigationState, updateVideoNavigationState, video?.data.videoType, revisions]);

  const currentRevision = revisions?.find(revision => revision.id === videoNavigationState?.revisionId);
  const clonedRevision = revisions?.find(revision => revision.id === currentRevision?.clonedFrom);

  return (
    <HelmetProvider>
      {isLoading && <LoadingVideoDetails title={t('components.article.ArticleVideo.loading')} />}
      {!isLoading && (
        <>
          {!video && <NotFound title={t('components.article.ArticleVideo.empty')} />}
          {video && article && brand && revisions && currentRevision && (
            <>
              <FullPage className="flex">
                {currentRevision.videoType === 'SOCIAL_MEDIA' && (
                  <VideoSocialMediaDetails
                    currentRevision={currentRevision}
                    clonedRevision={clonedRevision?.videoType === 'SOCIAL_MEDIA' ? clonedRevision : undefined}
                    revisions={revisions}
                    article={article}
                    brand={brand}
                    videoId={videoId}
                  />
                )}
                {currentRevision.videoType === 'SUMMARY_SLIDESHOW' && (
                  <VideoSummarySlideshowDetails
                    currentRevision={currentRevision}
                    clonedRevision={clonedRevision?.videoType === 'SUMMARY_SLIDESHOW' ? clonedRevision : undefined}
                    revisions={revisions}
                    article={article}
                    brand={brand}
                    videoId={videoId}
                  />
                )}
                {currentRevision.videoType === 'PRESENTER' && (
                  <VideoPresenterDetails
                    currentRevision={currentRevision}
                    clonedRevision={clonedRevision?.videoType === 'PRESENTER' ? clonedRevision : undefined}
                    revisions={revisions}
                    article={article}
                    brand={brand}
                    videoId={videoId}
                  />
                )}
              </FullPage>
            </>
          )}
        </>
      )}
    </HelmetProvider>
  );
};
