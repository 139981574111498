export enum AssetType {
  'DATA' = 'data',
  'IMAGE' = 'image',
  'VIDEO' = 'video',
  'AUDIO' = 'audio',
  'SCRIPT' = 'script'
}

export declare type MediaLayerAssetType = AssetType.IMAGE | AssetType.VIDEO | AssetType.AUDIO;

export declare type LayerAssetType = MediaLayerAssetType | AssetType.DATA;

export declare type AbstractAsset<T extends AssetType> = {
  type: T;
};

export declare type LayerAsset<T extends LayerAssetType> = AbstractAsset<T> & {
  composition: string;
  layerName: string;
};

export declare type DataAsset = LayerAsset<AssetType.DATA> & {
  property: string;
  value: string;
};

export declare type MediaAsset<T extends MediaLayerAssetType> = LayerAsset<T> & {
  composition: string;
  layerName: string;
  src: string;
};

export declare type ImageAsset = MediaAsset<AssetType.IMAGE>;
export declare type VideoAsset = MediaAsset<AssetType.VIDEO>;
export declare type AudioAsset = MediaAsset<AssetType.AUDIO>;

export declare type ScriptAsset = AbstractAsset<AssetType.SCRIPT> & {
  src: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters: Record<string, any>;
};

export declare type Asset = DataAsset | ImageAsset | VideoAsset | AudioAsset | ScriptAsset;
