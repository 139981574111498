import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BuildingOfficeIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@src/components/common';
import * as routes from '@src/routes';
import { State, useGlobalState } from '@src/state/store';

export const WelcomeHeader = () => {
  const { t } = useTranslation();
  const [user] = useGlobalState(State.USER);
  const [organization] = useGlobalState(State.ORGANIZATION);

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div className="py-6 lg:flex lg:items-center lg:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              {user?.picture && <img className="hidden h-16 w-16 rounded-full sm:block" src={user.picture} alt="" />}

              <div>
                <div className="flex items-center">
                  {user?.picture && <img className="h-16 w-16 rounded-full sm:hidden" src={user.picture} alt="" />}

                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:ml-3 sm:truncate sm:leading-9">
                    {t('components.layout.dashboard.WelcomeHeader.title', {
                      name: user?.name
                    })}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  {organization && (
                    <>
                      <dt className="sr-only">{t('general.common.organization')}</dt>
                      <dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
                        <BuildingOfficeIcon className="mr-1.5 h-5 w-5 shrink-0 text-gray-400" aria-hidden="true" />
                        {organization.name}
                      </dd>
                    </>
                  )}
                  {user?.emailVerified && (
                    <>
                      <dt className="sr-only">{t('components.layout.dashboard.WelcomeHeader.accountStatus')}</dt>
                      <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        <CheckCircleIcon className="mr-1.5 h-5 w-5 shrink-0 text-green-400" aria-hidden="true" />
                        {t('components.layout.dashboard.WelcomeHeader.verifiedAccount')}
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:ml-3 lg:mt-0">
            <Link to={routes.RENDER_FORM}>
              <Button>{t('components.common.action.newRender')}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
