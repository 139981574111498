import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  useChangeTeamMemberRole,
  useCreateOrganizationInvitation,
  useDeleteOrganizationInvitation,
  useDeleteTeamMember,
  useGetOrganizationInvitations,
  useGetTeamMembers,
  useNotifications,
  useQueryParams
} from '@src/hooks';
import localizationHelper from '@src/i18n';
import { OrganizationRole, PlainlyPackage, TeamMemberDto } from '@src/models';
import * as routes from '@src/routes';
import { State, useGlobalState } from '@src/state/store';
import { isEmpty } from '@src/utils';

import { Badge, Button, Loading, Modal, RequiredMarker } from '../common';
import { FeatureGating } from '../featureGating';

const TeamInviteModal = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();

  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<OrganizationRole>(OrganizationRole.MEMBER);

  const { mutateAsync, isLoading } = useCreateOrganizationInvitation();
  const canGenerate = !!email && !!role;

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (canGenerate) {
        try {
          await mutateAsync({ email, role });
          notifyInfo(t('components.user.UserTeamCard.invitationSent', { email }));
        } finally {
          onClose();
        }
      }
    },
    [t, mutateAsync, notifyInfo, onClose, canGenerate, email, role]
  );

  return (
    <Modal visible={visible} close={onClose}>
      <form className="space-y-8" onSubmit={handleSubmit}>
        <div>
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {t('components.user.UserTeamCard.invitationTitle')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{t('components.user.UserTeamCard.invitationInfo')}</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                {t('general.common.email')}
                <RequiredMarker />
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                {t('components.user.common.role')}
              </label>
              <select
                id="role"
                name="role"
                value={role}
                onChange={e => setRole(e.target.value as OrganizationRole)}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {Object.keys(OrganizationRole).map(opt => (
                  <option key={opt} value={opt} className="w-full">
                    {t('components.user.common.role', { context: opt })}
                  </option>
                ))}
              </select>
              <p className="mt-1 text-xs text-gray-500">
                {t('components.user.UserTeamCard.roleInfo', { context: role })}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            type="submit"
            disabled={!canGenerate || isLoading}
            loading={isLoading}
            className="inline-flex w-full justify-center rounded-md px-3 py-2 sm:ml-3 sm:w-auto"
          >
            {t('components.user.common.invite')}
          </Button>
          <Button
            type="button"
            secondary
            className="mt-3 inline-flex w-full justify-center rounded-md sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            {t('general.action.cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const TeamMembersTable = ({ editDisabled }: { editDisabled: boolean }) => {
  const { t } = useTranslation();
  const [user] = useGlobalState(State.USER);
  const { data, isLoading } = useGetTeamMembers();

  // everything for delete user support
  const [deleteUser, setDeleteUser] = useState<TeamMemberDto>();
  const { mutateAsync: deleteMember, isLoading: isDeleteLoading } = useDeleteTeamMember();
  const hideDeleteModal = useCallback(() => setDeleteUser(undefined), []);
  const showDeleteModal = useCallback((user: TeamMemberDto) => setDeleteUser(user), []);
  const executeDelete = useCallback(
    async (user: TeamMemberDto) => {
      try {
        await deleteMember(user.id);
      } finally {
        hideDeleteModal();
      }
    },
    [deleteMember, hideDeleteModal]
  );

  // everything for change role support
  const [changeUser, setChangeUser] = useState<
    TeamMemberDto & {
      newRole?: OrganizationRole;
    }
  >();
  const { mutateAsync: postMember, isLoading: isChangeLoading } = useChangeTeamMemberRole();
  const hideChangeModal = useCallback(() => setChangeUser(undefined), []);
  const showChangeModal = useCallback((user: TeamMemberDto) => setChangeUser({ ...user }), []);
  const handleChangeSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        if (changeUser && changeUser.newRole) {
          await postMember({ userId: changeUser.id, role: changeUser.newRole });
        }
      } finally {
        hideChangeModal();
      }
    },
    [postMember, hideChangeModal, changeUser]
  );

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {deleteUser && (
            <Modal visible close={hideDeleteModal}>
              <DeleteUserModalContent
                deleteUser={deleteUser}
                isDeleteLoading={isDeleteLoading}
                executeDelete={executeDelete}
                hideDeleteModal={hideDeleteModal}
              />
            </Modal>
          )}
          {changeUser && (
            <Modal visible close={hideChangeModal}>
              <ChangeUserRoleModalContent
                changeUser={changeUser}
                isChangeLoading={isChangeLoading}
                handleChangeSubmit={handleChangeSubmit}
                hideChangeModal={hideChangeModal}
                setChangeUser={setChangeUser}
              />
            </Modal>
          )}
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  {t('general.common.name')}
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  {t('general.common.email')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t('components.user.common.role')}
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">{t('general.action.edit')}</span>
                </th>
              </tr>
            </thead>
            {data && (
              <tbody className="divide-y divide-gray-100 bg-white">
                {data.map(member => (
                  <tr key={member.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <span>{member.name}</span>
                      {user?.id === member.id && <Badge label={t('general.common.you')} type="beta" pill />}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {member.email}
                    </td>
                    <td
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      title={t('components.user.UserTeamCard.roleInfo', { context: member.role })}
                    >
                      {t('components.user.common.role', { context: member.role })}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right sm:pr-0">
                      {user?.id !== member.id && (
                        <>
                          <Button disabled={editDisabled} secondary small onClick={() => showChangeModal(member)}>
                            {t('components.user.common.changeRole')}
                            <span className="sr-only">, {member.name}</span>
                          </Button>
                          <Button
                            disabled={editDisabled}
                            danger
                            small
                            className="ml-2"
                            onClick={() => showDeleteModal(member)}
                          >
                            {t('general.action.remove')}
                            <span className="sr-only">, {member.name}</span>
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </>
      )}
    </>
  );
};

const InvitationsTable = ({ editDisabled }: { editDisabled: boolean }) => {
  const { t } = useTranslation();
  const [invitationDeleted, setInvitationDeleted] = useState<string>();

  const { data, isLoading } = useGetOrganizationInvitations();
  const { mutateAsync: deleteInvitation, isLoading: deleteInProgress } = useDeleteOrganizationInvitation();

  const deleteInvitationHandler = useCallback(
    (id: string) => {
      setInvitationDeleted(id);
      deleteInvitation(id);
    },
    [deleteInvitation]
  );

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {isEmpty(data) && <p className="text-sm">{t('components.user.UserTeamCard.noInvitations')}</p>}
          {!isEmpty(data) && (
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    {t('general.common.email')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('components.user.common.role')}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {t('components.user.common.invitationBy')}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    {t('components.user.common.invitationDate')}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">{t('general.action.remove')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {data.map(invite => (
                  <tr
                    key={invite.email}
                    className={classNames(
                      deleteInProgress && invitationDeleted === invite.id && 'animate-pulse-tailwind'
                    )}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {invite.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {t('components.user.common.role', { context: invite.role })}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {invite.inviterEmail}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      <time
                        dateTime={invite.createdDate}
                        title={localizationHelper.forDate().formatDateTimeStringLocally(invite.createdDate)}
                      >
                        {localizationHelper.forDate().formatDistanceToNowWithDateString(invite.createdDate, true)}
                      </time>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right sm:pr-0">
                      <Button
                        disabled={deleteInProgress || editDisabled}
                        danger
                        small
                        onClick={() => deleteInvitationHandler(invite.id)}
                      >
                        {t('general.action.remove')}
                        <span className="sr-only">, {invite.email}</span>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

const tabs = ['members', 'invitations'];

export const OrganizationTeamCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organization] = useGlobalState(State.ORGANIZATION);

  const [invitationFormVisible, setInvitationFormVisible] = useState(false);
  const showInvitationForm = useCallback(() => setInvitationFormVisible(true), []);
  const hideInvitationForm = useCallback(() => setInvitationFormVisible(false), []);

  const { searchQuery, withQueryParams } = useQueryParams();
  const selectedTab = searchQuery.get('tab') || 'members';

  const isOrganizationOwner = organization?.role === OrganizationRole.OWNER;

  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="border-b border-gray-200 px-4 pt-5 sm:px-6">
          <div className="relative  pb-5 sm:pb-0">
            <div className="md:flex md:items-center md:justify-between">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('components.user.common.teamMembers')}</h3>
              {isOrganizationOwner && (
                <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
                  <FeatureGating
                    enabledPackages={[PlainlyPackage.TEAM, PlainlyPackage.PRO]}
                    freeTrialAllowed={true}
                    message={t('components.user.UserTeamCard.invitationNotAllowedMessage')}
                  >
                    <Button onClick={showInvitationForm}>{t('components.user.common.invite')}</Button>
                  </FeatureGating>
                </div>
              )}
            </div>
            <div className="mt-4">
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  {t('general.action.selectATab')}
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  value={selectedTab}
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  onChange={e => navigate(withQueryParams(routes.ORGANIZATION_SETTINGS_TEAM, { tab: e.target.value }))}
                >
                  {tabs.map(tab => (
                    <option key={tab} value={tab}>
                      {t(`components.user.common.${tab}`)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map(tab => (
                    <Link
                      key={tab}
                      to={withQueryParams(routes.ORGANIZATION_SETTINGS_TEAM, { tab })}
                      aria-current={tab === selectedTab ? 'page' : undefined}
                      className={classNames(
                        tab === selectedTab
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                      )}
                    >
                      {t(`components.user.common.${tab}`)}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto px-4 py-5 sm:px-6">
          {selectedTab === 'members' && <TeamMembersTable editDisabled={!isOrganizationOwner} />}
          {selectedTab === 'invitations' && <InvitationsTable editDisabled={!isOrganizationOwner} />}
        </div>
      </div>
      <TeamInviteModal visible={invitationFormVisible} onClose={hideInvitationForm} />
    </>
  );
};

const DeleteUserModalContent = ({
  deleteUser,
  isDeleteLoading,
  executeDelete,
  hideDeleteModal
}: {
  deleteUser: TeamMemberDto;
  isDeleteLoading: boolean;
  executeDelete: (user: TeamMemberDto) => Promise<void>;
  hideDeleteModal: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t('components.user.UserTeamCard.removeMember')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              <Trans
                i18nKey="components.user.UserTeamCard.removeMemberInfo"
                tOptions={{
                  name: deleteUser.name
                }}
              >
                Are you sure you want to remove the user <strong>{deleteUser.name}</strong> from your organization?
              </Trans>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          danger
          disabled={isDeleteLoading}
          loading={isDeleteLoading}
          onClick={() => executeDelete(deleteUser)}
          className="inline-flex w-full justify-center rounded-md px-3 py-2 sm:ml-3 sm:w-auto"
        >
          {t('general.action.remove')}
        </Button>
        <Button
          secondary
          disabled={isDeleteLoading}
          data-autofocus
          onClick={hideDeleteModal}
          className="mt-3 inline-flex w-full justify-center rounded-md sm:mt-0 sm:w-auto"
        >
          {t('general.action.cancel')}
        </Button>
      </div>
    </>
  );
};

const ChangeUserRoleModalContent = ({
  changeUser,
  isChangeLoading,
  handleChangeSubmit,
  hideChangeModal,
  setChangeUser
}: {
  changeUser: TeamMemberDto & { newRole?: OrganizationRole };
  isChangeLoading: boolean;
  handleChangeSubmit: (e: React.FormEvent) => Promise<void>;
  hideChangeModal: () => void;
  setChangeUser: (user: TeamMemberDto & { newRole?: OrganizationRole }) => void;
}) => {
  const { t } = useTranslation();

  return (
    <form className="space-y-8" onSubmit={handleChangeSubmit}>
      <div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">{t('components.user.common.changeRole')}</h3>
          <p className="mt-1 text-sm text-gray-500">
            <Trans
              i18nKey="components.user.UserTeamCard.changeRoleInfo"
              tOptions={{
                name: changeUser.name
              }}
            >
              Select a new role for the user <strong>{changeUser.name}</strong> in your organization.
            </Trans>
          </p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
              {t('components.user.common.role')}
            </label>
            <select
              id="role"
              name="role"
              value={changeUser.newRole || changeUser.role}
              onChange={e => setChangeUser({ ...changeUser, newRole: e.target.value as OrganizationRole })}
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              {Object.keys(OrganizationRole).map(opt => (
                <option key={opt} value={opt} className="w-full">
                  {t('components.user.common.role', { context: opt })}
                </option>
              ))}
            </select>
            <p className="mt-1 text-xs text-gray-500">
              {t('components.user.UserTeamCard.roleInfo', { context: changeUser.newRole || changeUser.role })}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          type="submit"
          disabled={isChangeLoading || changeUser.newRole == undefined || changeUser.newRole === changeUser.role}
          loading={isChangeLoading}
          className="inline-flex w-full justify-center rounded-md px-3 py-2 sm:ml-3 sm:w-auto"
        >
          {t('general.action.update')}
        </Button>
        <Button
          secondary
          disabled={isChangeLoading}
          data-autofocus
          onClick={hideChangeModal}
          className="mt-3 inline-flex w-full justify-center rounded-md sm:mt-0 sm:w-auto"
        >
          {t('general.action.cancel')}
        </Button>
      </div>
    </form>
  );
};
