import { useEffect, useState } from 'react';

export const useHide = (showMenu: boolean, timeout: number) => {
  const [completelyHidden, setCompletelyHidden] = useState(!showMenu);

  useEffect(() => {
    if (showMenu) {
      setCompletelyHidden(false);
    } else {
      const timer = setTimeout(() => {
        setCompletelyHidden(true);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showMenu, timeout]);

  return completelyHidden;
};
