import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { FilmIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { AssetDisplay, AssetsModal, Button, RequiredMarker } from '@src/components';
import localizationHelper from '@src/i18n';
import { StockAsset, StockAssetSearchOut } from '@src/models';

export const AssetFormSection = ({
  currentAsset,
  setCurrentAsset,
  disabled,
  socialMedia,
  assetDurationWarning,
  suggestedAssets
}: {
  currentAsset?: StockAsset;
  setCurrentAsset: (currentAsset: StockAsset) => void;
  disabled?: boolean;
  socialMedia?: boolean;
  assetDurationWarning?: boolean | number;
  suggestedAssets?: StockAssetSearchOut[];
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const isStockAsset = currentAsset?.type === 'stock';
  const assetExists = !!currentAsset;

  return (
    <div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {t('components.common.assetLabel', { context: `${socialMedia}` })}
          <RequiredMarker />
        </label>
        <p className="text-sm text-gray-500">
          {t('components.article.ArticleVideo.sceneSettings.sceneForm.assetLabelDescription', {
            context: `${socialMedia}`
          })}
        </p>
      </div>
      <div className="mt-1 flex flex-col gap-6 xl:flex-row">
        <AssetDisplay
          asset={currentAsset}
          className="h-60 w-full xl:w-1/2"
          actions={
            <>
              {assetExists && (
                <Button
                  disabled
                  icon={
                    isStockAsset ? (
                      currentAsset.out.assetType === 'IMAGE' ? (
                        <PhotoIcon />
                      ) : (
                        <FilmIcon />
                      )
                    ) : currentAsset.assetType === 'IMAGE' ? (
                      <PhotoIcon />
                    ) : (
                      <FilmIcon />
                    )
                  }
                  secondary
                  small
                  className="pointer-events-none absolute left-3 top-3 text-xs"
                >
                  {t('components.article.ArticleVideo.sceneSettings.sceneForm.provider', {
                    context: isStockAsset ? currentAsset.out.provider : 'manual'
                  })}
                </Button>
              )}
              <Button
                disabled={disabled}
                small
                className="absolute right-3 top-3 text-xs"
                onClick={() => setShowModal(true)}
              >
                {t('general.action.edit')}
              </Button>
            </>
          }
        />
        <div className="flex w-full flex-col divide-y divide-dashed divide-gray-200 text-sm text-gray-500 xl:w-1/2">
          {!assetExists && (
            <div className="rounded-md border border-yellow-100 bg-yellow-50 p-1.5">
              <p className="flex items-center text-xs text-yellow-900">
                <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400" />
                {t('components.article.ArticleVideo.settings.common.assetFormSection.missingAssetDesc')}
              </p>
            </div>
          )}
          {assetExists && !isStockAsset && (
            <div className="flex gap-2 py-2">
              <p className="w-20 font-medium text-gray-700">{t('components.common.typeTitle')}</p>
              <span>
                {t('components.article.ArticleVideo.settings.common.assetFormSection.stockAssetType', {
                  context: currentAsset.assetType?.toUpperCase()
                })}
              </span>
            </div>
          )}
          {assetExists && isStockAsset && (
            <>
              <div className="flex gap-2 pb-2">
                <p className="w-20 shrink-0 font-medium text-gray-700">
                  {t('components.article.ArticleVideo.sceneSettings.sceneForm.keywordsTitle')}
                </p>
                <div>{currentAsset.out.query && <span>{currentAsset.out.query.join(', ')}</span>}</div>
              </div>
              <div className="flex gap-2 py-2">
                <p className="w-20 font-medium text-gray-700">{t('components.common.typeTitle')}</p>
                <span>
                  {t('components.article.ArticleVideo.settings.common.assetFormSection.stockAssetType', {
                    context: currentAsset.out.assetType
                  })}
                </span>
              </div>
              {currentAsset.out.duration && (
                <div className="flex flex-col gap-2 py-2 sm:flex-row">
                  <div className="flex shrink-0 items-center gap-2">
                    <p className="w-20 shrink-0 font-medium text-gray-700">
                      {t('components.article.ArticleVideo.sceneSettings.sceneForm.durationTitle')}
                    </p>
                    <span className="shrink-0">
                      {t('general.common.duration', {
                        duration: localizationHelper
                          .forNumber({ maximumFractionDigits: 1, minimumFractionDigits: 1 })
                          .format(currentAsset.out.duration)
                      })}
                    </span>
                  </div>
                  {assetDurationWarning && (
                    <div className="rounded-md border border-yellow-100 bg-yellow-50 p-1.5">
                      <p className="flex items-center text-xs text-yellow-900">
                        <ExclamationTriangleIcon className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400" />
                        {t('components.article.ArticleVideo.sceneSettings.sceneForm.durationWarning')}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="flex gap-2 py-2">
                <p className="w-20 font-medium text-gray-700">
                  {t('components.article.ArticleVideo.sceneSettings.sceneForm.resolutionTitle')}
                </p>
                <span>
                  {t('general.common.resolution', {
                    width: currentAsset.out.width,
                    height: currentAsset.out.height
                  })}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <AssetsModal
        showModal={showModal}
        setShowModal={setShowModal}
        assets={suggestedAssets}
        setCurrentAsset={setCurrentAsset}
      />
    </div>
  );
};
