import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useReanalyzeProject } from '@src/hooks';

import { Button } from '../common';

export const ProjectReanalyzeAction = ({ projectId, disabled }: { projectId: string; disabled?: boolean }) => {
  const { t } = useTranslation();
  const { isLoading, mutateAsync: reanalyzeProject } = useReanalyzeProject();
  const [updated, setUpdated] = useState(false);

  const doFire = () => {
    reanalyzeProject(projectId).then(() => setUpdated(true));
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('components.project.ProjectReanalyzeAction.title')}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t('components.project.ProjectReanalyzeAction.text')}</p>
            </div>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
            <Button secondary disabled={disabled || isLoading || updated} loading={isLoading} onClick={doFire}>
              {t('components.project.ProjectReanalyzeAction.action')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
