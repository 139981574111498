import { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

type Base = { key: string; label: string };
type Button = Base & { action: (() => void) | undefined; disabled?: boolean; tooltip?: string; primary?: boolean };

type ButtonWithDropdownProps = {
  labeled: Omit<Button, 'key'>;
  buttons: Button[];
  className?: string;
};

export const ButtonWithDropdown = ({ labeled, buttons, className }: ButtonWithDropdownProps) => {
  const { t } = useTranslation();

  const getButtons = useMemo(() => {
    if (!buttons) return null;

    return (
      <>
        {buttons.map(button => (
          <MenuItem key={button.key}>
            {({ focus }) => (
              <button
                title={button.tooltip}
                disabled={button.disabled}
                type="button"
                className={classNames(
                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full cursor-pointer px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-50'
                )}
                onClick={button.action}
              >
                {button.label}
              </button>
            )}
          </MenuItem>
        ))}
      </>
    );
  }, [buttons]);

  return (
    <>
      <button
        title={labeled.tooltip}
        disabled={labeled.disabled}
        onClick={labeled.action}
        type="button"
        className={classNames(
          'relative inline-flex items-center rounded-l-md border px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1  disabled:cursor-not-allowed disabled:opacity-50',
          className,
          labeled.primary
            ? 'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500'
            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:border-indigo-500 focus:ring-indigo-500'
        )}
      >
        {labeled.label}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <MenuButton
          className={classNames(
            'relative inline-flex items-center rounded-r-md border px-2 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1',
            labeled.primary
              ? 'border-indigo-600 border-l-indigo-500 bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500'
              : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:border-indigo-500 focus:ring-indigo-500'
          )}
        >
          <span className="sr-only">{t('general.action.openMenu')}</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="-mr-1 mt-2 w-56 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {getButtons}
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
};
