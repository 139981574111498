import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { Checkbox, StyledA } from '@src/components/common';
import { ImageSequenceLoaderScript } from '@src/models';

type ImageSequenceLoaderFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: ImageSequenceLoaderScript | undefined) => void;
  editScript?: ImageSequenceLoaderScript;
};

export const ImageSequenceLoaderForm = ({ setFormValid, setFormScript, editScript }: ImageSequenceLoaderFormProps) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<ImageSequenceLoaderScript>({
    scriptType: ScriptType.IMAGE_SEQUENCE_LOADER,
    parameterName: editScript?.parameterName || '',
    mandatory: editScript?.mandatory || false
  });

  const valid = inputs.parameterName !== '';

  useEffect(() => {
    setFormValid(valid);
    if (valid) {
      setFormScript(inputs);
    } else {
      setFormScript(undefined);
    }
  }, [valid, inputs, setFormValid, setFormScript]);

  return (
    <div className="max-h-vh-300 overflow-auto px-4 py-5">
      <fieldset className="space-y-5">
        <div>
          <label htmlFor="parameterName" className="block text-sm font-medium text-gray-700">
            {t('components.project.template.layer.scripts.common.parameterName')}
            <p className="text-sm font-normal text-gray-500">
              <Trans i18nKey={'components.project.template.layer.scripts.ImageSequenceLoaderForm.parameterNameDesc'}>
                Name of the parameter that will be used to pass a list of images. If this parameter is passed, it should
                contain an array of links to the images. Read more information
                <StyledA
                  href={`${import.meta.env.VITE_APP_BASE_URL}/asciidoc/plainly-manual.html#image-sequence`}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </StyledA>
                .
              </Trans>
            </p>
          </label>
          <input
            autoFocus
            id="parameterName"
            name="parameterName"
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={e => setInputs({ ...inputs, parameterName: e.target.value.trim() })}
            defaultValue={inputs.parameterName}
          />
        </div>
        <Checkbox
          id={'mandatory'}
          checked={inputs.mandatory === true}
          onChange={e => setInputs(prev => ({ ...prev, mandatory: e.target.checked }))}
          label={t('components.project.template.common.mandatory')}
          description={t('components.project.template.layer.scripts.ImageSequenceLoaderForm.mandatoryDescription')}
          className={'mb-2'}
        />
      </fieldset>
    </div>
  );
};
