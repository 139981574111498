import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import { PlainlyLogo, SidebarLinks, UserProfileAvatar } from '@src/components';

export const MobileMenu = ({
  sidebarFullyCollapsed,
  sidebarCollapsed,
  toggleSideBar
}: {
  sidebarFullyCollapsed: boolean;
  sidebarCollapsed: boolean;
  toggleSideBar: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames('fixed inset-0 z-40 flex md:hidden', sidebarFullyCollapsed && 'hidden')}>
        <Transition
          as="div"
          className="fixed inset-0"
          show={!sidebarCollapsed}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </Transition>
        <Transition
          as="div"
          className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800"
          show={!sidebarCollapsed}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="absolute right-0 top-0 -mr-12 pt-2">
            <button
              onClick={toggleSideBar}
              className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">{t('general.action.closeSidebar')}</span>
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <UserProfileAvatar toggleSideBar={toggleSideBar} />
          <SidebarLinks toggleSideBar={toggleSideBar} />
          <div>
            <div className="flex shrink-0 items-center justify-between px-4">
              <Link to="/">
                <PlainlyLogo className="h-12 w-auto" />
              </Link>
            </div>
            <div className="flex shrink-0 px-4 py-2">
              <div className="group block w-full shrink-0">
                <p className="text-xs text-gray-700">v. {import.meta.env.VITE_APP_VERSION}</p>
              </div>
            </div>
          </div>
        </Transition>
        <div className="w-14 shrink-0" />
      </div>
    </>
  );
};
