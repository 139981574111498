import { useTranslation } from 'react-i18next';

import { StepNavigation } from '@src/components/common';
import { BatchRenderType } from '@src/types';

type RenderBatchNavigationProps = {
  activeStep: number;
  context: BatchRenderType;
};

export const RenderBatchNavigation = ({ activeStep, context }: RenderBatchNavigationProps) => {
  const { t } = useTranslation();

  const steps = [
    { value: 1, name: t('components.render.RenderBatchNavigation.uploadFile', { context: context }) },
    { value: 2, name: t('components.render.RenderBatchNavigation.selectParameters') },
    { value: 3, name: t('components.render.RenderBatchNavigation.advancedOptions') },
    { value: 4, name: t('components.render.RenderBatchNavigation.render') }
  ];

  return <StepNavigation steps={steps} activeStep={activeStep} />;
};
