import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useQueryParams } from '@src/hooks';
import * as routes from '@src/routes';

import { Modal } from '../common';

type FeatureGatingModalProps = {
  visible: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
};

export const FeatureGatingModal = ({ visible, onClose, message, title }: FeatureGatingModalProps) => {
  return (
    <Modal visible={visible} close={onClose}>
      <FeatureGatingContent onClose={onClose} message={message} title={title} />
    </Modal>
  );
};

const FeatureGatingContent = ({ onClose, message, title }: Omit<FeatureGatingModalProps, 'visible'>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { withQueryParams } = useQueryParams();

  return (
    <>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <InformationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {title ? title : t('components.featureGating.FeatureGatingModal.title')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {message ? message : t('components.featureGating.FeatureGatingModal.message')}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => navigate(withQueryParams(routes.ORGANIZATION_SETTINGS_SUBSCRIPTION, { openPlans: true }))}
        >
          {t('components.featureGating.FeatureGatingModal.subscribe')}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => onClose()}
        >
          {t('general.action.cancel')}
        </button>
      </div>
    </>
  );
};
