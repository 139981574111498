import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScriptType } from '@plainly/types';
import { TimeActionSetDurationScript } from '@src/models';

type SetDurationScriptingFormProps = {
  setFormValid: (valid: boolean) => void;
  setFormScript: (script: TimeActionSetDurationScript | undefined) => void;
  editScript?: TimeActionSetDurationScript;
};

export const SetDurationScriptingForm = ({
  setFormValid,
  setFormScript,
  editScript
}: SetDurationScriptingFormProps) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState<number>(editScript?.duration || 0);

  useEffect(() => {
    if (duration < 0) {
      setFormValid(false);
      return;
    }

    setFormValid(true);
    setFormScript({
      scriptType: ScriptType.SET_DURATION,
      duration: duration
    });
  }, [duration, setFormScript, setFormValid]);

  return (
    <div className="max-h-vh-300 space-y-5 overflow-auto px-4 py-5">
      <fieldset>
        <div>
          <label htmlFor="duration" className="block text-sm font-medium text-gray-700">
            {t('general.common.durationLabel')}
            <p className="text-sm font-normal text-gray-500">
              {t('components.project.template.layer.scripts.SetDurationScriptingForm.durationDescription')}
            </p>
          </label>
          <input
            id="duration"
            name="duration"
            type="number"
            inputMode="decimal"
            step="any"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={e => setDuration(parseFloat(e.target.value))}
            min={0}
            defaultValue={duration || 0}
          />
        </div>
      </fieldset>
    </div>
  );
};
