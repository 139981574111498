import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from '@src/hooks';
import {
  ACTIVE_CAMPAIGN_SRC,
  AIRTABLE_SRC,
  AMAZON_LAMBDA_SRC,
  AMAZON_REDSHIFT_SRC,
  AMAZON_S3_SRC,
  AMAZON_SNS_SRC,
  APIFY_SRC,
  ASANA_SRC,
  AZURE_CS_SRC,
  BLACKBOARD_SRC,
  CALENDLY_SRC,
  CANVAS_SRC,
  CLEARBIT_SRC,
  CONNECTWISE_SRC,
  DEEPAI_SRC,
  DOCUSIGN_SRC,
  DROPBOX_SRC,
  FREE_FOREX_SRC,
  FRESHDESK_SRC,
  GITHUB_SRC,
  GOOGLE_ANALYTICS_SRC,
  GOOGLE_BIGQUERY_SRC,
  GOOGLE_CALENDAR_SRC,
  GOOGLE_DRIVE_SRC,
  GOOGLE_GMAIL_SRC,
  GOOGLE_SHEETS_SRC,
  HIGHRISE_SRC,
  HUBSPOT_SRC,
  JIRA_SRC,
  MONDAY_SRC,
  MONGODB_SRC,
  MS_CALENDAR_SRC,
  MS_DYNAMICS_365_SRC,
  MS_EXCEL_SRC,
  MS_MAIL_SRC,
  MS_ONEDRIVE_SRC,
  MS_SHAREPOINT_SRC,
  MS_TEAMS_SRC,
  MSSSQL_SRC,
  MYSQL_SRC,
  ONESIGNAL_SRC,
  OPENAI_SRC,
  PIPEDRIVE_SRC,
  PLIVO_SRC,
  SALESFORCE_SRC,
  SCHOOLOGY_SRC,
  SHOPIFY_SRC,
  SLACK_SRC,
  SNOWFLAKE_SRC,
  TRELLO_SRC,
  TWILIO_SRC,
  VIMEO_SRC,
  VOYS_SRC,
  WEBFLOW_SRC,
  WORDPRESS_SRC,
  XERO_SRC,
  YOUTUBE_SRC,
  ZENDESK_CHAT_SRC,
  ZOHO_BOOKS_SRC,
  ZOHO_CRM_SRC
} from '@src/icons';

type SupportedProducts = {
  productName: string;
  productLogo: string;
};

export const AppmixerSupportedProducts = () => {
  const { t } = useTranslation();

  const supportedProducts: SupportedProducts[] = [
    { productName: 'ActiveCampaign', productLogo: ACTIVE_CAMPAIGN_SRC },
    { productName: 'Airtable', productLogo: AIRTABLE_SRC },
    { productName: 'Amazon Lambda', productLogo: AMAZON_LAMBDA_SRC },
    { productName: 'Amazon Redshift', productLogo: AMAZON_REDSHIFT_SRC },
    { productName: 'Amazon S3', productLogo: AMAZON_S3_SRC },
    { productName: 'Amazon SNS', productLogo: AMAZON_SNS_SRC },
    { productName: 'Apify', productLogo: APIFY_SRC },
    { productName: 'Asana', productLogo: ASANA_SRC },
    { productName: 'Azure Cognitive Services', productLogo: AZURE_CS_SRC },
    { productName: 'Blackboard', productLogo: BLACKBOARD_SRC },
    { productName: 'Calendly', productLogo: CALENDLY_SRC },
    { productName: 'Canvas', productLogo: CANVAS_SRC },
    { productName: 'Clearbit', productLogo: CLEARBIT_SRC },
    { productName: 'Connectwise', productLogo: CONNECTWISE_SRC },
    { productName: 'DeepAI', productLogo: DEEPAI_SRC },
    { productName: 'Docusign', productLogo: DOCUSIGN_SRC },
    { productName: 'Dropbox', productLogo: DROPBOX_SRC },
    { productName: 'Free Forex', productLogo: FREE_FOREX_SRC },
    { productName: 'Freshdesk', productLogo: FRESHDESK_SRC },
    { productName: 'Github', productLogo: GITHUB_SRC },
    { productName: 'Google Analytics', productLogo: GOOGLE_ANALYTICS_SRC },
    { productName: 'Google BigQuery', productLogo: GOOGLE_BIGQUERY_SRC },
    { productName: 'Google Calendar', productLogo: GOOGLE_CALENDAR_SRC },
    { productName: 'Google Gmail', productLogo: GOOGLE_GMAIL_SRC },
    { productName: 'Google drive', productLogo: GOOGLE_DRIVE_SRC },
    { productName: 'Google sheets', productLogo: GOOGLE_SHEETS_SRC },
    { productName: 'Highrise', productLogo: HIGHRISE_SRC },
    { productName: 'Hubspot', productLogo: HUBSPOT_SRC },
    { productName: 'Jira', productLogo: JIRA_SRC },
    { productName: 'Microsoft Calendar', productLogo: MS_CALENDAR_SRC },
    { productName: 'Microsoft Dynamics 365 CRM', productLogo: MS_DYNAMICS_365_SRC },
    { productName: 'Microsoft Excel', productLogo: MS_EXCEL_SRC },
    { productName: 'Microsoft Mail', productLogo: MS_MAIL_SRC },
    { productName: 'Microsoft OneDrive', productLogo: MS_ONEDRIVE_SRC },
    { productName: 'Microsoft SharePoint', productLogo: MS_SHAREPOINT_SRC },
    { productName: 'Microsoft Teams', productLogo: MS_TEAMS_SRC },
    { productName: 'Monday', productLogo: MONDAY_SRC },
    { productName: 'MongoDB', productLogo: MONGODB_SRC },
    { productName: 'MySQL', productLogo: MYSQL_SRC },
    { productName: 'OneSignal', productLogo: ONESIGNAL_SRC },
    { productName: 'Openai', productLogo: OPENAI_SRC },
    { productName: 'Pipedrive', productLogo: PIPEDRIVE_SRC },
    { productName: 'Plivo', productLogo: PLIVO_SRC },
    { productName: 'Salesforce', productLogo: SALESFORCE_SRC },
    { productName: 'Schoology', productLogo: SCHOOLOGY_SRC },
    { productName: 'Shopify', productLogo: SHOPIFY_SRC },
    { productName: 'Slack', productLogo: SLACK_SRC },
    { productName: 'Snowflake', productLogo: SNOWFLAKE_SRC },
    { productName: 'Trello', productLogo: TRELLO_SRC },
    { productName: 'Twilio', productLogo: TWILIO_SRC },
    { productName: 'Vimeo', productLogo: VIMEO_SRC },
    { productName: 'Voys', productLogo: VOYS_SRC },
    { productName: 'Webflow', productLogo: WEBFLOW_SRC },
    { productName: 'WordPress', productLogo: WORDPRESS_SRC },
    { productName: 'Xero', productLogo: XERO_SRC },
    { productName: 'Youtube', productLogo: YOUTUBE_SRC },
    { productName: 'Zendesk Chat', productLogo: ZENDESK_CHAT_SRC },
    { productName: 'Zoho Books', productLogo: ZOHO_BOOKS_SRC },
    { productName: 'Zoho CRM', productLogo: ZOHO_CRM_SRC },
    { productName: 'mssql', productLogo: MSSSQL_SRC }
  ];

  const isSmallScreen = useBreakpoint('sm');
  const isMediumScreen = useBreakpoint('md');
  const isLargeScreen = useBreakpoint('lg');

  const getItemsPerPage = () => {
    if (!isSmallScreen) return 6;
    if (isLargeScreen) return 16;
    if (isMediumScreen) return 10;
    return 6;
  };

  const itemsPerPage = getItemsPerPage();
  const totalPages = Math.ceil(supportedProducts.length / itemsPerPage);

  const [activePage, setActivePage] = useState(0);
  const [resetCounter, setResetCounter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const displayedProducts = supportedProducts.slice(
    activePage * itemsPerPage,
    activePage * itemsPerPage + itemsPerPage
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!resetCounter && !isHovered) {
        setActivePage(prevPage => (prevPage + 1) % totalPages);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [totalPages, isHovered, resetCounter]);

  return (
    <section className="mt-auto">
      <div className="py-8 lg:py-16">
        <div className="mb-6 flex flex-col items-center gap-2">
          <h2 className="text-center font-semibold leading-tight tracking-tight text-gray-900">
            {t('components.appmixer.AppmixerTemplates.supportedProducts')}
          </h2>
          <p className="w-4/5 text-center text-xs text-gray-500 md:text-sm">
            {t('components.appmixer.AppmixerTemplates.supportedProductsDesc')}
          </p>
        </div>
        <div
          className={classNames(
            'grid h-[140px] grid-cols-3 items-start justify-items-center align-top text-gray-500 sm:gap-x-4 md:grid-cols-5 lg:grid-cols-8 dark:text-gray-400'
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {displayedProducts.map(({ productName, productLogo }, index) => (
            <div key={index} className="flex h-[70px] flex-col items-center gap-2">
              <img src={productLogo} alt={productName} className={classNames('h-5 w-5 md:h-6 md:w-6')} />
              <p className="text-center text-xs">{productName}</p>
            </div>
          ))}
        </div>
        <div className="col-span-full mt-4 flex justify-center gap-3">
          {Array.from({ length: totalPages }).map((_, index) => (
            <div
              key={index}
              className={classNames(
                'h-3 w-3 cursor-pointer rounded-full transition-colors duration-300 hover:bg-gray-400',
                index === activePage ? 'bg-indigo-600' : 'bg-gray-300'
              )}
              onClick={() => {
                setActivePage(index);
                setResetCounter(true);
                setTimeout(() => setResetCounter(false), 0);
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
