import {
  AnyAvatarProviderSettings,
  AnySummaryProviderSettings,
  AnyVoiceoverProviderSettings,
  StockAssetSettings
} from './providers';

export enum VideoType {
  SUMMARY_SLIDESHOW = 'SUMMARY_SLIDESHOW',
  PRESENTER = 'PRESENTER',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA'
}

export type CommonVideoSettings = {
  id?: string;
  enabled?: boolean;
  projectId: string;
  targetLanguage: string;
  templateId: string;
  design: boolean;
};

export type VideoSettings<T extends VideoType> = CommonVideoSettings & {
  videoType: T;
};

export type SocialMediaVideoSettings = VideoSettings<VideoType.SOCIAL_MEDIA> & {
  defaultCta: string;
  defaultLogoUrl?: string;
  defaultMusic?: string;
};

export type SceneBasedVideoSettings = {
  stockAssetSettings: StockAssetSettings;
  summaryProviderSettings: AnySummaryProviderSettings;
  defaultCta?: string;
  defaultLogoUrl?: string;
};

export type PresenterVideoSettings = VideoSettings<VideoType.PRESENTER> &
  SceneBasedVideoSettings & {
    avatarProviderSettings: AnyAvatarProviderSettings;
  };
export type SummaryVideoSettings = VideoSettings<VideoType.SUMMARY_SLIDESHOW> &
  SceneBasedVideoSettings & {
    voiceoverProviderSettings: AnyVoiceoverProviderSettings;
  };

export type AnyVideoSettings = PresenterVideoSettings | SocialMediaVideoSettings | SummaryVideoSettings;
