import { useTranslation } from 'react-i18next';

import { ArticlesListItemHeader, Loading, LoadingProps } from '@src/components';
import { useGetArticles } from '@src/hooks';
import { Article, Brand } from '@src/models';
import { isEmpty } from '@src/utils';

import { ArticleItem } from './ArticleItem';

const LoadingArticles = ({ title }: LoadingProps) => (
  <div className="h-full w-full place-content-center">
    <Loading title={title} />
  </div>
);

export const ArticlesListItem = ({ brand }: { brand: Brand }) => {
  const { t } = useTranslation();
  const { isLoading, data: articles = [] } = useGetArticles(brand.id);

  return (
    <div key={brand.id} className="pt-5">
      <ArticlesListItemHeader brand={brand} />
      {isEmpty(articles) && (
        <div className="pt-5 text-center">
          <span className="text-sm">{t('components.publishers.common.articlesEmpty')}</span>
        </div>
      )}
      {isLoading && <LoadingArticles title={t('components.brand.BrandDetails.loading')} />}
      {!isLoading && !isEmpty(articles) && (
        <div className="grid w-full grid-cols-1 gap-6 pt-5 sm:grid-cols-2 lg:grid-cols-3">
          {articles.slice(0, 3).map((article: Article) => (
            <div className="relative" key={article.id}>
              <ArticleItem article={article} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
