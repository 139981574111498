import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAcceptUserInvitation,
  useGetUserInvitations,
  useNotifications,
  useRejectUserInvitation,
  useSwitchOrganization
} from '@src/hooks';
import { OrganizationRole } from '@src/models';
import { State, useGlobalState } from '@src/state/store';

import { Button, Checkbox, Loading, Modal } from '../common';

export const UserIncomingInvitesCard = () => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
        <div className="md:flex md:items-center md:justify-between">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('components.user.UserIncomingInvitesCard.title')}
          </h3>
        </div>
      </div>
      <div className="overflow-x-auto px-4 py-5 sm:px-6">
        <UserIncomingInvitesTable />
      </div>
    </div>
  );
};

const UserIncomingInvitesTable = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetUserInvitations();
  const { mutateAsync: rejectInvite } = useRejectUserInvitation();

  const [invitationId, setInvitationId] = useState<string>();
  const [invitationRole, setInvitationRole] = useState<OrganizationRole>();

  const canShowModal = !!invitationId && !!invitationRole;

  const showModal = useCallback((id: string, role: OrganizationRole) => {
    setInvitationId(id);
    setInvitationRole(role);
  }, []);

  const closeModal = useCallback(() => {
    setInvitationId(undefined);
    setInvitationRole(undefined);
  }, []);

  if (isLoading) return <Loading />;

  if (data && !data.length)
    return <p className="text-sm">{t('components.user.UserIncomingInvitesCard.noInvitations')}</p>;

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              {t('components.common.organizationName')}
            </th>
            <th
              scope="col"
              className="hidden whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              {t('components.user.UserIncomingInvitesCard.UserIncomingInvitesTable.inviterEmail')}
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              {t('components.user.common.role')}
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">{t('general.action.edit')}</span>
            </th>
          </tr>
        </thead>
        {data && (
          <tbody className="divide-y divide-gray-100 bg-white">
            {data.map(inviter => (
              <tr key={inviter.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {inviter.organizationName}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {inviter.inviterEmail}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {t('components.user.common.role', { context: inviter.role })}
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right sm:pr-0">
                  <Button small onClick={() => showModal(inviter.id, inviter.role)}>
                    {t('general.action.accept')}
                    <span className="sr-only">, {inviter.inviterEmail}</span>
                  </Button>
                  <Button danger small className="ml-2" onClick={() => rejectInvite(inviter.id)}>
                    {t('components.user.UserIncomingInvitesCard.reject')}
                    <span className="sr-only">, {inviter.inviterEmail}</span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {canShowModal && (
        <UserAcceptInvitationModal
          visible={canShowModal}
          onClose={closeModal}
          invitationId={invitationId}
          invitationRole={invitationRole}
        />
      )}
    </>
  );
};

const UserAcceptInvitationModal = ({
  visible,
  onClose,
  invitationId,
  invitationRole
}: {
  visible: boolean;
  onClose: () => void;
  invitationId: string;
  invitationRole: string;
}) => {
  const { t } = useTranslation();
  const { notifyInfo } = useNotifications();

  const [makeDefault, setMakeDefault] = useState(false);

  const { mutateAsync, isLoading } = useAcceptUserInvitation();
  const [organization] = useGlobalState(State.ORGANIZATION);
  const { switchOrg, invalidate } = useSwitchOrganization();

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const organizationId = organization?.id;

      try {
        await mutateAsync({ invitationId, makeDefault });
        notifyInfo(t('components.user.UserTeamCard.accepted'));
      } finally {
        organizationId ? switchOrg(organizationId, true) : invalidate();
        onClose();
      }
    },
    [invalidate, invitationId, makeDefault, mutateAsync, notifyInfo, onClose, organization?.id, switchOrg, t]
  );

  return (
    <Modal visible={visible} close={onClose}>
      <form className="space-y-8" onSubmit={handleSubmit}>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t('components.user.UserTeamCard.pendingInvitationTitle')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('components.user.UserTeamCard.pendingInvitationInfo', { context: invitationRole })}
          </p>
        </div>
        <Checkbox
          id={'makeDefault'}
          checked={makeDefault === true}
          onChange={() => setMakeDefault(!makeDefault)}
          label={t('components.user.UserTeamCard.setDefaultOrg')}
          description={t('components.user.UserTeamCard.setDefaultOrgDesc')}
          className="mb-2"
        />
        <div className="flex justify-end">
          <Button type="button" secondary className="mr-2" onClick={onClose}>
            {t('general.action.cancel')}
          </Button>
          <Button type="submit" disabled={isLoading} loading={isLoading}>
            {t('general.action.accept')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
