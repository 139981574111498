import { useTranslation } from 'react-i18next';

import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';
import { Button, RenderBatchButton } from '@src/components';

export const RenderFormHeader = ({
  showAdvancedOptions,
  setShowAdvancedOptions,
  onBatchRender
}: {
  showAdvancedOptions: boolean;
  setShowAdvancedOptions: (showAdvancedOptions: boolean) => void;
  onBatchRender?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between xl:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {!showAdvancedOptions && <span>{t('components.designs.DesignAppForm.title')}</span>}
            {showAdvancedOptions && (
              <div className="inline-flex items-center">
                <AdjustmentsVerticalIcon className="h-5 w-5" />
                <span className="ml-2">{t('components.designs.DesignAppForm.advancedOptions')}</span>
              </div>
            )}
          </h3>
        </div>
        <div className="align-center ml-4 mt-2 flex flex-shrink-0 gap-2">
          {!showAdvancedOptions && (
            <>
              <RenderBatchButton type="render" onBatchRender={onBatchRender} />
              <Button
                secondary
                icon={<AdjustmentsVerticalIcon />}
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >
                {t('components.designs.DesignAppForm.advancedOptions')}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
