import { Navigate } from 'react-router-dom';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { DashboardWithUser } from './DashboardWithUser';

export const SignupDashboard = () => {
  const { isAuthenticated } = useAuth0();

  // if not authenticated show sign-up page
  if (!isAuthenticated) {
    const SignUp = withAuthenticationRequired(DashboardWithUser, {
      loginOptions: { authorizationParams: { screen_hint: 'signup' } }
    });

    return <SignUp />;
  }

  // otherwise navigate to dashboard
  return <Navigate replace to={'/dashboard'} />;
};
