import { useTranslation } from 'react-i18next';

import { PresenterSettings, SocialMediaSettings, SummarySlideshowSettings } from '@src/components';
import { VideoType } from '@src/models';

export const VideoSidebarSettings = ({
  videoType: articleType,
  setShowVideoDialog
}: {
  videoType: VideoType;
  setShowVideoDialog: (showVideoDialog: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-1 lg:px-2">
      <div className="mx-auto max-w-7xl px-5 lg:px-3">
        <h3 className="inline-flex items-center gap-x-1 text-xs font-semibold uppercase tracking-wider text-gray-600">
          {t('general.common.settings')}
        </h3>
      </div>
      <ol className="space-y-3 px-5 py-2 lg:px-3">
        {articleType === 'PRESENTER' && <PresenterSettings setShowVideoDialog={setShowVideoDialog} />}
        {articleType === 'SOCIAL_MEDIA' && <SocialMediaSettings setShowVideoDialog={setShowVideoDialog} />}
        {articleType === 'SUMMARY_SLIDESHOW' && <SummarySlideshowSettings setShowVideoDialog={setShowVideoDialog} />}
      </ol>
    </div>
  );
};
