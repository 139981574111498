import { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

export type InfiniteTableProps<T> = {
  data: T[][] | null | undefined;
  headerRow: ReactNode;
  renderItem: (item: T) => ReactNode;
  isSuccess: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  onClick?: (item: T) => void;
};

export const InfiniteTable = <T,>({
  data,
  headerRow,
  renderItem,
  isSuccess,
  hasNextPage,
  fetchNextPage,
  onClick
}: InfiniteTableProps<T>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const content =
    isSuccess &&
    data?.map((page, index) => {
      const isLastPage = index === data.length - 1;
      return page.map((render, i) => {
        const isLastInLastPage = isLastPage && i === page.length - 1;
        return (
          <tr
            onClick={() => onClick?.(render)}
            key={i}
            ref={isLastInLastPage ? ref : undefined}
            className={classNames('bg-white px-4 hover:bg-gray-50', onClick && 'cursor-pointer')}
          >
            {renderItem(render)}
          </tr>
        );
      });
    });

  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>{headerRow}</thead>
        <tbody className="divide-y divide-gray-200 bg-white">{content}</tbody>
      </table>
    </>
  );
};
