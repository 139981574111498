import { WideSlideover } from '../common';

type PaddleOverrideSlideoverProps = {
  visible: boolean;
  onClose: () => void;
  url?: string;
  title?: string;
  subtitle?: string;
};

export const PaddleOverrideSlideover = ({ visible, onClose, url, title, subtitle }: PaddleOverrideSlideoverProps) => {
  const startCheckout = () => {
    if (visible) {
      // @ts-expect-error Paddle is not defined
      Paddle.Checkout.open({
        override: url,
        method: 'inline',
        frameTarget: 'checkout-container', // The className of your checkout <div>
        frameInitialHeight: 450,
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' // Please ensure the minimum width is kept at or above 312px.
      });
    }
  };

  return (
    <WideSlideover visible={visible} onClose={onClose} title={title} subtitle={subtitle} unmount={false}>
      {visible && <div className="checkout-container" ref={() => startCheckout()} />}
    </WideSlideover>
  );
};
