import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScreenPage, TemplateCreate } from '@src/components';

export const TemplateCreatePage = () => {
  const { t } = useTranslation();
  const { projectId, step } = useParams() as { projectId: string; step?: string };

  return (
    <HelmetProvider>
      <ScreenPage>
        <Helmet>
          <title>{t('general.pageTitle.TemplateCreatePage')}</title>
        </Helmet>
        <TemplateCreate projectId={projectId} step={step} />
      </ScreenPage>
    </HelmetProvider>
  );
};
