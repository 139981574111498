import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Loading } from '../common';

import { useAppmixerContext } from './AppmixerContext';

import './styles/integrationsAccounts.css';

export const AppmixerAccounts = () => {
  const { t } = useTranslation();
  const appmixerContext = useAppmixerContext();
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [accountsWidget, setAccountsWidget] = useState<any>();
  const [loading, setLoading] = useState(false);
  const showLoading = loading || !appmixerContext.ready;

  useEffect(() => {
    // define setup
    const setup = () => {
      // do nothing if not ready
      if (!appmixerContext.ready) {
        return;
      }

      setLoading(true);

      // read from content
      const appmixer = appmixerContext.appmixer;

      // eslint-disable-next-line
      function getCustomAccountsFilter(params: any) {
        return {
          ...params,
          filter: [
            ...(params?.filter || []),
            // Exclude flows shared by other users.
            `userId:${appmixer.get('user').id}`,
            `service:!${import.meta.env.VITE_APP_APPMIXER_SERVICE}`
          ]
        };
      }

      const accounts = appmixer.ui.Accounts({
        el: '#accounts',
        api: {
          getAccounts(params: unknown) {
            return appmixer.api.getAccounts(getCustomAccountsFilter(params));
          }
        }
      });

      accounts.open();

      setAccountsWidget(accounts);
      setLoading(false);
    };

    // call setup
    setup();
  }, [appmixerContext, setLoading]);

  //always reload when we become active
  useEffect(() => {
    if (accountsWidget) {
      accountsWidget.reload();
    }
  }, [accountsWidget]);

  return (
    <>
      <div id="appmixer-accounts" className="flex">
        {showLoading && <Loading small />}
        <div id="accounts" className="w-full" />
      </div>
      {!showLoading && <Alert show type="info" alertContent={t('components.appmixer.AppmixerAccounts.info')} />}
    </>
  );
};
