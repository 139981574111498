import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  AppmixerIntegrationsLogs,
  AppmixerProvider,
  Breadcrumb,
  BreadcrumbItem,
  HeaderWithBreadcrumbs,
  ScrollingPage
} from '@src/components';
import * as routes from '@src/routes';

export const AppmixerIntegrationsLogsPage = () => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <ScrollingPage className="space-y-6">
        <Helmet>
          <title>{t('general.pageTitle.AppmixerIntegrationsLogsPage')}</title>
        </Helmet>
        <HeaderWithBreadcrumbs
          header={t('general.common.logs')}
          subheader={t('components.appmixer.AppmixerIntegrationsLogsPage.subtitle')}
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem to={routes.INTEGRATIONS} label={t('general.common.integrations')} />
              <BreadcrumbItem to={window.location.pathname} label={t('general.common.logs')} />
            </Breadcrumb>
          }
        />
        <AppmixerProvider>
          <AppmixerIntegrationsLogs />
        </AppmixerProvider>
      </ScrollingPage>
    </HelmetProvider>
  );
};
