import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { VideoDataErrorCode } from '@src/models';

export const VideoDataErrorBadge = ({ code }: { code: VideoDataErrorCode }) => {
  const { t } = useTranslation();

  return (
    <span
      className={classNames(
        'ml-auto flex items-center rounded-md px-1.5 py-0.5 text-xs ring-1 ring-inset',
        code === VideoDataErrorCode.SUMMARY_FAILED && 'bg-red-50 text-red-700 ring-red-600/10'
      )}
    >
      {t('components.article.ArticleVideo.code', {
        context: code
      })}
    </span>
  );
};
